import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from '@tg/core/components';

const Avatar = ({
  user,
  size,
  email,
  subHeading,
  showName,
  header,
  roleValue,
}) => {
  const getName = () => {
    let sub = roleValue || user.job_title || user.email;
    if (subHeading) sub = subHeading;
    return (
      <div className='ml-3.5 min-w-0 leading-none'>
        <div
          className={`whitespace-nowrap font-medium text-sm ${
            header ? 'text-gray-200' : 'text-gray-900'
          } truncate`}
        >
          {email ? user.email : user.full_name}
        </div>
        {sub && (
          <div
            className={`truncate text-sm ${
              header ? 'text-gray-200' : 'text-gray-500'
            }`}
          >
            {sub}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='flex items-center'>
      <Tooltip content={user.email}>
        <div
          className={classNames(
            'font-bold text-dark-blue bg-gray-200 rounded-full flex items-center justify-center uppercase flex-shrink-0',
            {
              'text-base w-8.5 h-8.5': size === 'small',
              'text-lg w-12 h-12': size === 'medium',
            },
          )}
        >
          {user.initials}
        </div>
      </Tooltip>
      {showName && getName()}
    </div>
  );
};

Avatar.propTypes = {
  user: PropTypes.shape({
    imageUrl: PropTypes.string,
    initials: PropTypes.string,
    email: PropTypes.string,
    full_name: PropTypes.string,
    job_title: PropTypes.string,
  }),
  size: PropTypes.oneOf(['small', 'medium']),
  email: PropTypes.bool,
  showName: PropTypes.bool,
  header: PropTypes.bool,
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  roleValue: PropTypes.string,
};

Avatar.defaultProps = {
  user: {},
  size: 'medium',
  email: false,
  showName: true,
  header: false,
  subHeading: null,
  roleValue: null,
};

export default Avatar;
