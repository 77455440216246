import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import emptySet from '@tg/core/assets/images/no-payslips.svg';
import { Heading } from '@tg/core/components';

const EmptySet = ({ heading, description, image }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        max-width: 500px;
        margin: auto;
        text-align: center;
        font-size: 20px;
        line-height: 1.5;
      `}
    >
      <div
        css={css`
          margin: 0 auto 20px;
        `}
      >
        <img src={image || emptySet} alt='' height='138px' width='138px' />
      </div>
      {heading && <Heading level='h2'>{heading}</Heading>}
      {description && <span>{description}</span>}
    </div>
  );
};

EmptySet.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

EmptySet.defaultProps = {
  heading: '',
  description: '',
  image: null,
};

export default EmptySet;
