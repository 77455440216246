import React from 'react';
import PropTypes from 'prop-types';

const LabelledInput = ({ label, id, children, error, required }) => {
  return (
    <div>
      <label
        htmlFor={id}
        className='block text-sm text-gray-800 tracking-wide font-bold leading-none mb-1'
      >
        {label}
        {required && <span className='text-red'>*</span>}
      </label>
      {React.cloneElement(children, { id })}
      <div className='text-sm'>{error}</div>
    </div>
  );
};

LabelledInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  required: PropTypes.string,
};

LabelledInput.defaultProps = {
  error: null,
  required: false,
};

export default LabelledInput;
