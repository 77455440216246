import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { requestWithDispatch } from '@tg/core/utils/requestHelpers';
import { transformCollectionResponse } from '@tg/core/utils/dataTransformers';
import { formatLocaleDate, formatYMD } from '@tg/core/utils/datetimeHelpers';
import { formatGrossSalary } from '@tg/core/utils/stringHelpers';
import { Button, List, PaginationControl } from '@tg/core/components';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useResource } from '@tg/core/hooks';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import permissions from '@tg/core/constant';
import Form from './Form';

const initialState = {
  fetching: false,
  data: null,
  error: null,
  ui: {
    create: false,
    update: null,
  },
};

const {
  EMPLOYER_DETAIL_VIEW_EMPLOYEES_CONTRACT: {
    OVERVIEW: { SALARY_HISTORY_EDIT, SALARY_HISTORY_CREATE_NEW },
  },
  EMPLOYER_DETAIL_VIEW_EMPLOYEE: {
    CONTRACT_OVERVIEW: { SALARY_HISTORY_VERIFY },
  },
} = permissions;

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'FETCH_BEGIN':
    case 'CREATE_BEGIN':
    case 'UPDATE_BEGIN':
    case 'DELETE_BEGIN':
      return {
        ...state,
        fetching: true,
      };

    case 'FETCH_END':
    case 'CREATE_END':
    case 'UPDATE_END':
    case 'DELETE_END':
      return {
        ...state,
        fetching: false,
      };

    case 'FETCH_SUCCESS':
    case 'CREATE_SUCCESS':
    case 'UPDATE_SUCCESS':
    case 'DELETE_SUCCESS':
      return {
        ...state,
        data: transformCollectionResponse(payload.data),
        ui: initialState.ui,
      };

    case 'FETCH_FAILURE':
    case 'CREATE_FAILURE':
    case 'UPDATE_FAILURE':
    case 'DELETE_FAILURE':
      return {
        ...state,
        error: payload.data,
      };

    case 'CREATE':
      return {
        ...state,
        ui: {
          ...state.ui,
          create: true,
        },
      };

    case 'UPDATE':
      return {
        ...state,
        ui: {
          ...state.ui,
          update: payload,
        },
      };

    case 'CANCEL':
      return {
        ...state,
        ui: initialState,
      };

    default:
      return state;
  }
};

const ContractSalaries = ({ currentSalary, getResource }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [confirm, setConfirm] = useState(false);
  const [page, setPage] = useState(1);
  const { t } = useTranslation(['salary_history']);
  const accesslist = useSelector(data => data?.access?.roles);
  const token = useSelector(s => s.session.aut);
  const call = requestWithDispatch(dispatch, token);
  const [getId, setId] = useState();

  const { contractId } = useParams();

  const { pagination } = useResource(
    {
      url: `contracts/${contractId}/salaries?page=${page}&pagesize=10`,
    },
    true,
  );

  useEffect(() => {
    if (page) {
      call({
        url: `contracts/${contractId}/salaries?page=${page}&pagesize=10`,
        action: 'fetch',
      });
    }
  }, [page]);

  const onCreateSubmit = ({ effective_date, ...rest }) => {
    call({
      url: `contracts/${contractId}/salaries`,
      action: 'create',
      method: 'post',
      data: { salary: { effective_date: formatYMD(effective_date), ...rest } },
    }).then(() => setPage(1));
  };
  const onUpdateSubmit = ({ effective_date, ...rest }) => {
    const id = state.ui.update;
    call({
      url: `contracts/${contractId}/salaries/${id}`,
      action: 'update',
      method: 'patch',
      data: { salary: { effective_date: formatYMD(effective_date), ...rest } },
    }).then(() => setPage(1));
  };

  const onAprroveSubmit = id => {
    call({
      url: `contracts/${contractId}/salaries/${id}/approve`,
      action: 'update',
      method: 'patch',
    }).then(() => {
      setPage(1);
      getResource();
    });
  };

  const onCancel = () => dispatch({ type: 'CANCEL' });

  const listData = state?.data?.byId ? Object.values(state?.data?.byId) : [];

  const selectEditableData = () => {
    return state.data.byId[state.ui.update];
  };

  const handleConfirm = event => {
    if (event.target.textContent === 'Yes') {
      dispatch({ type: 'UPDATE', payload: getId });
    }
  };

  return (
    <>
      {state.ui.create && (
        <Form onSubmit={onCreateSubmit} onCancel={onCancel} />
      )}
      {state.ui.update && (
        <Form
          onSubmit={onUpdateSubmit}
          onCancel={onCancel}
          data={selectEditableData}
        />
      )}

      {/* <Heading level='h3'>Salary details</Heading> */}

      {/* <div className='mb-8'>
        <div className='font-gray-600'>Current salary:</div>
        <div className='text-xl font-semibold'>
          {currentSalary.currency.code}{' '}
          {formatSalary(currentSalary.gross_salary)}
        </div>
      </div> */}

      {/* <Heading level='h5'>Salary history</Heading> */}

      <div className='mb-4'>
        <List
          headings={[
            { text: 'Gross salary', width: '275px' },
            { text: 'Effective from' },
            { text: '', align: 'right' },
          ]}
          items={listData.map(
            (
              { id, gross_salary, effective_date, currency, editable, approve },
              index,
            ) => {
              return {
                id,
                columns: [
                  <span className='break-words'>{`${
                    currency.code
                  } ${formatGrossSalary(gross_salary)}`}</span>,
                  <span>{formatLocaleDate(effective_date)}</span>,
                  <span className='space-x-2'>
                    {getPermission(accesslist, SALARY_HISTORY_VERIFY) && (
                      <>
                        {id !== currentSalary.id && approve === false ? (
                          <Button
                            color='quaternary'
                            onClick={() => onAprroveSubmit(id)}
                          >
                            {t(`salary_history:verify`)}
                          </Button>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                    {id === currentSalary.id && (
                      <span className='font-semibold'>
                        {' '}
                        {t(`salary_history:current`)}
                      </span>
                    )}
                    {(editable || index === 0) && (
                      <>
                        {getPermission(accesslist, SALARY_HISTORY_EDIT) && (
                          <Button
                            size='small'
                            color='tertiary'
                            onClick={() => {
                              setConfirm(true);
                              setId(id);
                            }}
                          >
                            Edit
                          </Button>
                        )}
                        <Modal
                          open={confirm}
                          header={t(`salary_history:salary_history`)}
                          content={t(`salary_history:confirm`)}
                          actions={[
                            { key: 'No', content: 'No', negative: true },
                            { key: 'Yes', content: 'Yes', positive: true },
                          ]}
                          onActionClick={event => {
                            handleConfirm(event);
                          }}
                          size='mini'
                          onClose={() => setConfirm(false)}
                        />
                      </>
                    )}
                  </span>,
                ],
              };
            },
          )}
        />
      </div>

      {getPermission(accesslist, SALARY_HISTORY_CREATE_NEW) && (
        <Button onClick={() => dispatch({ type: 'CREATE' })}>Create new</Button>
      )}
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};

ContractSalaries.propTypes = {
  getResource: PropTypes.func.isRequired,
  currentSalary: PropTypes.shape({
    id: PropTypes.string,
    gross_salary: PropTypes.string,
    currency: PropTypes.shape({
      code: PropTypes.string,
    }),
  }).isRequired,
};

export default ContractSalaries;
