import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResource } from '@tg/core/hooks';
import {
  Heading,
  Segment,
  List,
  Button,
  PaginationControl,
} from '@tg/core/components';
import { formatDate } from '@tg/core/utils/datetimeHelpers';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import permissions from '@tg/core/constant';
import { useSelector } from 'react-redux';
import EmailRecipientsModal from '../components/EmailRecipientsModal';

interface Props {
  employerId: string;
}

const {
  EMPLOYER_DETAIL_VIEW: {
    PAYROLL: {
      PAYROLL_LIST_VIEW,
      EDIT_EMAIL_RECIPIENTS,
      DETAILED_MONTH_YEAR_VIEW,
    },
  },
} = permissions;

const EmployerPayroll: FC<Props> = ({ employerId }) => {
  const { t } = useTranslation('datetime');

  const [modalOpen, setModalOpen] = useState(false);
  const accesslist = useSelector(state => state?.access?.roles);

  const [page, setPage] = useState(1);
  const { data, isFetching, pagination } = useResource(
    {
      url: `/payroll_adjustments/${employerId}?page=${page}`,
    },
    true,
  );

  return (
    <>
      <Heading level='h3'>Payroll</Heading>
      {getPermission(accesslist, EDIT_EMAIL_RECIPIENTS) && (
        <EmailRecipientsModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          employerId={employerId}
        />
      )}
      <Button type='button' onClick={() => setModalOpen(true)} color='tertiary'>
        Edit Email Recipients
      </Button>
      {data && !!data.length && (
        <>
          {getPermission(accesslist, PAYROLL_LIST_VIEW) && (
            <List
              headings={[
                { text: 'Date' },
                { text: 'Submitted' },
                { text: 'Completed' },
              ]}
              items={data.map(
                ({ id, month, year, submitted_at, locked_at }) => ({
                  id,
                  to:
                    getPermission(accesslist, DETAILED_MONTH_YEAR_VIEW) &&
                    `payroll/${id}`,
                  columns: [
                    `${t(`dateTime:months.${month}.full`)} ${year}`,
                    submitted_at ? formatDate({ dateStr: submitted_at }) : '',
                    locked_at ? formatDate({ dateStr: locked_at }) : '',
                  ],
                }),
              )}
            />
          )}
          {pagination && (
            <div className='mb-6'>
              <PaginationControl {...pagination} onPageChange={setPage} />
            </div>
          )}
        </>
      )}

      {!isFetching && data && !data.length && (
        <Segment>No Payroll Months Found</Segment>
      )}
    </>
  );
};

export default EmployerPayroll;
