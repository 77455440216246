import * as defaultTheme from '@tg/core/styles/theme';

export const colors = {
  ...defaultTheme.colors,
};

export const shadow = {
  ...defaultTheme.shadow,
  default: '0 1px 2px 0 rgba(34,36,38,.15)',
};

export const radius = {
  ...defaultTheme.radius,
  default: '4px',
  button: '4px',
};
