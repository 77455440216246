import React, { FC } from 'react';
import { Button, ButtonBar, Form, Segment } from '@tg/core/components';
import { useResource } from '@tg/core/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@tg/core/store/modules/toasts';
import { useTranslation } from 'react-i18next';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import { YearOptions } from '@tg/core/utils/datetimeHelpers';

interface FormData {
  year: number;
  month: number;
}

const {
  PAYROLL: { CREATE_PAYROLL },
} = permissions;

const AddPayrollSheet: FC = () => {
  const { t } = useTranslation(['forms', 'datetime']);
  const accesslist = useSelector(state => state?.access?.roles);
  const dispatch = useDispatch();

  const { postResource, isFetching, errors } = useResource({
    url: `payroll_adjustments/`,
  });

  const { handleSubmit, getFieldProps } = Form.useForm<FormData>();

  const onSubmit = (values: { create_payroll: FormData }) => {
    postResource({
      formData: { ...values.create_payroll },
      onSuccess: () => {
        dispatch(
          actions.addToast({
            id: 'payroll_created',
            type: 'success',
            title: 'Created',
            children: 'Payroll Sheet created for all employers',
          }),
        );
      },
      onError: error => {
        dispatch(
          actions.addToast({
            id: 'payroll_creation_fail',
            type: 'error',
            children: error.response.error,
          }),
        );
      },
    });
  };
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      errors={errors}
      // isFetching={isFetching}
    >
      <div className='p-4 -mt-6'>
        <p className='mb-4'>
          Create a new payroll input sheet for all employers
        </p>
        <div className='flex gap-4'>
          <div className='w-80'>
            <Form.SelectField
              {...getFieldProps({ id: 'create_payroll', name: 'month' })}
              required
              defaultValue={new Date().getMonth()}
              options={Array.from(new Array(12)).map((_, index) => ({
                text: t(`datetime:monthnames.${index}`),
                value: index,
              }))}
            />
          </div>
          <div className='w-80'>
            <Form.SelectField
              {...getFieldProps({ id: 'create_payroll', name: 'year' })}
              required
              defaultValue={new Date().getFullYear()}
              options={YearOptions}
            />
          </div>
        </div>
      </div>

      {getPermission(accesslist, CREATE_PAYROLL) && (
        <ButtonBar>
          <Button type='submit' loading={isFetching}>
            Create
          </Button>
        </ButtonBar>
      )}
    </Form>
  );
};

export default AddPayrollSheet;
