/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@tg/core/store/modules/toasts';
import { FormBuilder } from '@tg/core/components';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default ({ data, patchResource, errors, isFetching, setIsVisible }) => {
  const dispatch = useDispatch();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [formDatas, setFormData] = useState(null);
  const { t } = useTranslation(['time_off']);

  const onSubmit = formData => {
    setFormData(formData);
    setShowConfirmationPopup(true);
  };

  const handleConfirmation = () => {
    patchResource({
      formData: formDatas,
      onSuccess: newData => {
        setIsVisible(false);
        if (newData?.meta?.domicile) {
          dispatch(
            actions.addToast({
              id: 'clear_error',
              type: 'error',
              children: newData?.meta?.domicile,
              time: 2000,
            }),
          );
          setTimeout(() => window.location.reload(), 2000);
        } else {
          dispatch(
            actions.addToast({
              id: newData.id,
              type: 'success',
              children: 'Saved',
              time: 2000,
            }),
          );
          setTimeout(() => window.location.reload(), 2000);
        }
      },
      onError: err => {
        setTimeout(() => window.location.reload(), 2000);
        dispatch(
          actions.addToast({
            id: 'clear_error',
            type: 'error',
            children: err?.response?.error,
            time: 2000,
          }),
        );
      },
    });
  };

  if (data) {
    const {
      user: { first_name, last_name, email, phone },
      ...rest
    } = data;

    return showConfirmationPopup ? (
      <Modal
        open={showConfirmationPopup}
        header={t('confirm_popup.notif_heading')}
        content={t('confirm_popup.continue_question')}
        actions={['OK']}
        size='mini'
        onActionClick={handleConfirmation}
        onClose={() => setShowConfirmationPopup(false)}
      />
    ) : (
      <FormBuilder
        onSubmit={onSubmit}
        isFetching={isFetching}
        errors={errors}
        setIsVisible={setIsVisible}
        values={{
          employee: {
            first_name,
            last_name,
            email,
            phone,
            ...rest,
          },
        }}
        fieldsets={[
          {
            id: 'employee',
            fieldset: 'employee',
            // title: 'Personal details',
          },
        ]}
        submitButtonText='Save changes'
      />
    );
  }

  return null;
};
