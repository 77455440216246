import axios from 'axios';

const { ROOT_API } = process.env;

export const checkCanDelete = async (
  leave_type_id,
  deleteValue,
  type,
  token,
) => {
  let retValue = null;

  if (leave_type_id && deleteValue && type) {
    let validate;
    if (type === 'country') {
      validate = 'validate_country';
    } else if (type === 'emp') {
      validate = 'validate_employer';
    }
    const option = {
      method: 'get',
      url: `${ROOT_API}/leave_types/${validate}/${leave_type_id}/${deleteValue}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(option);
      retValue = response?.data.json?.can_delete;
    } catch (error) {
      const { response } = error;
      const { request, ...errorObject } = response;
      retValue = errorObject;
    }
  }
  return retValue;
};

export const iff = (condition, then, otherwise) =>
  condition ? then : otherwise;
