import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const CustomTextField = ({
  required,
  name,
  errors,
  id,
  disabled,
  defaultValue,
  translationKey,
  register,
  autoComplete,
}) => {
  const { t } = useTranslation('forms');
  const [isLabelDisabled, setLabelDisabled] = useState(true);

  const placeholder = t(`placeholders.${translationKey}`);
  const fieldId = `${id}.${name}`;

  let errorMessage = '';
  if (errors) {
    if (errors.message.key) {
      errorMessage = t(`validations.${errors.message.key}`);
    } else if (errors.type === 'required') {
      errorMessage = t(`validations.field_required`);
    }
  }

  useEffect(() => {
    window.addEventListener('click', e => {
      if (
        e.target.id === 'overview_custom_icon' ||
        (e.target.id === 'contract_defaults.custom_label' &&
          document.activeElement ===
            document.getElementById('contract_defaults.custom_label')) ||
        (e.target.id === 'custom_label_div' &&
          document.activeElement ===
            document.getElementById('custom_label_div'))
      ) {
        setLabelDisabled(false);
        document.getElementById(fieldId).focus();
      } else {
        setLabelDisabled(true);
      }
    });
  }, []);

  return (
    <Form.Field required={required && !disabled} error={!!errors}>
      <div className='flex' id='custom_label_div'>
        <input
          id={fieldId}
          name={fieldId}
          type='text'
          className='font-bold text-sm '
          placeholder={placeholder}
          defaultValue={defaultValue}
          disabled={disabled || isLabelDisabled}
          required={required && !disabled}
          ref={register({ required })}
          style={{
            border: 'none',
            maxHeight: '20px',
            paddingLeft: '2px',
            color: 'rgba(0,0,0,.87)',
            fontSize: '.92857143em',
            fontFamily: 'inherit',
            marginBottom: '0.21rem',
            opacity: 1,
          }}
          autoComplete={autoComplete}
        />
        <div aria-hidden='true' style={{ cursor: 'pointer' }}>
          <Icon name='edit outline' id='overview_custom_icon' />
        </div>
      </div>
      {errors && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </Form.Field>
  );
};

CustomTextField.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  id: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  translationKey: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  autoComplete: PropTypes.string,
};

CustomTextField.defaultProps = {
  errors: null,
  defaultValue: '',
  autoComplete: null,
  required: false,
  disabled: false,
};

export default CustomTextField;
