import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

const SelectField = ({ multiple, options, placeholder, onChange, ...rest }) => {
  return (
    <Dropdown
      placeholder={placeholder}
      multiple={multiple}
      selection
      onChange={(_, { value }) => {
        onChange(value);
      }}
      options={options}
      {...rest}
    />
  );
};

SelectField.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  multiple: PropTypes.bool,
};

SelectField.defaultProps = {
  onChange: f => f,
  placeholder: '',
  options: [],
  multiple: false,
};

export default SelectField;
