import { React, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SelectField, Button, LabelledInput } from '@tg/core/components';
import { Form, Radio, Loader, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useResource } from '@tg/core/hooks';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions } from '@tg/core/store/modules/toasts';
import { iff } from 'src/routes/Manage/components/editModalHelper';
import {
  validatePreviousDayNextYear,
  monthNames,
  daysOfMonth,
  attachZero,
  getEndDate,
} from '@tg/core/utils/holidayYearHelper';

export const HolidayYearForm = () => {
  const { t } = useTranslation(['forms', 'datetime', 'time_off']);
  const [applicableTo, setApplicableTo] = useState('');
  const [dataHolidayYear, setDataHolidayYear] = useState(null);
  const [dateValues, setDateValues] = useState(null);
  const { control, handleSubmit } = useForm();
  const [dateError, setDateError] = useState({});
  const [loading, setLoading] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const { employerId } = useParams();
  const dispatch = useDispatch();
  const { data } = useResource(
    {
      url: `employers/${employerId}/holiday_years`,
    },
    true,
  );
  const { patchResource: patchHolidayYear } = useResource(
    {
      url: `employers/${employerId}/update_holiday_years`,
    },
    true,
  );
  const getSplittedDate = dates => {
    return {
      startDay: dates.start_date.split('/')[0],
      startMonth: dates.start_date.split('/')[1],
      endDay: dates.end_date.split('/')[0],
      endMonth: dates.end_date.split('/')[1],
    };
  };
  const allAreEqual = countryDates => {
    const result = countryDates.every(element => {
      let boolValue = false;
      if (element.start_date === countryDates[0].start_date) {
        boolValue = true;
      }
      return boolValue;
    });
    return result;
  };

  const setDataFromResponse = responseData => {
    const defaultData = responseData.filter(item => {
      return item.all_countries;
    });
    const countryDates = responseData.filter(item => {
      return !item.all_countries;
    });
    const defaultDate = { ...getSplittedDate(defaultData[0]) };

    const { startDay, startMonth, endDay, endMonth } = defaultDate;
    const defValuesCopy = {
      defaultDate: {
        startDate: {
          startDay,
          startMonth: monthNames[Number(startMonth) - 1],
        },
        endDate: {
          endDay,
          endMonth: monthNames[Number(endMonth) - 1],
        },
        id: defaultData[0].id,
      },
      countryWiseDate: [],
    };
    if (allAreEqual(countryDates) === true) {
      setApplicableTo('all');
    } else {
      setApplicableTo('custom');
    }
    countryDates.forEach(item => {
      const {
        startDay: startDayCopy,
        startMonth: startMonthCopy,
        endDay: endDayCopy,
        endMonth: endMonthCopy,
      } = {
        ...getSplittedDate(item),
      };
      defValuesCopy.countryWiseDate.push({
        id: item.id,
        startDate: {
          startDay: startDayCopy,
          startMonth: monthNames[Number(startMonthCopy) - 1],
        },
        endDate: {
          endDay: endDayCopy,
          endMonth: monthNames[Number(endMonthCopy) - 1],
        },
        employerId: item.employer_id,
        allCountries: item.all_countries,
        country: { ...item.country },
      });
    });
    setDateValues(defValuesCopy);
    setDataHolidayYear(defValuesCopy.countryWiseDate);
  };

  useEffect(() => {
    if (data?.length) {
      setDataFromResponse(data);
    }
  }, [data]);

  const monthNumWithPrefix = monthNum => {
    let retValue;
    if (monthNum >= 1 && monthNum <= 9) {
      retValue = `0${monthNum}`;
    } else {
      retValue = monthNum;
    }
    return retValue;
  };

  const onSubmit = event => {
    if (event.target.textContent === 'OK') {
      const values = Object.values(dateError);
      if (!values.some(val => val !== null)) {
        setLoading(true);
        if (applicableTo === 'all') {
          const startDt = `${
            dateValues.defaultDate.startDate.startDay
          }/${monthNumWithPrefix(
            monthNames.indexOf(dateValues.defaultDate.startDate.startMonth) + 1,
          )}`;
          const endDate = `${
            dateValues.defaultDate.endDate.endDay
          }/${monthNumWithPrefix(
            monthNames.indexOf(dateValues.defaultDate.endDate.endMonth) + 1,
          )}`;

          patchHolidayYear({
            formData: {
              holiday_year: [
                {
                  id: dateValues.defaultDate.id,
                  start_date: startDt,
                  end_date: endDate,
                  all_countries: true,
                  employer_id: employerId,
                },
              ],
            },
            onSuccess: response => {
              setDataFromResponse(response.json.data);
              setLoading(false);
              dispatch(
                actions.addToast({
                  id: 'holiday_year_updated',
                  type: 'success',
                  children: response.json.message,
                }),
              );
            },
            onError: () => {
              setLoading(false);
            },
          });
        } else {
          patchHolidayYear({
            formData: {
              holiday_year: dataHolidayYear.map(item => {
                const startDate = `${
                  item.startDate.startDay
                }/${monthNumWithPrefix(
                  monthNames.indexOf(item.startDate.startMonth) + 1,
                )}`;
                const endDate = `${item.endDate.endDay}/${monthNumWithPrefix(
                  monthNames.indexOf(item.endDate.endMonth) + 1,
                )}`;
                return {
                  id: item.id,
                  start_date: startDate,
                  end_date: endDate,
                  employer_id: item.employerId,
                  all_countries: false,
                  country_id: item.country.id,
                };
              }),
            },
            onSuccess: response => {
              setDataFromResponse(response.json.data);
              setLoading(false);
              setShowConfirmationPopup(false);
              dispatch(
                actions.addToast({
                  id: 'holiday_year_updated',
                  type: 'success',
                  children: response.json.message,
                }),
              );
            },
            onError: () => {
              setLoading(false);
              setShowConfirmationPopup(false);
            },
          });
        }
      }
    } else {
      setShowConfirmationPopup(false);
    }
  };

  const handleCheck = (e, { value }) => {
    setApplicableTo(value);
  };
  const validateDate = (start, end, id) => {
    const err = JSON.parse(JSON.stringify(dateError));
    const retValue = validatePreviousDayNextYear(
      start.startDay,
      start.startMonth,
      end,
    );
    if (retValue) {
      err[id] = null;
    } else {
      err[id] = t('forms:validations.date_error');
    }

    setDateError(err);
  };
  const getDefaultCountryDate = (defaultOrCountry, startEnd, dayMonth, v) => {
    if (defaultOrCountry === 'default') {
      const { defaultDate } = dateValues;
      let {
        startDate: { startDay, startMonth },
        endDate: { endDay, endMonth },
      } = defaultDate;

      let defaultDateCopy = JSON.parse(JSON.stringify(defaultDate));
      if (startEnd === 'start') {
        if (dayMonth === 'day') {
          startDay = v;
        } else {
          startMonth = v;
        }

        const { endDayCopy, endMonthCopy } = getEndDate(startDay, startMonth);
        defaultDateCopy = {
          ...defaultDateCopy,
          startDate: { startDay, startMonth },
          endDate: {
            endDay: endDayCopy > 9 ? `${endDayCopy}` : `0${endDayCopy}`,
            endMonth: endMonthCopy,
          },
        };
      } else {
        if (dayMonth === 'day') {
          endDay = v;
        } else {
          endMonth = v;
        }
        defaultDateCopy = {
          ...defaultDateCopy,
          endDate: { endDay, endMonth },
        };
      }
      let dateValuesCopy = JSON.parse(JSON.stringify(dateValues));
      dateValuesCopy = {
        defaultDate: defaultDateCopy,
      };

      setDateValues(dateValuesCopy);
      validateDate(
        dateValuesCopy.defaultDate.startDate,
        dateValuesCopy.defaultDate.endDate,
        0,
      );
    } else {
      const dataHolidayYearCopy = dataHolidayYear.map(item => {
        const { startDate, endDate } = item;
        let startDayCopy = startDate?.startDay;
        let startMonthCopy = startDate?.startMonth;
        let endDayCopy = endDate?.endDay;
        let endMonthCopy = endDate?.endMonth;
        if (item.country.id === defaultOrCountry) {
          if (startEnd === 'start') {
            if (dayMonth === 'day') {
              startDayCopy = v;
            } else {
              startMonthCopy = v;
            }
            const {
              endDayCopy: eDayCopy,
              endMonthCopy: eMonthCopy,
            } = getEndDate(startDayCopy, startMonthCopy);
            // eslint-disable-next-line no-param-reassign
            item = {
              ...item,
              startDate: { startDay: startDayCopy, startMonth: startMonthCopy },
              endDate: {
                endDay: eDayCopy > 9 ? `${eDayCopy}` : `0${eDayCopy}`,
                endMonth: eMonthCopy,
              },
            };
          } else {
            if (dayMonth === 'day') {
              endDayCopy = v;
            } else {
              endMonthCopy = v;
            }
            // eslint-disable-next-line no-param-reassign
            item = {
              ...item,
              endDate: { endDay: endDayCopy, endMonth: endMonthCopy },
            };
          }
          validateDate(item.startDate, item.endDate, item.country.id);
        }
        return item;
      });
      setDataHolidayYear(dataHolidayYearCopy);
    }
  };

  const sm = dateValues?.defaultDate?.startDate?.startMonth;
  const em = dateValues?.defaultDate?.endDate?.endMonth;
  const startTotalDays = sm && Array.from(new Array(daysOfMonth[sm]));
  const endTotalDays = em && Array.from(new Array(daysOfMonth[em]));

  return iff(
    showConfirmationPopup,
    <Modal
      open={showConfirmationPopup}
      header={t('time_off:confirm_popup.heading')}
      content={t('time_off:confirm_popup.continue_question')}
      actions={[
        { key: 'Cancel', content: 'Cancel', negative: true },
        { key: 'OK', content: 'OK', positive: true },
      ]}
      onActionClick={event => {
        handleSubmit(onSubmit(event));
      }}
      size='mini'
      onClose={() => setShowConfirmationPopup(false)}
    />,
    iff(
      loading,
      <Loader>Loading</Loader>,
      <Form>
        {dateError && dateError[0] && (
          <div className='text-red'>{dateError[0]}</div>
        )}
        {applicableTo === 'all' && (
          <div className='flex gap-8 mb-3'>
            <Form.Group>
              <Form.Field required>
                <label htmlFor='startDate'>Start Date</label>
                <div className='flex gap-2 bg-gray-200 p-1'>
                  {startTotalDays &&
                    dateValues?.defaultDate?.startDate?.startDay && (
                      <SelectField
                        options={startTotalDays.map((_, index) => ({
                          text: attachZero(index),
                          value: attachZero(index),
                        }))}
                        style={{ minWidth: '20px' }}
                        required
                        control={control}
                        name='endDay'
                        value={dateValues?.defaultDate?.startDate?.startDay}
                        onChange={v =>
                          getDefaultCountryDate('default', 'start', 'day', v)
                        }
                      />
                    )}
                  {dateValues?.defaultDate?.startDate?.startMonth && (
                    <SelectField
                      options={Array.from(new Array(12)).map((_, index) => ({
                        text: monthNames[index],
                        value: monthNames[index],
                      }))}
                      style={{ minWidth: '20px' }}
                      required
                      control={control}
                      name='startMonth'
                      onChange={v =>
                        getDefaultCountryDate('default', 'start', 'month', v)
                      }
                      value={dateValues?.defaultDate?.startDate?.startMonth}
                    />
                  )}
                </div>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field required>
                <label htmlFor='endDate'>End Date</label>
                <div className='flex gap-2 bg-gray-200 p-1'>
                  {endTotalDays && dateValues?.defaultDate?.endDate?.endDay && (
                    <SelectField
                      options={endTotalDays.map((_, index) => ({
                        text: attachZero(index),
                        value: attachZero(index),
                      }))}
                      style={{ minWidth: '20px' }}
                      required
                      control={control}
                      name='endDay'
                      value={dateValues?.defaultDate?.endDate?.endDay}
                      onChange={v =>
                        getDefaultCountryDate('default', 'end', 'day', v)
                      }
                    />
                  )}
                  {dateValues?.defaultDate?.endDate?.endMonth && (
                    <SelectField
                      options={Array.from(new Array(12)).map((_, index) => ({
                        text: monthNames[index],
                        value: monthNames[index],
                      }))}
                      style={{ minWidth: '20px' }}
                      required
                      control={control}
                      name='endMonth'
                      value={dateValues?.defaultDate?.endDate?.endMonth}
                      onChange={v =>
                        getDefaultCountryDate('default', 'end', 'month', v)
                      }
                    />
                  )}
                </div>
              </Form.Field>
            </Form.Group>
          </div>
        )}
        <div className='mt-6'>
          <LabelledInput
            id='holiday-year'
            label='Applicable to'
            required='true'
          >
            <div className='flex gap-6 mt-2'>
              <Radio
                label='All'
                value='all'
                checked={applicableTo === 'all'}
                onChange={handleCheck}
                id='all_radio'
              />
              <Radio
                label='Custom'
                value='custom'
                checked={applicableTo === 'custom'}
                onChange={handleCheck}
                id='custom_radio'
              />
            </div>
          </LabelledInput>
        </div>
        {applicableTo === 'custom' && (
          <div className='mt-4'>
            {dataHolidayYear?.map(val => {
              const startCountryTotalDays = Array.from(
                new Array(daysOfMonth[val?.startDate?.startMonth]),
              );
              const endCountryTotaldays = Array.from(
                new Array(daysOfMonth[val?.endDate?.endMonth]),
              );
              return (
                <div key={val.id}>
                  <div className='flex gap-8 mt-3'>
                    <div className='flex items-center gap-2 w-60'>
                      <img src={val.country.flag} alt='' className='w-6' />
                      <dd className='ml-0 mt-1 mr-4 font-medium'>
                        {val.country.name}
                      </dd>
                    </div>
                    <div className='flex gap-2'>
                      <Form.Group>
                        <Form.Field required>
                          <label htmlFor='startDate'>Start Date</label>
                          <div className='flex gap-2 bg-gray-200 p-1'>
                            {val?.startDate?.startDay && (
                              <SelectField
                                options={startCountryTotalDays.map(
                                  (_, index) => ({
                                    text: attachZero(index),
                                    value: attachZero(index),
                                  }),
                                )}
                                style={{ minWidth: '20px' }}
                                required
                                control={control}
                                name='startDay'
                                onChange={v =>
                                  getDefaultCountryDate(
                                    val.country.id,
                                    'start',
                                    'day',
                                    v,
                                  )
                                }
                                value={val?.startDate?.startDay}
                              />
                            )}
                            {val?.startDate?.startMonth && (
                              <SelectField
                                options={Array.from(new Array(12)).map(
                                  (_, index) => ({
                                    text: monthNames[index],
                                    value: monthNames[index],
                                  }),
                                )}
                                style={{ minWidth: '20px' }}
                                required
                                control={control}
                                name='startMonth'
                                onChange={v =>
                                  getDefaultCountryDate(
                                    val.country.id,
                                    'start',
                                    'month',
                                    v,
                                  )
                                }
                                value={val?.startDate?.startMonth}
                              />
                            )}
                          </div>
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field required>
                          <label htmlFor='endDate'>End Date</label>
                          <div className='flex gap-2 bg-gray-200 p-1'>
                            {val?.endDate?.endDay && (
                              <SelectField
                                options={endCountryTotaldays.map(
                                  (_, index) => ({
                                    text: attachZero(index),
                                    value: attachZero(index),
                                  }),
                                )}
                                style={{ minWidth: '20px' }}
                                required
                                control={control}
                                name='endDay'
                                value={val?.endDate?.endDay}
                                onChange={v =>
                                  getDefaultCountryDate(
                                    val.country.id,
                                    'end',
                                    'day',
                                    v,
                                  )
                                }
                              />
                            )}
                            {val?.endDate?.endMonth && (
                              <SelectField
                                options={Array.from(new Array(12)).map(
                                  (_, index) => ({
                                    text: monthNames[index],
                                    value: monthNames[index],
                                  }),
                                )}
                                style={{ minWidth: '20px' }}
                                required
                                control={control}
                                name='endMonth'
                                value={val?.endDate?.endMonth}
                                onChange={v =>
                                  getDefaultCountryDate(
                                    val.country.id,
                                    'end',
                                    'month',
                                    v,
                                  )
                                }
                              />
                            )}
                          </div>
                        </Form.Field>
                      </Form.Group>
                    </div>
                  </div>
                  {dateError && dateError[val.country.id] && (
                    <div className='text-red'>{dateError[val.country.id]}</div>
                  )}
                </div>
              );
            })}
          </div>
        )}
        <div className='mt-10'>
          <Button
            type='button'
            loading={false}
            onClick={() => setShowConfirmationPopup(true)}
          >
            Save Changes
          </Button>
        </div>
      </Form>,
    ),
  );
};
