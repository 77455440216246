/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Form, Button, ButtonBar } from '@tg/core/components';
import { Radio } from 'semantic-ui-react';
import TextAreaField from '@tg/core/components/systems/Form/Fields/TextAreaField';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useResource } from '@tg/core/hooks';
import { checkCanDelete, iff } from './editModalHelper';

const LeaveAddEditModal = ({
  isFetching,
  postResource,
  patchResource,
  isOpen,
  onClose,
  defaultValues,
  mode = 'add',
  collection,
  colors,
  datas,
}) => {
  const { handleSubmit, getFieldProps, resetForm } = Form.useForm();
  const token = useSelector(s => s.session.aut);

  const { t } = useTranslation('time_off');
  const [color, setColor] = useState(null);
  const [isChecked, setChecked] = useState(
    // eslint-disable-next-line no-nested-ternary
    defaultValues ? (defaultValues.applicable_to ? 'all' : 'custom') : 'all',
  );
  const { is_planneryear_created = false } = defaultValues || {};
  const allowAllToCustom =
    mode === 'edit' && is_planneryear_created && defaultValues.applicable_to;

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedEmployers, setSelectedEmployers] = useState([]);
  const [employerOptions, setEmployerOptions] = useState([]);
  const [empOptions, setEmpOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState([null, null, null, null, null]);
  const [msg, setMsg] = useState([null, null]);
  const [allColors, setAllColors] = useState([]);
  const [employersResponse, setEmployersResponse] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    data: employersData,
    postResource: postCountries,
    isFetching: loadingEmployers,
  } = useResource({
    url: `leave_types/employers`,
  });

  const { postResource: callClearAllEmployersApi } = useResource({
    url: `leave_types/${defaultValues?.id}/validate_employers`,
  });

  const { getResource: callClearCountryApi } = useResource({
    url: `leave_types/validate_country/${defaultValues?.id}`,
  });

  useEffect(() => {
    if (selectedCountries?.length) {
      const countries_id = selectedCountries.map(country => `${country.value}`);
      const formData = new FormData();
      formData.append('countries_id', JSON.stringify(countries_id));
      postCountries({
        formData,
        headers: {},
      });
    } else if (selectedCountries && !selectedCountries.length) {
      setEmployersResponse([]);
      setEmployerOptions([]);
      setSelectedEmployers([]);
    }
  }, [selectedCountries?.length]);

  useEffect(() => {
    if (employersData?.length) {
      const empOptionsObjectCopy = employersData.map(employer => {
        const { employee_data } = employer;
        return {
          key: employer.id,
          id: employer.id,
          value: employer.id,
          label: employer.name,
          countryIds: employee_data.map(emp => emp.country_id),
        };
      });
      const empOptionsObject = iff(
        empOptionsObjectCopy.length,
        [
          {
            value: 'select_all',
            label: <b>Select all</b>,
          },
          ...empOptionsObjectCopy,
        ],
        [],
      );
      setEmployersResponse(employersData);
      setEmployerOptions(empOptionsObject);
    }
  }, [employersData]);

  useEffect(() => {
    if (selectedEmployers && employerOptions) {
      const leftOut = [];
      const selectedEmployersValues = selectedEmployers.map(
        selectedEmployer => selectedEmployer.value,
      );
      employerOptions.forEach(employerOption => {
        if (!selectedEmployersValues.includes(employerOption.value)) {
          leftOut.push(employerOption);
        }
      });
      if (
        employerOptions.length - selectedEmployers.length === 1 &&
        leftOut[0]?.value === 'select_all'
      ) {
        setEmpOptions([]);
      } else {
        setEmpOptions(employerOptions);
      }
    }
  }, [selectedEmployers, employerOptions]);

  useEffect(() => {
    if (defaultValues && mode === 'edit' && options) {
      const { leavetype_applicable_countries, leavetype_applicable_employers } =
        defaultValues;
      if (
        options?.length &&
        leavetype_applicable_countries?.length &&
        leavetype_applicable_employers?.length
      ) {
        const defaultCountries = options
          ?.filter(op => {
            return leavetype_applicable_countries.some(
              val => val.country_id === op.value,
            );
          })
          .map(op => {
            return { ...op, fromDefault: true };
          });
        setSelectedCountries(defaultCountries);
        const countries_id = defaultCountries.map(
          country => `${country.value}`,
        );
        const formData = new FormData();
        formData.append('countries_id', JSON.stringify(countries_id));
        postCountries({
          formData,
          headers: {},
          onSuccess: response => {
            const { data } = response;
            const empOptionsObjectCopy = data.map(employer => {
              const { employee_data } = employer;
              return {
                key: employer.id,
                id: employer.id,
                value: employer.id,
                label: employer.name,
                countryIds: employee_data.map(emp => emp.country_id),
              };
            });
            const empOptionsObject = iff(
              empOptionsObjectCopy.length,
              [
                {
                  value: 'select_all',
                  label: <b>Select all</b>,
                },
                ...empOptionsObjectCopy,
              ],
              [],
            );
            if (mode === 'edit') {
              const employersDefault = empOptionsObject
                .filter(employer => {
                  return leavetype_applicable_employers.some(
                    val => val.employer_id === employer.id,
                  );
                })
                .map(op => {
                  return { ...op, fromDefault: true };
                });
              setSelectedEmployers(employersDefault);
            }
            setEmployersResponse(data);
            setEmployerOptions(empOptionsObject);
          },
        });
      }
    }
  }, [defaultValues, options]);

  useEffect(() => {
    if (colors?.length) {
      setAllColors(
        colors.map(col => ({
          key: col.id,
          id: col.id,
          value: col.colour_code,
          isDisabled: col.used,
          label: (
            <div className='grid grid-cols-5'>
              <div
                className='rounded-sm h-7 w-7'
                style={{
                  backgroundColor: col.colour_code,
                }}
              />
              <div className='p-1.5 col-span-4'>{col.name}</div>
            </div>
          ),
        })),
      );
    }
  }, [colors]);

  useEffect(() => {
    if (defaultValues?.colour_id && allColors?.length) {
      const colorObj = allColors.find(
        colr => colr.id === defaultValues.colour_id,
      );
      setColor(colorObj);
    }
  }, [defaultValues?.colour_id, allColors]);

  useEffect(() => {
    if (collection) {
      const optionsObj = collection.map(country => {
        return {
          label: country.name,
          value: country.id,
          key: country.id,
        };
      });
      setOptions(optionsObj);
    }
  }, [collection]);

  const validateNoEmpFound = set => {
    const countryWithoutEmployer = selectedCountries.filter(
      country => !set.has(country.value),
    );
    return countryWithoutEmployer;
  };

  const validateCountry = () => {
    const errorObj = [...error];
    const allCurrentEmpsSet = new Set();
    employersResponse.forEach(emp =>
      emp.employee_data.forEach(empData =>
        allCurrentEmpsSet.add(empData.country_id),
      ),
    );
    if (validateNoEmpFound(allCurrentEmpsSet)?.length) {
      errorObj[1] = t('leave_types.no_emp_found');
    } else if (errorObj[1] !== t('leave_types.cannot_delete_country'))
      errorObj[1] = null;

    setError(errorObj);
    return errorObj[1];
  };

  useEffect(() => {
    if (!loadingEmployers) {
      validateCountry();
    }
  }, [employersResponse, loadingEmployers]);

  useEffect(() => {
    if (isChecked && isChecked === 'all') {
      setSelectedCountries([]);
      setSelectedEmployers([]);
      const errorObj = [...error];
      errorObj[1] = null;
      errorObj[2] = null;
      errorObj[3] = null;
      setError(errorObj);
    }
  }, [isChecked]);

  const hasAnyError = errorValue => {
    return errorValue.some(value => value !== null);
  };

  const validateForm = (
    leaveType,
    isCheckedObj,
    countryObj,
    empObj,
    colorObj,
  ) => {
    const errorObj = [...error];
    errorObj[0] = null;
    errorObj[1] = null;
    errorObj[3] = null;
    errorObj[4] = null;
    // check leavetype
    const format = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (!leaveType) {
      errorObj[0] = t('leave_types.cannot_be_empty');
      // eslint-disable-next-line no-restricted-globals
    }
    // check applicableto
    if (isCheckedObj === 'custom') {
      if (!(countryObj?.length && empObj?.length)) {
        errorObj[3] = t('leave_types.select_atleast_one_country_emp');
      }
    }
    if (!(colorObj?.value && colorObj?.id)) {
      errorObj[4] = t('leave_types.select_color_code');
    }
    errorObj[1] = validateCountry(countryObj);
    const allCurrentEmpsSet = new Set();
    selectedEmployers.forEach(emp =>
      emp.countryIds.forEach(countryId => allCurrentEmpsSet.add(countryId)),
    );
    if (validateNoEmpFound(allCurrentEmpsSet)?.length) {
      errorObj[1] = t('leave_types.no_emp_found_for_selected');
    } else if (errorObj[1] === t('leave_types.cannot_delete_country')) {
      errorObj[1] = null;
    }
    setError(errorObj);
    return errorObj;
  };

  const onSubmit = values => {
    const customError = validateForm(
      values.leave_types.event_type,
      isChecked,
      selectedCountries,
      selectedEmployers,
      color,
      defaultValues,
    );
    customError[2] = null;
    if (!hasAnyError(customError)) {
      const { event_type, description } = values.leave_types;

      const formData = {};
      formData.event_type = event_type;
      formData.colour_code = color.value;
      formData.colour_id = color.id;
      formData.description = description;
      formData.applicable_to = isChecked === 'all';
      formData.countries =
        isChecked === 'all'
          ? []
          : selectedCountries.map(country => country.value);
      formData.employers =
        isChecked === 'all' ? [] : selectedEmployers.map(obj => obj.value);
      if (mode === 'add') {
        postResource({
          formData,
          onSuccess: res => {
            if (res?.json?.errors) {
              setErrorMessage('Leave Type must be unique');
            } else {
              onClose();
            }
          },
          onError: setErrorMessage('Leave Type must be unique'),
        });
      }
      if (mode === 'edit') {
        patchResource({
          id: defaultValues.id,
          formData,
          onSuccess: res => {
            if (res?.json?.errors) {
              setErrorMessage('Leave Type must be unique');
            } else {
              onClose();
            }
          },
          onError: setErrorMessage('Leave Type must be unique'),
        });
      }
    }
  };

  const closeModal = () => {
    resetForm();
    onClose();
  };

  const handleCheck = (e, { value }) => {
    setChecked(value);
  };

  const deleteCountry = (countries, emps, removedValue) => {
    const empsWithCountryToDelete = emps.filter(emp => {
      const { countryIds } = emp;
      return countryIds.includes(removedValue.value);
    });
    let otherCountries = countries.filter(
      country => country.value !== removedValue.value,
    );
    otherCountries = otherCountries.map(otherCountry => otherCountry.value);
    let empsToDelete = [];
    empsWithCountryToDelete.forEach(empWithCountryToDelete => {
      const { countryIds } = empWithCountryToDelete;
      if (!countryIds.some(countryId => otherCountries.includes(countryId))) {
        empsToDelete.push(empWithCountryToDelete);
      }
    });
    empsToDelete = empsToDelete.map(empToDelete => empToDelete.value);
    const finalArr = emps.filter(emp => !empsToDelete.includes(emp.value));
    const filtered = countries.filter(
      countryObj => countryObj.value !== removedValue.value,
    );
    setSelectedCountries(filtered);
    setSelectedEmployers(finalArr);
  };

  const handleChangeCountries = async (allSelectedCountries, actionValue) => {
    const optionsObj = [...options];
    const msgObj = [...msg];
    const errorObj = [...error];

    if (actionValue.action === 'select-option') {
      const {
        option: { value },
      } = actionValue;
      const filtered = optionsObj.filter(
        countryObj => countryObj.value === value,
      );
      const countriesValue = [...selectedCountries, ...filtered];
      setSelectedCountries(countriesValue);
      msgObj[0] = null;
      msgObj[1] = null;
      errorObj[1] = null;
      errorObj[2] = null;
    } else if (actionValue.action === 'remove-value') {
      const { removedValue } = actionValue;
      if (mode === 'edit' && removedValue.fromDefault === true) {
        callClearCountryApi({
          id: removedValue.value,
          onSuccess: response => {
            const res = 'not_found';
            if (response.status === res) {
              errorObj[2] = response.json.message;
            } else {
              const { deleted_country, deleted_employers } = response?.json;
              let employersToDelete = [];
              let empsWithoutfromDefault = [];
              const otherCountries = selectedCountries.filter(
                selectedCountry =>
                  !(selectedCountry.value === removedValue.value),
              );
              const empsHavingCountryToBeCleared = selectedEmployers.filter(
                emp => emp.countryIds.includes(removedValue.value),
              );
              const savedEmps = empsHavingCountryToBeCleared.filter(
                emp => emp.fromDefault,
              );
              const unSavedEmps = empsHavingCountryToBeCleared.filter(
                emp => !emp.fromDefault,
              );

              savedEmps.forEach(savedEmp => {
                const { countryIds } = savedEmp;
                if (deleted_employers.includes(savedEmp.value)) {
                  if (
                    !countryIds.some(countryId =>
                      otherCountries.includes(countryId),
                    )
                  ) {
                    employersToDelete.push(savedEmp);
                  } else {
                    // eslint-disable-next-line no-param-reassign
                    delete savedEmp.fromDefault;
                    empsWithoutfromDefault.push(savedEmp);
                  }
                }
              });

              unSavedEmps.forEach(unsavedEmp => {
                const { countryIds } = unsavedEmp;
                if (
                  !countryIds.some(countryId =>
                    otherCountries.includes(countryId),
                  )
                ) {
                  employersToDelete.push(unsavedEmp);
                }
              });
              employersToDelete = employersToDelete.map(
                employerToDelete => employerToDelete.value,
              );

              empsWithoutfromDefault = empsWithoutfromDefault.map(
                empWithoutfromDefault => empWithoutfromDefault.value,
              );
              let finalArr = selectedEmployers.filter(
                selectedEmployer =>
                  !employersToDelete.includes(selectedEmployer.value),
              );
              finalArr = finalArr.map(selEmployer => {
                if (empsWithoutfromDefault.includes(selEmployer.value)) {
                  // eslint-disable-next-line no-param-reassign
                  delete selEmployer.fromDefault;
                }
                return selEmployer;
              });
              const countryNameArr = selectedCountries
                .filter(selectedCountry =>
                  deleted_country.includes(selectedCountry.value),
                )
                .map(i => i.label);
              if (deleted_country?.length) {
                msgObj[0] = `${t(
                  'leave_types.country_deleted',
                )}${countryNameArr.join(',')} ${t('leave_types.got_deleted')}`;
                errorObj[1] = null;
              } else {
                errorObj[1] = t('leave_types.cannot_delete_country');
                msgObj[0] = null;
              }
              if (deleted_employers?.length) {
                msgObj[1] = `${t('leave_types.employer_deleted')}`;
              }
              setSelectedEmployers(finalArr);
              const countriesLeft = selectedCountries.filter(
                selectedCountry =>
                  !deleted_country.includes(selectedCountry.value),
              );
              setSelectedCountries(countriesLeft);
            }
          },
        });
      } else {
        deleteCountry(selectedCountries, selectedEmployers, removedValue);
        msgObj[0] = null;
      }
    }
    setMsg(msgObj);
    setError(errorObj);
  };

  const deleteEmployer = async (emps, removedValue) => {
    const employersLeft = emps.filter(
      selectedEmployer => selectedEmployer.value !== removedValue.value,
    );
    setSelectedEmployers(employersLeft);
  };

  const handleChangeEmployer = async (empValue, empAction) => {
    const errorObj = [...error];
    const msgObj = [...msg];
    if (empAction.action === 'select-option') {
      const {
        option: { value },
      } = empAction;
      if (value === 'select_all') {
        const selectedEmployersCopy = selectedEmployers.map(
          selectedEmployer => selectedEmployer.value,
        );
        const employersToBeAdded = employerOptions.filter(
          empOption =>
            !(
              selectedEmployersCopy.includes(empOption.value) ||
              empOption.value === 'select_all'
            ),
        );
        const newSelectedEmployers =
          selectedEmployers.concat(employersToBeAdded);

        setSelectedEmployers(newSelectedEmployers);
      } else {
        const filtered = employerOptions.filter(
          empObj => empObj.value === value,
        );
        const employersValue = [...selectedEmployers, ...filtered];
        setSelectedEmployers(employersValue);
      }
      errorObj[1] = null;
      errorObj[2] = null;
    } else if (empAction.action === 'remove-value') {
      const { removedValue } = empAction;
      if (mode === 'edit' && removedValue.fromDefault === true) {
        const canDelete = await checkCanDelete(
          defaultValues?.id,
          removedValue.value,
          'emp',
          token,
        );
        if (typeof canDelete === 'boolean') {
          if (canDelete) {
            msgObj[0] = null;
            msgObj[1] = t('leave_types.employer_deleted');
            errorObj[2] = null;
            deleteEmployer(selectedEmployers, removedValue);
          } else {
            errorObj[2] = t('leave_types.cannot_delete_emp');
            msgObj[1] = null;
          }
        } else {
          errorObj[3] = canDelete?.statusText;
        }
      } else {
        deleteEmployer(selectedEmployers, removedValue);
        msgObj[1] = null;
      }
    } else if (empAction.action === 'clear') {
      const { removedValues } = empAction;
      if (removedValues.some(removedValue => removedValue.fromDefault)) {
        const employerIdsToCheck = removedValues.map(emp => emp.id);
        callClearAllEmployersApi({
          formData: {
            employers_id: JSON.stringify(employerIdsToCheck),
          },
          onSuccess: response => {
            const res = 'not_found';
            if (response.status === res) {
              errorObj[2] = response.json.message;
            } else {
              const { deleted_employers, undeleted_employers } = response?.json;
              if (deleted_employers?.length) {
                const employersLeft = removedValues.filter(removedValue =>
                  undeleted_employers.includes(removedValue.value),
                );
                setSelectedEmployers(employersLeft);
                msgObj[1] = t('leave_types.employer_deleted');
                errorObj[2] = null;
              } else {
                errorObj[2] = t('leave_types.cannot_delete_emp');
              }
            }
          },
          onError: err => {
            errorObj[2] = err.response.error;
          },
        });
      } else {
        setSelectedEmployers([]);
      }
    }
    setError(errorObj);
    setMsg(msgObj);
  };

  const handleColor = colorValue => {
    const errorObj = [...error];
    errorObj[4] = null;
    setError(errorObj);
    setColor(colorValue);
  };

  const asterik = () => {
    return <span className='text-[#db2828]'>*</span>;
  };

  return (
    <>
      <Modal
        title={
          mode === 'edit'
            ? t('leave_types.edit_text')
            : t('leave_types.add_new')
        }
        isOpen={isOpen}
        onClose={() => closeModal()}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Content>
            <Form.TextField
              {...getFieldProps({ name: 'event_type', id: 'leave_types' })}
              required
              defaultValue={defaultValues?.event_type}
            />
            {error[0] && <div className='text-red'>{error[0]}</div>}
            <div className='font-semibold mb-2'>Color code {asterik()}</div>
            <div style={{ width: '200px' }}>
              <Select
                value={color}
                onChange={colorValue => handleColor(colorValue)}
                placeholder='Select color'
                options={allColors}
                closeMenuOnSelect
                classNamePrefix='select'
                isSearchable={false}
              />
            </div>
            {error[4] && <div className='text-red'>{error[4]}</div>}
            <div className='mt-4'>
              <TextAreaField
                {...getFieldProps({ name: 'description', id: 'leave_types' })}
                defaultValue={defaultValues?.description}
              />
            </div>
            <div className='mt-4 font-semibold'>Applicable to {asterik()}</div>
            <div className='flex flex-row space-x-4 mt-3'>
              <Radio
                label='All'
                value='all'
                checked={isChecked === 'all'}
                onChange={handleCheck}
              />
              <Radio
                label='Custom'
                value='custom'
                checked={isChecked === 'custom'}
                disabled={allowAllToCustom}
                onChange={handleCheck}
              />
            </div>
            {error[3] && <div className='mt-2 text-red'>{error[3]}</div>}
            {allowAllToCustom && (
              <div className='mt-3 ml-1'>
                {t('leave_types.switching_not_allowed')}
              </div>
            )}
            {isChecked === 'custom' && (
              <div className='mt-3'>
                <div className='required field mb-3'>
                  <label htmlFor='leave_types.countries'>Country</label>
                  <div style={{ marginBottom: '10px' }}>
                    <Select
                      value={selectedCountries}
                      onChange={(value, actionValue) =>
                        handleChangeCountries(value, actionValue)
                      }
                      options={options}
                      isMulti
                      isClearable={false}
                      placeholder={t('leave_types.countries')}
                    />
                  </div>
                  {msg[0] && <div className='italic mb-2'>{msg[0]}</div>}
                  {error[1] && <div className='text-red'>{error[1]}</div>}
                  <div>
                    <Select
                      value={selectedEmployers}
                      onChange={(empValue, empActionValue) =>
                        handleChangeEmployer(empValue, empActionValue)
                      }
                      isClearable
                      isMulti
                      placeholder={t('leave_types.employer_placeholder')}
                      options={empOptions}
                    />
                  </div>
                  {msg[1] && <div className='italic mb-2'>{msg[1]}</div>}
                  {error[2] && <div className='mt-2 text-red'>{error[2]}</div>}
                </div>
              </div>
            )}
          </Modal.Content>
          <ButtonBar>
            <div className='space-x-2'>
              <Button color='secondary' onClick={() => closeModal()}>
                Cancel
              </Button>
              <Button type='submit' loading={isFetching}>
                Save
              </Button>
            </div>
          </ButtonBar>
        </Form>
        <Modal
          title='Error'
          isOpen={errorMessage && !isFetching}
          onClose={() => {
            setErrorMessage(null);
          }}
          width='sm'
        >
          <Modal.Content>{errorMessage}</Modal.Content>
          <ButtonBar>
            <Button
              onClick={() => {
                setErrorMessage(null);
              }}
              color='primary'
            >
              Ok
            </Button>
          </ButtonBar>
        </Modal>
      </Modal>
    </>
  );
};

export default LeaveAddEditModal;
