/* eslint-disable react/prop-types */
import React, { FC, ReactNode, useEffect, useReducer, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Message, Icon } from '@tg/core/components';
import type { MessageType } from '../../molecules/Message/Message';

export interface Toast {
  id: string;
  title?: string;
  type: MessageType;
  children: ReactNode;
  time: number;
}

interface StateToast extends Toast {
  show: boolean;
  timeAdded: string;
}

interface Props {
  updateList: (toasts: Toast[]) => void;
  toasts: Toast[];
}

interface State {
  [key: string]: StateToast;
}

const reducer = (
  state: State,
  action: { type: 'ADD' | 'DELETE' | 'RESET'; payload?: Toast },
) => {
  if (action.type === 'ADD') {
    // console.log('toast added', action.payload);
    return {
      ...state,
      [action.payload.id]: {
        ...action.payload,
        show: true,
        timeAdded: new Date(),
      },
    };
  }
  if (action.type === 'DELETE') {
    // hide's it but doesn't delete it so we can transition it out
    return {
      ...state,
      [action.payload.id]: {
        ...action.payload,
        show: false,
      },
    };
  }
  if (action.type === 'RESET') {
    return {};
  }
  return state;
};

/**
 * Shows a list of toasts that can be removed with the close button or will remove
 * themselves after a set time.
 * NOTE: Old toasts hang around in state so that we can transition them in/out, they can't be unmounted
 * This *may* cause an issue as that state gets bigger and bigger?
 */
const AppToasts: FC<Props> = ({ toasts = [] }) => {
  const timeouts = {} as { [toastId: string]: any };
  const [state, dispatch] = useReducer(reducer, {} as State);

  // Everytime a the list is updated, add a timeout for the toasts we don't already have one for
  useEffect(() => {
    toasts.forEach(toast => {
      dispatch({ type: 'ADD', payload: toast });

      // we're already set to delete it
      if (timeouts[toast.id]) return;
      // add timeout to delete it after a while
      timeouts[toast.id] = setTimeout(
        () => dispatch({ type: 'DELETE', payload: toast }),
        toast?.time || 5000,
      );
    });
  }, [toasts]);

  const [sorted, setSorted] = useState([]);
  useEffect(() => {
    if (!state) return;
    const newList = [...Object.values(state)].sort(
      (a: StateToast, b: StateToast) => {
        if (a.timeAdded < b.timeAdded) return -1;
        if (a.timeAdded > b.timeAdded) return 1;
        return 0;
      },
    );
    setSorted(newList);
  }, [state]);

  return (
    <div className='fixed right-8 bottom-4 w-[380px]'>
      <div className='translate-x-20' />
      <ol className='space-y-3'>
        {sorted.map(toast => (
          <Transition
            as='li'
            show={toast.show}
            appear
            key={toast.id}
            enter='transform transition duration-[400ms]'
            enterFrom='opacity-0 translate-x-20'
            enterTo='opacity-100 translate-x-0'
            leave='transform duration-200 transition ease-in-out'
            leaveFrom='opacity-100 scale-100 '
            leaveTo='opacity-0 scale-95 '
          >
            <div className='shadow-md'>
              <Message
                type={toast.type}
                title={toast.title}
                action={
                  <button
                    type='button'
                    onClick={() => dispatch({ type: 'DELETE', payload: toast })}
                    aria-label='close'
                  >
                    <Icon name='close' />
                  </button>
                }
              >
                <p className='min-h-[30px]'>{toast.children}</p>
              </Message>
            </div>
          </Transition>
        ))}
      </ol>
    </div>
  );
};

export default AppToasts;
