/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Icon } from 'semantic-ui-react';
import { formatYMD } from '@tg/core/utils/datetimeHelpers';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  CheckboxField,
  CollectionInput,
  DateField,
} from '@tg/core/components';
import { actions } from '@tg/core/store/modules/toasts';
import { contract } from '../../../utils/validations';
import WeekdaysField from '../../molecules/WeekdaysField/WeekdaysField';

const ContractOverview = ({ data, onSubmit, isFetching }) => {
  const { register, handleSubmit, errors, control, watch } = useForm({
    resolver: yupResolver(contract),
  });
  const dispatch = useDispatch();
  const [isLabelDisabled, setLabelDisabled] = useState(true);

  const {
    contract_type,
    attendance_type,
    start_date,
    end_date,
    sickday_entitlement,
    sickday_carryover,
    holiday_entitlement,
    holiday_carryover,
    working_days,
    entitlement_start_contract_start,
    entitlement_public_holidays_employer_domicile,
    entitlement_public_holidays_exclusive,
    contractor,
    setup_fee_amount,
    maximum_fee_amount,
    minimum_fee_amount,
    fixed_fee_amount,
    deposit_amount,
    deposit_currency,
    percentage_tec,
    fixed_fee,
    fees_currency,
    custom_input,
    custom_label,
    payroll_only,
  } = data;

  // TODO: create a better selector for this
  // as 'fixed term' may not reliably be
  // in this array position
  const contractTypesFixedTerm = useSelector(
    state => state.collections.contract_types.allIds[1],
  );
  const watchContractType = watch(
    'contract_type_id',
    end_date ? contractTypesFixedTerm : null,
  );

  useEffect(() => {
    window.addEventListener('click', e => {
      if (
        e.target.id === 'contract_custom_icon' ||
        (e.target.id === 'custom_label_div' &&
          document.activeElement ===
            document.getElementById('custom_label_div'))
      ) {
        setLabelDisabled(false);
        document.getElementById('custom_label_div').focus();
      } else {
        setLabelDisabled(true);
      }
    });
  }, []);

  const submitForm = vals => {
    const end = vals.end_date ? { end_date: formatYMD(vals.end_date) } : {};

    onSubmit({
      formData: {
        contract: {
          ...vals,
          start_date: formatYMD(vals.start_date),
          fixed_term: !!vals.fixed_term,
          deposit_amount: vals?.deposit_amount?.replace(/,/g, ''),
          fixed_fee_amount: vals?.fixed_fee_amount?.replace(/,/g, ''),
          maximum_fee_amount: vals?.maximum_fee_amount?.replace(/,/g, ''),
          minimum_fee_amount: vals?.minimum_fee_amount?.replace(/,/g, ''),
          setup_fee_amount: vals?.setup_fee_amount?.replace(/,/g, ''),
          percentage_tec: vals?.percentage_tec?.replace(/,/g, ''),
          ...end,
        },
      },
      onSuccess: newData => {
        dispatch(
          actions.addToast({
            id: newData.id,
            type: 'success',
            children: 'Saved',
          }),
        );
      },
    });
  };

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <input
        ref={register}
        type='hidden'
        name='fixed_term'
        defaultValue={
          watchContractType && watchContractType === contractTypesFixedTerm
        }
      />

      <Form.Group widths='3'>
        <Form.Field required error={!!errors.contract_type_id}>
          <label htmlFor='contract_type_id'>Contract type</label>
          <Controller
            as={CollectionInput}
            control={control}
            name='contract_type_id'
            defaultValue={contract_type.id}
            rules={{ required: 'required' }}
            resource='contract_types'
            useName
          />
          {errors.contract_type_id && <p>field_error</p>}
        </Form.Field>

        <Form.Field required error={!!errors.attendance_type_id}>
          <label htmlFor='attendance_type_id'>Attendance type</label>
          <Controller
            as={CollectionInput}
            control={control}
            name='attendance_type_id'
            defaultValue={attendance_type.id}
            rules={{ required: 'required' }}
            resource='attendance_types'
            useName
          />
          {errors.attendance_type_id && <p>field_error</p>}
        </Form.Field>
      </Form.Group>

      <Form.Group widths='3'>
        <Form.Field required error={!!errors.start_date}>
          <label htmlFor='start_date'>Start date</label>
          <Controller
            as={DateField}
            control={control}
            name='start_date'
            defaultValue={start_date ? new Date(start_date) : new Date()}
            rules={{ required: 'required' }}
          />
          {errors.start_date && <p>field_error</p>}
        </Form.Field>

        {watchContractType && watchContractType === contractTypesFixedTerm ? (
          <Form.Field required error={!!errors.end_date}>
            <label htmlFor='end_date'>End date</label>
            <Controller
              as={DateField}
              control={control}
              name='end_date'
              defaultValue={end_date ? new Date(end_date) : new Date()}
              rules={{ required: 'required' }}
            />
            {errors.end_date && <p>field_error</p>}
          </Form.Field>
        ) : null}
      </Form.Group>

      <Form.Group>
        <Form.Field>
          <Controller
            as={CheckboxField}
            control={control}
            name='contractor'
            defaultValue={contractor}
            label='Tick indicates employee is a CONTRACTOR'
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <Controller
            as={CheckboxField}
            control={control}
            name='payroll_only'
            defaultValue={payroll_only}
            label='Tick indicates Payroll only'
          />
        </Form.Field>
      </Form.Group>

      <p> </p>
      <hr />
      <p> </p>
      <p> </p>

      <Form.Group>
        <Form.Field>
          <label>Working days</label>
          <Controller
            as={WeekdaysField}
            control={control}
            name='working_days'
            defaultValue={working_days}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths='3'>
        <Form.Field required error={!!errors.holiday_entitlement}>
          <label htmlFor='holiday_entitlement'>Holiday entitlement</label>
          <input
            name='holiday_entitlement'
            type='text'
            defaultValue={holiday_entitlement}
            ref={register}
          />
          {errors.holiday_entitlement && <p>field_error</p>}
        </Form.Field>

        <Form.Field required error={!!errors.holiday_carryover}>
          <label htmlFor='holiday_carryover'>Holiday carryover</label>
          <input
            name='holiday_carryover'
            type='text'
            defaultValue={holiday_carryover}
            ref={register}
          />
          {errors.holiday_carryover && <p>field_error</p>}
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field>
          <Controller
            as={CheckboxField}
            control={control}
            name='entitlement_public_holidays_exclusive'
            defaultValue={entitlement_public_holidays_exclusive}
            label='Tick indicates holiday entitlement is EXCLUSIVE of public holidays'
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field>
          <Controller
            as={CheckboxField}
            control={control}
            name='entitlement_public_holidays_employer_domicile'
            defaultValue={entitlement_public_holidays_employer_domicile}
            label='Tick indicates the EMPLOYER domicile public holidays are applied to this contract'
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field>
          <Controller
            as={CheckboxField}
            control={control}
            name='entitlement_start_contract_start'
            defaultValue={entitlement_start_contract_start}
            label='Tick indicates holiday entitlement calendar begins on the anniversary of the contract'
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths='3'>
        <Form.Field required error={!!errors.sickday_entitlement}>
          <label htmlFor='sickday_entitlement'>Sickday entitlement</label>
          <input
            name='sickday_entitlement'
            type='text'
            defaultValue={sickday_entitlement}
            ref={register}
          />
          {errors.sickday_entitlement && <p>field_error</p>}
        </Form.Field>

        <Form.Field required error={!!errors.sickday_carryover}>
          <label htmlFor='sickday_carryover'>Sickday carryover</label>
          <input
            name='sickday_carryover'
            type='text'
            defaultValue={sickday_carryover}
            ref={register}
          />
          {errors.sickday_carryover && <p>field_error</p>}
        </Form.Field>
      </Form.Group>

      <p> </p>
      <hr />
      <p> </p>
      <p> </p>

      <Form.Group widths='3'>
        <Form.Field required error={!!errors.setup_fee_amount}>
          <label htmlFor='setup_fee_amount'>Setup fee</label>
          <input
            name='setup_fee_amount'
            type='text'
            defaultValue={parseFloat(setup_fee_amount).toLocaleString('en-US', {
              minimumFractionDigits: 2,
            })}
            ref={register}
          />
          {errors.setup_fee_amount && <p>field_error</p>}
        </Form.Field>

        <Form.Field required error={!!errors.deposit_amount}>
          <label htmlFor='deposit_amount'>Deposit</label>
          <input
            name='deposit_amount'
            type='text'
            defaultValue={parseFloat(deposit_amount).toLocaleString('en-US', {
              minimumFractionDigits: 2,
            })}
            ref={register}
          />
          {errors.deposit_amount && <p>field_error</p>}
        </Form.Field>
        <Form.Field required error={!!errors.deposit_currency_id}>
          <label htmlFor='deposit_currency_id'>Deposit Currency</label>
          <Controller
            as={CollectionInput}
            control={control}
            name='deposit_currency_id'
            defaultValue={deposit_currency?.id}
            rules={{ required: 'required' }}
            resource='currencies'
            status
          />
          {errors?.deposit_currency_id && <p>{errors?.deposit_currency_id}</p>}
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field>
          <Controller
            as={CheckboxField}
            control={control}
            name='fixed_fee'
            defaultValue={fixed_fee}
            label='Tick indicates contract has a fixed fee'
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths='3'>
        <Form.Field required error={!!errors.fixed_fee_amount}>
          <label htmlFor='fixed_fee_amount'>Fixed fee</label>
          <input
            name='fixed_fee_amount'
            type='text'
            defaultValue={parseFloat(fixed_fee_amount).toLocaleString('en-US', {
              minimumFractionDigits: 2,
            })}
            ref={register}
          />
          {errors.fixed_fee_amount && <p>field_error</p>}
        </Form.Field>
      </Form.Group>

      <Form.Group widths='3'>
        <Form.Field required error={!!errors.percentage_tec}>
          <label htmlFor='percentage_tec'>Percentage TEC</label>
          <input
            name='percentage_tec'
            type='text'
            defaultValue={parseFloat(percentage_tec).toLocaleString('en-US', {
              minimumFractionDigits: 2,
            })}
            ref={register}
          />
          {errors.percentage_tec && <p>field_error</p>}
        </Form.Field>

        <Form.Field required error={!!errors.maximum_fee_amount}>
          <label htmlFor='maximum_fee_amount'>Maximum fee</label>
          <input
            name='maximum_fee_amount'
            type='text'
            defaultValue={parseFloat(maximum_fee_amount).toLocaleString(
              'en-US',
              { minimumFractionDigits: 2 },
            )}
            ref={register}
          />
          {errors.maximum_fee_amount && <p>field_error</p>}
        </Form.Field>

        <Form.Field required error={!!errors.minimum_fee_amount}>
          <label htmlFor='minimum_fee_amount'>Minimum fee</label>
          <input
            name='minimum_fee_amount'
            type='text'
            defaultValue={parseFloat(minimum_fee_amount).toLocaleString(
              'en-US',
              { minimumFractionDigits: 2 },
            )}
            ref={register}
          />
          {errors.minimum_fee_amount && <p>field_error</p>}
        </Form.Field>
      </Form.Group>

      <Form.Group widths='3'>
        <Form.Field required error={!!errors.fees_currency_id}>
          <label htmlFor='fees_currency_id'>Fees currency</label>
          <Controller
            as={CollectionInput}
            control={control}
            name='fees_currency_id'
            defaultValue={fees_currency.id}
            rules={{ required: 'required' }}
            resource='currencies'
            status
          />
          {errors.fees_currency_id && <p>field_error</p>}
        </Form.Field>
        <div className='ml-2 field mt-0.5'>
          <Form.Field>
            <div className='flex'>
              <input
                id='custom_label_div'
                name='custom_label'
                type='text'
                className='font-bold text-sm '
                placeholder='PO/ reference number'
                defaultValue={custom_label}
                style={{
                  border: 'none',
                  maxHeight: '20px',
                  paddingLeft: '2px',
                  color: 'rgba(0,0,0,.87)',
                  fontSize: '0.92857143em',
                  fontFamily: 'inherit',
                  marginBottom: '0.23rem',
                  opacity: 1,
                }}
                ref={register}
                disabled={isLabelDisabled}
              />
              <div aria-hidden='true' style={{ cursor: 'pointer' }}>
                <Icon name='edit outline' id='contract_custom_icon' />
              </div>
            </div>
          </Form.Field>
          <Form.Field>
            <input
              name='custom_input'
              type='text'
              defaultValue={custom_input}
              ref={register}
            />
          </Form.Field>
        </div>
      </Form.Group>

      <Button type='submit' loading={isFetching}>
        Save
      </Button>
    </Form>
  );
};

ContractOverview.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.shape(),
  isFetching: PropTypes.bool.isRequired,
};

ContractOverview.defaultProps = {
  data: {
    job_title: '',
    start_date: null,
    end_date: null,
    contract_type: { id: '' },
    attendance_type: { id: '' },
    sickday_entitlement: 0,
    sickday_carryover: 0,
    holiday_entitlement: 0,
    holiday_carryover: 0,
    entitlement_start_contract_start: false,
    entitlement_public_holidays_employer_domicile: false,
    entitlement_public_holidays_exclusive: true,
    working_days: [false, true, true, true, true, true, false],
  },
};

export default ContractOverview;
