/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
import axios from 'axios';
import {
  Heading,
  List,
  PaginationControl,
  SelectField,
  LabelledInput,
  Segment,
} from '@tg/core/components';
import { Popup, Search, Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permissionHelpers';

const {
  EMPLOYER_DETAIL_VIEW_EMPLOYEES: { VIEW_CONTRACT_LINK },
  EMPLOYER_DETAIL_VIEW_EMPLOYEE: { VIEW_EMPLOYEE_LINK },
} = permissions;

const EmployerContracts = ({ employerId }) => {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectData, setSelectData] = useState(null);
  const [filterBy, setFilterBy] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [fetchingResource, setFetchingResource] = useState(null);
  const { ROOT_API } = process.env;
  const token = useSelector(s => s.session.aut);

  let endpointUrl = `employers/${employerId}/contracts?page=${page}&pagesize=15`;

  if (searchTerm) {
    endpointUrl += `&employee_with_name=${searchTerm}`;
  }

  if (filterBy && selectedValue) {
    endpointUrl += `&filter_by=${filterBy}&selected_filter=${selectedValue}`;
  }

  const accesslist = useSelector(state => state?.access?.roles);
  const { data, isFetching, pagination } = useResource(
    { url: endpointUrl },
    true,
  );

  const getEmployersList = selectValue => {
    setSelectedValue(selectValue);
    setPage(1);
  };

  const getFilterResource = filter => {
    axios({
      url: `${ROOT_API}/${filter}s?pagination=false`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'get',
    }).then(response => {
      const { data: items } = response.data;
      setSelectData(
        items.map(item => {
          return { text: item.name, value: item.id, key: item.id };
        }),
      );
      setFetchingResource(false);
    });
  };

  const getData = filter => {
    setSelectedValue(null);
    setFilterBy(filter);
    if (filter === 'domicile') {
      setFetchingResource(true);
      getFilterResource(filter);
    } else if (filter === 'employee_status') {
      const employerStatusList = [
        { text: 'Active', value: 'active', key: 'active' },
        { text: 'Inactive', value: 'inactive', key: 'inactive' },
        { text: 'Pending', value: 'pending', key: 'pending' },
      ];
      setSelectData(employerStatusList);
    }
  };

  const clearSortAndSearch = () => {
    setFilterBy(null);
    setSearchTerm('');
    setSelectData(null);
    setSelectedValue(null);
    setPage(1);
  };

  const filterByOptions = [
    { text: 'Employee Status', value: 'employee_status' },
    { text: 'Domicile', value: 'domicile' },
  ];

  return (
    <>
      <div className='flex justify-between'>
        <div>
          <LabelledInput id='filter_by' label='Filter By'>
            <SelectField
              className='mr-2'
              style={{ width: '224px' }}
              options={filterByOptions}
              onChange={filter => getData(filter)}
              placeholder='Search by'
              loading={fetchingResource}
              value={filterBy}
            />
          </LabelledInput>
        </div>
        <div className='ml-4'>
          <LabelledInput id='filter_by' label='Select'>
            <SelectField
              className='mr-2'
              style={{ width: '230px' }}
              options={selectData}
              search
              loading={fetchingResource}
              placeholder='Select from the dropdown'
              onChange={selectValue => {
                getEmployersList(selectValue);
              }}
              value={selectedValue}
            />
          </LabelledInput>
        </div>
        <div className='ml-4'>
          <LabelledInput id='filter_by' label='Search Employees'>
            <Search
              placeholder='Search by employee name'
              value={searchTerm}
              onSearchChange={event => {
                setSearchTerm(event.target.value);
                setPage(1);
              }}
              loading={fetchingResource}
              showNoResults={false}
            />
          </LabelledInput>
          <div className='text-right ml-2'>
            <button
              type='button'
              className='link'
              onClick={() => {
                clearSortAndSearch();
              }}
            >
              Clear Filter & Search
            </button>
          </div>
        </div>
      </div>
      <Heading level='h3'>Employees</Heading>

      {data && !!data.length ? (
        <List
          items={data.map(contract => ({
            id: contract.id,
            columns: [
              <div>
                <div className='whitespace-nowrap font-medium leading-none truncate'>
                  <Popup
                    trigger={
                      contract.employee.user?.full_name.length <= 10 ? (
                        <span>{contract.employee.user?.full_name}</span>
                      ) : (
                        <span>{`${contract.employee.user?.full_name.substring(
                          0,
                          10,
                        )}...`}</span>
                      )
                    }
                    content={contract.employee.user?.full_name}
                    on='hover'
                    hideOnScroll
                  />
                </div>
                <div className='truncate text-gray-500'>
                  <Popup
                    trigger={
                      contract.job_title.length <= 10 ? (
                        <span>{contract.job_title}</span>
                      ) : (
                        <span>{`${contract.job_title.substring(
                          0,
                          10,
                        )}...`}</span>
                      )
                    }
                    content={contract.job_title}
                    on='hover'
                    hideOnScroll
                  />
                </div>
              </div>,
              contract?.employee?.domicile?.name,
              contract.status,
              contract?.employee?.contracts[0]?.start_date,
              contract?.employee?.contracts[0]?.end_date,
              <div className='flex space-x-2'>
                {getPermission(accesslist, VIEW_CONTRACT_LINK) && (
                  <Link to={`employees/${contract.id}`} className='link'>
                    View Contract
                  </Link>
                )}
                {getPermission(accesslist, VIEW_EMPLOYEE_LINK) && (
                  <Link
                    to={`/employees/${contract.employee.id}`}
                    className='link'
                  >
                    View Employee
                  </Link>
                )}
              </div>,
            ],
          }))}
          headings={[
            { text: 'Employee name' },
            { text: 'Domicile' },
            { text: 'Status' },
            { text: 'Start date' },
            { text: 'Termination date' },
            { text: '', align: 'right', width: '215px' },
          ]}
        />
      ) : (
        <div className='text-center font-semibold'>
          {isFetching ? (
            <div
              className='flex items-center justify-center flex-col'
              style={{ marginTop: '100px' }}
            >
              <Loader size='massive' active inline='centered' />
              <span style={{ fontWeight: 400, marginTop: 5 }}>Loading...</span>
            </div>
          ) : (
            <Segment>No Employees Found</Segment>
          )}
        </div>
      )}

      {pagination && (
        <div className='mb-6'>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};

export default EmployerContracts;
