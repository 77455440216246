import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ListBenefits from './ListBenefits';
import ShowBenefit from './ShowBenefit';
import CreateBenefit from './CreateBenefit';

export default () => {
  const { path, url } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={url}>
          <ListBenefits />
        </Route>
        <Route path={`${path}/create`}>
          <CreateBenefit baseUrl={url} />
        </Route>
        <Route path={`${path}/:benefitId`}>
          <ShowBenefit baseUrl={url} />
        </Route>
      </Switch>
    </>
  );
};
