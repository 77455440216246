/* eslint-disable react/prop-types */
import React from 'react';
import { Form as SemanticForm } from 'semantic-ui-react';
import LoadingSpinner from '../../atoms/LoadingSpinner';

const Form = ({ children, onSubmit, isFetching = false }) => {
  return (
    <SemanticForm noValidate onSubmit={onSubmit}>
      {isFetching ? (
        <div className='flex items-center justify-center my-4'>
          <LoadingSpinner />
        </div>
      ) : (
        children
      )}
    </SemanticForm>
  );
};

export default Form;
