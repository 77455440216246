import {
  Button,
  ButtonBar,
  InputText,
  LabelledInput,
  List,
  Message,
  Modal,
  PageHeader,
  PaginationControl,
} from '@tg/core/components';
import { useResource } from '@tg/core/hooks';
import React, { useState } from 'react';

function ExchangeRate() {
  const [isOpen, setIsOpen] = useState(false);
  const [defVal, setDefVal] = useState(null);
  const [mode, setMode] = useState('add');
  const [id, setId] = useState(null);
  const [page, setPage] = useState(1);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [error, setError] = useState('');
  const { data, postResource, getResource, isFetching, pagination } =
    useResource({ url: `exchange_rate_adjustments?page=${page}` }, true);

  const { patchResource } = useResource(
    { url: `exchange_rate_adjustments/${id}` },
    true,
  );
  const { deleteResource } = useResource({
    url: `exchange_rate_adjustments/${undefined}`,
  });

  const closeModal = () => {
    setIsOpen(false);
    setDefVal(null);
    setMode('add');
    setId(null);
    setError('');
  };

  const addExhangeRate = () => {
    if (!Number(defVal) && Number(defVal) !== 0) {
      setError('Enter a valid value from 0 and 100');
    } else if (Number(defVal) < 0 || Number(defVal) > 100) {
      setError('Enter a valid value from 0 and 100');
    } else {
      const formData = {
        exchange_rate_value: defVal,
      };
      if (mode === 'add') {
        postResource({
          formData,
          onSuccess: () => {
            closeModal();
            setDefVal(null);
            setMode('add');
            getResource();
            setError('');
          },
          onError: () => {
            setError('Enter a valid value from 0 and 100');
          },
        });
      } else {
        patchResource({
          formData,
          onSuccess: () => {
            closeModal();
            setDefVal(null);
            setMode('add');
            getResource();
            setError('');
          },
          onError: () => {
            setError('Enter a valid value from 0 and 100');
          },
        });
      }
    }
  };

  const deleteRate = () => {
    deleteResource({
      onSuccess: () => {
        setId(null);
        setConfirmationPopup(false);
        getResource();
      },
    });
  };

  const listItems = data
    ? data.map(rate => ({
        id: rate.id,
        columns: [
          `${rate?.exchange_rate_value}%`,
          <button
            type='button'
            className='underline'
            onClick={() => {
              setIsOpen(true);
              setDefVal(rate.exchange_rate_value);
              setMode('edit');
              setId(rate.id);
            }}
          >
            Edit
          </button>,
        ],
      }))
    : [];
  return (
    <div>
      <PageHeader
        heading='Exchange Rate Adjustment'
        action={
          data?.length !== 0 ? null : (
            <Button
              onClick={() => {
                setIsOpen(true);
              }}
            >
              ADD EXCHANGE RATE
            </Button>
          )
        }
      />
      <div className='flex flex-wrap '>
        <List
          loading={isFetching}
          items={listItems}
          headings={[{ text: 'Value', width: '50vw' }, { width: '40px' }]}
        />
      </div>
      {
        <Modal
          title='Add Exchange Rate Adjustment'
          isOpen={isOpen}
          onClose={() => closeModal()}
          // width={width}
        >
          <div
            className='mb-8 '
            style={{ paddingInline: '20px', marginTop: '20px' }}
          >
            <LabelledInput label='Exchange Rate Value' id='editingRefId'>
              <InputText
                type='number'
                placeholder='Exchange Rate Adjustment Value in %'
                value={defVal}
                onChange={e => {
                  if (Number(e.target.value) < 0) {
                    setError('Enter a valid value from 0 and 100');
                    setDefVal(e.target.value);
                  } else {
                    setDefVal(e.target.value);
                  }
                }}
              />
            </LabelledInput>

            {error?.length > 0 && (
              <div className='mt-4'>
                <Message type='error'>{error}</Message>
              </div>
            )}
            <div style={{ marginTop: '20px' }}>
              <ButtonBar>
                <div className='space-x-2'>
                  <Button color='secondary' onClick={() => closeModal()}>
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    loading={isFetching}
                    onClick={() => addExhangeRate()}
                  >
                    Save
                  </Button>
                </div>
              </ButtonBar>
            </div>
          </div>
        </Modal>
      }
      {
        <Modal
          title='Are you sure you want to delete ?'
          isOpen={confirmationPopup}
          onClose={() => setConfirmationPopup(false)}
        >
          <div style={{ marginTop: '20px' }}>
            <ButtonBar>
              <div className='space-x-2'>
                <Button
                  color='secondary'
                  onClick={() => {
                    setId(null);
                    setConfirmationPopup(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  loading={isFetching}
                  onClick={() => deleteRate()}
                >
                  Save
                </Button>
              </div>
            </ButtonBar>
          </div>
        </Modal>
      }
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </div>
  );
}

export default ExchangeRate;
