import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import { actions } from '@tg/core/store/modules/collections';

const CollectionField = ({
  multiple,
  resource,
  onChange,
  search,
  useName,
  status,
  getValues,
  ...rest
}) => {
  const collection = useSelector(state => state.collections[resource] || null);
  const dispatch = useDispatch();
  const { t } = useTranslation('collections');
  const resourceKey = resource.match(/_countries$/) ? 'countries' : resource;

  useEffect(() => {
    if (!collection) dispatch(actions.fetchCollection({ resource }));
  }, []);

  if (collection && status) {
    return (
      <Dropdown
        multiple={multiple}
        selection
        search={search}
        onChange={(_, { value }) => {
          onChange(value);
        }}
        options={Object.keys(collection.byId).map(key => {
          const { id, code, name } = collection.byId[key];
          return {
            text: `${code} (${name})`,
            value: id,
            key: id,
          };
        })}
        {...rest}
      />
    );
  }
  if (collection) {
    return (
      <Dropdown
        multiple={multiple}
        selection
        search={search}
        onChange={(_, { value }) => {
          onChange(value);
          getValues(value);
        }}
        options={Object.keys(collection.byId).map(key => {
          const { id, code, name } = collection.byId[key];
          return {
            text: useName ? name : t(`${resourceKey}.${code}`),
            value: id,
            key: id,
          };
        })}
        {...rest}
      />
    );
  }
  return null;
};

CollectionField.propTypes = {
  onChange: PropTypes.func,
  resource: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  search: PropTypes.bool,
  useName: PropTypes.bool,
  status: PropTypes.bool,
  getValues: PropTypes.func,
};

CollectionField.defaultProps = {
  onChange: f => f,
  getValues: f => f,
  multiple: false,
  search: false,
  useName: false,
  status: false,
};

export default CollectionField;
