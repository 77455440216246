import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Message } from '@tg/core/components';
import { useResource } from '@tg/core/hooks';

const EmailPopup = ({
  ownerType,
  owner,
  openCustomEmailPopup,
  id,
  setHidePopup,
}) => {
  const [defaultLoading, setDefaultLoading] = useState(false);
  const [error, setError] = useState(null);
  const { patchResource, error: err } = useResource({
    url: `${ownerType}/${owner}/invoices/${id}/email`,
  });

  useEffect(() => {
    setError(err);
  }, [err]);

  return (
    <>
      {error && (
        <div className='mb-4'>
          <Message type='error'>{error}</Message>
        </div>
      )}
      <p style={{ textAlign: 'center' }}>
        Would you like to customize the email?
      </p>
      <div className='space-x-2'>
        <Button
          color='secondary'
          size='small'
          onClick={() => openCustomEmailPopup(true, id)}
        >
          Yes
        </Button>
        <Button
          color='primary'
          size='small'
          disabled={defaultLoading}
          loading={defaultLoading}
          onClick={() => {
            setDefaultLoading(true);
            patchResource({
              onSuccess: () => {
                setDefaultLoading(false);
                setHidePopup(true);
              },
              onError: res => {
                setError(res.response.error);
                setDefaultLoading(false);
              },
            });
          }}
        >
          No (Default Email)
        </Button>
      </div>
    </>
  );
};

EmailPopup.propTypes = {
  owner: PropTypes.string.isRequired,
  ownerType: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  openCustomEmailPopup: PropTypes.func.isRequired,
  setHidePopup: PropTypes.func.isRequired,
};

export default EmailPopup;
