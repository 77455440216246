import {
  array,
  boolean,
  date,
  mixed,
  number,
  object,
  ref,
  setLocale,
  string,
} from 'yup';

setLocale({
  mixed: {
    default: { key: 'field_invalid' },
    required: { key: 'field_required' },
  },
  string: {
    email: { key: 'email_invalid' },
    min: ({ min }) => ({ key: 'field_too_short', values: { min } }),
    max: ({ max }) => ({ key: 'field_too_long', values: { max } }),
  },
});

// const address = object().shape({
//   line_one: string().required(),
//   line_two: string(),
//   city: string(),
//   state: string(),
//   zip: string().required(),
//   country_id: string().required(),
// });

const user = object().shape({
  first_name: string().required(),
  last_name: string().required(),
  email: string().email(),
  phone: object().test(
    'phone-test',
    { key: 'phone_invalid' },
    ({ code, phone }) => {
      return code.match(/^\+[0-9]{1,3}$/) && phone.match(/^[\d-\s.)(]{1,20}$/);
    },
  ),
});

export const salary = object().shape({
  currency_id: string().required(),
  gross_salary: number().typeError({ key: 'number_required' }),
  effective_date: date().nullable().required(),
});

export const contract = object().shape({
  job_title: string().required(),
  contract_type_id: string().required(),
  attendance_type_id: string().required(),
  start_date: date()
    .nullable()
    // .min(new Date(), { key: 'start_date_past' })
    .required(),
  end_date: date()
    .nullable()
    .min(ref('start_date'), { key: 'end_date_before_start_date' })
    .when('fixed_term', {
      is: 'true',
      then: date().required(),
    }),
  currency_id: string().required(),
  gross_salary: number().typeError({ key: 'number_required' }),
});

const schemas = {
  address: object().shape({
    line_one: string().required(),
    line_two: string(),
    city: string(),
    state: string(),
    zip: string().required(),
    country_id: string().required(),
  }),
  address_with_same_as: object().shape({
    same_as_registered: boolean(),
    line_one: string().when('same_as_registered', {
      is: false,
      then: string().required(),
    }),
    line_two: string(),
    city: string(),
    state: string(),
    zip: string().when('same_as_registered', {
      is: false,
      then: string().required(),
    }),
    country_id: string().when('same_as_registered', {
      is: false,
      then: string().required(),
    }),
  }),
  employer: object().shape({
    name: string().required(),
    industry_sector_id: string().required(),
    domicile_id: string().required(),
    identifiers: array()
      .default([])
      .test('identifier-test', { key: 'field_required' }, identifiers =>
        identifiers.every(
          x => x.value && x.value.match(x.validator || /[a-zA-Z\d]/),
        ),
      ),
  }),
  administrator: object().shape({
    first_name: string().required(),
    last_name: string().required(),
    email: string().email().required(),
  }),
  officer: user.shape({
    job_title: string().required(),
  }),
  officer_create: user.shape({
    email: string().email().required(),
    job_title: string().required(),
  }),
  employee_create: user.shape({
    email: string().email().required(),
    domicile_id: string().required(),
  }),
  employee: user.shape({
    domicile_id: string().required(),
    identifiers: array()
      .default([])
      .test('identifier-test', { key: 'field_required' }, identifiers =>
        identifiers.every(
          x => x.value && x.value.match(x.validator || /[a-zA-Z\d]/),
        ),
      ),
  }),
  login: object().shape({
    email: string().email().required(),
    password: string().required(),
  }),
  forgot: object().shape({
    email: string().email().required(),
  }),
  create_password: object().shape({
    password: string().min(8).required(),
    password_again: string().oneOf([ref('password')], {
      key: 'password_match',
    }),
  }),
  accept_terms: object().shape({
    terms: mixed().required(),
    privacy: mixed().required(),
  }),
  bank_details: object().shape({
    account_name: string().required(),
    account_iban: string().required(),
    account_swift: string().required(),
  }),
  contract: object().shape({
    job_title: string().required(),
    contract_type_id: string().required(),
    attendance_type_id: string().required(),
    start_date: date()
      .nullable()
      // .min(new Date(), { key: 'start_date_past' })
      .required(),
    end_date: date()
      .nullable()
      .min(ref('start_date'), { key: 'end_date_before_start_date' })
      .when('fixed_term', {
        is: 'true',
        then: date().required(),
      }),
    currency_id: string().required(),
    gross_salary: number().typeError({ key: 'number_required' }),
  }),
  contract_benefit: object().shape({
    employee_benefit_id: string().required(),
    start_date: date()
      .nullable()
      // .min(new Date(), { key: 'start_date_past' })
      .required(),
  }),
  contract_defaults: object().shape({
    invoice_due_date: number()
      .typeError({ key: 'number_required' })
      .test('is-between-7-30', { key: 'is-between-7-30' }, value => {
        return value >= 7 && value <= 30;
      }),
  }),
};

export const getValidationSchema = fieldsets => {
  const schema = fieldsets.reduce((acc, { id, fieldset }) => {
    acc[id] = schemas[fieldset];
    return acc;
  }, {});
  return object().shape(schema);
};
