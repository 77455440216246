import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import { useResource } from '@tg/core/hooks';
import { FormBuilder } from '@tg/core/components';

const ShowBenefit = ({ baseUrl }) => {
  console.log(useParams());
  const { benefitId } = useParams();
  const { data, errors, isFetching, patchResource } = useResource(
    {
      url: `benefits/${benefitId}`,
    },
    true,
  );
  const history = useHistory();

  if (errors) console.log(errors);

  const onSubmit = formData => {
    console.log(formData);

    patchResource({
      formData,
      onSuccess: history.goBack({ pathname: `/${baseUrl}` }),
    });
  };

  const { employee_benefit_type: type, ...rest } = data || {};

  return (
    <>
      <Link to={baseUrl}>Back</Link>
      <Segment>
        {data && (
          <FormBuilder
            onSubmit={onSubmit}
            isFetching={isFetching}
            errors={errors}
            values={{
              employee_benefit: {
                employee_benefit_type_id: type.id,
                ...rest,
              },
            }}
            fieldsets={[
              { id: 'employee_benefit', fieldset: 'employee_benefit' },
            ]}
            submitButtonText='Save changes'
          />
        )}
      </Segment>
    </>
  );
};

ShowBenefit.propTypes = {
  baseUrl: PropTypes.string.isRequired,
};

export default ShowBenefit;
