/* eslint-disable react/prop-types */
import React, { FC, useState } from 'react';
import { useConfirm } from '@tg/core/hooks';
import { Button, ButtonBar, Form } from '@tg/core/components';
import { formatDate } from '@tg/core/utils/datetimeHelpers';

interface Props {
  patchResource: ({ formData }: { formData: unknown }) => void;
  data: unknown;
}

const ContractPayslips: FC<Props> = ({ patchResource, data }) => {
  const { getFieldProps, handleSubmit } = Form.useForm();

  const postValues = formVals => {
    const {
      termination_date,
      contract_termination_reason_id,
    } = formVals.contract_termination;

    patchResource({
      formData: {
        contract: {
          termination_date,
          contract_termination_reason_id,
        },
      },
    });
  };

  const [formData, setFormData] = useState({});
  const { open: openConfirm, Confirm } = useConfirm(
    () => postValues(formData),
    'Please confirm you wish to terminate this contract. This cannot be undone.',
  );

  const onSubmit = values => {
    setFormData(values);
    openConfirm();
  };

  if (data && data.termination_date) {
    return (
      <div className='p-4'>
        <p>
          <span className='font-bold'>Contract terminated:</span>{' '}
          {formatDate({ dateStr: data.termination_date })}
        </p>
        <p>
          <span className='font-bold'>Reason:</span>{' '}
          {data.contract_termination_reason.name}
        </p>
      </div>
    );
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className='p-4'>
          <Form.DateField
            {...getFieldProps({
              id: 'contract_termination',
              name: 'termination_date',
            })}
            required
          />
          <Form.CollectionField
            {...getFieldProps({
              id: 'contract_termination',
              name: 'contract_termination_reason_id',
            })}
            resource='contract_termination_reasons'
            useName
            required
          />
        </div>
        <ButtonBar>
          <Button type='submit'>Terminate Contract</Button>
        </ButtonBar>
      </Form>
      <Confirm />
    </>
  );
};

export default ContractPayslips;
