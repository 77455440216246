import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@tg/core/localisation/i18n';
import '@tg/core/logger';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import gb from 'date-fns/locale/en-GB';
import configureStore from '@tg/core/store';
import globalStyles from '@tg/core/styles/global';
import { Global, css } from '@emotion/core';
import App from './components/App';
import './styles/tailwind.css';

registerLocale('en-GB', gb);
setDefaultLocale('en-GB');

const { store, persistor } = configureStore({ admin: true });

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  <PersistGate loading={null} persistor={persistor}>
    <Provider store={store}>
      <Global
        styles={css`
          ${globalStyles};
        `}
      />
      <App />
    </Provider>
  </PersistGate>,
);
