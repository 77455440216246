import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useResource } from '@tg/core/hooks';
import {
  Container,
  DisplayDetails,
  Heading,
  Breadcrumbs,
  ListInline,
  Segment,
  Message,
} from '@tg/core/components';
import { formatDate } from '@tg/core/utils/datetimeHelpers';
import { formatPhone } from '@tg/core/utils/stringHelpers';
import { Icon, Modal } from 'semantic-ui-react';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import permissions from '@tg/core/constant';
import { useSelector } from 'react-redux';
import ActionItem from '../../ActionItem';
import EmployeeDocuments from '../EmployeeDocuments';
import EmployeeContracts from '../EmployeeContracts';
import UserForm from './UserForm';
import AddressForm from './AddressForm';

const {
  EMPLOYER_DETAIL_VIEW_EMPLOYEE: {
    EMPLOYEE_DETAIL_VIEW: {
      SET_UP_CHECK_LIST,
      VIEW_PERSONAL_DETAILS,
      VIEW_HOME_ADDRESS,
      VIEW_BANK_DETAILS,
      VIEW_EMERGENCY_CONTACT,
      VIEW_IDENTITY_AND_COMPLIANCE_DOCUMENTS_DRAG_DROP,
      VIEW_CONTRACTS,
    },
  },
  EMPLOYER_DETAIL_VIEW_EMPLOYEES_EMPLOYEE: {
    DETAIL_VIEW: {
      DETAILS_BELL_ICON_CLICK,
      HOME_ADDRESS_BELL_ICON,
      BANK_DETAILS_BELL_ICON,
      EMERGENCY_CONTACT_BELL_ICON,
    },
  },
} = permissions;

export default () => {
  const { t } = useTranslation('forms');

  const [isVisble, setIsVisible] = useState(false);
  const accesslist = useSelector(state => state?.access?.roles);
  const [successModal, setSuccessModal] = useState(false);
  const { employeeId } = useParams();
  const { data, errors, patchResource, isFetching } = useResource(
    {
      url: `employees/${employeeId}`,
    },
    true,
  );
  const { data: contractData } = useResource(
    { url: `employees/${employeeId}/contracts` },
    true,
  );

  const { postResource } = useResource({
    url: `employees/${employeeId}/reminder`,
  });

  const formatDateTime = dateStr => formatDate({ dateStr, incTime: true });

  if (errors) console.log(errors);

  // console.log(getValues());

  if (data) {
    const {
      approved_at,
      identity_check_email_sent_at,
      identity_check_completed_at,
      welcome_completed_at,
      welcome_email_sent_at,
      emergency_contact,
      bank_details,
      identifiers,
      user: {
        first_name,
        last_name,
        last_login,
        activated_at,
        show_bell_icon,
        phone,
      },
    } = data;

    const currentContract =
      contractData && contractData.find(({ status }) => status === 'active');

    const handleNotification = value => {
      const notification = {};
      notification.section = value;
      postResource({
        formData: notification,
        onSuccess: () => {
          setSuccessModal(true);
        },
      });
    };

    const handleEmergencyContact = () => {
      if (Object.keys(emergency_contact).length !== 0) {
        if (
          emergency_contact.name === '' ||
          emergency_contact.name === 'n/a' ||
          emergency_contact?.phone?.phone === null
        ) {
          return false;
        }
      }
      return true;
    };

    return (
      <Container>
        <header className='mb-6'>
          <div className='mb-1'>
            <Breadcrumbs items={[{ to: '/employees', text: 'Employees' }]} />
          </div>

          <div className='mb-2'>
            <Heading level='h3' zeropad>{`${first_name} ${last_name}`}</Heading>
          </div>

          <ListInline
            items={[
              <>
                Account activated:{' '}
                {activated_at ? (
                  formatDateTime(activated_at)
                ) : (
                  <span className='text-gray-500'>not activated</span>
                )}
              </>,
              <>
                Welcome completed:{' '}
                {welcome_completed_at ? (
                  formatDateTime(welcome_completed_at)
                ) : (
                  <span className='text-gray-500'>not completed</span>
                )}
              </>,
              <>
                Last login:{' '}
                {last_login ? (
                  formatDateTime(last_login)
                ) : (
                  <span className='text-gray-500'>never</span>
                )}
              </>,
            ]}
          />
        </header>

        <div className='space-y-6 mb-10'>
          {currentContract && (
            <Message type='info' title='Current Contract'>
              <p>
                {currentContract.job_title} at{' '}
                <Link
                  to={`/employers/${currentContract.employer.id}`}
                  className='link'
                >
                  {currentContract.employer.name}
                </Link>
              </p>
              <Link
                className='link'
                to={`/employers/${currentContract.employer.id}/employees/${currentContract.id}`}
              >
                View Contract
              </Link>
            </Message>
          )}
          {getPermission(accesslist, SET_UP_CHECK_LIST) && (
            <div className='grid gap-6 md:grid-cols-2'>
              <Segment heading='Setup checklist'>
                <ActionItem
                  url={`employees/${employeeId}/approve`}
                  timestamp={approved_at}
                  checklist
                >
                  Check employee setup data
                </ActionItem>

                <ActionItem
                  url={`employees/${employeeId}/send_welcome`}
                  timestamp={welcome_email_sent_at}
                  checklist
                >
                  Send welcome email
                </ActionItem>

                <ActionItem
                  url={`employees/${employeeId}/identity_check`}
                  timestamp={identity_check_completed_at}
                  checklist
                >
                  Complete identity check
                </ActionItem>
              </Segment>

              <Segment heading='Optional actions'>
                <p className='-mt-3 mb-4'>
                  <span className='font-bold'>NOTE: </span>
                  Emails will be sent a maximum of once in a 24 hour period
                </p>

                {!identity_check_completed_at && (
                  <ActionItem
                    url={`employees/${employeeId}/send_identity_check`}
                    timestamp={identity_check_email_sent_at}
                    repeatable
                  >
                    Send/resend request identity documents email
                  </ActionItem>
                )}

                {!welcome_completed_at && (
                  <ActionItem
                    url={`employees/${employeeId}/send_welcome`}
                    timestamp={welcome_email_sent_at}
                    repeatable
                  >
                    Resend welcome email
                  </ActionItem>
                )}
              </Segment>
            </div>
          )}
          {getPermission(accesslist, VIEW_PERSONAL_DETAILS) && (
            <Segment
              heading='Personal details'
              action={
                show_bell_icon ||
                !phone.phone ||
                (!identifiers[0]?.value && isVisble) ? (
                  <button
                    type='button'
                    aria-label='noifty'
                    onClick={() => handleNotification('Personal details')}
                  >
                    {getPermission(accesslist, DETAILS_BELL_ICON_CLICK) && (
                      <Icon color='red' name='bell outline' size='big' />
                    )}
                  </button>
                ) : (
                  ''
                )
              }
            >
              <UserForm
                data={data}
                isFetching={isFetching}
                patchResource={patchResource}
                errors={errors}
                setIsVisible={setIsVisible}
              />
            </Segment>
          )}

          {getPermission(accesslist, VIEW_HOME_ADDRESS) && (
            <Segment
              heading='Home address'
              action={
                !data.address || data?.address?.show_bell_icon ? (
                  <button
                    type='button'
                    aria-label='noifty'
                    onClick={() => handleNotification('Home address')}
                  >
                    {getPermission(accesslist, HOME_ADDRESS_BELL_ICON) && (
                      <Icon color='red' name='bell outline' size='big' />
                    )}
                  </button>
                ) : (
                  ''
                )
              }
            >
              <AddressForm
                data={data}
                isFetching={isFetching}
                patchResource={patchResource}
                errors={errors}
              />
            </Segment>
          )}

          {getPermission(accesslist, VIEW_BANK_DETAILS) && (
            <Segment
              heading={t('labels.bank_details.heading')}
              action={
                Object.keys(bank_details).length === 0 ? (
                  <button
                    type='button'
                    aria-label='noifty'
                    onClick={() => handleNotification('Bank Details')}
                  >
                    {getPermission(accesslist, BANK_DETAILS_BELL_ICON) && (
                      <Icon color='red' name='bell outline' size='big' />
                    )}
                  </button>
                ) : (
                  ''
                )
              }
            >
              <DisplayDetails
                details={[
                  {
                    title: t('labels.bank_details.account_name'),
                    value: data.bank_details.account_name,
                    width: '33.333%',
                  },
                  {
                    title: t('labels.bank_details.account_iban'),
                    value: data.bank_details.account_iban,
                    width: '33.333%',
                  },
                  {
                    title: t('labels.bank_details.account_swift'),
                    value: data.bank_details.account_swift,
                    width: '33.333%',
                  },
                ]}
              />
            </Segment>
          )}

          {getPermission(accesslist, VIEW_EMERGENCY_CONTACT) && (
            <Segment
              heading={t('labels.emergency_contact.heading')}
              action={
                !handleEmergencyContact() ||
                Object.keys(emergency_contact).length === 0 ? (
                  <button
                    type='button'
                    aria-label='noifty'
                    onClick={() => handleNotification('Emergency Contact')}
                  >
                    {getPermission(accesslist, EMERGENCY_CONTACT_BELL_ICON) && (
                      <Icon color='red' name='bell outline' size='big' />
                    )}
                  </button>
                ) : (
                  ''
                )
              }
            >
              <DisplayDetails
                details={[
                  {
                    title: t('labels.emergency_contact.name'),
                    value: handleEmergencyContact()
                      ? emergency_contact.name
                      : '-',
                    width: '50%',
                  },
                  {
                    title: t('labels.emergency_contact.phone'),
                    value: handleEmergencyContact()
                      ? formatPhone(emergency_contact.phone)
                      : '-',
                    width: '50%',
                  },
                ]}
              />
            </Segment>
          )}
          {getPermission(
            accesslist,
            VIEW_IDENTITY_AND_COMPLIANCE_DOCUMENTS_DRAG_DROP,
          ) && (
            <Segment heading='Identity and Compliance Documents'>
              <EmployeeDocuments employee={data} />
            </Segment>
          )}

          {getPermission(accesslist, VIEW_CONTRACTS) && (
            <Segment>
              <EmployeeContracts data={contractData} />
            </Segment>
          )}
        </div>
        <Modal
          open={successModal}
          header='Reminder'
          content='Email has been sent!!!'
          actions={['OK']}
          onClose={() => setSuccessModal(false)}
          size='mini'
        />
      </Container>
    );
  }

  return null;
};
