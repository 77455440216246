import { object, boolean, string } from 'yup';
import { isDate, isAfter } from 'date-fns';

export const dateValidation = string().test(
  'is-date-string',
  'field_invalid',
  value => isDate(new Date(value)),
);

export const endDateValidation = dateValidation.when(
  'start_date',
  (start_date, schema) => {
    return schema.test(
      'is-after-date',
      { key: 'end_date_before_start_date' },
      value => {
        if (!value) return true;
        if (value === start_date) return true;
        return isAfter(new Date(value), new Date(start_date));
      },
    );
  },
);

export default object().shape({
  new_time_off: object()
    .shape({
      first_day_half: boolean(),
      last_day_half: boolean(),
      take_unpaid: boolean(),
      start_date: dateValidation,
      end_date: endDateValidation,
      note: string(),
    })
    .noUnknown(),
});
