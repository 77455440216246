import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Heading,
  DisplayDetails,
  ButtonBar,
  Button,
  List,
  StaticAssetActions,
} from '@tg/core/components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { capitalise } from '@tg/core/utils/stringHelpers';
import { formatDateRange } from '@tg/core/utils/datetimeHelpers';
import { useResource } from '@tg/core/hooks';
import { callCancelRequest } from './eventDetailsHelper';

const EventDetailsModal = ({
  event,
  onClose,
  onEditClick,
  allowEditing,
  approveRejectButton,
  contractId,
  onSuccess,
  onDeleteSuccess,
  planYear,
}) => {
  const { t } = useTranslation(['time_off', 'forms']);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const token = useSelector(s => s.session.aut);
  const [isFetching, setIsFetching] = useState(false);

  const { deleteResource } = useResource(
    {
      url: `contracts/${contractId}/planner/${planYear}/${event?.id}`,
    },
    false,
  );
  const eventDetails = event
    ? [
        {
          title: t('time_off:event_details.status'),
          value: t(`time_off:event_status.${event.status}`),
          width: '25%',
        },
        {
          title: t('time_off:event_details.entitlement_used'),
          value: t(`time_off:days`, {
            count:
              event.event_type === 'unpaid'
                ? 0
                : event.entitlement_used[event.event_type],
          }),
          width: '25%',
        },
      ]
    : [];

  if (event) {
    const {
      start_perc,
      end_perc,
      status,
      rejection_note,
      note,
      documents,
    } = event;
    const docList = documents?.length ? (
      <List
        type='popup'
        items={documents.map(f => {
          const columnObj = [<div className='break-words'>{f.filename}</div>];
          if (f.url) {
            columnObj.push(<StaticAssetActions url={f.url} />);
          }
          return {
            id: f.file_id,
            name: f.filename,
            columns: columnObj,
          };
        })}
      />
    ) : (
      ''
    );

    if (start_perc === 50) {
      eventDetails.push({
        title: t('time_off:event_details.start_half'),
        value: 'Yes',
        width: '50%',
      });
    }
    if (end_perc === 50) {
      eventDetails.push({
        title: t('time_off:event_details.end_half'),
        value: 'Yes',
        width: '50%',
      });
    }
    if (status === 'rejected') {
      eventDetails.push({
        title: t('time_off:event_details.rejection_note'),
        value: rejection_note || '-',
        width: '100%',
      });
    }
    if (note) {
      eventDetails.push({
        title: t('time_off:event_details.note'),
        value: note || '-',
        width: '100%',
      });
    }
    if (documents) {
      eventDetails.push({
        title: t('time_off:event_details.documents'),
        value: docList || '-',
        width: '100%',
      });
    }
  }

  const onClosePopup = () => {
    onClose();
    setShowConfirmationPopup(false);
  };

  const deleteRequest = () => {
    deleteResource({
      onSuccess: res => {
        if (res) {
          onDeleteSuccess();
          onClosePopup();
        }
      },
    });
  };

  const openConfirmationPopup = val => {
    setShowConfirmationPopup(val);
  };

  const sendRequestCancellation = async () => {
    setIsFetching(true);
    const res = await callCancelRequest(
      `contracts/${contractId}/planner/${planYear}/${event?.id}/cancellation_pending`,
      token,
    );

    if (res.statusText === 'Unauthorized') {
      onClosePopup();
      setIsFetching(false);
    } else {
      onSuccess();
      onClosePopup();
      setIsFetching(false);
    }
  };

  const eventIsLocked = event && event.status === 'locked';

  return (
    <>
      <Modal isOpen={!!event} onClose={onClosePopup} width='xs'>
        {!showConfirmationPopup ? (
          <>
            <div className='flex px-6'>
              <div className='mr-4'>
                {event && (
                  <Heading level='h4' zeropad>
                    {capitalise(event.event_type)}
                  </Heading>
                )}
                <p>
                  {event &&
                    formatDateRange({
                      start_date: event.start_date,
                      end_date: event.end_date,
                    })}
                </p>
              </div>
              {typeof approveRejectButton === 'function' && (
                <div className='ml-auto'>{approveRejectButton(onClose)}</div>
              )}
            </div>
            <Modal.Content>
              <DisplayDetails details={eventDetails} />
            </Modal.Content>
            <ButtonBar>
              {allowEditing && !eventIsLocked && event?.status === 'pending' && (
                <div className='mr-2'>
                  <Button
                    type='button'
                    color='secondary'
                    onClick={() => {
                      onEditClick();
                      onClose();
                    }}
                  >
                    {t('time_off:event_details.edit_button')}
                  </Button>
                </div>
              )}
              {event?.status !== 'pending' &&
                (event?.status === 'approved' ||
                  event?.status === 'cancellation_pending') && (
                  <div className='mr-2'>
                    <Button
                      color='secondary'
                      onClick={() => {
                        deleteRequest();
                      }}
                    >
                      Delete Request
                    </Button>
                  </div>
                )}
              {event?.status === 'approved' && (
                <div className='mr-2'>
                  <Button
                    color='primary'
                    onClick={() => openConfirmationPopup(true)}
                  >
                    {t('time_off:event_details.cancel_request')}
                  </Button>
                </div>
              )}
              <Button type='button' onClick={onClosePopup} color='secondary'>
                {t('forms:buttons.modal.close')}
              </Button>
            </ButtonBar>
          </>
        ) : (
          <>
            <div className='flex px-6'>
              <div className='mr-4'>
                <Heading level='h4' zeropad>
                  {t('time_off:confirm_popup.heading')}
                </Heading>
                <Modal.Content>
                  <p>{t('time_off:confirm_popup.confirmation_question')}</p>
                </Modal.Content>
              </div>
            </div>
            <ButtonBar>
              <div className='mr-2'>
                <Button
                  type='button'
                  color='primary'
                  loading={isFetching}
                  onClick={e => sendRequestCancellation(e)}
                >
                  {t('forms:buttons.modal.yes')}
                </Button>
              </div>
              <Button
                type='button'
                color='secondary'
                onClick={() => openConfirmationPopup(false)}
              >
                {t('forms:buttons.modal.no')}
              </Button>
            </ButtonBar>
          </>
        )}
      </Modal>
    </>
  );
};

EventDetailsModal.propTypes = {
  event: PropTypes.shape({
    event_type: PropTypes.string,
    status: PropTypes.oneOf([
      'pending',
      'approved',
      'rejected',
      'cancellation_pending',
      'cancellation_approved',
    ]),
    entitlement_used: PropTypes.shape({
      holiday: PropTypes.number,
      sickday: PropTypes.number,
    }),
    start_date: PropTypes.string,
    start_perc: PropTypes.number,
    end_date: PropTypes.string,
    end_perc: PropTypes.number,
    id: PropTypes.string,
    year: PropTypes.number,
    rejection_note: PropTypes.string,
    note: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        file_id: PropTypes.string,
        filename: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }),
  contractId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  allowEditing: PropTypes.bool,
  approveRejectButton: PropTypes.func,
  planYear: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
};

EventDetailsModal.defaultProps = {
  contractId: null,
  event: null,
  allowEditing: false,
  approveRejectButton: null,
};

export default EventDetailsModal;
