import React, { useContext } from 'react';
import { css } from '@emotion/core';
import { Auth, Context } from '@tg/core/components';
import { Segment } from 'semantic-ui-react';

export default () => {
  const {
    theme: { colors },
  } = useContext(Context);

  return (
    <div
      css={css`
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 120;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${colors.backgroundGrey};

        .segment {
          max-width: 450px;
          width: 100%;
        }
      `}
    >
      <Segment>
        <Auth />
      </Segment>
    </div>
  );
};
