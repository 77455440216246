/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Segment, Form } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatYMD } from '@tg/core/utils/datetimeHelpers';
import { useResource } from '@tg/core/hooks';
import PropTypes from 'prop-types';
import { Heading, DateField, Button, Message } from '@tg/core/components';

const TeamedActivitiesReport = ({ setSuccessModal, setFailModal }) => {
  const { control, handleSubmit, errors: fieldErrors } = useForm();
  const { t } = useTranslation('forms');

  const [error, setError] = useState('');

  const { postResource } = useResource({ url: 'reports/admin_activity' });

  const onSubmit = values => {
    const { start_date, end_date } = values;
    if (start_date && end_date) {
      setError(null);
      postResource({
        formData: {
          start_date: formatYMD(start_date),
          end_date: formatYMD(end_date),
        },
        onSuccess: response => {
          const res = 'not_found';
          if (response.status === res) {
            setFailModal(response.json.message);
          } else {
            setSuccessModal(true);
          }
        },
        onError: err => {
          setFailModal(err.response.error);
        },
      });
    } else {
      setError('End date must be greater than start date');
    }
  };

  return (
    <>
      <Segment>
        <Heading level='h3'>Teamed Admins activity log</Heading>
        {error && (
          <div className='mb-4'>
            <Message type='error'>{error}</Message>
          </div>
        )}
        <p>
          Report of all the activities the Teamed Admins have done in the
          platform.
        </p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Field required error={!!fieldErrors.start_date}>
              <label>Date from</label>
              <Controller
                as={DateField}
                control={control}
                name='start_date'
                defaultValue={new Date()}
                rules={{ required: t('fieldErrors.required') }}
              />
              {fieldErrors.start_date && (
                <p className='text-red'>{fieldErrors.start_date.message}</p>
              )}
            </Form.Field>

            <Form.Field required error={!!fieldErrors.end_date}>
              <label>Date to</label>
              <Controller
                as={DateField}
                control={control}
                name='end_date'
                defaultValue={new Date()}
                rules={{ required: t('fieldErrors.required') }}
              />
              {fieldErrors.end_date && (
                <p className='text-red'>{fieldErrors.end_date.message}</p>
              )}
            </Form.Field>
          </Form.Group>

          <Button type='submit'>Get report</Button>
        </Form>
      </Segment>
    </>
  );
};

TeamedActivitiesReport.propTypes = {
  setSuccessModal: PropTypes.func.isRequired,
  setFailModal: PropTypes.func.isRequired,
};

export default TeamedActivitiesReport;
