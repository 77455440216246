/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Button,
  ButtonBar,
  SelectField,
  FileField,
  DateField,
  CollectionInput,
} from '@tg/core/components';
import { useResource } from '@tg/core/hooks';
import { actions } from '@tg/core/store/modules/toasts';
import { formatYMD } from '@tg/core/utils/datetimeHelpers';

const CreateInvoice = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const gbp = useSelector(state => state.collections.currencies.allIds[0]);
  const { control, handleSubmit, register, errors: fieldErrors } = useForm();
  const [employeeFiles, setEmployeeFiles] = useState([]);
  const [contractorFiles, setContractorFiles] = useState([]);
  const { t } = useTranslation(['forms', 'datetime']);
  const { employerId } = useParams();

  const url = `employers/${employerId}/invoice_data_file`;

  const { postResource, isFetching } = useResource({ url });

  /* eslint-disable consistent-return */
  const onSubmit = values => {
    if (!employeeFiles.length) {
      // eslint-disable-next-line no-alert
      return alert('No employees file attached');
    }

    const formData = new FormData();
    formData.append('invoice_number', values.invoice_number);
    formData.append('invoice_date', formatYMD(values.invoice_date));
    formData.append('due_date', formatYMD(values.due_date));
    formData.append('total_gbp', values.total_gbp);
    formData.append('total_fees', values.total_fees);
    formData.append('total_vat', values.total_vat);
    formData.append('contractor_total_gbp', values.contractor_total_gbp);
    formData.append('month', values.month);
    formData.append('year', values.year);
    formData.append('currency_id', values.currency_id);
    formData.append('employee_file', employeeFiles[0]);
    if (contractorFiles.length)
      formData.append('contractor_file', contractorFiles[0]);

    postResource({
      formData,
      onSuccess: () => {
        setEmployeeFiles([]);
        setContractorFiles([]);
        onSuccess();
        dispatch(
          actions.addToast({
            id: 'create_invoice_success',
            type: 'success',
            children: 'Invoice Created',
          }),
        );
      },
      headers: {},
    });
  };
  /* eslint-enable consistent-return */

  const onEmployeeFileDrop = useCallback(dropped => {
    setEmployeeFiles(dropped);
  }, []);

  const onContractorFileDrop = useCallback(dropped => {
    setContractorFiles(dropped);
  }, []);

  const now = new Date();
  const defaultValues = {
    month: now.getMonth(),
    year: now.getFullYear(),
    currency_id: gbp,
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className='p-4'>
        <div className='mb-4'>
          <label>
            <strong>Employees file (required)</strong>
          </label>
          <FileField onDrop={onEmployeeFileDrop} />
          {employeeFiles.map(f => (
            <div key={f.name}>{f.name}</div>
          ))}
        </div>

        <div className='mb-4'>
          <label>
            <strong>Contractors file (optional)</strong>
          </label>
          <FileField onDrop={onContractorFileDrop} />
          {contractorFiles.map(f => (
            <div key={f.name}>{f.name}</div>
          ))}
        </div>

        <div className='grid gap-4 grid-cols-3'>
          <Form.Field required error={!!fieldErrors.invoice_number}>
            <label>Invoice number</label>
            <input
              name='invoice_number'
              ref={register({ required: t('errors.required') })}
            />
            {fieldErrors.year && <p>{fieldErrors.invoice_number.message}</p>}
          </Form.Field>

          <Form.Field required error={!!fieldErrors.invoice_date}>
            <label>Invoice date</label>
            <Controller
              as={DateField}
              control={control}
              name='invoice_date'
              defaultValue={new Date()}
              rules={{ required: t('fieldErrors.required') }}
            />
            {fieldErrors.invoice_date && (
              <p>{fieldErrors.invoice_date.message}</p>
            )}
          </Form.Field>

          <Form.Field required error={!!fieldErrors.due_date}>
            <label>Due date</label>
            <Controller
              as={DateField}
              control={control}
              name='due_date'
              defaultValue={new Date()}
              rules={{ required: t('fieldErrors.required') }}
            />
            {fieldErrors.due_date && <p>{fieldErrors.due_date.message}</p>}
          </Form.Field>
        </div>

        <div className='grid gap-4 grid-cols-2'>
          <Form.Field required error={!!fieldErrors.total_gbp}>
            <label>Invoice amount (total)</label>
            <input
              name='total_gbp'
              ref={register({ required: t('errors.required') })}
            />
            {fieldErrors.value && <p>{fieldErrors.total_gbp.message}</p>}
          </Form.Field>

          <Form.Field required error={!!fieldErrors.currency_id}>
            <label>Currency</label>
            <Controller
              as={<CollectionInput resource='currencies' search useName />}
              control={control}
              name='currency_id'
              defaultValue={defaultValues.currency_id}
              rules={{ required: t('errors.required') }}
            />
            {fieldErrors.month && <p>{fieldErrors.month.message}</p>}
          </Form.Field>
        </div>

        <div className='grid gap-4 grid-cols-3'>
          <Form.Field required error={!!fieldErrors.total_fees}>
            <label>Fees total</label>
            <input
              name='total_fees'
              ref={register({ required: t('errors.required') })}
            />
            {fieldErrors.value && <p>{fieldErrors.total_fees.message}</p>}
          </Form.Field>

          <Form.Field required error={!!fieldErrors.total_vat}>
            <label>VAT total</label>
            <input
              name='total_vat'
              ref={register({ required: t('errors.required') })}
            />
            {fieldErrors.value && <p>{fieldErrors.total_vat.message}</p>}
          </Form.Field>

          <Form.Field error={!!fieldErrors.contractor_total_gbp}>
            <label>Contractors total</label>
            <input
              name='contractor_total_gbp'
              defaultValue='0'
              ref={register()}
            />
            {fieldErrors.value && (
              <p>{fieldErrors.contractor_total_gbp.message}</p>
            )}
          </Form.Field>
        </div>

        <div className='grid gap-4 grid-cols-2'>
          <Form.Field required error={!!fieldErrors.month}>
            <label>Month</label>
            <Controller
              as={
                <SelectField
                  options={Array.from(new Array(12)).map((_, index) => ({
                    text: t(`datetime:monthnames.${index}`),
                    value: index,
                  }))}
                />
              }
              control={control}
              name='month'
              defaultValue={defaultValues.month}
              rules={{ required: t('errors.required') }}
            />
            {fieldErrors.month && <p>{fieldErrors.month.message}</p>}
          </Form.Field>

          <Form.Field required error={!!fieldErrors.year}>
            <label>Year</label>
            <input
              name='year'
              type='number'
              ref={register({ required: t('errors.required') })}
              defaultValue={defaultValues.year}
            />
            {fieldErrors.year && <p>{fieldErrors.year.message}</p>}
          </Form.Field>
        </div>
      </div>

      <ButtonBar>
        <Button type='submit' loading={isFetching}>
          Upload
        </Button>
      </ButtonBar>
    </Form>
  );
};

CreateInvoice.propTypes = {
  // url: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

CreateInvoice.defaultProps = {
  onSuccess: f => f,
};

export default CreateInvoice;
