import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { requestWithDispatch } from '@tg/core/utils/requestHelpers';
import { transformCollectionResponse } from '@tg/core/utils/dataTransformers';
import {
  formatLocaleDate,
  handleTimeZone,
} from '@tg/core/utils/datetimeHelpers';
import { Button, List, PaginationControl } from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import { useResource } from '@tg/core/hooks';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import Form from './Form';

const initialState = {
  fetching: false,
  data: null,
  error: null,
  ui: {
    create: false,
    update: null,
  },
};

const {
  EMPLOYER_DETAIL_VIEW_EMPLOYEES_CONTRACT: {
    OVERVIEW: { JOB_TITLES_EDIT, JOB_TITLES_CREATE_NEW },
  },
  EMPLOYER_DETAIL_VIEW_EMPLOYEE: {
    CONTRACT_OVERVIEW: { CONTRACT_JOB_TITLES_VERIFY },
  },
} = permissions;

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'FETCH_BEGIN':
    case 'CREATE_BEGIN':
    case 'UPDATE_BEGIN':
    case 'DELETE_BEGIN':
      return {
        ...state,
        fetching: true,
      };

    case 'FETCH_END':
    case 'CREATE_END':
    case 'UPDATE_END':
    case 'DELETE_END':
      return {
        ...state,
        fetching: false,
      };

    case 'FETCH_SUCCESS':
    case 'CREATE_SUCCESS':
    case 'UPDATE_SUCCESS':
    case 'DELETE_SUCCESS':
      return {
        ...state,
        data: transformCollectionResponse(payload.data),
        ui: initialState.ui,
      };

    case 'FETCH_FAILURE':
    case 'CREATE_FAILURE':
    case 'UPDATE_FAILURE':
    case 'DELETE_FAILURE':
      return {
        ...state,
        error: payload.data,
      };

    case 'CREATE':
      return {
        ...state,
        ui: {
          ...state.ui,
          create: true,
        },
      };

    case 'UPDATE':
      return {
        ...state,
        ui: {
          ...state.ui,
          update: payload,
        },
      };

    case 'CANCEL':
      return {
        ...state,
        ui: initialState,
      };

    default:
      return state;
  }
};

const ContractJobTitles = ({ currentJobTitle, getResource }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [confirm, setConfirm] = useState(false);
  const [page, setPage] = useState(1);
  const { t } = useTranslation(['contract_job_tiltes']);
  const token = useSelector(s => s.session.aut);
  const accesslist = useSelector(data => data?.access?.roles);

  const call = requestWithDispatch(dispatch, token);
  const [getId, setId] = useState();

  const { contractId } = useParams();

  const { pagination } = useResource(
    {
      url: `contracts/${contractId}/job_titles?page=${page}&pagesize=10`,
    },
    true,
  );

  useEffect(() => {
    if (page) {
      call({
        url: `contracts/${contractId}/job_titles?page=${page}&pagesize=10`,
        action: 'fetch',
      });
    }
  }, [page]);

  const onCreateSubmit = ({ job_title: { effective_date, ...rest } }) => {
    call({
      url: `contracts/${contractId}/job_titles`,
      action: 'create',
      method: 'post',
      data: {
        job_title: { effective_date: handleTimeZone(effective_date), ...rest },
      },
    }).then(() => setPage(1));
  };
  const onUpdateSubmit = ({ job_title: { effective_date, ...rest } }) => {
    const id = state.ui.update;
    call({
      url: `contracts/${contractId}/job_titles/${id}`,
      action: 'update',
      method: 'patch',
      data: {
        job_title: { effective_date: handleTimeZone(effective_date), ...rest },
      },
    }).then(() => setPage(1));
  };

  const onAprroveSubmit = id => {
    call({
      url: `contracts/${contractId}/job_titles/${id}/approve`,
      action: 'update',
      method: 'patch',
    }).then(() => {
      setPage(1);
      getResource();
    });
  };

  const onCancel = () => dispatch({ type: 'CANCEL' });

  const listData = state?.data?.byId ? Object.values(state?.data?.byId) : [];

  const selectEditableData = () => {
    return state.data.byId[state.ui.update];
  };

  const handleConfirm = event => {
    if (event.target.textContent === 'Yes') {
      dispatch({ type: 'UPDATE', payload: getId });
    }
  };

  return (
    <>
      {state.ui.create && (
        <Form onSubmit={onCreateSubmit} onCancel={onCancel} />
      )}
      {state.ui.update && (
        <Form
          onSubmit={onUpdateSubmit}
          onCancel={onCancel}
          data={selectEditableData}
        />
      )}

      <div className='mb-4'>
        <List
          headings={[
            { text: 'Job title' },
            { text: 'Effective from' },
            { text: '', align: 'right' },
          ]}
          items={listData.map(
            (
              { id, job_title, effective_date, editable = true, approve },
              index,
            ) => {
              return {
                id,
                columns: [
                  <span style={{ whiteSpace: 'normal' }}>{job_title}</span>,
                  formatLocaleDate(effective_date),
                  <span className='space-x-2'>
                    {getPermission(accesslist, CONTRACT_JOB_TITLES_VERIFY) && (
                      <>
                        {id !== currentJobTitle.id && approve === false ? (
                          <Button
                            color='quaternary'
                            onClick={() => onAprroveSubmit(id)}
                          >
                            {t(`contract_job_tiltes:verify`)}
                          </Button>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                    {id === currentJobTitle.id && (
                      <span className='font-semibold'>
                        {t(`contract_job_tiltes:current`)}
                      </span>
                    )}
                    {(editable || index === 0) && (
                      <>
                        {getPermission(accesslist, JOB_TITLES_EDIT) && (
                          <Button
                            size='small'
                            color='tertiary'
                            onClick={() => {
                              setConfirm(true);
                              setId(id);
                            }}
                          >
                            Edit
                          </Button>
                        )}
                        <Modal
                          open={confirm}
                          header={t(`contract_job_tiltes:contract_job_tilte`)}
                          content={t(`contract_job_tiltes:confirm`)}
                          actions={[
                            { key: 'No', content: 'No', negative: true },
                            { key: 'Yes', content: 'Yes', positive: true },
                          ]}
                          onActionClick={event => handleConfirm(event)}
                          size='mini'
                          onClose={() => setConfirm(false)}
                        />
                      </>
                    )}
                  </span>,
                ],
              };
            },
          )}
        />
      </div>

      {getPermission(accesslist, JOB_TITLES_CREATE_NEW) && (
        <Button onClick={() => dispatch({ type: 'CREATE' })}>Create new</Button>
      )}
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};

ContractJobTitles.propTypes = {
  getResource: PropTypes.func.isRequired,
  currentJobTitle: PropTypes.shape({
    id: PropTypes.string,
    job_title: PropTypes.string,
  }).isRequired,
};

export default ContractJobTitles;
