import React from 'react';
import { useParams } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
import { formatPhone } from '@tg/core/utils/stringHelpers';
import {
  FormBuilder,
  DisplayDetails,
  Container,
  Segment,
} from '@tg/core/components';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@tg/core/store/modules/toasts';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import permissions from '@tg/core/constant';
import ActionItem from '../../../ActionItem';
import { HolidayYearForm } from './HolidayYearForm/HolidayYearForm';

const {
  EMPLOYER_DETAIL_VIEW_OVERVIEW: {
    SETUP_CHECKLIST,
    SET_UP_PLANNER_YEAR,
    CODAT_XERO_CONNECTION,
    CODAT_QBO,
  },
} = permissions;

export default () => {
  const dispatch = useDispatch();
  const { employerId } = useParams();
  const accesslist = useSelector(state => state?.access?.roles);
  const { data, errors, isFetching, patchResource, getResource } = useResource(
    {
      url: `employers/${employerId}`,
    },
    true,
  );

  function replaceEmptyStringsWithNull(obj) {
    for (let key in obj) {
      if (obj[key] === '') {
        obj[key] = null;
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        replaceEmptyStringsWithNull(obj[key]);
      }
    }
    return obj;
  }

  const onSubmit = formData => {
    // Remove same_as_registered from the primary address (where it live in the UI) on to
    // registered_address.use_as_contact_address (where it lives on the backend)
    const { same_as_registered, ...primary_address } = formData.primary_address;

    const patchData = {
      ...formData,
      primary_address,
      registered_address: {
        ...formData.registered_address,
        use_as_contact_address: same_as_registered,
      },
    };
    if (!Object.keys(primary_address).length) {
      delete patchData.primary_address;
    }
    const updatedData = replaceEmptyStringsWithNull(patchData);

    patchResource({
      formData: updatedData,
      onSuccess: resData => {
        dispatch(
          actions.addToast({
            id: resData.id,
            type: 'success',
            children: 'Saved',
          }),
        );
      },
    });
  };

  if (data) {
    const {
      approved_at,
      addresses,
      key_contact: { job_title, user },
      welcome_completed_at,
      welcome_email_sent_at,
      ...employer
    } = data;

    const registered_address =
      addresses.find(x => x.address_type === 'registered') || {};

    const existingPrimaryAdd = addresses.find(
      x => x.address_type === 'trading',
    );
    const primary_address = existingPrimaryAdd
      ? {
          ...existingPrimaryAdd,
          same_as_registered: false,
        }
      : {
          same_as_registered: true,
        };

    return (
      <Container>
        <div className='space-y-6'>
          {getPermission(accesslist, SETUP_CHECKLIST) && (
            <Segment heading='Setup checklist'>
              <ActionItem
                url={`employers/${employerId}/send_welcome`}
                timestamp={welcome_email_sent_at}
                checklist
              >
                Send welcome email
              </ActionItem>
            </Segment>
          )}

          <Segment heading='Optional actions'>
            <p>
              NOTE: Emails will be sent a maximum of once in a 24 hour period
            </p>

            {!welcome_completed_at && (
              <ActionItem
                url={`employers/${employerId}/send_welcome`}
                timestamp={welcome_email_sent_at}
                repeatable
                onSuccess={getResource}
              >
                Resend welcome email
              </ActionItem>
            )}
          </Segment>

          <Segment heading='Key contact'>
            <DisplayDetails
              details={[
                {
                  title: 'Name',
                  value: user.full_name,
                  width: '50%',
                },
                {
                  title: 'Job title',
                  value: job_title,
                  width: '50%',
                },
                {
                  title: 'Email',
                  value: user.email,
                  width: '50%',
                },
                {
                  title: 'Phone',
                  value: formatPhone(user.phone),
                  width: '50%',
                },
              ]}
            />
          </Segment>
          {getPermission(accesslist, SET_UP_PLANNER_YEAR) && (
            <Segment heading='Set up Holiday Year'>
              <HolidayYearForm />
            </Segment>
          )}

          <Segment>
            <FormBuilder
              onSubmit={onSubmit}
              isFetching={isFetching}
              errors={errors}
              values={{
                employer,
                registered_address,
                primary_address,
                contract_defaults: employer,
                codat_connection: employer,
                xero_connection: employer,
              }}
              fieldsets={[
                {
                  id: 'employer',
                  fieldset: 'employer',
                  title: 'Company details',
                },
                {
                  id: 'registered_address',
                  fieldset: 'address',
                  title: 'Registered address',
                },
                {
                  id: 'primary_address',
                  fieldset: 'address_with_same_as',
                  title: 'Primary address',
                },
                {
                  id: 'contract_defaults',
                  fieldset: 'contract_defaults',
                  title: 'Contract defaults',
                },
                ...(getPermission(accesslist, CODAT_QBO)
                  ? [
                      {
                        id: 'codat_connection',
                        fieldset: 'codat_connection',
                        title: 'Codat Quickbook online connection',
                      },
                    ]
                  : []),
                ...(getPermission(accesslist, CODAT_XERO_CONNECTION)
                  ? [
                      {
                        id: 'xero_connection',
                        fieldset: 'xero_connection',
                        title: 'Codat Xero connection',
                      },
                    ]
                  : []),
              ]}
              submitButtonText='Save changes'
            />
          </Segment>
        </div>
      </Container>
    );
  }

  return null;
};
