import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  SelectField,
  Button,
  LabelledInput,
  ButtonBar,
} from '@tg/core/components';
import { Form } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permissionHelpers';

const { ROOT_API } = process.env;

const {
  EMPLOYER_DETAIL_VIEW_EMPLOYEES_CONTRACT: {
    OVERVIEW: { LINE_MANAGER_EDIT },
  },
} = permissions;

const LineManager = ({ contract }) => {
  const token = useSelector(s => s.session.aut);
  const [officers, setOfficers] = useState([]);
  const [fetching, setFetching] = useState(false);
  const accesslist = useSelector(state => state?.access?.roles);

  const [lineManager, setLineManager] = useState(contract?.line_manager?.id);
  const { control, handleSubmit, errors: fieldErrors } = useForm();
  const { t } = useTranslation(['collections', 'forms']);

  const instance = axios.create({
    baseURL: ROOT_API,
    headers: { Authorization: `Bearer ${token}` },
  });

  useEffect(() => {
    setFetching(true);
    instance
      .get(`/employers/${contract.employer.id}/officers?page=1&pagesize=50`)
      .then(({ data }) => {
        setOfficers(
          data.data.map(({ id, user: { full_name } }) => ({
            key: id,
            value: id,
            text: full_name,
          })),
        );
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setFetching(false);
      });
  }, []);

  const onSubmit = values => {
    setFetching(true);
    instance
      .patch(`/contracts/${contract.id}`, { contract: { ...values } })
      .then(({ data }) => {
        setLineManager(data.data.line_manager.id);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setFetching(false);
      });
  };

  return officers.length ? (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className='p-4'>
        <LabelledInput
          label='Officer to whom time off requests will be sent (defaults to key
          contact if not set)'
          id='line_manager_select'
          error={
            fieldErrors.line_manager_id && fieldErrors.line_manager_id.message
          }
          required
        >
          <Controller
            as={
              <SelectField
                placeholder='Select a line Manager'
                options={officers}
              />
            }
            control={control}
            name='line_manager_id'
            rules={{ required: t('forms:errors.required') }}
            defaultValue={
              officers.some(obj => obj.value === lineManager)
                ? lineManager
                : undefined
            }
          />
        </LabelledInput>
      </div>
      {getPermission(accesslist, LINE_MANAGER_EDIT) && (
        <ButtonBar>
          <Button type='submit' disabled={fetching} loading={fetching}>
            Save
          </Button>
        </ButtonBar>
      )}
    </Form>
  ) : null;
};

LineManager.propTypes = {
  contract: PropTypes.shape().isRequired,
};

export default LineManager;
