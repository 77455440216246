import React, { useState } from 'react';
import { useResource } from '@tg/core/hooks';
import {
  PageHeader,
  List,
  Button,
  PaginationControl,
} from '@tg/core/components';
import AddEditModal from '../components/GenericAddEditModal';

const IndustrySectors = () => {
  const [page, setPage] = useState(1);
  const { data, isFetching, postResource, pagination } = useResource(
    { url: `industry_sectors?page=${page}&pagesize=10` },
    true,
  );

  const listItems = data
    ? data.map(currency => ({
        id: currency.id,
        columns: [currency.name, currency.code],
      }))
    : [];

  const [addModalOpen, setAddModalOpen] = useState(false);

  return (
    <>
      <PageHeader
        heading='Industry Sectors'
        action={<Button onClick={() => setAddModalOpen(true)}>Add New</Button>}
      />

      <List
        loading={isFetching}
        items={listItems}
        headings={[{ text: 'Name' }, { text: 'Code', width: '70px' }]}
      />

      <AddEditModal
        isOpen={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        isFetching={isFetching}
        postResource={postResource}
        data={data}
      />
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};

export default IndustrySectors;
