import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { selectSessionUser } from '@tg/core/store/modules/session';
import { useDispatch, useSelector } from 'react-redux';
import { useResource } from '@tg/core/hooks';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import permissions from '@tg/core/constant';
import AccessDenied from '@tg/core/components/molecules/AccessDenied/AccessDenied';
import ListEmployers from './ListEmployers';
import CreateEmployer from './CreateEmployer';
import ShowEmployer from './ShowEmployer';

const { EMPLOYERS_ADD_EMPLOYER } = permissions;

export default () => {
  const { path, url } = useRouteMatch();

  const user = useSelector(state => selectSessionUser(state));
  const dispatch = useDispatch();
  const accesslist = useSelector(state => state?.access?.roles);
  const [role, setRole] = useState(null);

  useEffect(() => {
    if (user) {
      const defaultRole = user?.roles?.sort((a, b) => {
        const roleA = a.name.toUpperCase();
        const roleB = b.name.toUpperCase();
        const SuperAdmin = 'Teamed Super Admin';

        // If one of the roles is the specific role, prioritize it
        /* eslint no-else-return: "error" */
        if (roleA === SuperAdmin.toUpperCase()) {
          return -1;
        } else if (roleB === SuperAdmin.toUpperCase()) {
          return 1;
        }

        if (roleA < roleB) {
          return -1;
        }
        if (roleA > roleB) {
          return 1;
        }
        return 0;
      });

      if (defaultRole) {
        setRole(defaultRole[0]?.id);
      }
    }
  }, [user]);

  const getId = useSelector(state => state?.access?.rolesId);

  const seleteId = getId || role;
  if (!getId) {
    dispatch({
      type: 'ADD_ROLE_ID',
      payload: seleteId,
    });
  }

  const { data: getAccessData } = useResource(
    { url: `${seleteId}/permissions` },
    true,
  );

  if (getAccessData) {
    dispatch({
      type: 'ADD_ACCESS',
      payload: getAccessData,
    });
  }

  return (
    <Switch>
      <Route exact path={path}>
        <ListEmployers />
      </Route>
      <Route path={`${path}/create`}>
        {getPermission(accesslist, EMPLOYERS_ADD_EMPLOYER) ? (
          <CreateEmployer baseUrl={url} />
        ) : (
          <AccessDenied />
        )}
      </Route>
      <Route path={`${path}/:employerId`}>
        <ShowEmployer />
      </Route>
    </Switch>
  );
};
