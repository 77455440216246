import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Search, Loader } from 'semantic-ui-react';
import {
  PaginationControl,
  LabelledInput,
  PageHeader,
  List,
  SelectField,
  Status,
} from '@tg/core/components';
import { capitalise } from '@tg/core/utils/stringHelpers';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permissionHelpers';

const {
  EMPLOYEE_DETAIL: { CLICK_ON_LABEL },
} = permissions;

export default () => {
  const { url } = useRouteMatch();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectData, setSelectData] = useState(null);
  const [filterBy, setFilterBy] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [fetchingResource, setFetchingResource] = useState(null);
  const { ROOT_API } = process.env;
  const token = useSelector(s => s.session.aut);

  const accesslist = useSelector(state => state?.access?.roles);

  let endpointUrl = `employees?page=${page}&pagesize=50`;
  if (searchTerm) {
    endpointUrl += `&with_name=${searchTerm}`;
  }
  if (filterBy && selectedValue) {
    endpointUrl += `&filter_by=${filterBy}&selected_filter=${selectedValue}`;
  }
  if (sortBy && sortOrder) {
    endpointUrl += `&sort_by=${sortBy}&sort_order=${sortOrder}`;
  }

  const { data, isFetching, pagination } = useResource(
    { url: endpointUrl },
    true,
  );

  const filterByOptions = [
    { text: 'Employer', value: 'employer' },
    { text: 'Employee Status', value: 'employee_status' },
    { text: 'Domicile', value: 'domicile' },
  ];

  const getEmployersList = selectValue => {
    setSelectedValue(selectValue);
    setPage(1);
  };

  const getFilterResource = filter => {
    const apiText = filter === 'employer' ? '/employers/name' : `/${filter}`;
    axios({
      url: `${ROOT_API}${apiText}s?pagination=false`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'get',
    }).then(response => {
      if (filter === 'employer') {
        const {
          data: {
            json: { data: items },
          },
        } = response;

        setSelectData(
          items.map(item => {
            return { text: item.name, value: item.id, key: item.id };
          }),
        );
      } else {
        const {
          data: { data: items },
        } = response;

        setSelectData(
          items.map(item => {
            return { text: item.name, value: item.id, key: item.id };
          }),
        );
      }
      setFetchingResource(false);
    });
  };
  const getData = filter => {
    setSelectedValue(null);
    setFilterBy(filter);
    if (filter === 'employer' || filter === 'domicile') {
      setFetchingResource(true);
      getFilterResource(filter);
    } else if (filter === 'employee_status') {
      const employerStatusList = [
        { text: 'Active', value: 'active', key: 'active' },
        { text: 'Inactive', value: 'inactive', key: 'inactive' },
        { text: 'Pending', value: 'pending', key: 'pending' },
      ];
      setSelectData(employerStatusList);
    }
  };

  const getIconStatus = (color, text) => {
    return <Status color={color} text={capitalise(text)} type='status' />;
  };

  const toggleSort = (sortByValue, sortOrderValue) => {
    let sortOrderCopy;
    if (
      sortBy !== sortByValue ||
      !sortOrderValue ||
      sortOrderValue === 'desc'
    ) {
      sortOrderCopy = 'asc';
    } else {
      sortOrderCopy = 'desc';
    }
    setSortOrder(sortOrderCopy);
    setSortBy(sortByValue);
    setPage(1);
  };

  const getStatus = contracts => {
    const { status } = contracts[0];
    let retValue;
    switch (status) {
      case 'terminated':
      case 'ended':
        retValue = getIconStatus('red', 'Inactive');
        break;
      case 'active':
        retValue = getIconStatus('green', status);
        break;
      default:
        retValue = getIconStatus('grey', status);
    }
    return retValue;
  };

  const clearSortAndSearch = () => {
    setFilterBy(null);
    setSearchTerm('');
    setSelectData(null);
    setSelectedValue(null);
    setSortBy('');
    setSortOrder('');
    setPage(1);
  };

  const listColumns = [
    { key: 'name', value: 'Name' },
    { key: 'email', value: 'Email' },
    { key: 'domicile', value: 'Domicile' },
    { key: 'start_date', value: 'Start date' },
    { key: 'end_date', value: 'End date' },
    { key: 'status', value: 'Status' },
  ];
  return (
    <>
      <PageHeader
        heading='Employees'
        type='employees'
        description='These are users of the Teamed platform. Although they will most
            likely have a contract and employer, they may not.'
        filter={
          <>
            <div className='grid grid-cols-1'>
              <div className='ml-4'>
                <LabelledInput id='filter_by' label='Filter By'>
                  <SelectField
                    options={filterByOptions}
                    onChange={filter => getData(filter)}
                    loading={fetchingResource}
                    placeholder='Filter By'
                    value={filterBy}
                    className='w-64'
                  />
                </LabelledInput>
              </div>
            </div>
            <div className='grid grid-cols-1'>
              <div className='ml-4'>
                <LabelledInput id='select' label='Select'>
                  <SelectField
                    options={selectData}
                    loading={fetchingResource}
                    onChange={selectValue => {
                      getEmployersList(selectValue);
                    }}
                    placeholder='Select from the dropdown'
                    value={selectedValue}
                    className='w-66'
                    disabled={!filterBy}
                  />
                </LabelledInput>
              </div>
            </div>
          </>
        }
        action={
          <>
            <div className='ml-4'>
              <LabelledInput
                label='Search Employees'
                id='employee-search-input'
              >
                <Search
                  value={searchTerm}
                  onSearchChange={event => {
                    setSearchTerm(event.target.value);
                    setPage(1);
                  }}
                  placeholder='Search by employee name'
                  loading={fetchingResource}
                  showNoResults={false}
                />
              </LabelledInput>
              <div className='text-right mr-10'>
                <button
                  type='button'
                  className='link'
                  onClick={() => {
                    clearSortAndSearch();
                  }}
                >
                  Clear Sort & Search
                </button>
              </div>
            </div>
          </>
        }
      />
      {data && !!data.length ? (
        <div className='mb-6'>
          <List
            type='employee'
            headings={listColumns.map(listColumn => ({
              text: (
                <div
                  style={{
                    display: 'flex',
                  }}
                  onClick={() => toggleSort(listColumn.key, sortOrder)}
                  aria-hidden='true'
                >
                  <div
                    style={{
                      marginTop: '0.3rem',
                    }}
                  >
                    {listColumn.value}
                  </div>
                  {listColumn.key === sortBy && (
                    <div
                      style={{
                        marginTop: sortOrder === 'desc' ? '0.5rem' : '0.2rem',
                      }}
                    >
                      <i
                        className={`icon sort ${
                          sortOrder === 'asc' ? 'down' : 'up'
                        }`}
                      />
                    </div>
                  )}
                  {!sortBy && (
                    <div>
                      <div className='float-left mt-1'>
                        <i className='icon sort up' />
                      </div>
                      <div className='float-left clear-left -mt-5 z-0'>
                        <i className='icon sort down' />
                      </div>
                    </div>
                  )}
                </div>
              ),
            }))}
            items={data.map(
              ({ id, user: { full_name, email }, domicile, contracts }) => {
                return {
                  id,
                  columns: [
                    <>
                      {getPermission(accesslist, CLICK_ON_LABEL) ? (
                        <Link
                          to={`${url}/${id}`}
                          className='font-semibold'
                          Contract
                          Overview
                        >
                          {full_name}
                        </Link>
                      ) : (
                        <div>{full_name}</div>
                      )}
                    </>,
                    <div className='break-words'>{email}</div>,
                    <div className='flex items-center space-x-4'>
                      <img src={domicile.flag} alt='' className='w-6' />
                      <span>{domicile.name}</span>
                    </div>,
                    <div>{contracts[0]?.start_date}</div>,
                    <div>{contracts[0]?.end_date || 'NA'}</div>,
                    <div>{getStatus(contracts)}</div>,
                  ],
                };
              },
            )}
          />
        </div>
      ) : (
        <div className='text-center font-semibold'>
          {isFetching ? (
            <div
              className='flex items-center justify-center flex-col'
              style={{ marginTop: '100px' }}
            >
              <Loader size='massive' active inline='centered' />
              <span style={{ fontWeight: 400, marginTop: 5 }}>Loading...</span>
            </div>
          ) : (
            'No data found'
          )}
        </div>
      )}
      {pagination && (
        <div className='mb-6'>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};
