import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LoadingSpinner } from '@tg/core/components';

const Button = React.forwardRef(function Button(
  { to, children, size, type, fluid, color, loading, ...props },
  ref,
) {
  const sizesMap = {
    small: 'text-xs px-4 py-2',
    medium: 'text-sm px-6 py-3',
    large: 'text-lg px-6 py-3',
  };
  const colorsMap = {
    primary: [
      'uppercase',
      'font-bold',
      'rounded-full',
      'bg-red-500',
      'text-white',
      'hover:bg-red-600',
      'hover:text-white',
    ],
    secondary: [
      'uppercase',
      'font-bold',
      'rounded-full',
      'border-2',
      'border-dark-blue',
      'text-dark-blue',
      'hover:border-dark-blue-light',
      'hover:text-dark-blue-light',
    ],
    tertiary: [
      'rounded-md',
      'font-semibold',
      'border',
      'border-gray-300',
      'text-gray-700',
      'bg-white',
      'leading-4',
      'font-medium',
      'hover:bg-gray-50',
    ],
    quaternary: [
      'font-bold',
      'bg-red-500',
      'text-white',
      'hover:bg-red-600',
      'hover:text-white',
      'rounded-md',
    ],
  };

  const buttonClassName = classNames(
    [
      'inline-block',
      'relative',
      'text-center',
      'leading-none',
      'transition-colors',
      'transition-opacity',
      'focus:outline-none',
      'focus:ring',
      'disabled:opacity-50',
      'disabled:pointer-events-none',
      ...colorsMap[color],
      sizesMap[size],
    ],
    {
      'w-full': fluid,
    },
  );

  if (to) {
    return (
      <Link to={to} className={buttonClassName} ref={ref}>
        {children}
      </Link>
    );
  }

  /* eslint-disable react/button-has-type */
  return (
    <button type={type} className={buttonClassName} ref={ref} {...props}>
      <span className={classNames({ invisible: loading })}>{children}</span>

      {loading && (
        <span
          className='absolute inset-0 flex items-center justify-center'
          aria-label='Loading'
        >
          <LoadingSpinner />
        </span>
      )}
    </button>
  );
});

Button.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  fluid: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  loading: PropTypes.bool,
};

Button.defaultProps = {
  to: null,
  type: 'button',
  fluid: false,
  size: 'medium',
  color: 'primary',
  loading: false,
};

export default Button;
