export const payroll_upload_data = [
  {
    'Employee ID*': '',
    'Employer ID': '',
    'Currency*': '',
    'Gross salary*': '',
    Overtime: '',
    'Holidays accrual': '',
    '13th month': '',
    '14th month': '',
    'Vacation Pay': '',
    'Expenses*': '',
    'Bonuses*': '',
    Commissions: '',
    'Benefits - Healthcare': '',
    'Benefits - Meal allowance': '',
    'Benefits - Private Pension': '',
    'Benefits - Stipends': '',
    'Benefits - Company car': '',
    'Benefits - Other': '',
    'Employer contributions*': '',
    'Employee - Deductions': '',
    'Employee - social security': '',
    'Employee -  Income Taxes': '',
    'Employee - Other': '',
    'TEC*': '',
    'Exchange Rate': '',
    'Fee Exchange Rate': '',
    'Salary Fee Exchange rate': '',
  },
];
