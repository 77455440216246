import React, { Suspense } from 'react';

const EditorField = React.lazy(() => import('./EditorField'));

/**
 * Lazyloads the EditorField component so that DraftJs and its dependencies
 * are placed in a seperate chunk and only downloaded when used.
 */
const EditorFieldLoader = props => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <EditorField {...props} />
    </Suspense>
  );
};

export default EditorFieldLoader;
