import React from 'react';
import PropTypes from 'prop-types';
import { useResource } from '@tg/core/hooks';
import { FormBuilder } from '@tg/core/components';

const AddressForm = ({ type, entity, title }) => {
  const { data, patchResource, errors, isFetching } = useResource(
    {
      url: `${entity}/address?address_type=${type}`,
    },
    true,
  );

  const onSubmit = formData => {
    patchResource({ formData });
  };

  if (data) {
    return (
      <FormBuilder
        onSubmit={onSubmit}
        isFetching={isFetching}
        errors={errors}
        values={{
          address: data,
        }}
        fieldsets={[{ id: 'address', fieldset: 'address', title }]}
      />
    );
  }

  return null;
};

AddressForm.propTypes = {
  type: PropTypes.oneOf(['registered', 'trading', 'home']).isRequired,
  entity: PropTypes.oneOf(['employers', 'employees']).isRequired,
  title: PropTypes.string.isRequired,
};

export default AddressForm;
