import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
import { formatDate } from '@tg/core/utils/datetimeHelpers';
import {
  Segment,
  PageHeader,
  List,
  Button,
  Avatar,
  PaginationControl,
} from '@tg/core/components';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { requestWithDispatch } from '@tg/core/utils/requestHelpers';
import AddEditModal from './AddEditModal';

const {
  EMPLOYER_DETAIL_VIEW_OFFICERS: { EDIT },
} = permissions;

const EmployerOfficers = () => {
  const { employerId } = useParams();
  const [page, setPage] = useState(1);
  const accesslist = useSelector(state => state?.access?.roles);
  const dispatch = useDispatch();
  const token = useSelector(s => s.session.aut);
  const call = requestWithDispatch(dispatch, token);
  const [employees, setEmployees] = useState([]);
  const { data, pagination, postResource, patchResource, getResource } =
    useResource(
      { url: `employers/${employerId}/officers?page=${page}&pagesize=10` },
      true,
    );

  const { data: employeesList, getResource: getEmployees } = useResource(
    {
      url: `employers/${employerId}/contract_list`,
    },
    true,
  );

  const formatLocaleDate = dateStr => formatDate({ dateStr, incTime: true });

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOfficer, setModalOfficer] = useState(null);
  const [err, setError] = useState(null);
  const openModal = ({ id, user, job_title, assigned_employees }) => {
    setModalOpen(true);
    // For editing existing officer
    if (id) {
      setModalOfficer({ id, user, job_title, assigned_employees });
    }
  };

  useEffect(() => {
    if (employeesList) {
      const newArray = employeesList.map(obj => ({
        key: obj.employee.user.id,
        value: obj.employee.user.id,
        text: `${obj.employee.user.full_name}`,
      }));
      setEmployees(newArray);
    }
  }, [employeesList]);

  const checkOfficerStatus = id => {
    call({
      url: `employers/${employerId}/officer/${id}/activate_deactive`,
      action: 'update',
      method: 'patch',
    }).then(() => getResource());
  };

  const onSaveSuccess = () => {
    setError(null);
    setModalOpen(false);
    getEmployees();
  };

  const onFailure = err => {
    setError(err?.response?.error);
  };

  return (
    <>
      <PageHeader
        heading='Officers'
        action={
          <Button size='small' onClick={openModal}>
            Add Officer
          </Button>
        }
      />

      <Segment>
        {data && (
          <>
            <List
              headings={[
                { text: 'Name' },
                { text: 'Role' },
                { text: 'Last Login' },
                { text: '', align: 'right' },
              ]}
              items={data.map(
                ({ id, user, assigned_employees, key_contact }) => ({
                  id,
                  columns: [
                    <Avatar size='small' user={user} subHeading={user.email} />,
                    <div style={{ whiteSpace: 'normal' }}>
                      {user?.roles?.map(value => value.name).join(', ')}
                    </div>,
                    user.last_login ? formatLocaleDate(user.last_login) : '',
                    <div>
                      {getPermission(accesslist, EDIT) && (
                        <div className='space-x-2'>
                          <Button
                            onClick={() =>
                              openModal({
                                id,
                                user,
                                assigned_employees,
                              })
                            }
                            size='small'
                            color='tertiary'
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => checkOfficerStatus(id)}
                            color='tertiary'
                            size='small'
                            disabled={key_contact}
                          >
                            {!user?.suspended_by_id ? 'Delete' : 'Enable'}
                          </Button>
                        </div>
                      )}
                    </div>,
                  ],
                }),
              )}
            />
          </>
        )}
      </Segment>
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
      <AddEditModal
        err={err}
        setError={setError}
        isOpen={modalOpen}
        employees={employees}
        modalOfficer={modalOfficer}
        setModalOfficer={setModalOfficer}
        onClose={() => setModalOpen(false)}
        datas={data}
        onSave={formData => {
          if (modalOfficer) {
            patchResource({
              id: modalOfficer.id,
              formData,
              onSuccess: onSaveSuccess,
              onError: err => onFailure(err),
            });
          } else {
            postResource({
              formData,
              onSuccess: onSaveSuccess,
              onError: err => onFailure(err),
            });
          }
        }}
      />
    </>
  );
};

export default EmployerOfficers;
