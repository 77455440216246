import React, { useState } from 'react';
import { Button, List } from '@tg/core/components';
import { useResource } from '@tg/core/hooks';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actions } from '@tg/core/store/modules/toasts';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Dropdown,
  Modal,
  Input,
  Popup,
  Select,
} from 'semantic-ui-react';
import { css } from '@emotion/core';
import { getYears } from '@tg/core/utils/datetimeHelpers';

const PayrollInvoice = () => {
  const { employerId } = useParams();
  const [successModal, setSuccessModal] = useState(null);
  const [failModal, setFailModal] = useState(null);
  const today = new Date();
  const [month, setMonth] = useState(
    today.toLocaleString('en-us', { month: 'short' }).toLocaleUpperCase(),
  );
  const [year, setYear] = useState(today.getFullYear());
  const { t } = useTranslation(['datetime']);
  const dispatch = useDispatch();
  const { isFetching, data, deleteResource } = useResource(
    {
      url: `employers/${employerId}/payroll_invoices?month=${month}&year=${year}`,
    },
    true,
  );

  const { postResource } = useResource({
    url: `employers/${employerId}/payroll_invoices/generate_invoice`,
  });

  const clearPayrollData = id => {
    deleteResource({
      id,
      onSuccess: () => {
        dispatch(
          actions.addToast({
            id: 'clear_success',
            type: 'success',
            title: 'Deleted',
            children: 'Payroll data cleared Successfully',
          }),
        );
      },
      onError: error => {
        dispatch(
          actions.addToast({
            id: 'clear_error',
            type: 'error',
            title: 'Error',
            children: error.response.error,
          }),
        );
      },
    });
  };

  const selectStatus = [
    { key: 'gross_salary', value: 'gross_salary', text: 'Gross salary' },
    {
      key: 'monthly_acccrual',
      value: 'monthly_acccrual',
      text: 'Monthly acccrual',
    },
    { key: 'month_13', value: 'month_13', text: '13th month' },
    { key: 'month_14', value: 'month_14', text: '14th month' },
    { key: 'vacation_pay', value: 'vacation_pay', text: 'Vacation pay' },
    { key: 'expenses', value: 'expenses', text: 'Expenses' },
    { key: 'bonuses', value: 'bonuses', text: 'Bonuses' },
    { key: 'commisions', value: 'commisions', text: 'Commisions' },
    { key: 'deductions', value: 'deductions', text: 'Employee - Deductions' },
    {
      key: 'benefits_healthcare',
      value: 'benefits_healthcare',
      text: 'Benefits - Healthcare',
    },
    {
      key: 'benefits_meal',
      value: 'benefits_meal',
      text: 'Benefits - Meal allowance',
    },
    {
      key: 'benefits_ncpc',
      value: 'benefits_ncpc',
      text: 'Benefits - Private Pension',
    },
    {
      key: 'benefits_stipend_w',
      value: 'benefits_stipend_w',
      text: 'Benefits - Stipends',
    },
    {
      key: 'benefits_company_car',
      value: 'benefits_company_car',
      text: 'Benefits - Company car',
    },
    {
      key: 'contribution',
      value: 'contribution',
      text: 'Employer contributions',
    },
    { key: 'overtime', value: 'overtime', text: 'Overtime' },
    {
      key: 'benefits_others',
      value: 'benefits_others',
      text: 'Benefits - Other',
    },
  ];

  const selectedPayrolls = {};

  const getValuesFromDropdown = dropdownId => {
    const selectedAttributes = [];
    const dropdownNode = document.getElementsByClassName(dropdownId);
    for (let i = 0; i < dropdownNode.length; i += 1) {
      if (dropdownNode[i].firstElementChild.checked) {
        const nodeValue =
          dropdownNode[i].attributes.key1.nodeValue.split(' ')[0];
        selectedAttributes.push(nodeValue);
      }
    }
    return selectedAttributes;
  };

  const handleCheckboxChange = (_, { checked, id }) => {
    const payrollInvoiceId = id.split(' ').pop();
    if (checked) {
      selectedPayrolls[payrollInvoiceId] = {
        id: payrollInvoiceId,
        selected_attributes: getValuesFromDropdown(payrollInvoiceId),
        teamed_fee:
          document.getElementById(`teamed_fee ${payrollInvoiceId}`).value === ''
            ? 0
            : document.getElementById(`teamed_fee ${payrollInvoiceId}`).value,
        vat:
          document.getElementById(`vat ${payrollInvoiceId}`).value === ''
            ? 0
            : document.getElementById(`vat ${payrollInvoiceId}`).value,
      };
    } else {
      delete selectedPayrolls[payrollInvoiceId];
    }
  };

  const handleDropdownChange = (event, { key1 }) => {
    const getId = event.currentTarget.className;
    const val = getId.split(' ').pop();
    const getValue = key1.split(' ')[0];
    if (selectedPayrolls[val]) {
      let selectedEntity = selectedPayrolls[val].selected_attributes;
      if (event.target.checked) {
        selectedEntity.push(getValue);
      } else {
        selectedEntity = selectedEntity.filter(item => item !== getValue);
      }
      selectedPayrolls[val].selected_attributes = selectedEntity;
    }
  };

  const handleFeeChange = (event, { value, id }) => {
    const feeId = id.split(' ').pop();
    if (selectedPayrolls[feeId]) {
      selectedPayrolls[feeId].teamed_fee = value;
    }
  };

  const handleVatChange = (event, { value, id }) => {
    const vatId = id.split(' ').pop();
    if (selectedPayrolls[vatId]) {
      selectedPayrolls[vatId].vat = value;
    }
  };

  const onSubmit = () => {
    if (Object.keys(selectedPayrolls).length !== 0) {
      const payrollData = {};
      const selectedData = [];
      Object.values(selectedPayrolls).forEach(val => {
        selectedData.push(val);
      });

      payrollData.payroll_invoices = selectedData;
      payrollData.month = month;
      payrollData.year = year;

      postResource({
        formData: payrollData,
        onSuccess: res => {
          setSuccessModal(
            res?.json?.message ??
              'This may take some time. Please be patient...',
          );
        },
        onError: error => {
          setFailModal(error.response.error);
        },
      });
    } else {
      setFailModal('Please select employees');
    }
  };

  const preventDecimalPlaces = event => {
    if (event.target.value.split('.')[1]?.length > 1) {
      event.preventDefault();
    }
  };

  const { data: employerData } = useResource(
    { url: `employers/${employerId}` },
    true,
  );

  const setOptionText = payroll_invoice => {
    const updatedText = selectStatus.map(({ key, text }) => ({
      key: `${key} ${payroll_invoice.id}`,
      value: `${payroll_invoice[key]}`,
      text: `${text}: ${payroll_invoice[key]}`,
    }));
    return updatedText;
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <h3 style={{ marginRight: 'auto', maxWidth: '630px' }}>
          {employerData?.name}
        </h3>
        <div>
          {data && !!data.length ? (
            <>
              <Select
                style={{ marginRight: '5px', minWidth: '120px' }}
                defaultValue={new Date().getMonth()}
                options={Array.from(new Array(12)).map((_, index) => ({
                  text: t(`datetime:monthnames.${index}`),
                  value: index,
                }))}
                onChange={e => {
                  setMonth(e.target.textContent);
                }}
              />
              <Select
                style={{ marginRight: '5px', minWidth: '120px' }}
                defaultValue={year}
                options={getYears.map(item => ({
                  text: item,
                  value: item,
                }))}
                onChange={e => {
                  setYear(e.target.textContent);
                }}
              />
              <Button size='medium' color='primary' onClick={onSubmit}>
                Generate Invoice
              </Button>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <div style={{ marginTop: '60px' }}>
        {data && !!data.length && (
          <div className='mb-6'>
            <List
              headings={[
                { text: 'Employee', align: 'left', width: '135px' },
                { text: 'Status', align: 'left', width: '105px' },
                { text: 'Country  ', align: 'left', width: '80px' },
                { text: 'Month ', align: 'left', width: '60px' },
                { text: 'Configure ', align: 'left', width: '490px' },
                { text: 'Actions', align: 'left', width: '110px' },
              ]}
              items={data.map(
                ({
                  id,
                  employee_name,
                  payroll_invoice,
                  country_name,
                  teamed_fee,
                  vat,
                }) => {
                  return {
                    id,
                    columns: [
                      <div>
                        <Popup
                          trigger={
                            <Checkbox
                              id={`checkbox ${payroll_invoice?.id}`}
                              label={
                                employee_name.length < 15
                                  ? employee_name
                                  : `${employee_name.substring(0, 12)}...`
                              }
                              value={payroll_invoice}
                              onChange={handleCheckboxChange}
                              disabled={!payroll_invoice}
                            />
                          }
                          content={employee_name}
                          on='hover'
                          hideOnScroll
                        />
                      </div>,
                      <div>
                        {payroll_invoice === null ? 'NOT RECEIVED' : 'RECEIVED'}
                      </div>,
                      <Popup
                        trigger={
                          country_name.length <= 10 ? (
                            <span>{country_name}</span>
                          ) : (
                            <span>{`${country_name.substring(0, 7)}...`}</span>
                          )
                        }
                        content={country_name}
                        on='hover'
                        hideOnScroll
                      />,
                      <div>{`${month} ${year}`}</div>,
                      <div>
                        {payroll_invoice ? (
                          <>
                            <Dropdown
                              item
                              selection
                              search
                              style={{ width: '398px' }}
                              text='Configure Invoice'
                              css={css`
                                > input.search {
                                  display: none;
                                }
                              `}
                            >
                              <Dropdown.Menu
                                style={{
                                  overflow: 'scroll',
                                  height: '200px',
                                  width: '215px',
                                }}
                              >
                                {setOptionText(payroll_invoice).map(
                                  ({ key, text, value }) => (
                                    <Dropdown.Item
                                      key={key}
                                      id={payroll_invoice?.id}
                                    >
                                      <Checkbox
                                        onChange={handleDropdownChange}
                                        className={payroll_invoice?.id}
                                        key1={key}
                                        id={key}
                                        label={text}
                                        defaultChecked={value !== '0.0'}
                                        value={selectStatus.map(
                                          item => item.value,
                                        )}
                                      />
                                    </Dropdown.Item>
                                  ),
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                            <div
                              className='grid gap-4 grid-cols-2'
                              style={{ marginTop: '5px' }}
                            >
                              <span>
                                <label htmlFor='Teamed Fee'>Teamed Fee</label>
                                <Input
                                  id={`teamed_fee ${payroll_invoice?.id}`}
                                  style={{
                                    marginLeft: '10px',
                                    width: '130px',
                                    height: '30px',
                                  }}
                                  onChange={handleFeeChange}
                                  type='number'
                                  defaultValue={Number(teamed_fee).toFixed(2)}
                                  onKeyPress={preventDecimalPlaces}
                                />
                                <label
                                  htmlFor='Vat'
                                  style={{ marginLeft: '10px' }}
                                >
                                  Vat
                                </label>
                                <Input
                                  id={`vat ${payroll_invoice?.id}`}
                                  style={{
                                    marginLeft: '10px',
                                    width: '130px',
                                    height: '30px',
                                  }}
                                  type='number'
                                  onChange={handleVatChange}
                                  defaultValue={Number(vat).toFixed(2)}
                                  onKeyPress={preventDecimalPlaces}
                                />
                              </span>
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </div>,
                      <div>
                        <Button
                          onClick={() => {
                            clearPayrollData(payroll_invoice.id);
                          }}
                          className='link'
                          style={{
                            color: payroll_invoice ? 'red' : 'red',
                            cursor: payroll_invoice ? '' : 'not-allowed',
                            fontSize: '13px',
                          }}
                          disabled={!payroll_invoice}
                        >
                          Clear payroll data
                        </Button>
                      </div>,
                    ],
                  };
                },
              )}
            />
          </div>
        )}
      </div>
      {data && !data.length && !isFetching && 'No Employees Found'}
      <Modal
        open={successModal}
        header='Success'
        content={successModal}
        actions={['OK']}
        onClose={() => setSuccessModal(null)}
      />
      <Modal
        open={failModal}
        header='Opps! There is an error'
        content={failModal}
        actions={['OK']}
        onClose={() => setFailModal(null)}
      />
    </div>
  );
};

export default PayrollInvoice;
