import React, { useCallback, useState } from 'react';
import {
  Button,
  FileField,
  List,
  LoadingSpinner,
  PaginationControl,
} from '@tg/core/components';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';
import { Select, Modal } from 'semantic-ui-react';
import { useResource } from '@tg/core/hooks';
import { observableRequest } from '@tg/core/utils/requestHelpers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getYears } from '@tg/core/utils/datetimeHelpers';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { payroll_upload_data } from '../../utils/sample_payroll_data';

const ManagePayroll = () => {
  const [payrollManageFiles, setPayrollManageFiles] = useState([]);
  const [page, setPage] = useState(1);
  const [filterBy, setFilterBy] = useState(null);
  const today = new Date();
  const [month, setMonth] = useState(
    today.toLocaleString('en-us', { month: 'short' }).toLocaleUpperCase(),
  );
  const [year, setYear] = useState(today.getFullYear());
  const { t } = useTranslation(['datetime']);
  const url = `employers/invoice_data_file`;
  let endpointUrl = `employers/employers_invoices?page=${page}&pagesize=100`;

  if (filterBy) {
    endpointUrl += `&filter_by=${filterBy}`;
  }

  const { postResource } = useResource({ url });
  const token = useSelector(s => s.session.aut);
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successModalMsg, setSuccessModalMsg] = useState(null);
  const [failModal, setFailModal] = useState(null);
  /* eslint-disable consistent-return */

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = {
      Sheets: { data: ws },
      SheetNames: ['data'],
    };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const onPayrollManageFileDrop = useCallback(
    dropped => {
      setLoading(true);
      setPayrollManageFiles(dropped);
      if (!dropped.length) {
        // eslint-disable-next-line no-alert
        return alert('No employees file attached');
      }
      const formData = new FormData();
      formData.append('invoice_file', dropped[0]);
      formData.append('month', month);
      formData.append('year', year);
      postResource({
        formData,
        onSuccess: data => {
          exportToCSV(data.data, 'invoice_compare');
          setPayrollManageFiles([]);
          setLoading(false);
          setSuccessModalMsg('Invoice upload was a success');
          setSuccessModal(true);
        },
        onError: error => {
          setLoading(false);
          setFailModal(error.response.error);
          setPayrollManageFiles([]);
        },
        headers: {},
      });
    },
    [month, year],
  );

  const { data, pagination } = useResource({ url: endpointUrl }, true);

  const selectStatus = [
    { key: 'NS', value: 'NOT STARTED', text: 'NOT STARTED' },
    { key: 'NC', value: 'NOT COMPLETED', text: 'NOT COMPLETED' },
    { key: 'RTR', value: 'READY TO REVIEW', text: 'READY TO REVIEW' },
    { key: 'PUB', value: 'PUBLISHED', text: 'PUBLISHED' },
  ];

  /* eslint no-else-return: "error" */
  const listStatus = status => {
    if (status === 'NOT STARTED') {
      return <span style={{ color: 'red' }}>{status}</span>;
    } else if (status === 'NOT COMPLETED')
      return <span style={{ color: '#FFAF1D' }}>{status}</span>;
    else if (status === 'READY TO REVIEW')
      return (
        <Link href='/#' className='link' style={{ color: 'blue' }}>
          {status}
        </Link>
      );
    return <span style={{ color: 'green' }}>{status}</span>;
  };

  const onGenerateInvoiceClick = id => {
    setLoading(true);

    observableRequest({
      url: `employers/${id}/generate_invoice`,
      token,
      headers: {},
      config: {
        method: 'GET',
      },
    }).subscribe(
      res => {
        setLoading(false);
        setSuccessModalMsg(
          res?.response?.json?.message ??
            'This may take some time. Please be patient... ',
        );
        setSuccessModal(true);
      },
      () => {
        setLoading(false);
        setFailModal('Please Upload Invoice data first to generate Invoice..!');
      },
    );
  };

  const callExportFunc = () => {
    if (payroll_upload_data?.length) {
      exportToCSV(payroll_upload_data, 'payroll upload data');
    }
  };
  return (
    <div>
      <div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <h3 style={{ marginRight: 'auto' }}>Payroll data csv file</h3>
          <Select
            style={{ marginRight: '5px', minWidth: '120px' }}
            defaultValue={new Date().getMonth()}
            options={Array.from(new Array(12)).map((_, index) => ({
              text: t(`datetime:monthnames.${index}`),
              value: index,
            }))}
            onChange={e => {
              setMonth(e.target.textContent);
            }}
          />
          <Select
            style={{ minWidth: '120px' }}
            defaultValue={year}
            options={getYears.map(item => ({
              text: item,
              value: item,
            }))}
            onChange={e => {
              setYear(e.target.textContent);
            }}
          />
        </div>
        <div>
          <FileField onDrop={onPayrollManageFileDrop} />
        </div>
        {payrollManageFiles.map(f => (
          <div key={f.name}>{f.name}</div>
        ))}
        <div style={{ float: 'right' }}>
          <button
            type='button'
            onClick={callExportFunc}
            css={css`
              color: red;
              margin-right: 10px;
            `}
            className='link'
          >
            Download sample file
          </button>
        </div>
      </div>
      <div>
        <div
          css={css`
            border-bottom: groove;
            margin-left: 20px;
            margin-right: 20px;
            height: 80px;
            border-bottom-color: currentcolor;
          `}
        />
      </div>
      <div
        css={css`
          float: right;
          margin-top: 30px;
        `}
      >
        <Select
          options={selectStatus}
          placeholder='Filter By Status'
          onChange={event => {
            setFilterBy(event.target.textContent);
          }}
        />
      </div>
      <div
        css={css`
          margin-top: 120px;
        `}
      >
        {loading && (
          <div className='flex items-center justify-center my-4'>
            <LoadingSpinner />
          </div>
        )}
        {data && !!data.length && (
          <div className='mb-6'>
            <List
              headings={[
                { text: 'Employer' },
                { text: 'Status' },
                { text: 'Countries(Data pending from)' },
                { text: 'Generate Invoices' },
              ]}
              items={data.map(({ id, name, status, data_pending_from }) => {
                return {
                  id,
                  columns: [
                    <Link
                      to={`/employer/${id}/payrollInvoices`}
                      className='link'
                      style={{ color: 'black' }}
                    >
                      {name}
                    </Link>,
                    <div>{listStatus(status)}</div>,
                    <div className='flex items-center space-x-4'>
                      {data_pending_from.length <= 0 ? (
                        'NOT APPLICABLE'
                      ) : (
                        <div style={{ whiteSpace: 'normal' }}>
                          {data_pending_from}
                        </div>
                      )}
                    </div>,
                    <Button
                      type='submit'
                      onClick={() => onGenerateInvoiceClick(id)}
                    >
                      Generate Invoice
                    </Button>,
                  ],
                };
              })}
            />
          </div>
        )}
      </div>
      {pagination && (
        <div className='mb-6'>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
      <Modal
        open={successModal}
        header='Success'
        content={successModalMsg}
        actions={['OK']}
        onClose={() => setSuccessModal(false)}
      />

      <Modal
        open={!!failModal}
        header='Opps! There is an error'
        content={failModal}
        actions={['OK']}
        onClose={() => setFailModal(null)}
      />
    </div>
  );
};

export default ManagePayroll;
