import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Logo, Avatar, Icon } from '@tg/core/components';
import {
  actions,
  selectSessionAuthToken,
  selectSessionUser,
} from '@tg/core/store/modules/session';
import { useResource, useWindowSize } from '@tg/core/hooks';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import permissions from '@tg/core/constant';
import NavMenu from '../molecules/Menu/NavMenu';

const {
  MAIN_NAV_MENU_EMPLOYERS,
  MAIN_NAV_MENU_EMPLOYEES,
  MAIN_NAV_MENU_PAYROLL,
  MAIN_NAV_MENU_MANAGE,
  MAIN_NAV_MENU_ADMINISTRATORS,
  MAIN_NAV_MENU_REPORTS,
} = permissions;

export default () => {
  const { deleteResource } = useResource({ url: `auth/logout` });
  const hasSession = !!useSelector(state => selectSessionAuthToken(state));
  const user = useSelector(state => selectSessionUser(state));
  const accesslist = useSelector(state => state?.access?.roles);
  const dispatch = useDispatch();
  const history = useHistory();
  const getId = useSelector(state => state.access.rolesId);

  const items = [
    ...(getPermission(accesslist, MAIN_NAV_MENU_EMPLOYERS)
      ? [{ to: '/employers', text: 'Employers' }]
      : []),
    ...(getPermission(accesslist, MAIN_NAV_MENU_EMPLOYEES)
      ? [{ to: '/employees', text: 'Employees' }]
      : []),
    ...(getPermission(accesslist, MAIN_NAV_MENU_PAYROLL)
      ? [{ to: '/payroll', text: 'Payroll' }]
      : []),
    ...(getPermission(accesslist, MAIN_NAV_MENU_MANAGE)
      ? [{ to: '/manage', text: 'Manage' }]
      : []),
    ...(getPermission(accesslist, MAIN_NAV_MENU_ADMINISTRATORS)
      ? [{ to: '/administrators', text: 'Administrators' }]
      : []),
    ...(getPermission(accesslist, MAIN_NAV_MENU_REPORTS)
      ? [{ to: '/reports', text: 'Reports' }]
      : []),
  ];

  const windowSize = useWindowSize();
  const showLargeUserMenu = windowSize.width > 1180;

  const onRoleClick = e => {
    const id = e.target.value;
    dispatch({
      type: 'ADD_ROLE_ID',
      payload: id,
    });
    history.push({ pathname: '/' });
  };

  const newItems = user?.roles?.map(item => ({
    text: item.name,
    value: item.id,
    onClick: onRoleClick,
  }));

  const userMenuItems = [].filter(({ show }) => show !== false);

  if (newItems?.length) {
    userMenuItems.push(...newItems);
  }

  return (
    <nav className='bg-gray-900'>
      <div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
        <div className='relative flex items-center justify-between h-14'>
          {/* Logo */}
          <div className='flex mr-8 items-center -ml-2'>
            <div className='w-6'>
              <Logo type='small' />
            </div>
            <div className='ml-3 text-white font-black text-xl leading-none'>
              ADMIN
            </div>
          </div>

          {/* Nav Items */}
          <nav className='flex-1 flex items-center justify-center sm:items-stretch sm:justify-start'>
            <div className='flex space-x-4'>
              {items.map(({ to, text }) => (
                <NavLink
                  key={to}
                  to={to}
                  className='text-gray-200 hover:bg-gray-600 hover:text-white
                    px-3 py-2 rounded-md text-sm font-medium'
                  activeClassName='!bg-gray-700 !text-white'
                >
                  {text}
                </NavLink>
              ))}
            </div>
          </nav>
          <div className='flex w-18 sm:w-auto'>
            <NavMenu
              items={userMenuItems}
              header={
                !showLargeUserMenu && (
                  <div className='px-3 py-4 border-b border-gray-200'>
                    <div className='font-bold truncate text-base'>
                      {user?.full_name}
                    </div>
                    <div className='text-sm leading-none truncate'>
                      {user.job_title || user.email}
                    </div>
                  </div>
                )
              }
            >
              <div className='max-w-xs'>
                <Avatar
                  header
                  showName={showLargeUserMenu}
                  user={user}
                  roleValue={
                    userMenuItems.find(val => val.value === parseInt(getId, 10))
                      ?.text
                  }
                />
              </div>
              {userMenuItems?.length > 1 && (
                <span className='ml-2 self-center'>
                  <Icon style={{ color: '#E5E7EB' }} name='chevron down' />
                </span>
              )}
            </NavMenu>
          </div>
          {/* Logout Button */}
          {hasSession ? (
            <button
              type='button'
              className='text-gray-200 hover:bg-gray-700 hover:text-white
                    px-3 py-2 rounded-md text-sm font-medium'
              onClick={() => {
                deleteResource({
                  onSuccess: () => {
                    dispatch(actions.destroySession());
                    dispatch({ type: 'RESET_ACCESS' });
                    history.replace({ pathname: '/' });
                  },
                });
              }}
            >
              Logout
            </button>
          ) : (
            <Button to='/auth/login'>Login</Button>
          )}
        </div>
      </div>
    </nav>
  );
};
