const namespace = 'tgweb';
const toCamel = str =>
  str.toLowerCase().replace(/_\w/g, m => m[1].toUpperCase());

export const createPrefix = prefix => name => `${namespace}/${prefix}/${name}`;
export const createAction = type => payload => ({ type, payload });

export const createActions = ({ resource, verbs = [], prefix = '' }) => {
  const p = createPrefix(prefix);
  return verbs
    .reduce((acc, verb) => {
      return [
        ...acc,
        `${verb}_${resource}`.toUpperCase(),
        `${verb}_${resource}_SUCCESS`.toUpperCase(),
        `${verb}_${resource}_FAILURE`.toUpperCase(),
      ];
    }, [])
    .reduce((acc, type) => {
      const namespaced = p(type);
      acc[type] = namespaced;
      acc[toCamel(type)] = createAction(namespaced);
      return acc;
    }, {});
};
