import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { getActiveStorageUrl } from '@tg/core/utils/urlHelpers';
import { Context } from '@tg/core/components';
import downloadSvg from '@tg/core/assets/images/download.svg';
import viewSvg from '@tg/core/assets/images/file.svg';

const getDownloadUrl = url => {
  const newUrl = new URL(url);
  newUrl.searchParams.set('disposition', 'attachment');
  return newUrl.toString();
};

/**
 * A list of documents / assets with a link to view in the browser and another to download
 */
const DocumentList = ({ documents, headings }) => {
  const {
    theme: { colors },
  } = useContext(Context);

  /**
   * Share the grid column widths between items and headers
   */
  const gridCss = `
    display: flex;
    justify-content: space-between;
    grid-template-columns: repeat(${headings.length || 1}, 1fr) 120px;
    grid-column-gap: 10px;
    display: grid;
  `;

  return (
    <div>
      {headings && (
        <div
          css={css`
            ${gridCss}
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
            margin-left: 20px;
            color: ${colors.textGrey};
          `}
        >
          {headings.map(heading => (
            <div>{heading}</div>
          ))}
        </div>
      )}

      <ul
        className='reset-list'
        css={css`
          padding: 0;
        `}
      >
        {documents.map(({ id, url, columns }) => (
          <li
            key={id}
            css={css`
              ${gridCss}
              align-items: center;
              border: 1px solid ${colors.borderGrey};
              border-radius: 6px;
              overflow: hidden;
              background-color: #ffffff;
              margin-bottom: 10px;
              font-size: 12px;
              color: ${colors.textGrey};
              padding-left: 20px;

              a {
                padding: 20px;
                width: 60px;
                height: 60px;
              }

              a > span:first-of-type {
                display: block;
                height: 100%;
                background-position: center;
                background-repeat: no-repeat;
              }
            `}
          >
            {columns.map(content => content)}

            <div
              css={css`
                display: flex;
              `}
            >
              <a
                href={`${getActiveStorageUrl(url)}`}
                css={css`
                  background-color: ${colors.secondary};
                `}
                target='_blank'
                rel='noreferrer'
              >
                <span
                  css={css`
                    background: url(${viewSvg});
                  `}
                />
                <span className='visually-hidden'>View</span>
              </a>

              <a
                href={`${getDownloadUrl(getActiveStorageUrl(url))}`}
                css={css`
                  background-color: ${colors.primary};
                `}
              >
                <span
                  css={css`
                    background-image: url(${downloadSvg});
                  `}
                />
                <span className='visually-hidden'>Download</span>
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

DocumentList.propTypes = {
  headings: PropTypes.arrayOf(PropTypes.string),
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      columns: PropTypes.arrayOf(PropTypes.node).isRequired,
    }),
  ).isRequired,
};

DocumentList.defaultProps = {
  headings: [],
};

export default DocumentList;
