import React from 'react';
import PropTypes from 'prop-types';
import { PrivateRoute, Icon } from '@tg/core/components';
import { NavLink, Switch, useRouteMatch } from 'react-router-dom';

const SideNavLayout = ({ pages }) => {
  const { path, url } = useRouteMatch();

  return (
    <div>
      <div className='lg:grid lg:grid-cols-12 lg:gap-x-5 mb-8'>
        <aside className='mb-6 lg:col-span-3'>
          <nav className='space-y-1'>
            {pages.map(({ title, to, icon }) => (
              <NavLink
                to={`${url}${to}`}
                key={title}
                className='flex items-center px-3 py-2 text-sm font-semibold
                rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                activeClassName='bg-gray-200 text-dark-blue-light'
                exact
              >
                <span className='mr-4 w-4 h-4 text-center'>
                  <Icon name={icon} />
                </span>
                {title}
              </NavLink>
            ))}
          </nav>
        </aside>
        <div className='space-y-6 lg:col-span-9'>
          <Switch>
            {pages.map(({ title, to, component }) => (
              <PrivateRoute
                exact={!to}
                key={title}
                path={`${path}${to}`}
                component={component}
              />
            ))}
          </Switch>
        </div>
      </div>
    </div>
  );
};

SideNavLayout.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      to: PropTypes.string,
      component: PropTypes.any,
      icon: PropTypes.string,
    }),
  ).isRequired,
};

export default SideNavLayout;
