import React, { useEffect, useState } from 'react';
import { pluck } from 'rxjs/operators';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { observableRequest } from '@tg/core/utils/requestHelpers';
import { actions } from '@tg/core/store/modules/session';
import AuthErrors from '../AuthErrors';

const ConfirmEmail = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  // const [isFetching, setIsFetching] = useState(false);
  const [apiError, setApiError] = useState(null);

  const onSubmit = () => {
    // setIsFetching(true);
    observableRequest({
      url: `auth/confirm-email/${token}`,
      config: {
        method: 'PATCH',
      },
    })
      .pipe(pluck('response'))
      .subscribe(
        response => {
          dispatch(actions.createSession({ token: response.token }));
          history.replace({ pathname: '/' });
        },
        error => {
          dispatch(actions.destroySession());
          setApiError(error.response);
          // setIsFetching(false);
        },
      );
  };

  useEffect(() => {
    onSubmit();
  }, []);

  return (
    <>
      <AuthErrors error={apiError} />
    </>
  );
};

export default ConfirmEmail;
