import Form from './Form';
import TextField from '../FormBuilder/Fields/TextField';
import CollectionField from '../FormBuilder/Fields/CollectionField';
import PhoneField from '../FormBuilder/Fields/PhoneField';
import PasswordField from './Fields/PasswordField';
// import CheckboxField from './Fields/CheckboxField';
import DateField from './Fields/DateField';
import ToggleField from './Fields/ToggleField';
import RadioField from './Fields/RadioField';
import TextAreaField from './Fields/TextAreaField';
import HiddenField from './Fields/HiddenField';
import SelectField from './Fields/SelectField';
import TextFieldInput from './Fields/TextFieldInput';
import InputTextField from './Fields/InputTextField';
import useForm from './useForm';

// import EmergencyContactSet from './Sets/EmergencyContact/EmergencyContact';
// import BankDetailsSet from './Sets/BankDetails/BankDetails';
// import AddressSet from './Sets/Address/Address';
// import EmployeeSet from './Sets/Employee/Employee';
// import EmployerSet from './Sets/Employer/Employer';

Form.useForm = useForm;

Form.TextField = TextField;
Form.PasswordField = PasswordField;
Form.CollectionField = CollectionField;
Form.PhoneField = PhoneField;
// Form.CheckboxField = CheckboxField;
Form.DateField = DateField;
Form.ToggleField = ToggleField;
Form.RadioField = RadioField;
Form.TextAreaField = TextAreaField;
Form.HiddenField = HiddenField;
Form.SelectField = SelectField;
Form.TextFieldInput = TextFieldInput;
Form.InputTextField = InputTextField;

Form.Sets = {
  // EmergencyContact: EmergencyContactSet,
  // BankDetails: BankDetailsSet,
  // Address: AddressSet,
  // Employee: EmployeeSet,
  // Employer: EmployerSet,
};

export default Form;
