import React, { FC, ChangeEventHandler, Ref } from 'react';
import classNames from 'classnames';
import { LoadingSpinner, Icon } from '@tg/core/components';

export interface Props {
  inputRef: Ref<HTMLInputElement>;
  loading?: boolean;
  icon?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: ChangeEventHandler<HTMLInputElement>;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
  hasError?: boolean;
  autoComplete?: string;
  postInput?: string;
  preInput?: string;
  type?: string;
  min?: number;
  max?: number;
}

const InputTextField: FC<Props> = ({
  inputRef,
  icon,
  id,
  name,
  onChange,
  onBlur,
  type = 'text',
  placeholder,
  defaultValue,
  loading = false,
  disabled = false,
  required = false,
  hasError = false,
  autoComplete,
  postInput,
  preInput,
  min,
  max,
}) => {
  return (
    <div className='relative'>
      <div className='mt-1 relative rounded-md shadow-sm'>
        {preInput && (
          <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
            <span className='text-gray-500 sm:text-sm'>{preInput}</span>
          </div>
        )}
        <input
          id={id}
          name={name}
          type={type}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          defaultValue={defaultValue}
          disabled={disabled}
          required={required && !disabled}
          autoComplete={autoComplete}
          min={min}
          max={max}
          className={classNames('block w-full sm:text-sm rounded-md', {
            '!pl-7': preInput,
            'pr-12': postInput || icon,
            'text-red-900 border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500': hasError,
            'border-gray-300 focus:ring-blue-300 focus:border-blue-300': !hasError,
            '!bg-gray-50 !text-gray-500': disabled,
          })}
          ref={inputRef}
        />
        {icon && !loading && (
          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
            <div className='block'>
              <Icon name={icon} />
            </div>
          </div>
        )}
        {postInput && (
          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
            <span className='text-gray-500 sm:text-sm'>{postInput}</span>
          </div>
        )}
      </div>

      {loading && (
        <div className='absolute top-0 right-0 bottom-0 flex items-center pr-4'>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default InputTextField;
