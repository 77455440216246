import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

const FileField = ({ onDrop, multiple }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className='cursor-pointer'>
      <input {...getInputProps()} multiple={multiple} />
      <div className='rounded-lg p-4 border-2 border-dashed border-gray-700 bg-gray-100'>
        {isDragActive
          ? 'Drop files here'
          : 'Drag and drop files here, or click to select files'}
      </div>
    </div>
  );
};

FileField.propTypes = {
  onDrop: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};

FileField.defaultProps = {
  multiple: false,
};

export default FileField;
