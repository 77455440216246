import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Popup } from 'semantic-ui-react';
import { useResource } from '@tg/core/hooks';
import PropTypes from 'prop-types';
import { getActiveStorageUrl } from '@tg/core/utils/urlHelpers';
import {
  Heading,
  Segment,
  List,
  Button,
  Status,
  PaginationControl,
  Modal,
  Form,
  Message,
} from '@tg/core/components';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import permissions from '@tg/core/constant';
import { useSelector } from 'react-redux';
import PublishDocument from '../../../../PublishDocument';
import EmailPopup from '../../../../EmailDocument';
import DeleteDocument from '../../../../DeleteDocument';
import AttachInvoice from './AttachInvoice';
import CreateInvoice from './CreateInvoice';
import EmailReceipients from './EmailReceipients/EmailReceipients';
import CodatInvoice from './CodatInvoice';
import CodatEmailReceipients from './CodatReceipients/CodatEmailReceipients';
import XeroConnection from './XeroConnection';

const {
  EMPLOYER_DETAIL_VIEW: {
    INVOICES: {
      CREATE_AN_INVOICE_FROM_EXCEL_UPLOAD,
      UPLOAD_AN_EXISTING_INVOICE_UPLOAD,
      INVOICE_EMAIL_RECIPIENTS,
      INVOICE_HISTORY_VIEW,
      INVOICE_HISTORY_STATUS_CHANGES,
      INVOICE_HISTORY_EMAIL,
      INVOICE_HISTORY_DELETE,
    },
  },
  EMPLOYER_DETAIL_VIEW_OVERVIEW: {
    CODAT_EMAIL_RECEPIENTS,
    CODAT_QBO,
    CODAT_XERO_CONNECTION,
  },
} = permissions;

const EmployerInvoices = ({ employerData }) => {
  const { t } = useTranslation('datetime');
  const { employerId } = useParams();
  const accesslist = useSelector(state => state?.access?.roles);
  const [page, setPage] = useState(1);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hidePopup, setHidePopup] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const type = 'invoices';
  const ownerType = type === 'invoices' ? 'employers' : 'contracts';
  const [showCustomEmailPopup, setShowCustomEmailPopup] = useState(false);
  const { getFieldProps, handleSubmit } = Form.useForm();

  const {
    data,
    isFetching,
    pagination,
    getResource,
    patchResource,
    deleteResource,
  } = useResource(
    { url: `employers/${employerId}/invoices?page=${page}&pagesize=10` },
    true,
  );
  const { patchResource: patchEmailResource, error: err } = useResource({
    url: `${ownerType}/${employerId}/invoices/${currentId}/email`,
  });

  useEffect(() => {
    setError(err);
  }, [err]);

  const openCustomEmailPopup = (val, id) => {
    setShowCustomEmailPopup(val);
    if (!val) {
      setCurrentId(null);
    } else {
      setCurrentId(id);
    }
  };

  const onSuccess = () => {
    getResource();
  };

  const onDeleteSuccess = () => {
    // Edge Case Scenario
    if (page > 1 && data.length % 10 === 1) {
      setPage(page - 1);
    }
  };

  const markAsPaid = invoiceId => {
    patchResource({
      formData: {
        paid: true,
      },
      id: invoiceId,
    });
  };

  const closePopup = () => {
    openCustomEmailPopup(false);
    setError(null);
  };

  const onSubmit = formData => {
    const { message = '' } = formData?.invoice;
    if (message) {
      setLoading(true);
      patchEmailResource({
        formData: {
          message,
        },
        onSuccess: () => {
          setLoading(false);
          closePopup();
        },
        onError: res => {
          setLoading(false);
          setError(res.response.error);
        },
      });
    }
  };

  const {
    xero_company_id,
    xero_connection_id,
    xero_customer_id,
    xero_teamed_account_id,
    xero_line_item_accont_id,
    teamed_xero_customer_id,
  } = employerData || {};

  return (
    <>
      <Heading level='h3'>Invoices</Heading>

      {getPermission(accesslist, CREATE_AN_INVOICE_FROM_EXCEL_UPLOAD) && (
        <Segment heading='Create an invoice from Excel' flush>
          <CreateInvoice onSuccess={onSuccess} />
        </Segment>
      )}

      {getPermission(accesslist, UPLOAD_AN_EXISTING_INVOICE_UPLOAD) && (
        <Segment heading='Upload an existing invoice' flush>
          <AttachInvoice onSuccess={onSuccess} />
        </Segment>
      )}

      {getPermission(accesslist, INVOICE_EMAIL_RECIPIENTS) && (
        <Segment heading='Invoice email recipients' flush>
          <EmailReceipients employerId={employerId} />
        </Segment>
      )}
      {getPermission(accesslist, CODAT_EMAIL_RECEPIENTS) && (
        <Segment heading='Codat email recipients' flush>
          <CodatEmailReceipients employerId={employerId} />
        </Segment>
      )}
      {getPermission(accesslist, INVOICE_HISTORY_VIEW) && (
        <>
          <Heading level='h4'>Invoice history</Heading>
          {data && !!data.length && (
            <List
              headings={[
                { text: 'Date', width: '100px' },
                { text: 'Currency', width: '100px' },
                { text: 'Value', width: '100px' },
                { text: 'Status' },
                { text: '', align: 'right' },
              ]}
              items={data.map(
                ({
                  id,
                  document,
                  visible,
                  month,
                  year,
                  currency,
                  value,
                  paid,
                }) => ({
                  id,
                  columns: [
                    <a href={`${getActiveStorageUrl(document.url)}`}>{`${t(
                      `monthnames.${month}`,
                    )} ${year}`}</a>,
                    <span>{currency.code}</span>,
                    <span>
                      {Number(parseFloat(value).toFixed(2)).toLocaleString(
                        'en',
                      )}
                    </span>,
                    <>
                      {getPermission(
                        accesslist,
                        INVOICE_HISTORY_STATUS_CHANGES,
                      ) && (
                        <>
                          <PublishDocument
                            type='invoices'
                            visible={visible}
                            owner={employerId}
                            id={id}
                            onSuccess={onSuccess}
                          />
                          <div>
                            {visible &&
                              (paid ? (
                                <Status color='green' text='Paid' />
                              ) : (
                                <Button
                                  size='small'
                                  color='tertiary'
                                  onClick={() => {
                                    markAsPaid(id);
                                    setUpdate(!update);
                                  }}
                                >
                                  Mark as paid
                                </Button>
                              ))}
                          </div>
                        </>
                      )}
                    </>,
                    <div className='overflow-x-auto'>
                      <div className='flex items-center space-x-2 mb-2'>
                        {getPermission(accesslist, CODAT_QBO) && (
                          <>
                            {employerData?.codat_company_id &&
                              employerData?.codat_connection_id && (
                                <CodatInvoice
                                  type='invoices'
                                  employerId={employerId}
                                  id={id}
                                />
                              )}
                          </>
                        )}
                        {getPermission(accesslist, CODAT_XERO_CONNECTION) && (
                          <>
                            {((xero_company_id &&
                              xero_connection_id &&
                              xero_customer_id &&
                              xero_teamed_account_id &&
                              xero_line_item_accont_id) ||
                              teamed_xero_customer_id) && (
                              <XeroConnection
                                type='invoices'
                                employerId={employerId}
                                id={id}
                              />
                            )}
                          </>
                        )}
                        {getPermission(accesslist, INVOICE_HISTORY_DELETE) && (
                          <DeleteDocument
                            disabled={isFetching}
                            onClick={() => {
                              deleteResource({
                                id,
                                onSuccess: onDeleteSuccess,
                              });
                            }}
                          />
                        )}
                        {getPermission(accesslist, INVOICE_HISTORY_EMAIL) && (
                          <Popup
                            trigger={
                              <Button
                                size='small'
                                color='tertiary'
                                disabled={!visible}
                              >
                                Email
                              </Button>
                            }
                            position='top center'
                            on='click'
                            hideOnScroll
                            disabled={!!hidePopup}
                            onUnmount={() => setHidePopup(false)}
                          >
                            <Popup.Content>
                              <EmailPopup
                                id={id}
                                ownerType={ownerType}
                                owner={employerId}
                                openCustomEmailPopup={openCustomEmailPopup}
                                setHidePopup={setHidePopup}
                              />
                            </Popup.Content>
                          </Popup>
                        )}
                        {getPermission(accesslist, INVOICE_HISTORY_VIEW) && (
                          <a
                            className='link'
                            href={`${getActiveStorageUrl(document.url)}`}
                          >
                            View
                          </a>
                        )}
                      </div>
                    </div>,
                  ],
                }),
              )}
            />
          )}
        </>
      )}
      {pagination && (
        <div className='mb-6'>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}

      {!isFetching && data && !data.length && (
        <Segment>No Invoices Found</Segment>
      )}
      <Modal isOpen={!!showCustomEmailPopup} onClose={closePopup}>
        {error && (
          <div className='mb-4'>
            <Message type='error'>{error}</Message>
          </div>
        )}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Content>
            <Form.TextAreaField
              {...getFieldProps({ id: 'invoice', name: 'message' })}
              height='60px'
              required
            />
            <div className='space-x-4'>
              <Button type='submit' loading={loading}>
                Send
              </Button>
              <Button color='secondary' onClick={closePopup}>
                {t('forms:buttons.modal.cancel')}
              </Button>
            </div>
          </Modal.Content>
        </Form>
      </Modal>
    </>
  );
};

EmployerInvoices.propTypes = {
  employerData: PropTypes.object.isRequired,
};

export default EmployerInvoices;
