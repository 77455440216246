import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useResource } from '@tg/core/hooks';
import { Button, Status } from '@tg/core/components';

const PublishDocument = ({ visible, owner, type, id, onSuccess }) => {
  const [published, setPublished] = useState(visible);
  const ownerType = type === 'invoices' ? 'employers' : 'contracts';
  const { patchResource, error } = useResource({
    url: `${ownerType}/${owner}/${type}/${id}/publish`,
  });

  if (error) console.log(error);

  return published ? (
    <Status color='green' text='Published' />
  ) : (
    <Button
      size='small'
      onClick={() => {
        patchResource({
          onSuccess: () => {
            setPublished(!published);
            if (typeof onSuccess === 'function') onSuccess();
          },
        });
      }}
    >
      Publish
    </Button>
  );
};

PublishDocument.propTypes = {
  owner: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['payslips', 'documents', 'invoices']).isRequired,
  visible: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func,
};

PublishDocument.defaultProps = {
  onSuccess: null,
};

export default PublishDocument;
