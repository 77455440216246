import React, { FC } from 'react';
import { Segment } from '@tg/core/components';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

interface ListHeading {
  id?: string | number;
  text?: string;
  align?: 'left' | 'right' | 'center';
  width?: string;
}
interface ListItem {
  id: string;
  columns: (string | JSX.Element)[];
  to?: LinkProps['to'];
}

interface Props {
  headings?: ListHeading[];
  items: ListItem[];
  noBorder?: boolean;
  noShadow?: boolean;
  type?: string;
}

/**
 * A list laid out like a table, with headings
 */
const List: FC<Props> = ({ items, headings, noShadow, noBorder, type }) => {
  /**
   * Share the grid column widths between items and headers
   */
  const colWidths = headings
    ? headings.map(({ width }) => width)
    : items[0].columns.map(() => null);
  const gridColumns: string[] = colWidths.map(i => i || 'minmax(0, 1fr)');

  const gapInPercent =
    type === 'planner' && headings.length < 5 ? { gap: '2%' } : {};
  const gridClasses = 'flex grid content-between gap-4';

  // We can't use `text-${heading.align}` as purgeCSS wouldn't recognise it
  const alignCss = {
    left: 'text-left',
    right: 'text-right',
    center: 'text-center',
  };

  const getCellClassNames = (index: number) => {
    const whiteSpaceClass =
      type === 'popup' || 'employee' ? '' : 'whitespace-nowrap';
    return classNames(whiteSpaceClass, {
      'text-right': headings && headings[index].align === 'right',
      'text-center': headings && headings[index].align === 'center',
    });
  };

  const Wrapper = ({ to, children, ...rest }) => {
    if (to) {
      return (
        <Link to={to} {...rest}>
          {children}
        </Link>
      );
    }
    return <div {...rest}>{children}</div>;
  };

  const list = (
    <ul
      className={`${
        type === 'planner'
          ? 'border-gray-200 sm:border sm:rounded-md sm:shadow-sm  px-2 '
          : ''
      }divide-y divide-gray-200`}
    >
      {items.map(({ id, columns, to }) => (
        <li key={id}>
          <Wrapper
            to={to}
            className={classNames(
              'py-3 items-center text-current',
              gridClasses,
              {
                'px-3': !noBorder,
              },
            )}
            style={{
              gridTemplateColumns: gridColumns.join(' '),
              ...gapInPercent,
            }}
          >
            {columns.map((column, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`${id}_${index}`}
                className={getCellClassNames(index)}
                aria-describedby={`list_heading_${index}`}
              >
                {column}
              </div>
            ))}
          </Wrapper>
        </li>
      ))}
    </ul>
  );

  return (
    <div>
      {headings && (
        <div
          style={{
            gridTemplateColumns: gridColumns.join(' '),
            ...gapInPercent,
          }}
          className={classNames('font-semibold text-sm mb-1 mx-3', gridClasses)}
        >
          {headings.map((heading, index) => (
            <div
              className={`${alignCss[heading.align || 'left']} 
              ${type === 'planner' ? '' : 'whitespace-nowrap'}`}
              id={`list_heading_${index}`}
              // eslint-disable-next-line react/no-array-index-key
              key={heading.id || index}
            >
              {heading.text}
            </div>
          ))}
        </div>
      )}

      {noBorder ? (
        <div className={`${type === 'planner' ? 'grid' : ''}`}>{list}</div>
      ) : (
        <Segment flush shadowLevel={noShadow ? 'none' : undefined}>
          {list}
        </Segment>
      )}
    </div>
  );
};

export default List;
