/* eslint-disable react/prop-types */
import React, { FC, useEffect, useState, useRef } from 'react';
import { Modal, Button, ButtonBar } from '@tg/core/components';
import Form from '@tg/core/components/systems/Form';
import { useTranslation } from 'react-i18next';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { useResource } from '@tg/core/hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (formdata: any) => void;
  modalOfficer: any;
  setModalOfficer: () => void;
  setError: () => void;
  employees: any;
  datas: any;
  err: any;
}

const OfficersAddEdit: FC<Props> = ({
  isOpen,
  onClose,
  modalOfficer,
  onSave,
  setModalOfficer,
  setError,
  employees,
  datas,
  err,
}) => {
  console.log('errerr', err?.includes('User email has already been taken'));

  const { t } = useTranslation(['officers', 'forms', 'administrators']);
  const [validation, setValidation] = useState([null, null]);
  const [getRole, setRole] = useState([]);
  const [getemployees, setEmployees] = useState([]);
  const [visible, setVisible] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [isEmailCorrect, setIsEmialCorrect] = useState({
    isValid: true,
    message: '',
  });
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  const { data } = useResource(
    {
      url: `employers/roles`,
    },
    true,
  );

  const { getFieldProps, handleSubmit, resetForm } = Form.useForm({
    validationSchema: null,
  });

  /**
   * When the modal closes, clear its Officer data
   */

  useEffect(() => {
    if (err?.includes('User email has already been taken')) {
      setIsEmialCorrect({
        isValid: false,
        message: 'This email already exists',
      });
    }
  }, [err]);

  useEffect(() => {
    if (isOpen === false) {
      setModalOfficer(null);
      resetForm();
      setValidation([null]);
      setVisible([]);
      setEmployees(employees);
      setRole([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (modalOfficer) {
      const initialIds = modalOfficer?.user?.roles?.map(val => val.id);
      setRole(initialIds);
      setVisible(modalOfficer?.user?.roles?.map(val => val.name));
      setSelectedId(
        modalOfficer?.user?.roles.some(val => val.name === 'Line Manager') &&
          modalOfficer?.assigned_employees?.map(val => val.id),
      );
    }
  }, [modalOfficer]);

  useEffect(() => {
    if (!modalOfficer && employees) {
      setEmployees(employees);
    }
  }, [employees]);

  useEffect(() => {
    if (modalOfficer) {
      const newArray = modalOfficer?.assigned_employees?.map(obj => ({
        key: obj.id,
        value: obj.id,
        text: `${obj.full_name}`,
      }));
      setEmployees([...employees, ...newArray]);
    }
  }, [modalOfficer]);

  const hasAnyError = errorValue => {
    return errorValue.some(value => value !== null);
  };

  const validateForm = (getUserRole, getselectedId, isvisible) => {
    const errorObj = validation ? [...validation] : [];
    errorObj[0] = null;
    errorObj[1] = null;

    if (!getUserRole.length) {
      errorObj[0] = t('administrators:validation');
    }
    setValidation(errorObj);
    return errorObj;
  };

  const onSubmit = formData => {
    const customError = validateForm(getRole, selectedId, visible);
    const { first_name, last_name, email, job_title } = formData.officer;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail = emailRegex.test(email || modalOfficer?.user?.email);
    const emailExists = datas?.find((item: any) =>
      item?.user?.email?.includes(email),
    );
    if (!hasAnyError(customError)) {
      if (isValidEmail) {
        setIsEmialCorrect({ isValid: true, message: '' });
        if (emailExists) {
          setIsEmialCorrect({
            isValid: false,
            message: 'This email already exists',
          });
        } else {
          onSave({
            officer: {
              first_name,
              last_name,
              email,
              job_title,
              key_contact: modalOfficer ? modalOfficer.key_contact : false,
              role_ids: getRole,
              assigned_employee_ids: visible?.includes('Line Manager')
                ? selectedId
                : null,
            },
          });
        }
      } else {
        setIsEmialCorrect({
          isValid: false,
          message: 'Please Enter a valid email address',
        });
      }
    } else {
      executeScroll();
    }
  };

  const handleCheckBox = (e, { value, checked }) => {
    if (checked) {
      setRole([...getRole, value]);
      setVisible([...visible, e.target.textContent]);
      const errorObj = validation ? [...validation] : [];
      errorObj[0] = null;
      setValidation(errorObj);
    } else {
      setRole(getRole.filter(item => item !== value));
      setVisible(visible.filter(item => item !== e.target.textContent));
      const errorObj = validation ? [...validation] : [];
      errorObj[1] = null;
      setValidation(errorObj);
    }
  };

  const onEmployeeChange = (e, { value }) => {
    setSelectedId(value);
  };

  return (
    <>
      <Modal
        title={modalOfficer ? t('officers:edit') : t('officers:add')}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setIsEmialCorrect({ isValid: true, message: '' });
          setError(null);
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Content>
            <Form.TextField
              {...getFieldProps({
                id: 'officer',
                name: 'first_name',
              })}
              defaultValue={modalOfficer && modalOfficer.user.first_name}
              required
            />
            <Form.TextField
              {...getFieldProps({
                id: 'officer',
                name: 'last_name',
              })}
              defaultValue={modalOfficer && modalOfficer.user.last_name}
              required
            />
            <Form.TextField
              {...getFieldProps({
                id: 'officer',
                name: 'email',
              })}
              defaultValue={modalOfficer && modalOfficer.user.email}
              disabled={!!modalOfficer}
              required
            />
            {!isEmailCorrect?.isValid && (
              <div
                style={{
                  color: 'red',
                  opaciy: 0.5,
                  position: 'relative',
                  top: -10,
                }}
              >
                {isEmailCorrect?.message}
              </div>
            )}
            <Form.TextField
              {...getFieldProps({
                id: 'officer',
                name: 'job_title',
              })}
              defaultValue={modalOfficer && modalOfficer.user.job_title}
            />
            <div className='flex-col'>
              <div className='mt-6 text-lg font-semibold'>
                {t('administrators:access')}
              </div>
              <div className='flex gap-4 mt-4'>
                {data?.map(({ id, name }) => (
                  <Checkbox
                    value={id}
                    key={id}
                    label={name}
                    onChange={handleCheckBox}
                    defaultChecked={modalOfficer?.user?.roles?.some(
                      val => val.id === id,
                    )}
                    className='mr-4'
                  />
                ))}
              </div>
              {validation && validation[0] && (
                <div ref={myRef} style={{ color: 'red' }}>
                  {validation[0]}
                </div>
              )}
              {visible.includes('Line Manager') && (
                <Dropdown
                  fluid
                  options={getemployees}
                  placeholder='Select Employees'
                  multiple
                  search
                  selection
                  className='mt-4'
                  onChange={onEmployeeChange}
                  defaultValue={modalOfficer?.assigned_employees?.map(
                    item => item.id,
                  )}
                />
              )}
            </div>
          </Modal.Content>

          <ButtonBar>
            <div className='mr-2'>
              <Button
                color='secondary'
                appearance='outline'
                onClick={() => {
                  onClose();
                  setIsEmialCorrect({ isValid: true, message: '' });
                  setError(null);
                }}
              >
                {t('forms:buttons.modal.cancel')}
              </Button>
            </div>
            <Button type='submit'>{t('forms:buttons.save')}</Button>
          </ButtonBar>
        </Form>
      </Modal>
    </>
  );
};

export default OfficersAddEdit;
