import React from 'react';
import { Heading } from '@tg/core/components';
import PropTypes from 'prop-types';

/**
 * A page title and description. Maintains styling across all pages
 */
const PageHeader = ({
  heading,
  subHeading,
  description,
  action: Action,
  filter,
}) => {
  return (
    <header className='mb-10'>
      <div
        className={`${
          filter ? 'grid grid-cols-4' : 'mb-1 flex justify-between'
        }`}
      >
        <div className={`${filter && 'grid grid-cols-1 '}space-y-4`}>
          <Heading level='h3' element='h1' zeropad>
            {heading}
          </Heading>
          {subHeading && <div className='text-gray-500 mb-4'>{subHeading}</div>}
          {description && <p>{description}</p>}
        </div>
        {filter}
        {Action && <div className='grid grid-cols-1'>{Action}</div>}
      </div>
    </header>
  );
};

PageHeader.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  description: PropTypes.string,
  action: PropTypes.node,
  filter: PropTypes.node,
};

PageHeader.defaultProps = {
  heading: '',
  subHeading: null,
  description: null,
  action: null,
  filter: null,
};

export default PageHeader;
