import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { CollectionInput } from '@tg/core/components';

const CollectionField = ({
  required,
  name,
  errors,
  id,
  translationKey,
  disabled,
  defaultValue,
  control,
  resource,
  multiple,
  search,
  useName,
  getValues,
}) => {
  const { t } = useTranslation('forms');

  const label = t(`labels.${translationKey}`);
  const placeholder = t(`placeholders.${translationKey}`);
  const fieldId = `${id}.${name}`;
  return (
    <Form.Field required={required && !disabled} error={!!errors}>
      <label htmlFor={fieldId}>{label}</label>
      <Controller
        as={
          <CollectionInput
            id={fieldId}
            resource={resource}
            multiple={multiple}
            search={search}
            useName={useName}
            defaultValue={defaultValue}
            getValues={getValues}
          />
        }
        name={fieldId}
        control={control}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
      />
      {errors && <p>{t(`validations.${errors.message.key}`)}</p>}
    </Form.Field>
  );
};

CollectionField.propTypes = {
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  id: PropTypes.string.isRequired,
  translationKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string,
  control: PropTypes.any.isRequired,
  resource: PropTypes.oneOf([
    'address_countries',
    'domiciles',
    'industry_sectors',
    'employee_benefit_types',
    'employee_benefits',
    'contract_types',
    'attendance_types',
    'currencies',
  ]).isRequired,
  multiple: PropTypes.bool,
  search: PropTypes.bool,
  useName: PropTypes.bool,
  getValues: PropTypes.func,
};

CollectionField.defaultProps = {
  getValues: f => f,
  errors: null,
  defaultValue: '',
  multiple: false,
  search: false,
  useName: false,
};

export default CollectionField;
