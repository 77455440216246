/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/core';
import {
  Button,
  ButtonBar,
  SelectField,
  CollectionInput,
} from '@tg/core/components';
import { useResource } from '@tg/core/hooks';
import { actions } from '@tg/core/store/modules/toasts';

const AttachInvoice = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const gbp = useSelector(state => state.collections.currencies.allIds[0]);
  const { control, handleSubmit, register, errors: fieldErrors } = useForm();
  const [files, setFiles] = useState([]);
  const { t } = useTranslation(['forms', 'datetime']);
  const { employerId } = useParams();

  const url = `employers/${employerId}/invoices`;

  const { postResource, isFetching } = useResource({ url });

  /* eslint-disable consistent-return */
  const onSubmit = values => {
    if (!files.length) {
      // eslint-disable-next-line no-alert
      return alert('No file attached');
    }

    const formData = new FormData();
    formData.append('month', values.month);
    formData.append('year', values.year);
    formData.append('currency_id', values.currency_id);
    formData.append('value', values.value);
    formData.append('file', files[0]);

    postResource({
      formData,
      onSuccess: () => {
        setFiles([]);
        onSuccess();
        dispatch(
          actions.addToast({
            id: 'attach_invoice_success',
            type: 'success',
            children: 'Invoice Created',
          }),
        );
      },
      headers: {},
    });
  };
  /* eslint-enable consistent-return */

  const onDrop = useCallback(dropped => {
    // console.log(dropped);
    setFiles(dropped);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const now = new Date();
  const defaultValues = {
    month: now.getMonth(),
    year: now.getFullYear(),
    currency_id: gbp,
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className='p-4'>
        <div {...getRootProps()} className='cursor-pointer mb-4'>
          <input {...getInputProps()} multiple={false} />
          <div
            css={css`
              padding: 1rem;
              background: #ededed;
              border: 2px dashed;
            `}
          >
            {isDragActive
              ? 'Drop files here'
              : 'Drag and drop files here, or click to select files'}
          </div>
        </div>

        {files.map(f => (
          <div key={f.name}>{f.name}</div>
        ))}
        <div className='grid gap-4 grid-cols-2'>
          <Form.Field required error={!!fieldErrors.month}>
            <label>Month</label>
            <Controller
              as={
                <SelectField
                  options={Array.from(new Array(12)).map((_, index) => ({
                    text: t(`datetime:monthnames.${index}`),
                    value: index,
                  }))}
                />
              }
              control={control}
              name='month'
              defaultValue={defaultValues.month}
              rules={{ required: t('errors.required') }}
            />
            {fieldErrors.month && <p>{fieldErrors.month.message}</p>}
          </Form.Field>

          <Form.Field required error={!!fieldErrors.year}>
            <label>Year</label>
            <input
              name='year'
              type='number'
              ref={register({ required: t('errors.required') })}
              defaultValue={defaultValues.year}
            />
            {fieldErrors.year && <p>{fieldErrors.year.message}</p>}
          </Form.Field>

          <Form.Field required error={!!fieldErrors.currency_id}>
            <label>Currency</label>
            <Controller
              as={<CollectionInput resource='currencies' search useName />}
              control={control}
              name='currency_id'
              defaultValue={defaultValues.currency_id}
              rules={{ required: t('errors.required') }}
            />
            {fieldErrors.month && <p>{fieldErrors.month.message}</p>}
          </Form.Field>

          <Form.Field required error={!!fieldErrors.value}>
            <label>Invoice amount</label>
            <input
              name='value'
              ref={register({ required: t('errors.required') })}
            />
            {fieldErrors.value && <p>{fieldErrors.value.message}</p>}
          </Form.Field>
        </div>
      </div>

      <ButtonBar>
        <Button type='submit' loading={isFetching}>
          Upload
        </Button>
      </ButtonBar>
    </Form>
  );
};

AttachInvoice.propTypes = {
  // url: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

AttachInvoice.defaultProps = {
  onSuccess: f => f,
};

export default AttachInvoice;
