import { createSelector } from 'reselect';

export const selectSessionAuthToken = state => state.session.aut;
export const selectSessionUser = createSelector(
  state => state.session,
  session => {
    const { user_id, role_id, role_desc: role, user } = session;
    return { user_id, role_id, role, ...user };
  },
);

export const getCurrentUserRole = state => state.access.rolesId;
