import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const DisplayDetails = ({ details }) => {
  const { t } = useTranslation(['time_off']);

  return (
    <dl className='flex flex-wrap -ml-5'>
      {details.map(({ title, value, width }) => (
        <div
          key={title}
          style={{ width, minWidth: '250px' }}
          className={classNames(
            `pl-5 mb-6 ${
              title === t('time_off:event_details.note') ? 'break-words' : ''
            }`,
            {
              'w-full': !width,
            },
          )}
        >
          <dt className='font-medium text-gray-500'>{title}</dt>

          {title === t('time_off:event_details.note') ? (
            <div style={{ whiteSpace: 'pre-wrap' }}>{value}</div>
          ) : (
            <dd
              className={classNames('ml-0 mt-1 font-medium', {
                'text-gray-300': !value,
              })}
            >
              {value || '-'}
            </dd>
          )}
        </div>
      ))}
    </dl>
  );
};

DisplayDetails.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.any,
      width: PropTypes.string,
    }),
  ),
};

DisplayDetails.defaultProps = {
  details: null,
};

export default DisplayDetails;
