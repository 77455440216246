import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { observableRequest } from '@tg/core/utils/requestHelpers';
import {
  reducer as collections,
  epic as collectionsEpic,
} from './modules/collections';
import { reducer as app, epic as bootstrapEpic } from './modules/bootstrap';
import { reducer as session, epic as sessionEpic } from './modules/session';
import { reducer as toasts } from './modules/toasts';
import { accessReducer } from './modules/session/accessReducer';

const isDev = () => true;

export default (config = {}) => {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['session', 'access'],
  };

  const rootEpic = combineEpics(bootstrapEpic, collectionsEpic, sessionEpic);

  const rootReducer = combineReducers({
    app,
    session,
    collections,
    toasts,
    access: accessReducer,
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const epicMiddleware = createEpicMiddleware({
    dependencies: { observableRequest, config },
  });

  const middleware = [epicMiddleware];

  const enableHMR = store => {
    if (isDev() && module.hot) {
      module.hot.accept('./index', () => {
        store.replaceReducer(rootReducer);
      });
    }
  };

  const composeEnhancers = isDev() ? composeWithDevTools({}) : compose;

  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  const persistor = persistStore(store);

  epicMiddleware.run(rootEpic);

  enableHMR(store);

  return { store, persistor };
};
