import React, { useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useResource } from '@tg/core/hooks';
import { Button, PageHeader, Segment } from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import Calendar, {
  dateReducer,
} from '@tg/core/components/organisms/Calendar/Calendar';
import { handleTimeZone } from '@tg/core/utils/datetimeHelpers';
import NewBooking from './NewBooking/NewBooking';

const ContractCalendar = ({ contract, ui }) => {
  const [holidayYear, setHolidayYear] = useState({
    startDate: '',
    endDate: '',
  });
  const [calendarYearsData, setCalendarYearsData] = useState([]);

  // The year we are looking at on the calendar
  const [dateState, dateDispatch] = useReducer(dateReducer, {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  });

  const {
    data: calendarYearData,
    isFetching: calendarYearFetching,
    getResource: getCalendarData,
    errors: calendarYearDataErrors,
  } = useResource({ url: `contracts/${contract.id}/planners` }, true);

  const [calData, setCalData] = useState({});
  const [editModalEvent, setEditModalEvent] = useState();
  const { t } = useTranslation(['time_off']);
  const [addEditModalType, setAddEditModalType] = useState(false);
  const [startDate, setStartDate] = useState({ month: 0, date: 1 });
  const [endDate, setEndDate] = useState({ month: 11, date: 31 });
  const [currentYearData, setCurrentYearData] = useState(null);
  const [event_types, setEventTypes] = useState(null);
  const [maxYear, setMaxYear] = useState(null);

  useEffect(() => {
    if (ui?.create === false && ui.update === null) {
      getCalendarData();
    }
  }, [ui]);

  useEffect(() => {
    if (editModalEvent) {
      const eventType =
        editModalEvent.event_type === 'unpaid'
          ? 'holiday'
          : editModalEvent.event_type;
      setAddEditModalType(eventType);
    }
  }, [editModalEvent]);

  const findMaxYear = calYearData => {
    const maxYr = Math.max(
      ...calYearData.map(singleYearData => singleYearData.year),
    );
    return calYearData.find(calYear => calYear.year === maxYr);
  };

  useEffect(() => {
    if (calendarYearData?.length) {
      const singlecalDataCopy = {};
      calendarYearData.forEach(val => {
        const yearWiseLeaveTypeOptions = val?.planneryear_leaves?.find(
          planneryear_leave =>
            planneryear_leave.leave_type === 'public-holidays',
        );
        if (!val) singlecalDataCopy[val.id] = {};
        singlecalDataCopy[val.id] = {
          user: {
            ...val.employee?.user,
            contract_id: val.contract_id,
          },
          events: val.events,
          publicHolidays: yearWiseLeaveTypeOptions?.leave_type_value,
        };
      });
      setCalData(singlecalDataCopy);

      const maxYr = findMaxYear(calendarYearData);
      setMaxYear(maxYr.year);
      setCalendarYearsData(calendarYearData);
      const today = handleTimeZone(new Date());

      const currentYrData = calendarYearData.find(
        yearData =>
          today >= handleTimeZone(new Date(yearData.start_date)) &&
          today <= handleTimeZone(new Date(yearData.end_date)),
      );
      if (currentYrData && currentYrData !== undefined) {
        setCurrentYearData(currentYrData);
        const {
          holiday_year: { start_date, end_date },
        } = currentYrData;
        setHolidayYear({
          startDate: start_date,
          endDate: end_date,
        });
      }
    }
  }, [calendarYearData, dateState?.year]);

  useEffect(() => {
    if (dateState && calendarYearData?.length) {
      const plannerYearLeaves = [];
      const { year, month } = dateState;
      const firstDayOfMonth = handleTimeZone(new Date(year, month, 1));
      const lastDayOfMonth = handleTimeZone(new Date(year, month + 1, 0));
      calendarYearData.forEach(calendarYear => {
        const startDt = handleTimeZone(new Date(calendarYear.start_date));
        const endDt = handleTimeZone(new Date(calendarYear.end_date));
        if (
          (firstDayOfMonth >= startDt && firstDayOfMonth <= endDt) ||
          (lastDayOfMonth >= startDt && lastDayOfMonth <= endDt)
        ) {
          calendarYear?.planneryear_leaves.forEach(planneryear_leave => {
            if (
              !plannerYearLeaves.find(
                plannerYearLeave =>
                  plannerYearLeave.colour_code ===
                  planneryear_leave.colour_code,
              )
            ) {
              plannerYearLeaves.push(planneryear_leave);
            }
          });
        }
        if (plannerYearLeaves?.length) {
          setEventTypes(plannerYearLeaves);
        } else {
          setEventTypes(null);
        }
      });
    }
  }, [calendarYearData, dateState.month]);

  const hasHolidayYearStartEndDate = hYear => {
    return hYear?.startDate && hYear.endDate;
  };

  useEffect(() => {
    if (hasHolidayYearStartEndDate(holidayYear)) {
      const startDateCopy = holidayYear.startDate.split('/');
      const endDateCopy = holidayYear.endDate.split('/');
      const startDateMonth = Number(startDateCopy[1]) - 1;
      const startDateDay = Number(startDateCopy[0]);

      const endDateMonth = Number(endDateCopy[1]) - 1;
      const endDateDay = Number(endDateCopy[0]);

      setStartDate({
        month: startDateMonth,
        date: startDateDay,
      });
      setEndDate({
        month: endDateMonth,
        date: endDateDay,
      });
    }
  }, [holidayYear]);

  const remainingDays =
    currentYearData &&
    Number(currentYearData?.leave_available?.holiday) -
      Number(currentYearData.entitlement_used.holiday);

  return (
    <>
      <p className='mb-4'>
        {t(`time_off:employee.description`)}
        <Link to='/reports' className='link'>
          Reports
        </Link>
        {'. '}
      </p>
      <Segment
        heading='Time Off'
        customClass
        action={
          !calendarYearDataErrors.length && (
            <div className='flex'>
              <Button
                onClick={() => {
                  setAddEditModalType('holiday');
                }}
                disabled={calendarYearFetching}
              >
                {t(`time_off:employee.request_leave`)}
              </Button>
            </div>
          )
        }
      >
        <PageHeader
          description={
            currentYearData &&
            t('time_off:employee.summary', {
              currentYear: currentYearData?.year,
              entitlement:
                Number(currentYearData?.leave_available?.holiday) > 0
                  ? Number(currentYearData?.leave_available?.holiday)?.toFixed(
                      1,
                    )
                  : 0,
              remaining:
                remainingDays > 0 ? Number(remainingDays)?.toFixed(1) : 0,
            })
          }
        />
        <Calendar
          year={dateState.year}
          month={dateState.month}
          data={calData}
          calendarYearData={calendarYearData}
          dispatch={dateDispatch}
          wrapDays
          renderEvents
          loading={calendarYearFetching}
          allowEditingEvents
          onEditEventClick={event => setEditModalEvent(event)}
          onSuccess={() => getCalendarData()}
          errors={calendarYearDataErrors}
          event_types={event_types}
          currentYearData={currentYearData}
          onDeleteSuccess={() => {
            getCalendarData();
          }}
        />
        <NewBooking
          isOpen={!!addEditModalType}
          onClose={() => {
            setAddEditModalType(false);
            setEditModalEvent(undefined);
          }}
          existingEvent={editModalEvent}
          event_types={currentYearData?.planneryear_leaves}
          onSuccess={() => {
            getCalendarData();
          }}
          year={currentYearData?.year}
          getCalendarData={getCalendarData}
          calendarYearsData={calendarYearsData}
          contractId={contract?.id}
          holidayYear={holidayYear}
          startDate={startDate}
          endDate={endDate}
          maxYear={maxYear}
        />
      </Segment>
    </>
  );
};

ContractCalendar.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.string,
    employee: PropTypes.any,
  }).isRequired,
  ui: PropTypes.shape({
    create: PropTypes.bool,
    update: PropTypes.any,
  }),
};

ContractCalendar.defaultProps = {
  ui: {
    create: null,
    update: null,
  },
};

export default ContractCalendar;
