import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const TextAreaField = ({
  required,
  name,
  errors,
  id,
  disabled,
  defaultValue,
  translationKey,
  register,
  autoComplete,
  placeholder,
}) => {
  const { t } = useTranslation('forms');

  const label = t(`labels.${translationKey}`);
  const placeHolder = placeholder || t(`placeholders.${translationKey}`);
  const fieldId = `${id}.${name}`;

  let errorMessage = '';
  if (errors) {
    if (errors.message.key) {
      errorMessage = t(`validations.${errors.message.key}`);
    } else if (errors.type === 'required') {
      errorMessage = t(`validations.field_required`);
    }
  }

  return (
    <Form.Field required={required && !disabled} error={!!errors}>
      <label htmlFor={fieldId}>{label}</label>
      <textarea
        id={fieldId}
        name={fieldId}
        placeholder={placeHolder}
        defaultValue={defaultValue}
        disabled={disabled}
        required={required && !disabled}
        ref={register({ required })}
        autoComplete={autoComplete}
        style={{ height: '130px' }}
      />
      {errors && <p>{errorMessage}</p>}
    </Form.Field>
  );
};

TextAreaField.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  id: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  translationKey: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
};

TextAreaField.defaultProps = {
  errors: null,
  defaultValue: '',
  autoComplete: null,
  required: false,
  disabled: false,
  placeholder: '',
};

export default TextAreaField;
