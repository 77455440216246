import React, { FC, useState } from 'react';
import { Button, SelectField, Form, ButtonBar } from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { useResource } from '@tg/core/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@tg/core/store/modules/toasts';
import { Modal } from 'semantic-ui-react';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import permissions from '@tg/core/constant';
import { YearOptions } from '@tg/core/utils/datetimeHelpers';

const {
  PAYROLL: { GENERATE_REPORT },
} = permissions;

const PayrollGenerateReport: FC = () => {
  const { t } = useTranslation(['forms', 'datetime']);
  const dispatch = useDispatch();
  const [month, getMonth] = useState(new Date().getMonth());
  const accesslist = useSelector(state => state?.access?.roles);

  const [year, setYear] = useState(new Date().getFullYear());
  const [openModal, setOpenModal] = useState(false);
  const [successModal, setSuccessModal] = useState(null);

  const { postResource } = useResource({ url: 'reports/payroll_adjustments' });

  const { getFieldProps } = Form.useForm<FormData>();
  const onSubmit = () => {
    postResource({
      formData: {
        year,
        month,
      },
      onSuccess: () => {
        dispatch(
          actions.addToast({
            id: 'report_created',
            type: 'success',
            title: 'Report created',
            children: 'Your report will be emailed to you shortly.',
          }),
        );
      },
      onError: error => {
        dispatch(
          actions.addToast({
            id: 'payroll_creation_fail',
            type: 'error',
            title: 'Report could not be created',
            children: error.response.error,
          }),
        );
      },
    });
  };

  const onPreviewSubmit = () => {
    postResource({
      formData: {
        year,
        month,
        preview: true,
      },
      onSuccess: () => {
        setSuccessModal('Your report will be emailed to you shortly.');
      },
      onError: () => {
        setSuccessModal('Something went wrong');
      },
    });
  };

  const handleSubmit = (event: any) => {
    if (event.target.textContent === 'Yes') {
      onSubmit();
    }
  };

  return (
    <>
      <div className='px-4 pb-4 -mt-2'>
        <p className='mb-4'>
          Generate a CSV report for a given month for all employers. Generating
          this report will lock any active Payroll Input Sheets, preventing
          edits.
        </p>
        <div className='flex gap-3'>
          <div className='w-60'>
            <SelectField
              {...getFieldProps({ id: 'create_payroll', name: 'month' })}
              required
              onChange={(value: any) => {
                getMonth(value);
              }}
              defaultValue={new Date().getMonth()}
              options={Array.from(new Array(12)).map((_, index) => ({
                text: t(`datetime:monthnames.${index}`),
                value: index,
              }))}
            />
          </div>
          <div className='w-80'>
            <SelectField
              {...getFieldProps({ id: 'create_payroll', name: 'year' })}
              required
              onChange={(value: any) => {
                setYear(value);
              }}
              defaultValue={new Date().getFullYear()}
              options={YearOptions}
            />
          </div>
          <div />
        </div>
      </div>
      {getPermission(accesslist, GENERATE_REPORT) && (
        <ButtonBar>
          <Button onClick={onPreviewSubmit} style={{ marginRight: '10px' }}>
            Preview Report
          </Button>
          <Button onClick={() => setOpenModal(true)}>Generate Report</Button>
        </ButtonBar>
      )}
      <Modal
        open={openModal}
        header='Generate report'
        content='Are you sure you want to Generate report?'
        actions={[
          { key: 'No', content: 'No', negative: true },
          { key: 'Yes', content: 'Yes', positive: true },
        ]}
        onActionClick={handleSubmit}
        size='small'
        onClose={() => setOpenModal(false)}
      />
      <Modal
        open={successModal}
        header='Preview report'
        content={successModal}
        actions={['OK']}
        size='mini'
        onClose={() => setSuccessModal(null)}
      />
    </>
  );
};

export default PayrollGenerateReport;
