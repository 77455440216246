import React, { useState } from 'react';
import { useResource } from '@tg/core/hooks';
import {
  PageHeader,
  List,
  Button,
  PaginationControl,
} from '@tg/core/components';
import AddEditModal from '../components/GenericAddEditModal';

const Currencies = () => {
  const [page, setPage] = useState(1);
  const {
    data,
    isFetching,
    postResource,
    patchResource,
    pagination,
  } = useResource({ url: `currencies?page=${page}&pagesize=10` }, true);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editingCurrency, setEditingCurrency] = useState(null);

  const listItems = data
    ? data.map(currency => ({
        id: currency.id,
        columns: [
          currency.name,
          currency.code,
          <button
            type='button'
            className='underline'
            onClick={() => {
              setAddModalOpen(true);
              setEditingCurrency(currency);
            }}
          >
            Edit
          </button>,
        ],
      }))
    : [];

  return (
    <>
      <PageHeader
        heading='Currencies'
        action={
          <Button
            onClick={() => {
              setAddModalOpen(true);
              setEditingCurrency(null);
            }}
          >
            Add New
          </Button>
        }
      />

      <List
        loading={isFetching}
        items={listItems}
        headings={[
          { text: 'Name' },
          { text: 'Code', width: '70px' },
          { width: '40px' },
        ]}
      />

      <AddEditModal
        isOpen={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        isFetching={isFetching}
        postResource={postResource}
        patchResource={patchResource}
        defaultValues={editingCurrency}
        mode={editingCurrency ? 'edit' : 'add'}
      />
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};

export default Currencies;
