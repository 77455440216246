import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
import { useSelector } from 'react-redux';
import {
  PaginationControl,
  PageHeader,
  Button,
  List,
} from '@tg/core/components';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import { Search } from 'semantic-ui-react';

const { EMPLOYERS_ADD_EMPLOYER } = permissions;

export default () => {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const accesslist = useSelector(state => state?.access?.roles);

  let endpointUrl = `employers?page=${page}&pagesize=50`;
  if (searchTerm) {
    endpointUrl += `&with_name=${searchTerm}`;
  }
  const { data, pagination, isFetching } = useResource(
    { url: endpointUrl },
    true,
  );

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, []);

  return (
    <>
      <PageHeader
        heading='Employers'
        action={
          <div className='flex justify-end'>
            <Search
              value={searchTerm}
              onSearchChange={event => {
                setSearchTerm(event.target.value);
                setPage(1);
              }}
              placeholder='Search by employer name'
              loading={isFetching && loading}
              showNoResults={false}
              className='mr-4'
            />
            {getPermission(accesslist, EMPLOYERS_ADD_EMPLOYER) ? (
              <Button as={Link} to='employers/create'>
                Add Employer
              </Button>
            ) : (
              ''
            )}
          </div>
        }
      />

      {data && data.length ? (
        <div className='mb-6'>
          <List
            items={data.map(({ id, name }) => {
              return {
                id,
                columns: [<Link to={`employers/${id}`}>{name}</Link>],
              };
            })}
          />
        </div>
      ) : (
        'No employers found'
      )}

      {pagination && (
        <div className='mb-6'>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};
