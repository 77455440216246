import React from 'react';
import { Icon, Header as Heading } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Container } from '@tg/core/components';

const NotFound = () => {
  const { t } = useTranslation('errors');

  return (
    <>
      <Container>
        <Heading as='h2' icon textAlign='center'>
          <Icon name='sitemap' />
          {t('404.heading')}
          <Heading.Subheader>{t('404.description')}</Heading.Subheader>
        </Heading>
      </Container>
    </>
  );
};

export default NotFound;
