import React, { FC } from 'react';
import { useParams, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '@tg/core/components';

import List from './pages/List';
import Single from './pages/Single';

const EmployerPayroll: FC = () => {
  const { employerId } = useParams<{ employerId: string }>();
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <PrivateRoute
          exact
          path={`${path}`}
          component={() => <List employerId={employerId} />}
        />
        <PrivateRoute
          path={`${path}/:submission_id`}
          component={() => <Single />}
        />
      </Switch>
    </>
  );
};

export default EmployerPayroll;
