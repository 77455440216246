import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import { useResource } from '@tg/core/hooks';
import { ButtonBar, FormBuilder, Modal, Button } from '@tg/core/components';

const CreateBenefit = ({ baseUrl }) => {
  const { errors, isFetching, postResource } = useResource({
    url: `benefits`,
  });
  const [isOpen, setIsOpen] = useState(false);

  if (errors) console.log(errors);

  const onSubmit = formData => {
    const { description, employee_benefit_type_id, name } =
      formData?.employee_benefit;
    if (
      description &&
      employee_benefit_type_id?.length > 0 &&
      name?.length > 0
    ) {
      setIsOpen(false);
      postResource({
        formData,
        onSuccess: res =>
          res?.data && window.location.replace('/manage/benefits'),
        onError: err =>
          err?.response?.error?.includes('Already exists') && setIsOpen(true),
      });
    } else {
      setIsOpen(true);
    }
  };

  return (
    <>
      <Link to={baseUrl}>Back</Link>

      <Segment>
        <FormBuilder
          onSubmit={onSubmit}
          isFetching={isFetching}
          errors={errors}
          fieldsets={[{ id: 'employee_benefit', fieldset: 'employee_benefit' }]}
          submitButtonText='Create benefit'
        />
        <Modal
          title={
            errors?.length ? errors?.at(0) : 'Please fill all the feilds..'
          }
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          width='xs'
        >
          <ButtonBar>
            <div className='space-x-2'>
              {/* <Button color='secondary' onClick={() => setIsOpen(false)}>
                Cancel
              </Button> */}
              <Button
                onClick={() => setIsOpen(false)}
                type='submit'
                loading={isFetching}
              >
                Ok
              </Button>
            </div>
          </ButtonBar>
        </Modal>
      </Segment>
    </>
  );
};

CreateBenefit.propTypes = {
  baseUrl: PropTypes.string.isRequired,
};

export default CreateBenefit;
