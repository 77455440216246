/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@tg/core/store/modules/toasts';
import { FormBuilder } from '@tg/core/components';

export default ({ data, patchResource, errors, isFetching }) => {
  const dispatch = useDispatch();

  const onSubmit = formData => {
    patchResource({
      formData,
      onSuccess: newData => {
        dispatch(
          actions.addToast({
            id: newData.id,
            type: 'success',
            children: 'Saved',
          }),
        );
      },
    });
  };

  if (data) {
    const { address: primary_address } = data;

    return (
      <FormBuilder
        onSubmit={onSubmit}
        isFetching={isFetching}
        errors={errors}
        values={{
          primary_address,
        }}
        fieldsets={[
          {
            id: 'primary_address',
            fieldset: 'address',
            // title: 'Home address',
          },
        ]}
        submitButtonText='Save changes'
      />
    );
  }

  return null;
};
