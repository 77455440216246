import React, { FC, useState, useEffect } from 'react';
import { useResource } from '@tg/core/hooks';
import { Form, Modal, Button } from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { Employer } from 'src/types';
import { object, string } from 'yup';

interface FormData {
  new_recipient: {
    email: string;
  };
}

interface Props {
  employerId: string;
  isOpen: boolean;
  onClose: () => void;
}

const validationSchema = object().shape({
  new_recipient: object().shape({
    email: string().email().required(),
  }),
});

const EmailRecipientsModal: FC<Props> = ({ isOpen, onClose, employerId }) => {
  const { t } = useTranslation(['payroll']);

  const { handleSubmit, getFieldProps } = Form.useForm<FormData>({
    validationSchema,
  });

  const { data, isFetching, patchResource, errors } = useResource<Employer>(
    { url: `employers/${employerId}` },
    true,
  );

  const [recipients, setRecipients] = useState<string[]>([]);
  // Set existing recipients on load
  useEffect(() => {
    const { payroll_notice_recipients } = data || {};
    if (typeof payroll_notice_recipients === 'string') {
      setRecipients(payroll_notice_recipients.split(','));
    }
  }, [data]);

  const onSubmit = (formData: FormData) => {
    const newRecipients = recipients;
    newRecipients.push(formData.new_recipient.email);

    patchResource({
      formData: {
        employer: {
          payroll_notice_recipients: newRecipients.join(', '),
        },
      },
    });
  };

  return (
    <Modal
      title={t('payroll:email_recipients.modal.title')}
      isOpen={isOpen}
      onClose={onClose}
      width='xs'
    >
      <Modal.Content>
        <p className='-mt-4 text-gray-800 mb-4'>
          {t('payroll:email_recipients.modal.description')}
        </p>
        {!!recipients.length && (
          <ul className='divide-y divide-gray-200 mb-6'>
            {recipients.map(email => (
              <li key={email} className='py-2 flex'>
                <span className='text-sm text-gray-500'>{email}</span>
              </li>
            ))}
          </ul>
        )}
        <Form
          onSubmit={handleSubmit(onSubmit)}
          isFetching={isFetching}
          errors={errors}
        >
          <div className='flex items-end'>
            <div className='w-full'>
              <Form.TextField
                {...getFieldProps({
                  id: 'new_recipient',
                  name: 'email',
                })}
                required
                autoComplete='off'
              />
            </div>
            <span className='ml-3 mt-5'>
              <Button color='tertiary' type='submit'>
                Add
              </Button>
            </span>
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default EmailRecipientsModal;
