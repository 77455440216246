import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Input, Flag } from 'semantic-ui-react';
import { css } from '@emotion/core';

/**
 * Removing the 'search' prop breaks styling and a few of the
 * nice interactions so we pass it a search function that does nothing
 * instead
 */
const dummySearch = options => {
  return options;
};

const PhoneField = ({
  onChange,
  defaultValue,
  placeholder,
  disabled,
  selectableOptions,
}) => {
  // const [code, setCode] = useState(defaultValue?.code || '');
  const [country_code, setCountryCode] = useState(defaultValue?.country_code);
  const [phone, setPhone] = useState(defaultValue?.phone || '');

  /**
   * Format as per semantic-ui Dropdown component
   */
  const options = selectableOptions.map(
    ({ id, value, flag, countryName, code }) => {
      return {
        key: id,
        value,
        flag,
        code: `+${code}`,
        text: `${countryName} (+${code})`,
      };
    },
  );

  const [selectedCountry, setSelectedCountry] = useState(undefined);
  useEffect(() => {
    const country = options.find(({ value }) => value === country_code);
    setSelectedCountry(country);
  }, [selectableOptions, country_code]);

  /**
   * When the user changes the values, emit them to the parent
   */
  useEffect(() => {
    if (selectedCountry || phone) {
      onChange({
        phone,
        country_code: selectedCountry?.value,
        code: selectedCountry?.code,
      });
    }
  }, [selectedCountry, phone]);

  return (
    <>
      <Input
        label={
          <Dropdown
            css={css`
              /* For the error state */
              border-width: 1px !important;
              border-color: rgba(34, 36, 38, 0.15) !important;
            `}
            compact
            search={dummySearch}
            closeOnChange
            placeholder={placeholder.code}
            onChange={(_, { value }) => {
              setCountryCode(value);
            }}
            defaultValue={country_code}
            disabled={disabled}
            trigger={
              selectedCountry && (
                <span>
                  <Flag name={selectedCountry.flag} />
                  {selectedCountry.code}
                </span>
              )
            }
            options={options}
          />
        }
        labelPosition='left'
        placeholder={placeholder.phone}
        defaultValue={phone}
        onChange={(_, { value }) => {
          setPhone(value);
        }}
        disabled={disabled}
      />
    </>
  );
};

PhoneField.propTypes = {
  selectableOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      flag: PropTypes.string.isRequired,
      countryName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.shape({
    code: PropTypes.string,
    phone: PropTypes.string,
    country_code: PropTypes.string.isRequired,
  }),
  placeholder: PropTypes.shape({
    code: PropTypes.string,
    phone: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

PhoneField.defaultProps = {
  onChange: f => f,
  defaultValue: undefined,
  placeholder: { code: 'Code', phone: 'Phone' },
  disabled: false,
};

export default PhoneField;
