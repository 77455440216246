import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form, Input } from 'semantic-ui-react';

const IdentifiersField = props => {
  const {
    formContext,
    domicileId,
    onChange,
    errors,
    defaultValue,
    disabled,
    setIsVisible,
  } = props;

  /* 
  identifier_types example:
  [
    { code: 'NIN', name: 'NI No.', description: 'National Insurance Number', validator: [REGEX_STRING] }
  ]

  identifiers example:
  [
    { code: 'NIN', name: 'NIN', value: 'JA 74 32 09 C' }
  ]
  */

  const identifier_types = useSelector(
    state =>
      state.collections.domiciles.byId[domicileId][
        `${formContext}_identifiers`
      ],
  );

  const [identifiers, setIdentifiers] = useState(
    identifier_types.map((identifier_type, index) => ({
      ...identifier_type,
      value: defaultValue[index]?.value || null,
    })),
  );

  useEffect(() => {
    setIdentifiers(
      identifier_types.map((identifier_type, index) => ({
        ...identifier_type,
        value: identifiers[index]?.value || null,
      })),
    );
  }, [identifier_types]);

  useEffect(() => {
    onChange(
      identifiers.map(({ code, name, value }) => ({ code, name, value })),
    );
  }, [identifiers]);

  return identifier_types.map((identifier_type, index) => {
    if (identifier_type.name && setIsVisible) {
      setIsVisible(true);
    }
    const hasError = !!errors;
    return (
      <Form.Field required={!disabled} error={hasError}>
        <label>{identifier_type.name}</label>
        <Input
          onChange={(_, { value }) => {
            setIdentifiers(
              identifier_types.map((x, i) =>
                i === index ? { ...x, value } : x,
              ),
            );
          }}
          defaultValue={identifiers[index]?.value || null}
          disabled={disabled}
        />
        {hasError ? <p>{errors.message.key}</p> : null}
      </Form.Field>
    );
  });
};

IdentifiersField.propTypes = {
  formContext: PropTypes.oneOf(['employer', 'employee']).isRequired,
  domicileId: PropTypes.string.isRequired,
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      domicile: PropTypes.string,
      code: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  errors: PropTypes.shape({
    message: PropTypes.shape({
      key: PropTypes.string,
    }),
  }),
  disabled: PropTypes.bool,
};

IdentifiersField.defaultProps = {
  errors: null,
  onChange: f => f,
  defaultValue: [],
  disabled: false,
};

export default IdentifiersField;
