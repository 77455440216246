import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const WeekdaysField = ({ value, onChange }) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    onChange(state);
  }, [state]);

  const Weekday = ({ children, index }) => {
    const toggleDay = () => {
      setState(state.map((val, i) => (i === index ? !val : val)));
    };

    return (
      <Button primary={state[index]} type='button' onClick={toggleDay}>
        {children}
      </Button>
    );
  };

  Weekday.propTypes = {
    children: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
  };

  return (
    <div>
      <Weekday index={1}>MON</Weekday>
      <Weekday index={2}>TUE</Weekday>
      <Weekday index={3}>WED</Weekday>
      <Weekday index={4}>THU</Weekday>
      <Weekday index={5}>FRI</Weekday>
      <Weekday index={6}>SAT</Weekday>
      <Weekday index={0}>SUN</Weekday>
    </div>
  );
};

WeekdaysField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.bool),
  onChange: PropTypes.func,
};

WeekdaysField.defaultProps = {
  // Sunday = 0
  value: [false, true, true, true, true, true, false],
  onChange: f => f,
};

export default WeekdaysField;
