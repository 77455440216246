/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { Form } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useResource } from '@tg/core/hooks';
import { getActiveStorageUrl } from '@tg/core/utils/urlHelpers';
import {
  FileField,
  SelectField,
  Button,
  PaginationControl,
} from '@tg/core/components';
import DeleteDocument from '../../DeleteDocument';

const ContractorInvoices = () => {
  const { t } = useTranslation(['forms', 'datetime']);

  const { control, handleSubmit, register, errors: fieldErrors } = useForm();
  const [files, setFiles] = useState([]);
  const { contractId } = useParams();
  const [page, setPage] = useState(1);

  const { data, pagination, postResource, deleteResource } = useResource(
    {
      url: `contracts/${contractId}/contractor_invoices?page=${page}&pagesize=10`,
    },
    true,
  );

  const onSubmit = values => {
    if (!files.length) {
      return console.log('invalid form');
    }

    const formData = new FormData();
    formData.append('month', values.month);
    formData.append('year', values.year);
    formData.append('file', files[0]);

    return postResource({ formData, headers: {} });
  };

  const onDrop = useCallback(dropped => {
    setFiles(dropped);
  }, []);

  const now = new Date();
  const defaultValues = {
    month: now.getMonth(),
    year: now.getFullYear(),
  };

  const onDeleteSuccess = () => {
    // Edge Case Scenario
    if (page > 1 && data.length % 10 === 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      {/* <Heading level='h3'>Contractor Invoices</Heading> */}
      <p className='mb-4'>
        If this employee is a contractor, they will contact Teamed with their
        invoices. Those invoices should be uploaded here and will then be
        viewable by employers when they log in to the app.
      </p>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-4'>
          <FileField onDrop={onDrop} />
        </div>

        {files.map(f => (
          <div key={f.name}>{f.name}</div>
        ))}
        <Form.Group widths={2}>
          <Form.Field required error={!!fieldErrors.month}>
            <label>Month</label>
            <Controller
              as={
                <SelectField
                  options={Array.from(new Array(12)).map((_, index) => ({
                    text: t(`datetime:monthnames.${index}`),
                    value: index,
                  }))}
                />
              }
              control={control}
              name='month'
              defaultValue={defaultValues.month}
              rules={{ required: t('errors.required') }}
            />
            {fieldErrors.month && <p>{fieldErrors.month.message}</p>}
          </Form.Field>

          <Form.Field required error={!!fieldErrors.year}>
            <label>Year</label>
            <input
              name='year'
              type='number'
              ref={register({ required: t('errors.required') })}
              defaultValue={defaultValues.year}
            />
            {fieldErrors.year && <p>{fieldErrors.year.message}</p>}
          </Form.Field>
        </Form.Group>

        <Button type='submit'>{t('buttons.upload')}</Button>
      </Form>

      {data ? (
        <div>
          <ul>
            {data.map(({ id, document, month, year }) => (
              <li
                key={id}
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <a href={`${getActiveStorageUrl(document.url)}`}>{`${t(
                  `datetime:monthnames.${month}`,
                )} ${year}`}</a>
                <div>
                  <DeleteDocument
                    onClick={() => {
                      deleteResource({ id, onSuccess: onDeleteSuccess });
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};

export default ContractorInvoices;
