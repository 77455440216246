import { Toast } from '@tg/core/components/organisms/AppToasts/AppToasts';
import { createActions } from '@tg/core/utils/actionHelpers';

export const actions = {
  ...createActions({
    resource: 'toast',
    verbs: ['ADD'],
    prefix: 'app',
  }),
};

const initialState = {
  toasts: [] as Toast[],
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.ADD_TOAST: {
      const toasts = [...state.toasts, payload];
      return {
        ...state,
        toasts,
      };
    }

    default:
      return state;
  }
};
