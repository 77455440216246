import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
import { getActiveStorageUrl } from '@tg/core/utils/urlHelpers';
import {
  FileField,
  Heading,
  Segment,
  PaginationControl,
} from '@tg/core/components';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import permissions from '@tg/core/constant';
import { useSelector } from 'react-redux';
import DeleteDocument from '../../../../DeleteDocument';
import { Modal } from 'semantic-ui-react';
import ErrorModal from '@tg/core/components/molecules/ErrorModal';

const {
  EMPLOYER_DETAIL_VIEW: {
    DOCUMENTS: {
      DRAG_AND_DROP_CONTRACT_DOCUMENTS,
      LIST_OF_DOCUMENTS_UPLOADED,
      LIST_OF_DOCUMENTS_DELETE,
    },
  },
} = permissions;

const EmployerDocuments = () => {
  const { employerId } = useParams();
  const [page, setPage] = useState(1);
  const [isValidFormat, setIsValidFormat] = useState({
    message: null,
    isValid: true,
  });
  const accesslist = useSelector(state => state?.access?.roles);
  const {
    data,
    isFetching,
    postResource,
    deleteResource,
    pagination,
  } = useResource(
    { url: `employers/${employerId}/documents?page=${page}&pagesize=10` },
    true,
  );

  const onDrop = useCallback(dropped => {
    const formData = new FormData();
    dropped.forEach(file => {
      formData.append('files[]', file);
    });
    postResource({
      formData,
      headers: {},
      onSuccess: setPage(1),
      onError: err => {
        if (err?.response?.error?.includes('invalid file type')) {
          setIsValidFormat({ message: err?.response?.error, isValid: false });
        }
      },
    });
  }, []);

  const onDeleteSuccess = () => {
    // Edge Case Scenario
    if (page > 1 && data.length % 10 === 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      <Heading level='h3'>Contract documents</Heading>

      {getPermission(accesslist, DRAG_AND_DROP_CONTRACT_DOCUMENTS) && (
        <FileField onDrop={onDrop} multiple />
      )}

      {getPermission(accesslist, LIST_OF_DOCUMENTS_UPLOADED) && (
        <Segment>
          <ul>
            {data &&
              data.map(({ id, url, filename }) => (
                <li key={id}>
                  <div>
                    <a href={`${getActiveStorageUrl(url)}`}>{filename}</a>
                    {getPermission(accesslist, LIST_OF_DOCUMENTS_DELETE) && (
                      <DeleteDocument
                        onClick={() => {
                          deleteResource({ id, onSuccess: onDeleteSuccess });
                        }}
                      />
                    )}
                  </div>
                </li>
              ))}
          </ul>
          {!isFetching && data && !data.length && 'No Documents Found'}
        </Segment>
      )}
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
      <ErrorModal
        size='tiny'
        open={!isValidFormat?.isValid}
        header='Error'
        content={isValidFormat?.message}
        actions={['OK']}
        onClose={() => setIsValidFormat({ message: null, isValid: true })}
      />
    </>
  );
};

export default EmployerDocuments;
