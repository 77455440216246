/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Dimmer, Loader } from 'semantic-ui-react';
import {
  Button,
  Heading,
  CheckboxField,
  DateField,
  IdentifiersField,
  EditorField,
  Segment,
} from '@tg/core/components';
import PasswordField from './Fields/PasswordField';
import TextField from './Fields/TextField';
import PhoneField from './Fields/PhoneField';
import CollectionField from './Fields/CollectionField';
import { getValidationSchema } from './validations';
import TextAreaField from '../Form/Fields/TextAreaField';
import CustomTextField from './Fields/CustomTextField';

const FormBuilder = ({
  values,
  onSubmit,
  fieldsets,
  segmented,
  isFetching,
  disabled,
  widths,
  errors: apiErrors,
  submitButtonText,
  submitButtonProps,
  setIsVisible,
}) => {
  const {
    register,
    control,
    watch,
    handleSubmit,
    errors: fieldErrors,
  } = useForm({
    resolver: yupResolver(getValidationSchema(fieldsets)),
  });
  const { t } = useTranslation('forms');
  // console.log(fieldErrors, values);
  if (apiErrors && apiErrors.length) console.log(apiErrors);

  const defaultValues = fieldsets.reduce((acc, { id }) => {
    acc[id] = values[id] || {};
    return acc;
  }, {});

  const disabledFields = fieldsets.reduce((acc, { id }) => {
    acc[id] = disabled[id] || false;
    return acc;
  }, {});

  const isDisabled = (id, name) => {
    return disabledFields[id]
      ? typeof disabledFields[id] === 'boolean'
        ? true
        : !!disabledFields[id].find(x => x === name)
      : false;
  };

  const getIdentifiersFields = ({
    id,
    name,
    errors,
    formContext,
    watchDomicile,
  }) => {
    const domicileId =
      watchDomicile ||
      (values[formContext] ? values[formContext].domicile_id : null);
    return domicileId ? (
      <Controller
        as={
          <IdentifiersField
            formContext={formContext}
            domicileId={domicileId}
            errors={errors[name]}
            defaultValue={defaultValues[id][name]}
            setIsVisible={setIsVisible}
          />
        }
        control={control}
        name={`${id}.${name}`}
        disabled={isDisabled(id, name)}
      />
    ) : null;
  };

  /**
   * Used within getFieldset() by mutliple fieldset types
   */
  const getAddressFields = ({ getFieldProps }) => {
    const translationKey = 'address';
    return (
      <>
        <Form.Group widths={widths}>
          <TextField
            {...getFieldProps({ name: 'line_one', translationKey })}
            required
          />
          <TextField {...getFieldProps({ name: 'line_two', translationKey })} />
          <TextField {...getFieldProps({ name: 'city', translationKey })} />
        </Form.Group>
        <Form.Group widths={widths}>
          <TextField {...getFieldProps({ name: 'state', translationKey })} />
          <TextField
            {...getFieldProps({ name: 'zip', translationKey })}
            required
          />
          <CollectionField
            {...getFieldProps({ name: 'country_id', translationKey })}
            resource='address_countries'
            control={control}
            required
            search
            useName
          />
        </Form.Group>
      </>
    );
  };

  /**
   * Used within getFieldset() by mutliple fieldset types
   */
  const getEmployeeFields = ({ getFieldProps }) => {
    const translationKey = 'employee';
    return (
      <>
        <Form.Group widths={widths}>
          <TextField
            {...getFieldProps({ name: 'first_name', translationKey })}
            required
          />
          <TextField
            {...getFieldProps({ name: 'last_name', translationKey })}
            required
          />
          <CollectionField
            {...getFieldProps({ name: 'domicile_id', translationKey })}
            resource='domiciles'
            useName
            control={control}
            required
            search
          />
        </Form.Group>

        <Form.Group widths={2}>
          <TextField
            {...getFieldProps({ name: 'email', translationKey })}
            required
          />
          <PhoneField
            {...getFieldProps({ name: 'phone', translationKey })}
            required
            control={control}
          />
        </Form.Group>
      </>
    );
  };

  /**
   * The bulk of form building is done here
   * This returns the input fields and is run multiple times to create each form
   */
  const getFieldset = ({
    id,
    fieldset,
    title = null,
    description = null,
    afterFields = null,
  }) => {
    const errors = fieldErrors[id] || {};

    /**
     * Get the props that are the same for all input fields
     * @param {string} name Used to calculate the props, default value, errors, disabled etc.
     * @param {string} translationKey Optional. Some fieldsets share a translation
     * the key can't be implied. e.g `registered_address.zip` doesn't exist, its `address.zip`
     */
    const getFieldProps = ({ name, translationKey }) => {
      return {
        id,
        name,
        translationKey: `${translationKey || fieldset}.${name}`,
        errors: errors[name],
        disabled: isDisabled(id, name),
        defaultValue: defaultValues[id][name],
        ref: register,
        register,
      };
    };

    const getFields = () => {
      switch (fieldset) {
        case 'create_password': {
          return (
            <>
              <PasswordField
                {...getFieldProps({ name: 'password' })}
                required
              />
              <PasswordField
                {...getFieldProps({ name: 'password_again' })}
                required
              />
            </>
          );
        }

        case 'accept_terms': {
          return (
            <>
              <Form.Field required error={!!errors.terms}>
                <Controller
                  as={CheckboxField}
                  control={control}
                  rules={{ required: t('errors.accept_terms.terms') }}
                  name={`${id}.terms`}
                  label={
                    <label
                      dangerouslySetInnerHTML={{
                        __html: t('labels.accept_terms.terms', {
                          interpolation: { escapeValue: false },
                          terms_url:
                            'https://static.teamed.global/legals/2020-10-18/tos.pdf',
                        }),
                      }}
                    />
                  }
                />
              </Form.Field>

              <Form.Field required error={!!errors.privacy}>
                <Controller
                  as={CheckboxField}
                  rules={{ required: t('errors.accept_terms.privacy') }}
                  name={`${id}.privacy`}
                  control={control}
                  label={
                    <label
                      dangerouslySetInnerHTML={{
                        __html: t('labels.accept_terms.privacy', {
                          interpolation: { escapeValue: false },
                          privacy_url:
                            'https://static.teamed.global/legals/2020-10-18/privacy_policy.pdf',
                        }),
                      }}
                    />
                  }
                />
              </Form.Field>
            </>
          );
        }

        case 'login': {
          return (
            <>
              <TextField {...getFieldProps({ name: 'email' })} required />
              <PasswordField
                {...getFieldProps({ name: 'password' })}
                required
              />
            </>
          );
        }

        case 'forgot': {
          return <TextField {...getFieldProps({ name: 'email' })} required />;
        }

        case 'bank_details': {
          return (
            <Form.Group widths={widths}>
              <TextField
                {...getFieldProps({ name: 'account_name' })}
                required
              />
              <TextField
                {...getFieldProps({ name: 'account_iban' })}
                required
              />
              <TextField
                {...getFieldProps({ name: 'account_swift' })}
                required
              />
            </Form.Group>
          );
        }

        case 'emergency_contact': {
          return (
            <Form.Group widths={2}>
              <TextField {...getFieldProps({ name: 'name' })} />
              <PhoneField
                {...getFieldProps({ name: 'phone' })}
                control={control}
              />
            </Form.Group>
          );
        }

        case 'employee': {
          const watchDomicile = watch(`${id}.domicile_id`, null);
          return (
            <>
              {getEmployeeFields({ getFieldProps })}

              <Form.Group widths={widths}>
                {getIdentifiersFields({
                  id,
                  name: 'identifiers',
                  required: true,
                  errors,
                  formContext: 'employee',
                  watchDomicile,
                })}
              </Form.Group>
            </>
          );
        }

        case 'employee_create': {
          return getEmployeeFields({ getFieldProps });
        }

        case 'contract_defaults': {
          return (
            <>
              <Form.Group widths={widths}>
                <TextField {...getFieldProps({ name: 'probation_period' })} />
                <TextField
                  {...getFieldProps({ name: 'notice_during_probation' })}
                />
                <TextField
                  {...getFieldProps({ name: 'notice_after_probation' })}
                />
              </Form.Group>

              <Form.Group widths={widths}>
                <TextField {...getFieldProps({ name: 'non_compete_clause' })} />
                <TextField {...getFieldProps({ name: 'specific_ip_clause' })} />
                <TextField {...getFieldProps({ name: 'working_from_home' })} />
              </Form.Group>

              <Form.Group widths={widths}>
                <TextField
                  {...getFieldProps({ name: 'holiday_entitlement' })}
                />
                <TextField {...getFieldProps({ name: 'holiday_carryover' })} />
              </Form.Group>

              <Form.Group widths={widths}>
                <TextField
                  {...getFieldProps({ name: 'sickday_entitlement' })}
                />
                <TextField {...getFieldProps({ name: 'sickday_carryover' })} />
              </Form.Group>

              <Form.Group widths={widths}>
                <TextAreaField {...getFieldProps({ name: 'benefits' })} />
                <div className='flex flex-col ml-2 field'>
                  <CustomTextField
                    {...getFieldProps({ name: 'custom_label' })}
                  />
                  <TextField
                    {...getFieldProps({ name: 'custom_input' })}
                    showLabel={false}
                  />
                </div>
                <TextField
                  {...getFieldProps({ name: 'invoice_due_date' })}
                  required
                />
              </Form.Group>
            </>
          );
        }

        case 'codat_connection': {
          return (
            <>
              <Segment>
                <Form.Group widths={widths}>
                  <TextField {...getFieldProps({ name: 'codat_company_id' })} />
                  <TextField
                    {...getFieldProps({ name: 'codat_connection_id' })}
                  />
                  <TextField {...getFieldProps({ name: 'supplier_id' })} />
                </Form.Group>
                <Form.Group widths={widths}>
                  <TextField {...getFieldProps({ name: 'supplier_name' })} />
                  <TextField {...getFieldProps({ name: 'bank_account_id' })} />
                  <TextField {...getFieldProps({ name: 'account_name' })} />
                </Form.Group>
              </Segment>
            </>
          );
        }

        case 'xero_connection': {
          return (
            <>
              <Segment>
                <Form.Group widths={widths}>
                  <TextField {...getFieldProps({ name: 'xero_company_id' })} />
                  <TextField
                    {...getFieldProps({ name: 'xero_connection_id' })}
                  />
                  <TextField {...getFieldProps({ name: 'xero_customer_id' })} />
                </Form.Group>
                <Form.Group widths={widths}>
                  <TextField
                    {...getFieldProps({ name: 'xero_teamed_account_id' })}
                  />
                  <TextField
                    {...getFieldProps({ name: 'xero_line_item_accont_id' })}
                  />
                  <TextField
                    {...getFieldProps({ name: 'xero_base_currency' })}
                  />
                </Form.Group>
                <Form.Group widths={widths}>
                  <TextField {...getFieldProps({ name: 'vat_tax_id' })} />
                  <TextField {...getFieldProps({ name: 'item_tax_id' })} />
                  <TextField
                    {...getFieldProps({ name: 'teamed_xero_customer_id' })}
                  />
                </Form.Group>
              </Segment>
            </>
          );
        }

        case 'employer': {
          const watchDomicile = watch(`${id}.domicile_id`, null);
          return (
            <>
              <Form.Group widths={widths}>
                <TextField {...getFieldProps({ name: 'name' })} required />
                <CollectionField
                  {...getFieldProps({ name: 'industry_sector_id' })}
                  resource='industry_sectors'
                  control={control}
                  required
                  search
                  useName
                />
                <CollectionField
                  {...getFieldProps({ name: 'domicile_id' })}
                  resource='domiciles'
                  useName
                  control={control}
                  required
                  search
                />
              </Form.Group>

              <Form.Group widths={widths}>
                {getIdentifiersFields({
                  id,
                  name: 'identifiers',
                  required: true,
                  errors,
                  formContext: 'employer',
                  watchDomicile,
                })}

                <TextField {...getFieldProps({ name: 'vat_number' })} />
                <CollectionField
                  {...getFieldProps({ name: 'currency_id' })}
                  resource='currencies'
                  control={control}
                  search
                  useName
                />
              </Form.Group>
            </>
          );
        }

        case 'officer': {
          return (
            <>
              <Form.Group widths={widths}>
                <TextField
                  {...getFieldProps({ name: 'first_name' })}
                  required
                />
                <TextField {...getFieldProps({ name: 'last_name' })} required />
                <TextField {...getFieldProps({ name: 'job_title' })} required />
              </Form.Group>

              <Form.Group widths={2}>
                <TextField {...getFieldProps({ name: 'email' })} required />
                <PhoneField
                  {...getFieldProps({ name: 'phone' })}
                  required
                  control={control}
                />
              </Form.Group>
            </>
          );
        }

        case 'administrator': {
          return (
            <>
              <Form.Group widths={widths}>
                <TextField
                  {...getFieldProps({ name: 'first_name' })}
                  required
                />
                <TextField {...getFieldProps({ name: 'last_name' })} required />
                <TextField {...getFieldProps({ name: 'email' })} required />
              </Form.Group>
            </>
          );
        }

        case 'address': {
          return getAddressFields({ getFieldProps });
        }

        case 'address_with_same_as': {
          const defaultSameAsRegistered = !!defaultValues[id]
            .same_as_registered;

          const watchSameAs = watch(
            `${id}.same_as_registered`,
            defaultSameAsRegistered,
          );

          return (
            <>
              <Form.Field>
                <Controller
                  as={CheckboxField}
                  control={control}
                  name={`${id}.same_as_registered`}
                  label={t(`labels.address_with_same_as.sameAsCheckbox`)}
                  defaultValue={defaultSameAsRegistered}
                  id='sameAsCheckbox'
                />
              </Form.Field>
              {!watchSameAs && getAddressFields({ getFieldProps })}
            </>
          );
        }

        case 'employee_benefit': {
          return (
            <>
              <Form.Group widths={2}>
                <CollectionField
                  {...getFieldProps({ name: 'employee_benefit_type_id' })}
                  resource='employee_benefit_types'
                  control={control}
                  required
                  search
                  useName
                />
                <TextField {...getFieldProps({ name: 'name' })} required />
              </Form.Group>

              <Form.Field required>
                <label>{t('labels.employee_benefit.description')}</label>
                <Controller
                  as={EditorField}
                  control={control}
                  name={`${id}.description`}
                  placeholder={t('placeholders.employee_benefit.description')}
                  defaultValue={defaultValues.employee_benefit.description}
                />
              </Form.Field>
            </>
          );
        }

        case 'contract_benefit': {
          return (
            <>
              <Form.Group widths={2}>
                <CollectionField
                  {...getFieldProps({ name: 'employee_benefit_id' })}
                  resource='employee_benefits'
                  control={control}
                  required
                  search
                  useName
                />
                <Form.Field required error={!!errors.start_date}>
                  <label>{t('labels.contract_benefit.start_date')}</label>
                  <Controller
                    as={DateField}
                    control={control}
                    name={`${id}.start_date`}
                    placeholder={t('placeholders.contract_benefit.start_date')}
                    defaultValue={defaultValues[id].start_date}
                    rules={{ required: t('errors.required') }}
                  />
                  {errors.start_date && (
                    <p>{t(`validations.${errors.start_date.message.key}`)}</p>
                  )}
                </Form.Field>
              </Form.Group>
            </>
          );
        }

        case 'contract': {
          // TODO: create a better selector for this
          // as 'fixed term' may not reliably be
          // in this array position
          const contractTypesFixedTerm = useSelector(
            state => state.collections.contract_types.allIds[1],
          );
          const watchContractType = watch(
            `${id}.contract_type_id`,
            defaultValues[id].end_date ? contractTypesFixedTerm : null,
          );
          return (
            <>
              <input
                ref={register}
                type='hidden'
                name={`${id}.fixed_term`}
                value={
                  watchContractType &&
                  watchContractType === contractTypesFixedTerm
                }
              />
              <Form.Group widths={widths}>
                <TextField {...getFieldProps({ name: 'job_title' })} required />
                <CollectionField
                  {...getFieldProps({ name: 'contract_type_id' })}
                  resource='contract_types'
                  control={control}
                  required
                  search
                />
                <CollectionField
                  {...getFieldProps({ name: 'attendance_type_id' })}
                  resource='attendance_types'
                  control={control}
                  required
                />
              </Form.Group>

              <Form.Group widths={widths}>
                <Form.Field required error={!!errors.start_date}>
                  <label>{t('labels.contract.start_date')}</label>
                  <Controller
                    as={DateField}
                    control={control}
                    name={`${id}.start_date`}
                    placeholder={t('placeholders.contract.start_date')}
                    defaultValue={
                      defaultValues[id].start_date
                        ? new Date(defaultValues[id].start_date)
                        : null
                    }
                  />
                  {errors.start_date && (
                    <p>{t(`validations.${errors.start_date.message.key}`)}</p>
                  )}
                </Form.Field>

                {watchContractType &&
                watchContractType === contractTypesFixedTerm ? (
                  <Form.Field required error={!!errors.end_date}>
                    <label>{t('labels.contract.end_date')}</label>
                    <Controller
                      as={DateField}
                      control={control}
                      name={`${id}.end_date`}
                      placeholder={t('placeholders.contract.end_date')}
                      defaultValue={
                        defaultValues[id].end_date
                          ? new Date(defaultValues[id].end_date)
                          : null
                      }
                      rules={{ required: t('errors.required') }}
                    />
                    {errors.end_date && (
                      <p>{t(`validations.${errors.end_date.message.key}`)}</p>
                    )}
                  </Form.Field>
                ) : null}
              </Form.Group>
            </>
          );
        }

        default:
          return null;
      }
    };

    const childNodes = (
      <>
        {title && <Heading level='h3'>{title}</Heading>}
        {description && <p>{description}</p>}
        {getFields()}
        {afterFields}
      </>
    );

    return (
      <div key={id} style={{ marginBottom: '30px' }}>
        {segmented ? <Segment key={id}>{childNodes}</Segment> : childNodes}
      </div>
    );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Dimmer inverted active={isFetching}>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      {fieldsets.map(fieldset => getFieldset(fieldset))}
      {onSubmit && (
        <Button disabled={isFetching} type='submit' {...submitButtonProps}>
          {submitButtonText || t('buttons.submit')}
        </Button>
      )}
    </Form>
  );
};

FormBuilder.propTypes = {
  values: PropTypes.shape(),
  onSubmit: PropTypes.func,
  fieldsets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      afterFields: PropTypes.node,
    }),
  ),
  segmented: PropTypes.bool,
  isFetching: PropTypes.bool,
  errors: PropTypes.array,
  disabled: PropTypes.shape(),
  widths: PropTypes.number,
  submitButtonText: PropTypes.string,
  submitButtonProps: PropTypes.object,
  setIsVisible: PropTypes.func,
};

FormBuilder.defaultProps = {
  values: {},
  onSubmit: null,
  fieldsets: [],
  segmented: false,
  isFetching: false,
  errors: [],
  disabled: {},
  widths: 3,
  submitButtonText: null,
  submitButtonProps: {},
  setIsVisible: false,
};

export default FormBuilder;
