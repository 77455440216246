import React from 'react';
import PropTypes from 'prop-types';

const DisplayField = ({ label, value }) => {
  return (
    <div>
      <label className='font-bold'>{label}</label>
      <div>{value}</div>
    </div>
  );
};

DisplayField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default DisplayField;
