import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Login from './Login';
import Activate from './Activate';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import ConfirmEmail from './ConfirmEmail';

export default props => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/login`}>
        <Login {...props} />
      </Route>

      <Route path={`${path}/forgot`}>
        <ForgotPassword {...props} />
      </Route>

      <Route path={`${path}/reset/:token`}>
        <ResetPassword {...props} />
      </Route>

      <Route path={`${path}/activate/:token`}>
        <Activate {...props} />
      </Route>

      <Route path={`${path}/confirm/:token`}>
        <ConfirmEmail {...props} />
      </Route>
    </Switch>
  );
};
