import React, { FC, useState, useEffect } from 'react';
import {
  Message,
  List,
  Avatar,
  Heading,
  Breadcrumbs,
  PaginationControl,
  Button,
  ButtonBar,
} from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
// import { Contract } from 'src/types';
import { formatValue } from '@tg/core/utils/currencyHelpers';

import ExpensesModal from '../components/ExpensesModal';
import ViewReceiptModal from '../components/ViewReceiptModal';

interface Row {
  id: string;
  bonuses: string;
  commisions: string;
  completed_at: string;
  contract: unknown; // Contract;
  created_at: string;
  deductions: string;
  expenses: string;
  notes: string;
  month: number;
  receipts: File[];
  submitted_at: string | null;
  year: number;
}

const OfficerPayrollSingle: FC = () => {
  const { t } = useTranslation(['page_titles', 'dateTime', 'collections']);

  const { submission_id } = useParams<{ submission_id: string }>();

  const [page, setPage] = useState(1);

  const { data, isFetching, getResource, pagination } = useResource<Row[]>(
    {
      url: `payroll_adjustments/submissions/${submission_id}?page=${page}`,
    },
    true,
  );

  const [month, setMonth] = useState<number>();
  const [year, setYear] = useState<number>();
  useEffect(() => {
    if (data && data.length) {
      setMonth(data[0].month);
      setYear(data[0].year);
    }
  }, [data]);

  const { patchResource: submitSheet } = useResource<Row[]>({
    url: `/payroll_adjustments/${submission_id}/submit`,
  });
  const history = useHistory();
  const onSubmit = () => {
    submitSheet({
      onSuccess: () => {
        history.push({ pathname: `/payroll` });
        // console.log('done');
      },
    });
  };

  if (data && !data.length) {
    return (
      <Message type='error' title='Error Loading Payroll Input Sheet'>
        <p>
          This sheet either does not exist or does not contain any employees
        </p>
        <Link to='/payroll' className='underline'>
          Back to list
        </Link>
      </Message>
    );
  }

  return (
    <>
      <div className='mb-2'>
        <Breadcrumbs
          items={[{ to: '/payroll', text: t('page_titles:payroll') }]}
        />
      </div>
      <Heading level='h3'>
        {typeof month === 'number' &&
          year &&
          `${t(`dateTime:months.${month}.full`)} ${year}`}
      </Heading>

      {isFetching && <div className='mt-4 space-y-4'>Loading</div>}

      {data && !!data.length && (
        <List
          type='payroll'
          headings={[
            { text: '' },
            { text: 'Current Salary', width: '105px' },
            { text: 'Expenses', width: '105px' },
            { text: 'Bonuses', width: '105px' },
            { text: 'Commission', width: '105px' },
            { text: '', width: '60px' },
          ]}
          items={data.map(
            ({
              id,
              contract,
              contract: {
                current_salary: { currency, approve, gross_salary },
                employee: { user, domicile },
              },
              bonuses,
              commisions,
              expenses,
              receipts,
              notes,
            }) => ({
              id,
              columns: [
                <Link to={`/employees/${contract.id}`}>
                  <Avatar user={user} size='small' subHeading={domicile.name} />
                </Link>,
                <div className='break-words'>
                  {gross_salary === '0.0' || !approve
                    ? '-'
                    : formatValue({
                        value: gross_salary,
                        currency,
                        showName: false,
                        type: 'salary',
                        subtype: 'grossSalary',
                      })}
                </div>,
                <>
                  <div className='break-words'>
                    {expenses === '0.0'
                      ? '-'
                      : formatValue({
                          value: expenses,
                          currency,
                          showName: false,
                          type: 'salary',
                        })}
                  </div>
                  <div className='flex justify-between text-sm'>
                    {!!receipts.length && (
                      <>
                        <ViewReceiptModal
                          receipts={receipts}
                          userFullName={user.full_name}
                          emp_id={id}
                          sub_id={submission_id}
                          getList={() => getResource()}
                        />
                      </>
                    )}
                  </div>
                </>,
                <div className='break-words'>
                  {bonuses === '0.0'
                    ? '-'
                    : formatValue({
                        value: bonuses,
                        currency,
                        showName: false,
                        type: 'salary',
                      })}
                </div>,
                <div className='break-words'>
                  {commisions === '0.0'
                    ? '-'
                    : formatValue({
                        value: commisions,
                        currency,
                        showName: false,
                        type: 'salary',
                      })}
                </div>,
                <>
                  <ExpensesModal
                    payrollId={id}
                    submission_id={submission_id}
                    userFullName={user.full_name}
                    currency={currency}
                    emp_id={id}
                    defaultValues={{
                      bonuses,
                      commisions,
                      expenses,
                      gross_salary,
                      receipts: receipts || [],
                      notes: notes || '',
                    }}
                    getList={() => getResource()}
                  />
                </>,
              ],
            }),
          )}
        />
      )}
      {pagination && (
        <div className='mt-6'>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
      <div className='mt-6'>
        <ButtonBar type='border'>
          <div className='space-x-4 flex'>
            <p className='max-w-2xl'>
              Submitting this will mark it as submitted to that the company
              officers can no longer edit it. It will still be possible to edit
              it here in admin.
            </p>
            <div>
              <Button
                type='button'
                onClick={() => onSubmit()}
                loading={isFetching}
              >
                Submit
              </Button>
            </div>
          </div>
        </ButtonBar>
      </div>
    </>
  );
};

export default OfficerPayrollSingle;
