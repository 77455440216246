import React from 'react';
import { Input } from 'semantic-ui-react';
import { LoadingSpinner } from '@tg/core/components';
import PropTypes from 'prop-types';

const InputText = ({ loading, icon, ...props }) => {
  return (
    <div className='relative'>
      <Input fluid icon={loading ? null : icon} {...props} />
      {loading && (
        <div className='absolute top-0 right-0 bottom-0 flex items-center pr-4'>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

InputText.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
  icon: PropTypes.string,
};

InputText.defaultProps = {
  label: null,
  loading: false,
  icon: null,
};

export default InputText;
