import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { selectSessionAuthToken } from '@tg/core/store/modules/session';

const PrivateRoute = ({ component: Component, redirectPath, ...rest }) => {
  const hasSession = !!useSelector(state => selectSessionAuthToken(state));
  const allowed = hasSession;

  return (
    <Route
      {...rest}
      render={routeProps =>
        allowed ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  redirectPath: PropTypes.string,
};

PrivateRoute.defaultProps = {
  redirectPath: '/auth/login',
};

export default PrivateRoute;
