import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';
import { Heading, DisplayField } from '@tg/core/components';

const EmployeeContracts = ({ data }) => {
  return (
    <>
      <Heading level='h3'>Contracts</Heading>

      {data ? (
        <div>
          <ul>
            {data.map(contract => (
              <li key={contract.id}>
                <div
                  css={css`
                    padding: 1rem;
                    border-bottom: 1px solid;
                  `}
                  className='flex items-center justify-between'
                >
                  <DisplayField
                    label='Employer name'
                    value={contract.employer.name}
                  />
                  <DisplayField label='Job title' value={contract.job_title} />
                  <DisplayField label='Status' value={contract.status} />
                  <Link
                    to={`/employers/${contract.employer.id}/employees/${contract.id}`}
                    className='link'
                  >
                    View details
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
};

EmployeeContracts.propTypes = {
  data: PropTypes.any.isRequired,
};

export default EmployeeContracts;
