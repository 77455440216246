import React, { useEffect, useState } from 'react';
import { useResource } from '@tg/core/hooks';
import { Button, Form, Segment } from '@tg/core/components';

const XeroConnection = () => {
  const [editingXero, setEditing] = useState(null);

  const {
    data,
    isFetching,
    postResource,
    patchResource,
    getResource,
  } = useResource({ url: `xero_connection` }, true);

  const { handleSubmit, getFieldProps } = Form.useForm({
    validationSchema: null,
  });

  const onSubmit = values => {
    if (!data?.length) {
      postResource({
        formData: values.xero_connection,
        onSuccess: () => getResource(),
      });
    } else {
      patchResource({
        id: editingXero.id,
        formData: values.xero_connection,
        onSuccess: () => getResource(),
      });
    }
  };

  useEffect(() => {
    if (data) {
      setEditing(data[0]);
    }
  }, [data]);

  const {
    xero_connection_id,
    xero_company_id,
    xero_teamed_account_id,
    xero_line_item_accont_id,
    xero_base_currency,
    vat_tax_id,
    item_tax_id,
  } = editingXero || {};

  return (
    <>
      <Segment heading='Xero Connection'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className='grid grid-cols-3 gap-6'>
            <Form.TextField
              {...getFieldProps({
                name: 'xero_connection_id',
                id: 'xero_connection',
              })}
              required
              defaultValue={xero_connection_id}
            />
            <Form.TextField
              {...getFieldProps({
                name: 'xero_company_id',
                id: 'xero_connection',
              })}
              required
              defaultValue={xero_company_id}
            />
            <Form.TextField
              {...getFieldProps({
                name: 'xero_teamed_account_id',
                id: 'xero_connection',
              })}
              defaultValue={xero_teamed_account_id}
              required
            />
          </div>
          <div className='grid grid-cols-3 gap-6'>
            <Form.TextField
              {...getFieldProps({
                name: 'xero_line_item_accont_id',
                id: 'xero_connection',
              })}
              defaultValue={xero_line_item_accont_id}
              required
            />
            <Form.TextField
              {...getFieldProps({
                name: 'xero_base_currency',
                id: 'xero_connection',
              })}
              defaultValue={xero_base_currency}
              required
            />
            <Form.TextField
              {...getFieldProps({
                name: 'vat_tax_id',
                id: 'xero_connection',
              })}
              defaultValue={vat_tax_id}
              required
            />
          </div>
          <div className='grid grid-cols-3 gap-6'>
            <Form.TextField
              {...getFieldProps({
                name: 'item_tax_id',
                id: 'xero_connection',
              })}
              defaultValue={item_tax_id}
              required
            />
          </div>

          <div className='mt-4'>
            <Button type='submit' loading={isFetching}>
              {!data?.length ? 'Add' : 'Update'}
            </Button>
          </div>
        </Form>
      </Segment>
    </>
  );
};

export default XeroConnection;
