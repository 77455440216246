/* eslint-disable react/prop-types */
import React, { FC, ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';

interface TooltipProps {
  content: ReactNode;
}

const Tooltip: FC<TooltipProps> = ({ content, children }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
  });

  return (
    <span className='group relative'>
      <span ref={setReferenceElement}>{children}</span>
      <div
        ref={setPopperElement}
        className='opacity-0 group-hover:opacity-100 transition-opacity p-1 pointer-events-none whitespace-nowrap z-10'
        style={styles.popper}
        {...attributes.popper}
      >
        <div className='rounded leading-none bg-gray-700 text-white px-3 py-2 shadow-md'>
          {content}
        </div>
      </div>
    </span>
  );
};

export default Tooltip;
