/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React, { useReducer, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Checkbox } from 'semantic-ui-react';
import { requestWithDispatch } from '@tg/core/utils/requestHelpers';
import { transformCalendarificResponse } from '@tg/core/utils/dataTransformers';
import { formatLocaleDate } from '@tg/core/utils/datetimeHelpers';

const initialState = {
  fetching: false,
  data: null,
  customHolidayData: null,
  error: null,
  formData: null,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'FETCH_BEGIN':
      return {
        ...state,
        fetching: true,
      };

    case 'FETCH_END':
      return {
        ...state,
        fetching: false,
      };

    case 'FETCH_SUCCESS':
      return {
        ...state,
        data: transformCalendarificResponse(payload),
      };

    case 'FETCH_FAILURE':
      return {
        ...state,
        error: payload.data,
      };

    case 'FORMDATA_ADD': {
      payload.forEach(x => state.formData.add(x));
      return {
        ...state,
        formData: state.formData,
      };
    }

    case 'FORMDATA_REMOVE': {
      payload.forEach(x => state.formData.delete(x));
      return {
        ...state,
        formData: state.formData,
      };
    }

    case 'FETCH_CUSTOM_BEGIN':
      return {
        ...state,
        fetching: true,
      };

    case 'FETCH_CUSTOM_END':
      return {
        ...state,
        fetching: false,
      };

    case 'FETCH_CUSTOM_SUCCESS':
      return {
        ...state,
        customHolidayData: payload,
      };

    case 'FETCH_CUSTOM_FAILURE':
      return {
        ...state,
        error: payload.data,
      };

    default:
      return state;
  }
};

const Component = ({
  data,
  onSubmit,
  onCancel,
  publicHolidayRegion,
  contractId,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    formData: new Set(data.public_holidays),
  });
  const token = useSelector(s => s.session.aut);
  const call = requestWithDispatch(dispatch, token);
  const [isAddHolidayVisible, setIsAddHolidayVisible] = useState(false);
  const [holidayDate, setholidayDate] = useState(null);
  const [holidayName, setHolidayName] = useState(null);

  useEffect(() => {
    call({
      url: 'calendarific',
      action: 'fetch',
      params: {
        year: data.year,
        country: publicHolidayRegion.country,
        contract_id: contractId,
      },
    });
  }, []);

  useEffect(() => {
    call({
      url: 'custom_holidays',
      action: 'fetch_custom',
      params: { year: data.year, country: publicHolidayRegion.country },
    });
  }, []);

  const handleChange = (_, { checked, value }) => {
    dispatch({
      type: `FORMDATA_${checked ? 'ADD' : 'REMOVE'}`,
      payload: [value],
    });
  };

  const handleSubmit = () => {
    onSubmit(Array.from(state.formData).sort());
  };

  const handleSelectAll = (region = null) => {
    const holidays = region
      ? state.data.regional[region].holidays
      : state.data.national;
    dispatch({ type: 'FORMDATA_ADD', payload: holidays.map(x => x.date) });
  };

  const handleDeselectAll = (region = null) => {
    const holidays = region
      ? state.data.regional[region].holidays
      : state.data.national;
    dispatch({ type: 'FORMDATA_REMOVE', payload: holidays.map(x => x.date) });
  };

  const addExtraHoliday = e => {
    e.preventDefault();
    call({
      url: `custom_holidays?country=${publicHolidayRegion.country}&year=${data.year}`,
      method: 'post',
      action: 'fetch_custom',
      data: {
        date: holidayDate,
        description: holidayName,
      },
    });
    setIsAddHolidayVisible(false);
  };

  return (
    <Modal as={Form} onSubmit={handleSubmit} onClose={onCancel} open>
      <Modal.Header>Form</Modal.Header>
      <Modal.Content>
        {state.data && state.formData ? (
          <>
            <p>
              <strong>National holidays</strong>{' '}
              <a
                href='#'
                onClick={e => {
                  e.preventDefault();
                  handleSelectAll();
                }}
              >
                Select all
              </a>
              {' | '}
              <a
                href='#'
                onClick={e => {
                  e.preventDefault();
                  handleDeselectAll();
                }}
              >
                Deselect all
              </a>
            </p>
            {state.data.national.map(item => (
              <Form.Field key={item.date}>
                <Checkbox
                  name='public_holidays'
                  value={item.date}
                  onChange={handleChange}
                  checked={state.formData.has(item.date)}
                  label={`${formatLocaleDate(item.date)} - ${item.name}`}
                />
              </Form.Field>
            ))}
            <p> </p>
            <hr />
            <p>
              <strong>Regional holidays</strong>
            </p>
            {Object.keys(state.data.regional).map(key => (
              <div key={key}>
                <div>
                  <strong>{state.data.regional[key].name}</strong>{' '}
                  <a
                    href='#'
                    onClick={e => {
                      e.preventDefault();
                      handleSelectAll(key);
                    }}
                  >
                    Select all
                  </a>
                  {' | '}
                  <a
                    href='#'
                    onClick={e => {
                      e.preventDefault();
                      handleDeselectAll(key);
                    }}
                  >
                    Deselect all
                  </a>
                </div>
                {state.data.regional[key].holidays.map(item => (
                  <Form.Field key={item.date}>
                    <Checkbox
                      name='public_holidays'
                      value={item.date}
                      onChange={handleChange}
                      checked={state.formData.has(item.date)}
                      label={`${formatLocaleDate(item.date)} - ${item.name}`}
                    />
                  </Form.Field>
                ))}
              </div>
            ))}
            <p>
              <strong>Custom Holiday</strong> {' | '}
              <a
                href='#'
                onClick={e => {
                  e.preventDefault();
                  setIsAddHolidayVisible(!isAddHolidayVisible);
                }}
              >
                Add Extra Holiday
              </a>
            </p>
            {state.customHolidayData?.data?.map(item => (
              <Form.Field key={item.date}>
                <Checkbox
                  name='public_holidays'
                  value={item.date}
                  onChange={handleChange}
                  checked={state.formData.has(item.date)}
                  label={`${formatLocaleDate(item.date)} - ${item.description}`}
                />
              </Form.Field>
            ))}
            {isAddHolidayVisible && (
              <div className='flex mb-4'>
                <div className='w-1/5 h-12'>
                  <input
                    type='date'
                    value={holidayDate}
                    onChange={e => setholidayDate(e.target.value)}
                    placeholder='Holiday Date'
                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  />
                </div>
                <div className='w-1/5 h-12'>
                  <input
                    type='text'
                    value={holidayName}
                    onChange={e => setHolidayName(e.target.value)}
                    placeholder='Holiday Description'
                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  />
                </div>
                <div className='w-1/5 h-12'>
                  <Button onClick={e => addExtraHoliday(e)}>Add</Button>
                </div>
              </div>
            )}
          </>
        ) : (
          <p>wait</p>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button type='submit'>Save</Button>
        <Button color='black' onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

Component.propTypes = {
  data: PropTypes.shape({
    public_holidays: PropTypes.arrayOf(PropTypes.string),
    year: PropTypes.number,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  publicHolidayRegion: PropTypes.shape({
    state: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
};

Component.defaultProps = {
  data: {
    public_holidays: [],
    year: null,
  },
};

export default Component;
