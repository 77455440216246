import React, { FC, useState } from 'react';
import {
  SelectField,
  List,
  LabelledInput,
  Message,
  PaginationControl,
} from '@tg/core/components';
import { useResource } from '@tg/core/hooks';
import { formatDate, getYears } from '@tg/core/utils/datetimeHelpers';
import { useTranslation } from 'react-i18next';

interface AdjustmentSheet {
  employer: {
    id: string;
    name: string;
  };
  id: string;
  submitted_at: string | null;
  month: number;
  year: number;
}

const PayrollStatus: FC = () => {
  const { t } = useTranslation(['datetime']);

  const today = new Date();
  const [month, setMonth] = useState(today.getMonth());
  const [year, setYear] = useState(today.getFullYear());
  const [page, setPage] = useState(1);
  const { data, isFetching, errors, pagination } = useResource<
    AdjustmentSheet[]
  >(
    {
      url: `payroll_adjustments?year=${year}&month=${month}&page=${page}&pagesize=10`,
    },
    true,
  );

  const monthOptions = getYears.reduce((acc, _year) => {
    Array.from(new Array(12)).forEach((_, index) => {
      acc.push({
        key: `${_year}-${index}`,
        text: `${t(`datetime:monthnames.${index}`)} ${_year}`,
        value: { month: index, year: _year },
      });
    });
    return acc;
  }, []);

  return (
    <>
      <div className='w-80 mb-4'>
        <LabelledInput id='data-filter' label='Month'>
          <SelectField
            options={monthOptions}
            defaultValue={{ month, year }}
            onChange={val => {
              setYear(val.year);
              setMonth(val.month);
            }}
          />
        </LabelledInput>
      </div>
      {data && !data.length && !isFetching && (
        <Message type='info'>
          No Payroll Input Sheets Found for {t(`datetime:monthnames.${month}`)}{' '}
          {year}
        </Message>
      )}
      {data && !!data.length && (
        <List
          headings={[{ text: 'Employer' }, { text: 'Submitted At' }]}
          items={data.map(({ id, submitted_at, employer }) => {
            return {
              id,
              to: `employers/${employer.id}/payroll`,
              columns: [
                employer.name,
                submitted_at ? formatDate({ dateStr: submitted_at }) : '',
              ],
            };
          })}
        />
      )}
      {pagination && (
        <div className='mt-4'>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};

export default PayrollStatus;
