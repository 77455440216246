import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Icon } from 'semantic-ui-react';
import { useResource } from '@tg/core/hooks';
import { Button } from '@tg/core/components';

const ActionItem = ({ url, children, repeatable, checklist, ...props }) => {
  const { isFetching, patchResource } = useResource({ url });

  /* eslint-disable react/destructuring-assignment */
  const [timestamp, setTimestamp] = useState(props.timestamp);
  useEffect(() => {
    setTimestamp(props.timestamp);
  }, [props.timestamp]);
  /* eslint-enable react/destructuring-assignment */

  const onSuccess = response => {
    setTimestamp(response);
    props.onSuccess(response);
  };

  const onSubmit = () => {
    patchResource({ onSuccess });
  };

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0;
        border-bottom: 1px solid;
      `}
    >
      <div>
        {checklist && (
          <Icon name={`${timestamp ? 'check ' : ''}square outline`} />
        )}
        <span>{children}</span>
      </div>
      <div>
        {(!timestamp || repeatable) && (
          <Button
            size='small'
            onClick={onSubmit}
            disabled={isFetching}
            loading={isFetching}
          >
            {checklist ? 'complete' : 'do'}
          </Button>
        )}
      </div>
    </div>
  );
};

ActionItem.propTypes = {
  timestamp: PropTypes.string,
  onSuccess: PropTypes.func,
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  repeatable: PropTypes.bool,
  checklist: PropTypes.bool,
};

ActionItem.defaultProps = {
  timestamp: null,
  onSuccess: f => f,
  repeatable: false,
  checklist: false,
};

export default ActionItem;
