import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelledInput, SelectInput } from '@tg/core/components';
import { Control, Controller } from 'react-hook-form';
import { SelectOption } from '@tg/core/components/molecules/SelectInput/SelectInput';

interface Props {
  name: string;
  id: string;
  translationKey: string;
  required: boolean;
  disabled: boolean;
  control: Control;
  defaultValue: string;
  //   errors: PropTypes.object;
  options: SelectOption[];
  multiple?: boolean;
}

const SelectField: FC<Props> = ({
  required,
  name,
  id,
  disabled,
  defaultValue,
  translationKey,
  control,
  errors,
  options,
  multiple,
}) => {
  const { t } = useTranslation('forms');

  const label = t(`labels.${translationKey}`);
  const placeholder = t(`placeholders.${translationKey}`);
  const fieldId = `${id}.${name}`;

  let errorMessage = '';
  if (errors) {
    if (errors.message.key) {
      errorMessage = t(`validations.${errors.message.key}`);
    } else if (errors.type === 'required') {
      errorMessage = t(`validations.field_required`);
    }
  }

  return (
    <LabelledInput
      label={label}
      id={fieldId}
      required={required && !disabled}
      error={errors && errorMessage}
    >
      <Controller
        name={fieldId}
        render={props => (
          <SelectInput
            id={fieldId}
            placeholder={placeholder}
            options={options}
            multiple={multiple}
            {...props}
          />
        )}
        control={control}
        defaultValue={defaultValue}
      />
    </LabelledInput>
  );
};

export default SelectField;
