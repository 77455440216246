import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Segment, List } from 'semantic-ui-react';
import { useResource } from '@tg/core/hooks';
import { PaginationControl, Button, PageHeader } from '@tg/core/components';
import BenefitTypes from './components/BenefitTypes';

const ListBenefits = () => {
  const { url } = useRouteMatch();
  const [page, setPage] = useState(1);
  const { data, pagination } = useResource(
    { url: `benefits?page=${page}` },
    true,
  );

  return (
    <>
      <PageHeader
        heading='Benefits'
        action={<Button to={`${url}/create`}>Add Benefit</Button>}
      />

      <Segment>
        {data ? (
          <List divided relaxed>
            {data.map(({ id, name, employee_benefit_type }) => (
              <List.Item key={id}>
                <List.Icon
                  name='building'
                  size='large'
                  verticalAlign='middle'
                />
                <List.Content>
                  <List.Header as={Link} to={`${url}/${id}`}>
                    {name}
                  </List.Header>
                  <List.Description>
                    {employee_benefit_type.name}
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        ) : null}
      </Segment>
      {pagination && (
        <PaginationControl {...pagination} onPageChange={setPage} />
      )}

      <BenefitTypes />
    </>
  );
};

export default ListBenefits;
