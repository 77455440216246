import React, { useState } from 'react';
import { useResource } from '@tg/core/hooks';
import { List, Segment, Button, PaginationControl } from '@tg/core/components';
import AddEditModal from '../../../../components/GenericAddEditModal';

const BenefitTypes = () => {
  const [page, setPage] = useState(1);
  const { data, isFetching, postResource, pagination } = useResource(
    { url: `/employee_benefit_types?page=${page}&pagesize=10` },
    true,
  );
  const [addModalOpen, setAddModalOpen] = useState(false);

  return (
    <>
      <Segment
        heading='Benefit Types'
        action={<Button onClick={() => setAddModalOpen(true)}>Add New</Button>}
      >
        {data && (
          <List
            noShadow
            headings={[{ name: 'code', width: '140px' }, { name: 'name' }]}
            items={data.map(({ id, name, code }) => ({
              id,
              columns: [code, name],
            }))}
          />
        )}
      </Segment>
      <AddEditModal
        isOpen={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        isFetching={isFetching}
        postResource={postResource}
        data={data}
      />
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};

export default BenefitTypes;
