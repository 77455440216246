import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';

const Menu = ({ items, children, header }) => {
  const trigger = (
    <div
      css={css`
        display: inline-flex;
      `}
    >
      {children}
    </div>
  );

  return (
    <Dropdown trigger={trigger}>
      <Dropdown.Menu>
        {header}
        {items.map(({ to, text, onClick }) => {
          return (
            <Dropdown.Item
              as={to ? Link : null}
              key={text}
              text={text}
              onClick={onClick}
              to={to}
            />
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      to: PropTypes.string,
    }),
  ).isRequired,
};

Menu.defaultProps = {
  header: null,
};

export default Menu;
