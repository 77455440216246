import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import {
  Container,
  Segment,
  PageHeader,
  Form,
  Button,
} from '@tg/core/components';
import { useResource } from '@tg/core/hooks';
import { Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const CreateAdministrator = ({ baseUrl }) => {
  const [getRole, setRole] = useState([]);
  const [validation, setValidation] = useState(null);
  const [emailValidation, setEmailValidation] = useState(null);
  const { t } = useTranslation(['administrators']);

  const { getFieldProps, handleSubmit, resetForm, errors } = Form.useForm({});

  const { data, postResource } = useResource({
    url: `administrators`,
  });

  const { data: roles } = useResource(
    {
      url: `roles`,
    },
    true,
  );

  if (errors) console.log(errors);

  const onSuccess = () => {
    setRole([]);
    setValidation(null);
    resetForm();
  };

  const onSubmit = values => {
    const { first_name, last_name, email, job_title } = values.administrator;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail = emailRegex.test(email);
    if (isValidEmail) {
      setEmailValidation(null);
      if (getRole.length) {
        postResource({
          formData: {
            administrator: {
              first_name,
              last_name,
              email,
              job_title,
              role_ids: getRole,
            },
          },
          onSuccess: onSuccess(),
        });
      } else {
        setValidation(t('administrators:validation'));
      }
    } else {
      setEmailValidation('Please Enter a valid email..');
    }
  };

  const handleCheckBox = (e, { value, checked }) => {
    if (checked) {
      setRole([...getRole, value]);
    } else setRole(getRole.filter(item => item !== value));
  };

  return data ? (
    <Redirect to={`${baseUrl}/${data.id}`} />
  ) : (
    <Container>
      <Link to={baseUrl}>Back</Link>
      <PageHeader heading='Add Administrator' />

      <Segment>
        <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
          <div className='grid grid-cols-3 gap-3'>
            <Form.TextField
              {...getFieldProps({ id: 'administrator', name: 'first_name' })}
              required
            />
            <Form.TextField
              {...getFieldProps({ id: 'administrator', name: 'last_name' })}
              required
            />
            <div>
              <Form.TextField
                {...getFieldProps({ id: 'administrator', name: 'email' })}
                required
              />
              {emailValidation && (
                <div
                  style={{
                    color: 'red',
                    opaciy: 0.5,
                    position: 'relative',
                    top: -10,
                  }}
                >
                  {emailValidation}
                </div>
              )}
            </div>
          </div>
          <div className='grid grid-cols-2 gap-3'>
            <Form.TextField
              {...getFieldProps({ id: 'administrator', name: 'job_title' })}
            />
            <div>
              <label className='font-semibold ml-4'>
                {t('administrators:access')}
              </label>
              <div className='flex items-center mt-5'>
                {roles?.map(({ id, name }) => (
                  <Checkbox
                    value={id}
                    key={id}
                    label={name}
                    onChange={handleCheckBox}
                    className='mr-2'
                    checked={getRole.includes(id)}
                  />
                ))}
              </div>
              {validation && <div className='text-red'>{validation}</div>}
            </div>
          </div>
          <div className='mt-4'>
            <Button type='submit'>{t('administrators:create')}</Button>
          </div>
        </Form>
      </Segment>
    </Container>
  );
};

CreateAdministrator.propTypes = {
  baseUrl: PropTypes.string.isRequired,
};

export default CreateAdministrator;
