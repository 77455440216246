import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu as TailwindMenu, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';

const NavMenu = ({ items, children, header, triggerClassName }) => {
  const getId = useSelector(state => state.access.rolesId);

  const getClassName = (active, value) => {
    const classes = [
      'text-gray-700',
      'hover:text-gray-900',
      'hover:bg-gray-100',
      'flex',
      'justify-between',
      'w-full',
      'px-4',
      'py-2',
      'text-left',
    ];
    if (
      getId !== undefined &&
      value !== undefined &&
      parseInt(getId, 10) === value
    ) {
      classes.push('bg-gray-300');
    }
    return classes;
  };

  return (
    <div className='relative'>
      <TailwindMenu>
        {({ open }) => (
          <>
            <TailwindMenu.Button
              className={
                triggerClassName ||
                `inline-flex transition duration-150 ease-in-out rounded
                  focus:outline-none focus:ring`
              }
            >
              {children}
            </TailwindMenu.Button>

            <Transition
              show={open}
              enter='z-10 transition ease-out duration-100'
              enterFrom='z-10 transform opacity-0 scale-95'
              enterTo='z-10 transform opacity-100 scale-100'
              leave='z-10 transition ease-in duration-75'
              leaveFrom='z-10 transform opacity-100 scale-100'
              leaveTo='z-10 transform opacity-0 scale-95'
              // unmount={false}
            >
              {items?.length > 1 ? (
                <div
                  className='w-56 absolute right-0 z-10 mt-2 origin-top-right
                  bg-white border border-gray-200 rounded-md shadow-lg
                  outline-none'
                >
                  <TailwindMenu.Items className='outline-none'>
                    {header}
                    <div className='py-1'>
                      {items.map(({ to, text, onClick, value }) => {
                        return (
                          <TailwindMenu.Item key={text}>
                            {({ active }) =>
                              to ? (
                                <Link
                                  key={text}
                                  to={to}
                                  className={getClassName(active).join(' ')}
                                >
                                  {text}
                                </Link>
                              ) : (
                                <button
                                  type='button'
                                  key={text}
                                  value={value}
                                  to={to}
                                  onClick={onClick}
                                  className={getClassName(active, value).join(
                                    ' ',
                                  )}
                                >
                                  {text}
                                </button>
                              )
                            }
                          </TailwindMenu.Item>
                        );
                      })}
                    </div>
                  </TailwindMenu.Items>
                </div>
              ) : null}
            </Transition>
          </>
        )}
      </TailwindMenu>
    </div>
  );
};

NavMenu.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      to: PropTypes.string,
    }),
  ).isRequired,
  triggerClassName: PropTypes.string,
};

NavMenu.defaultProps = {
  header: null,
  triggerClassName: null,
};

export default NavMenu;
