import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useResource } from '@tg/core/hooks';
import { Button } from '@tg/core/components';
import { Modal } from 'semantic-ui-react';

const XeroConnection = ({ type, id, employerId }) => {
  const [successFailModal, setSuccessFailModal] = useState(null);
  const [open, setOpen] = useState(false);
  const { postResource } = useResource({
    url: `employers/${employerId}/${type}/${id}/codat_xero`,
  });

  /* eslint-disable */
  const onCodatSubmit = () => {
    postResource({
      onSuccess: res => {
        setSuccessFailModal(res.json.message), setOpen(true);
      },
      onError: res => {
        setSuccessFailModal(res.json.message), setOpen(true);
      },
    });
  };

  return (
    <>
      <Button
        style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
        size='small'
        color='tertiary'
        onClick={() => onCodatSubmit()}
      >
        Send to xero
      </Button>
      <Modal
        open={open}
        header='Success'
        content={successFailModal}
        actions={['OK']}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

XeroConnection.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['invoices']).isRequired,
  employerId: PropTypes.string.isRequired,
};

export default XeroConnection;
