/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useResource } from '@tg/core/hooks';
import { getActiveStorageUrl } from '@tg/core/utils/urlHelpers';
import {
  FileField,
  SelectField,
  Button,
  List,
  PaginationControl,
} from '@tg/core/components';
import collections from '@tg/core/localisation/en-GB/collections.json';
import permissions from '@tg/core/constant';
import { useSelector } from 'react-redux';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import PublishDocument from '../../PublishDocument';
import DeleteDocument from '../../DeleteDocument';
import ErrorModal from '@tg/core/components/molecules/ErrorModal';

const {
  EMPLOYER_DETAIL_VIEW_EMPLOYEES_CONTRACT: {
    OVERVIEW: { CONTRACT_DOCUMENTS_VIEW },
  },
} = permissions;

const {
  contract_document_types: types,
  contract_document_languages: langs,
} = collections;
const docTypes = Object.keys(types).map(key => {
  return { text: types[key], value: key };
});
const docLangs = Object.keys(langs).map(key => {
  return { text: langs[key], value: key };
});

const ContractDocuments = () => {
  const { control, handleSubmit, errors: fieldErrors } = useForm();
  const [files, setFiles] = useState([]);
  const [page, setPage] = useState(1);
  const [isValidFormat, setIsValidFormat] = useState({
    message: null,
    isValid: true,
  });

  const accesslist = useSelector(state => state?.access?.roles);
  const { contractId } = useParams();
  const {
    data,
    isFetching,
    postResource,
    deleteResource,
    pagination,
  } = useResource(
    { url: `contracts/${contractId}/documents?page=${page}&pagesize=10` },
    true,
  );
  const { t } = useTranslation(['collections', 'forms']);

  const onSubmit = values => {
    if (!files.length) {
      return console.log('invalid form');
    }

    const formData = new FormData();
    formData.append('document_type', values.document_type);
    formData.append('document_language', values.document_language);
    formData.append('file', files[0]);

    return postResource({
      formData,
      headers: {},
      onError: err => {
        if (err?.response?.error?.includes('invalid file type')) {
          setIsValidFormat({ message: err?.response?.error, isValid: false });
        }
      },
    });
  };

  const onDrop = useCallback(dropped => {
    setFiles(dropped);
  }, []);

  const onDeleteSuccess = () => {
    // Edge Case Scenario
    if (page > 1 && data.length % 10 === 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      {/* <Heading level='h3'>Contract documents</Heading> */}

      <div className='mb-4'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-4'>
            <FileField onDrop={onDrop} />
          </div>

          {/* Selected files, to be uploaded  */}
          {files.map(f => (
            <div key={f.name}>{f.name}</div>
          ))}

          <Form.Group widths={2}>
            <Form.Field required error={!!fieldErrors.document_type}>
              <label>Document type</label>
              <Controller
                as={<SelectField options={docTypes} />}
                control={control}
                name='document_type'
                rules={{ required: t('forms:errors.required') }}
              />
              {fieldErrors.month && <p>{fieldErrors.month.message}</p>}
            </Form.Field>

            <Form.Field required error={!!fieldErrors.document_language}>
              <label>Language</label>
              <Controller
                as={<SelectField options={docLangs} />}
                control={control}
                name='document_language'
                rules={{ required: t('forms:errors.required') }}
              />
              {fieldErrors.year && <p>{fieldErrors.year.message}</p>}
            </Form.Field>
          </Form.Group>

          {getPermission(accesslist, CONTRACT_DOCUMENTS_VIEW) && (
            <Button type='submit'>{t('forms:buttons.upload')}</Button>
          )}
        </Form>
      </div>

      {data && !!data.length && (
        <List
          headings={[
            { text: 'Document Type' },
            { text: 'Language' },
            { text: '', align: 'right' },
          ]}
          items={data.map(({ id, document, metadata, visible }) => ({
            id,
            columns: [
              <a href={`${getActiveStorageUrl(document.url)}`}>
                {t(`contract_document_types.${metadata.document_type}`)}
              </a>,
              t(`contract_document_languages.${metadata.document_language}`),
              <div className='space-x-2'>
                <PublishDocument
                  type='documents'
                  owner={contractId}
                  id={id}
                  visible={visible}
                />
                <DeleteDocument
                  onClick={() => {
                    deleteResource({ id, onSuccess: onDeleteSuccess });
                  }}
                />
              </div>,
            ],
          }))}
        />
      )}
      {data && !data.length && !isFetching && 'No Documents Found'}
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
      <ErrorModal
        size='tiny'
        open={!isValidFormat?.isValid}
        header='Error'
        content={isValidFormat?.message}
        actions={['OK']}
        onClose={() => setIsValidFormat({ message: null, isValid: true })}
      />
    </>
  );
};

export default ContractDocuments;
