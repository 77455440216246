import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Segment, List, Grid, Modal, Loader } from 'semantic-ui-react';
import { useResource } from '@tg/core/hooks';
import { PaginationControl, Button, PageHeader } from '@tg/core/components';
import { useDispatch, useSelector } from 'react-redux';
import { requestWithDispatch } from '@tg/core/utils/requestHelpers';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permissionHelpers';

const {
  ADMINISTRATORS: {
    VIEW_ADMINISTRATORS_LIST,
    ADD_ADMINISTRATORS,
    EDIT_ADMINISTRATORS,
  },
} = permissions;

export default () => {
  const { url } = useRouteMatch();
  const [page, setPage] = useState(1);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [adminId, setAdminId] = useState(null);
  const dispatch = useDispatch();
  const accesslist = useSelector(state => state?.access?.roles);
  const token = useSelector(s => s.session.aut);
  const call = requestWithDispatch(dispatch, token);
  const { data, pagination, getResource, isFetching } = useResource(
    { url: `administrators?page=${page}` },
    true,
  );

  const checkOfficerStatus = ev => {
    if (ev.target.textContent === 'Yes') {
      call({
        url: `${adminId}/activate_deactive`,
        action: 'update',
        method: 'patch',
      }).then(() => {
        getResource();
        setConfirmationPopup(false);
      });
    } else {
      setConfirmationPopup(false);
    }
  };

  const openPopup = (id, popup) => {
    setConfirmationPopup(popup);
    setAdminId(id);
  };
  return (
    <>
      {confirmationPopup && (
        <Modal
          open={confirmationPopup}
          header='Confirmation'
          content='Admin will be deleted. Do you want to continue?'
          actions={[
            <Button color='secondary' style={{ marginRight: '5px' }}>
              No
            </Button>,
            <Button color='primary'>Yes</Button>,
          ]}
          onActionClick={event => checkOfficerStatus(event)}
          onClose={() => setConfirmationPopup(false)}
          size='mini'
        />
      )}
      <PageHeader
        heading='Administrators'
        action={
          getPermission(accesslist, ADD_ADMINISTRATORS) && (
            <Button to={`${url}/create`}>Add Administrator</Button>
          )
        }
        description='The following users have access to the admin system you are currently looking at'
      />

      <Segment>
        {data && getPermission(accesslist, VIEW_ADMINISTRATORS_LIST) ? (
          <List divided relaxed>
            {data.map(
              ({
                id,
                user: { first_name, last_name, email, roles, suspended_by_id },
              }) => (
                <List.Item key={id}>
                  <div style={{ opacity: suspended_by_id ? '0.5' : '' }}>
                    <Grid columns={3}>
                      <Grid.Column>
                        <div className='flex items-center gap-2'>
                          <List.Icon
                            name='building'
                            size='large'
                            verticalAlign='middle'
                          />
                          <List.Content>
                            {!suspended_by_id &&
                            getPermission(accesslist, EDIT_ADMINISTRATORS) ? (
                              <List.Header as={Link} to={`${url}/${id}`}>
                                {`${first_name} ${last_name}`}
                              </List.Header>
                            ) : (
                              <List.Header>
                                {`${first_name} ${last_name}`}
                              </List.Header>
                            )}
                            <List.Description>{email}</List.Description>
                          </List.Content>
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <List.Content>
                          {roles
                            ?.map(value => {
                              if (value.name === 'Teamed Super Admin') {
                                return (
                                  <span
                                    className='font-semibold'
                                    key={value.id}
                                  >
                                    {value.name}
                                  </span>
                                );
                              }
                              return value.name;
                            })
                            .reduce((prev, curr) => {
                              if (prev === '') {
                                return curr;
                              }
                              return [prev, ', ', curr];
                            }, '')}
                        </List.Content>
                      </Grid.Column>
                      <Grid.Column textAlign='right'>
                        <List.Content>
                          <Button
                            onClick={() => openPopup(id, true)}
                            color='tertiary'
                            size='small'
                          >
                            {!suspended_by_id ? 'Delete' : 'Enable'}
                          </Button>
                        </List.Content>
                      </Grid.Column>
                    </Grid>
                  </div>
                </List.Item>
              ),
            )}
          </List>
        ) : (
          <div className='text-center font-semibold'>
            {isFetching ? (
              <div
                className='flex items-center justify-center flex-col'
                style={{ marginBlock: '100px' }}
              >
                <Loader size='massive' active inline='centered' />
                <span style={{ fontWeight: 400, marginTop: 5 }}>
                  Loading...
                </span>
              </div>
            ) : (
              'No data found'
            )}
          </div>
        )}
      </Segment>

      {pagination && (
        <PaginationControl {...pagination} onPageChange={setPage} />
      )}
    </>
  );
};
