import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Checkbox, Segment } from 'semantic-ui-react';
import { useResource } from '@tg/core/hooks';
import { Container, Form, Button } from '@tg/core/components';
import { useTranslation } from 'react-i18next';

const ShowAdministrator = ({ baseUrl }) => {
  const { administratorId } = useParams();
  const [getRole, setRole] = useState([]);
  const [validation, setValidation] = useState(null);
  const { t } = useTranslation(['administrators']);
  const history = useHistory();
  const { getFieldProps, handleSubmit, errors } = Form.useForm({});

  const { data, patchResource } = useResource(
    {
      url: `administrators/${administratorId}`,
    },
    true,
  );

  const { data: roles } = useResource(
    {
      url: `roles`,
    },
    true,
  );

  if (errors) console.log(errors);

  useEffect(() => {
    if (data) {
      const initialIds = data?.user?.roles?.map(val => val.id);
      setRole(initialIds);
    }
  }, [data]);

  const onSuccess = () => {
    history.replace({ pathname: baseUrl });
  };

  const onSubmit = values => {
    const { first_name, last_name, email, job_title } = values.administrator;
    if (getRole.length) {
      patchResource({
        formData: {
          administrator: {
            first_name,
            last_name,
            email,
            job_title,
            role_ids: getRole,
          },
        },
        onSuccess: onSuccess(),
      });
    } else {
      setValidation(t('administrators:validation'));
    }
  };

  const handleCheckBox = (e, { value, checked }) => {
    if (checked) {
      setRole([...getRole, value]);
    } else setRole(getRole.filter(item => item !== value));
  };

  if (data) {
    const { first_name, last_name, email } = data.user;

    return (
      <Container>
        <Link to={baseUrl}>Back</Link>

        <Segment>
          <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
            <div className='grid grid-cols-3 gap-2'>
              <Form.TextField
                {...getFieldProps({ id: 'administrator', name: 'first_name' })}
                defaultValue={first_name}
                required
              />
              <Form.TextField
                {...getFieldProps({ id: 'administrator', name: 'last_name' })}
                defaultValue={last_name}
                required
              />
              <Form.TextField
                {...getFieldProps({ id: 'administrator', name: 'email' })}
                defaultValue={email}
                disabled
              />
            </div>
            <div className='grid grid-cols-2 gap-3'>
              <Form.TextField
                {...getFieldProps({ id: 'administrator', name: 'job_title' })}
                defaultValue={data?.job_title}
              />
              <div>
                <label className='font-semibold ml-4 '>
                  {t('administrators:access')}
                </label>
                <div className='flex items-center mt-5'>
                  {roles?.map(({ id, name }) => (
                    <Checkbox
                      value={id}
                      key={id}
                      label={name}
                      onChange={handleCheckBox}
                      className='mr-2'
                      checked={getRole.includes(id)}
                    />
                  ))}
                </div>
                {validation && <div className='text-red'>{validation}</div>}
              </div>
            </div>
            <div className='mt-4'>
              <Button type='submit'>{t('administrators:save')}</Button>
            </div>
          </Form>
        </Segment>
      </Container>
    );
  }

  return null;
};

ShowAdministrator.propTypes = {
  baseUrl: PropTypes.string.isRequired,
};

export default ShowAdministrator;
