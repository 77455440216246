import {
  eachMonthOfInterval,
  eachWeekOfInterval,
  getWeekOfMonth,
  differenceInCalendarDays,
  isSameDay,
} from 'date-fns';
import {
  formatDateRange,
  getLastDateOfMonth,
  daysOfTheWeek,
  handleTimeZone,
} from '@tg/core/utils/datetimeHelpers';
import { PlannerEvent, EventBar, EventsByMonth } from './types';

// Given a monday, returns the Sunday
function getSundayFromMonday(d: Date) {
  const monday = new Date(d);
  const newDate = monday.getDate() + 6;
  return new Date(monday.setDate(newDate));
}

/**
 * If it's the same day, use the same date instance.
 * Creating a new one results in two dates that are different by a matter of miliseconds,
 * which causes future date comparison logic to fail
 *
 * Granted, the UI should prevent events with matching start and end date from being created
 * prefering null as the end date. But these things happen.
 */
function getEndDateSafe({ start, end }: { start: Date; end: Date }): Date {
  if (isSameDay(start, end)) return start;
  return end;
}

/**
 * Creates the Event Bars for a single user, grouped in to months
 */
export function createEventBarsForRow(
  events: PlannerEvent[],
  year: number,
): EventsByMonth {
  return (
    events?.length &&
    events.reduce((acc, event) => {
      if (
        event.status === 'rejected' ||
        event.status === 'cancellation_approved'
      )
        return acc;

      const start = handleTimeZone(new Date(event.start_date));
      const end =
        event.end_date &&
        getEndDateSafe({
          start,
          end: handleTimeZone(new Date(event.end_date)),
        });

      // Create an array of bars that thie event requires (if it spans 2 months, that's 2 bars)
      const newBarsSplitByMonth = [] as EventBar[];

      const createBar = ({
        startDate,
        endDate,
        rounded,
      }: {
        startDate: Date;
        endDate: Date;
        rounded: EventBar['rounded'];
      }): EventBar => {
        return {
          event,
          rounded,
          startCol: startDate.getDate(),
          month: startDate.getMonth(),
          barDates: { start: startDate, end: endDate },
          calendarDays: differenceInCalendarDays(endDate, startDate) + 1,
          stringDescription: formatDateRange({
            start_date: event.start_date,
            end_date: event.end_date,
          }),
          halfDays: {
            start: event.start_perc !== 100,
            end: event.end_perc !== 100,
          },
        };
      };

      // Events with no end are only one day long, making the calcs a lot simple
      if (!end) {
        const eventBar = createBar({
          startDate: start,
          endDate: start,
          rounded: 'all',
        });
        newBarsSplitByMonth.push(eventBar);
      } else {
        // An array of months that thie event spans
        // e.g. [0,1,2] is January, February, March - nice long holiday!
        const monthsEventIsIn = eachMonthOfInterval({
          start: handleTimeZone(start),
          end: getEndDateSafe({
            start: handleTimeZone(start),
            end: handleTimeZone(end),
          }),
        }).map(date => date.getMonth());

        monthsEventIsIn.forEach(eventMonth => {
          const eventEndsThisMonth =
            handleTimeZone(end).getMonth() === eventMonth;
          const eventStartsThisMonth =
            handleTimeZone(start).getMonth() === eventMonth;

          // Get the first day of this bar
          const barStartDate = eventStartsThisMonth
            ? handleTimeZone(start)
            : new Date(end.getFullYear(), eventMonth, 1);

          // Get the last day of this bar
          const barEndDate = eventEndsThisMonth
            ? handleTimeZone(end)
            : getLastDateOfMonth({
                year: start.getFullYear(),
                month: eventMonth,
              });

          // Track which ends of the rectangle should have rounded corners;
          let rounded = 'all' as EventBar['rounded'];
          if (eventStartsThisMonth && !eventEndsThisMonth) {
            rounded = 'left';
          }
          if (!eventStartsThisMonth && eventEndsThisMonth) {
            rounded = 'right';
          }

          const eventBar = createBar({
            startDate: barStartDate,
            endDate: barEndDate,
            rounded,
          });

          if (
            [barStartDate.getFullYear(), barEndDate.getFullYear()].includes(
              year,
            )
          ) {
            newBarsSplitByMonth.push(eventBar);
          }
        });
      }

      newBarsSplitByMonth.forEach(bar => {
        if (!acc[bar.month]) acc[bar.month] = [];
        acc[bar.month].push(bar);
      });

      return acc;
    }, {} as EventsByMonth)
  );
}

/**
 * Creates the Event Bars for a single user, grouped in to months and then weeks
 * if an event spans multiple weeks, multiple bars are created.
 */
export function createEventBarsForGrid(
  events: PlannerEvent[],
  year: number,
): EventsByMonth {
  const eventsByMonth = createEventBarsForRow(events, year);
  const monthNumbers = Object.keys(eventsByMonth).map(n => Number(n));
  return monthNumbers.reduce((acc, monthNumber) => {
    const newBarsSplitByWeek = [] as EventBar[];

    eventsByMonth[monthNumber].forEach(bar => {
      const { start: barStartDate, end: barEndDate } = bar.barDates;
      const weeksBarIsIn = eachWeekOfInterval(
        {
          start: barStartDate,
          end: getEndDateSafe({ start: barStartDate, end: barEndDate }),
        },
        { weekStartsOn: 1 },
      );

      weeksBarIsIn.forEach(monday => {
        // if the monday is not in the month we're rendering (the month starts midweek), then use
        // the first day of the month instead.
        let firstDayOfWeek = monday;
        if (monday.getMonth() !== monthNumber) {
          firstDayOfWeek = barStartDate;
        }

        const weekOfMonth = getWeekOfMonth(firstDayOfWeek, {
          weekStartsOn: 1,
        });
        const startsThisWeek =
          getWeekOfMonth(handleTimeZone(barStartDate), {
            weekStartsOn: 1,
          }) === weekOfMonth;

        const endsThisWeek =
          getWeekOfMonth(handleTimeZone(barEndDate), {
            weekStartsOn: 1,
          }) === weekOfMonth;

        let rounded = 'none' as EventBar['rounded'];
        if (startsThisWeek) {
          rounded = 'left';
        }
        if (endsThisWeek) {
          rounded = 'right';
        }
        if (startsThisWeek && endsThisWeek) {
          rounded = 'all';
        }

        const newBarDates = {
          start: startsThisWeek
            ? handleTimeZone(barStartDate)
            : handleTimeZone(firstDayOfWeek),
          end: endsThisWeek
            ? handleTimeZone(barEndDate)
            : handleTimeZone(getSundayFromMonday(monday)),
        };

        if (
          [
            newBarDates.start.getFullYear(),
            newBarDates.end.getFullYear(),
          ].includes(year)
        ) {
          newBarsSplitByWeek.push({
            ...bar,
            rounded,
            month: bar.month,
            startCol: daysOfTheWeek[newBarDates.start.getUTCDay()],
            row: weekOfMonth - 1,
            calendarDays:
              differenceInCalendarDays(newBarDates.end, newBarDates.start) + 1,
            halfDays: {
              start: startsThisWeek && bar.event.start_perc !== 100,
              end:
                endsThisWeek &&
                bar.event.end_perc &&
                bar.event.end_perc !== 100,
            },
            barDates: newBarDates,
          });
        }
      });
    });

    newBarsSplitByWeek.forEach(bar => {
      if (!acc[bar.month]) acc[bar.month] = [];
      acc[bar.month].push(bar);
    });

    return acc;
  }, {} as EventsByMonth);
}

//   return events;
