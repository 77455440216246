import React from 'react';
import { useParams, useRouteMatch, Switch } from 'react-router-dom';
// import { useResource } from '@tg/core/hooks';
import { PrivateRoute } from '@tg/core/components';
import EmployerContracts from './EmployerContracts';
import ContractDetails from './ContractDetails';

const EmployerContractsLayout = () => {
  const { employerId, contractId } = useParams();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute
        path={`${path}`}
        exact
        component={() => <EmployerContracts employerId={employerId} />}
      />
      <PrivateRoute
        path={`${path}/:contractId`}
        component={() => (
          <ContractDetails employerId={employerId} contractId={contractId} />
        )}
      />
    </Switch>
  );
};

export default EmployerContractsLayout;
