/* eslint-disable react/prop-types */
import React from 'react';
import fullLogo from '@tg/core/assets/images/logo_blue.svg';
import TeamedLogo from '@tg/core/assets/images/Teamed_T_White.png';

export default ({ type }) => {
  return (
    <picture>
      {type !== 'small' && (
        <source media='(min-width: 767px)' srcSet={fullLogo} />
      )}
      <img src={TeamedLogo} alt='Teamed Logo' />
    </picture>
  );
};
