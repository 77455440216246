import { month_names } from '../localisation/constants';

export const convertUTCToLocalDate = date => {
  if (!date) {
    return date;
  }
  const localDate = new Date(date);
  return new Date(
    localDate.getUTCFullYear(),
    localDate.getUTCMonth(),
    localDate.getUTCDate(),
  );
};

export const convertLocalToUTCDate = (date, incTime) => {
  if (!date) {
    return date;
  }
  const utcDate = new Date(date);
  return incTime
    ? new Date(
        Date.UTC(
          utcDate.getFullYear(),
          utcDate.getMonth(),
          utcDate.getDate(),
          utcDate.getUTCHours(),
          utcDate.getUTCMinutes(),
          utcDate.getUTCSeconds(),
        ),
      )
    : new Date(
        Date.UTC(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate()),
      );
};

export const handleTimeZone = (date, incTime) => {
  let d;
  if (date.getTimezoneOffset() > 0) {
    d = convertUTCToLocalDate(date);
  } else {
    d = convertLocalToUTCDate(date, incTime);
  }
  return d;
};

export const zeroPad = n => {
  return `00${n}`.slice(-2);
};

export const formatDate = ({
  lang = 'en-GB',
  dateStr = '1970-01-01',
  incTime = false,
  monthName = null,
}) => {
  const obj = handleTimeZone(new Date(dateStr), incTime);
  const time = incTime
    ? ` ${zeroPad(obj.getHours())}:${zeroPad(obj.getMinutes())}`
    : '';
  const day = zeroPad(obj.getUTCDate());
  const month = monthName
    ? month_names[lang][obj.getMonth()][monthName]
    : zeroPad(obj.getMonth() + 1);
  const year = obj.getFullYear();
  switch (lang) {
    default:
      return monthName
        ? `${day} ${month} ${year}`
        : `${day} / ${month} / ${year} ${time}`;
  }
};

export const formatYMD = date => {
  return `${date.getUTCFullYear()}-${zeroPad(date.getUTCMonth() + 1)}-${zeroPad(
    date.getUTCDate(),
  )}`;
};

export const getInputFormat = lang => {
  switch (lang) {
    default:
      return 'dd/MM/yyyy';
  }
};

export const formatLocaleDate = (dateStr = '1970-01-01') =>
  formatDate({ dateStr });

export const formatDateRange = ({ start_date, end_date }) => {
  const dates = [];
  const monthName = 'full';

  const singleDayRange = start_date === end_date || !end_date;
  const equalYears =
    new Date(start_date).getFullYear() === new Date(end_date).getFullYear();
  const equalMonths =
    equalYears &&
    new Date(start_date).getMonth() === new Date(end_date).getMonth();

  // start
  dates.push(
    formatDate({
      dateStr: start_date,
      monthName,
      showYear: singleDayRange || !equalYears,
      showMonth: singleDayRange || !equalMonths,
    }),
  );

  if (end_date && !singleDayRange) {
    dates.push(formatDate({ dateStr: end_date, monthName }));
  }

  return dates.join(' - ');
};

export const getLastDateOfMonth = ({ year, month }) => {
  // get the '0' day of the **next** month, this looks back to the last day of
  // the month we are displaying
  return new Date(year, month + 1, 0);
};

export const daysOfTheWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const currentYear = new Date().getFullYear();

export const YearOptions = Array.from({ length: 5 }, (_, index) => {
  const year = currentYear - 3 + index; // Adjust the starting point as needed
  return {
    text: year.toString(),
    value: year,
  };
}).sort((a, b) => b.value - a.value);

export const getYears = Array.from(
  { length: 5 },
  (_, index) => currentYear + 1 - index,
);
