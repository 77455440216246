import React, { useState } from 'react';
import { useResource } from '@tg/core/hooks';
import {
  PageHeader,
  List,
  Button,
  Tooltip,
  Icon,
  PaginationControl,
} from '@tg/core/components';
import AddEditModal from './AddEditModal';

const Domiciles = () => {
  const [page, setPage] = useState(1);
  const { data, getResource, isFetching, pagination } = useResource(
    { url: `domiciles?page=${page}&pagesize=10` },
    true,
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [editingDomicile, setEditingDomicile] = useState(null);

  const listItems = data
    ? data.map(domicile => ({
        id: domicile.id,
        columns: [
          <>
            <div style={{ whiteSpace: 'normal' }}>{domicile.name}</div>
            <div>{domicile.code}</div>
          </>,
          <img src={domicile.flag} alt='' className='pr-8' />,
          domicile.default_language,
          <>
            {domicile.employee_identifiers.map(iden => (
              <Tooltip
                content={
                  <>
                    <div className='mb-1'>{iden.description}</div>
                    <div>{iden.code}</div>
                  </>
                }
              >
                {iden.name} <Icon name='info circle' />
              </Tooltip>
            ))}
          </>,
          <>
            {domicile.employer_identifiers.map(iden => (
              <Tooltip
                content={
                  <>
                    <div className='mb-1'>{iden.description}</div>
                    <div>{iden.code}</div>
                  </>
                }
              >
                {iden.name} <Icon name='info circle' />
              </Tooltip>
            ))}
          </>,
          <button
            className='link'
            type='button'
            onClick={() => {
              setModalOpen(true);
              setEditingDomicile(domicile);
            }}
          >
            Edit
          </button>,
        ],
      }))
    : [];

  return (
    <>
      <PageHeader
        heading='Domiciles'
        // description='Manage entities that are used by all users in the Teamed app.'
        action={<Button onClick={() => setModalOpen(true)}>Add New</Button>}
      />
      <List
        loading={isFetching}
        items={listItems}
        headings={[
          { text: 'Name' },
          { text: 'Flag', width: '50px' },
          { text: 'Default Lang.', width: '90px' },
          { text: 'Employee Identifiers' },
          { text: 'Employer Identifiers' },
          { text: '', align: 'right', width: '30px' },
        ]}
      />
      <AddEditModal
        isOpen={modalOpen}
        onClose={({ reFetchData = false } = {}) => {
          setModalOpen(false);
          setEditingDomicile(null);
          if (reFetchData) {
            getResource();
          }
        }}
        domicileData={editingDomicile}
      />
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};

export default Domiciles;
