import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@tg/core/components';
import PropTypes from 'prop-types';

const getItem = item => {
  const { to, text } = item;
  if (to) {
    return <Link to={to}>{text}</Link>;
  }
  return <span>{text}</span>;
};

const Breadcrumbs = ({ items }) => {
  return (
    <nav
      className='leading-none flex items-center text-sm font-medium'
      aria-label='Breadcrumb'
    >
      {items.map((item, index) => (
        <Fragment key={item.to}>
          {getItem(item)}
          {index + 1 !== items.length && (
            <span className='mx-3 mt-0.5 text-xs leading-1 '>
              <Icon name='chevron right' />
            </span>
          )}
        </Fragment>
      ))}
    </nav>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Breadcrumbs;
