import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
import { FormBuilder, PageHeader } from '@tg/core/components';

const CreateEmployer = ({ baseUrl }) => {
  const { data, errors, isFetching, postResource } = useResource({
    url: 'employers',
  });

  const onSubmit = formData => {
    // Remove same_as_registered from the primary address (where it live in the UI) on to
    // registered_address.use_as_contact_address (where it lives on the backend)
    const { same_as_registered, ...primary_address } = formData.primary_address;

    const patchData = {
      ...formData,
      primary_address,
      registered_address: {
        ...formData.registered_address,
        use_as_contact_address: same_as_registered,
      },
    };
    if (!Object.keys(primary_address).length) {
      delete patchData.primary_address;
    }

    postResource({ formData: patchData });
  };

  return data ? (
    <Redirect to={`${baseUrl}/${data.id}`} />
  ) : (
    <div style={{ marginBottom: '50px' }}>
      <PageHeader heading='Create Employer' />
      <FormBuilder
        onSubmit={onSubmit}
        segmented
        isFetching={isFetching}
        errors={errors}
        fieldsets={[
          {
            id: 'employer',
            fieldset: 'employer',
            title: 'Company details',
          },
          {
            id: 'registered_address',
            fieldset: 'address',
            title: 'Registered address',
          },
          {
            id: 'primary_address',
            fieldset: 'address_with_same_as',
            title: 'Contact address',
          },
          { id: 'officer', fieldset: 'officer', title: 'Key contact' },
          {
            id: 'codat_connection',
            fieldset: 'codat_connection',
            title: 'Codat connection',
          },
        ]}
      />
    </div>
  );
};

CreateEmployer.propTypes = {
  baseUrl: PropTypes.string.isRequired,
};

export default CreateEmployer;
