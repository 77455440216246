export const monthNames = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

export const daysOfMonth = {
  JAN: 31,
  FEB: 29,
  MAR: 31,
  APR: 30,
  MAY: 31,
  JUN: 30,
  JUL: 31,
  AUG: 31,
  SEP: 30,
  OCT: 31,
  NOV: 30,
  DEC: 31,
};

const getPreviousMonth = m => {
  const currMonthIndex = monthNames.indexOf(m);
  return monthNames[currMonthIndex - 1];
};

export const validatePreviousDayNextYear = (day, month, end) => {
  const prevValue = { day, month };
  let retValue;
  if (day === '01') {
    if (month === 'JAN') {
      prevValue.month = 'DEC';
    } else prevValue.month = getPreviousMonth(month);
    prevValue.day = daysOfMonth[prevValue.month];
  } else {
    prevValue.day = Number(day) - 1;
  }

  if (
    Number(end.endDay) === prevValue.day &&
    end.endMonth === prevValue.month
  ) {
    retValue = true;
  } else if (month === 'MAR' && day === '01') {
    if (Number(end.endDay) === prevValue.day - 1) {
      retValue = true;
    }
  } else retValue = false;

  return retValue;
};

export const isStartDateAfterFirstJan = startDt => {
  return startDt.date !== 1 || startDt.month !== 0;
};

export const attachZero = ind => {
  return `${ind < 9 ? `0${ind + 1}` : ind + 1}`;
};

export const getEndDate = (day, month) => {
  let endDayCopy;
  let endMonthCopy;
  if (day === '01') {
    if (month === 'JAN') {
      endMonthCopy = 'DEC';
    } else endMonthCopy = getPreviousMonth(month);
    endDayCopy = daysOfMonth[endMonthCopy];
  } else {
    endDayCopy = Number(day) - 1;
    endMonthCopy = month;
  }
  return { endDayCopy, endMonthCopy };
};
