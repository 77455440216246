import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Icon, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { useCombinedRefs } from '@tg/core/hooks';

const PasswordField = React.forwardRef(
  (
    { required, name, errors, id, translationKey, disabled, defaultValue },
    ref,
  ) => {
    const { t } = useTranslation('forms');

    const label = t(`labels.${translationKey}`);
    const placeholder = t(`placeholders.${translationKey}`);
    const fieldId = `${id}.${name}`;

    // Show/Hide password functionality
    const [revealPw, setRevealPw] = useState(false);
    const [cursorPos, setCursorPos] = useState(0);
    const inputRef = useRef();
    const icon = revealPw ? 'eye slash' : 'eye';

    /**
     * Each time revealPw changes, re-focus the input and restore the cursor position
     */
    useEffect(() => {
      if (!inputRef.current) return;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(cursorPos, cursorPos);
    }, [revealPw]);

    return (
      <Form.Field required={required && !disabled} error={!!errors}>
        <label htmlFor={fieldId}>{label}</label>
        <Input
          id={fieldId}
          name={fieldId}
          type={revealPw ? 'text' : 'password'}
          placeholder={placeholder}
          defaultValue={defaultValue}
          disabled={disabled}
          required={required}
          icon
          ref={ref}
        >
          <input ref={useCombinedRefs(inputRef, ref)} />
          <button
            className='reset-button'
            type='button'
            onClick={() => {
              setCursorPos(inputRef.current.selectionStart);
              setRevealPw(!revealPw);
            }}
            css={css`
              position: absolute;
              right: 5px;
              top: 0;
              bottom: 0;
              cursor: pointer;
              > .icon {
                margin: 0;
                color: currentColor;
              }
              :focus:not(:focus-visible) {
                outline: none;
              }
            `}
          >
            <Icon name={icon} />
          </button>
        </Input>
        {errors && <p>{t(`validations.${errors.message.key}`)}</p>}
      </Form.Field>
    );
  },
);

PasswordField.propTypes = {
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  id: PropTypes.string.isRequired,
  translationKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string,
};

PasswordField.defaultProps = {
  errors: null,
  defaultValue: '',
};

export default PasswordField;
