import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Context } from '@tg/core/components';

/**
 * DEPRECATED: use DisplayDetails instead
 */
const DisplayFieldset = ({ children }) => {
  const {
    theme: { colors },
  } = useContext(Context);

  return (
    <div
      css={css`
        margin-bottom: 2rem;
        > div {
          display: flex;
          > div {
            flex: 1;
            margin: 1rem;
            &:first-of-type {
              margin-left: 0;
            }
            &:last-of-type {
              margin-right: 0;
            }
            > span {
              display: block;
              &:first-of-type {
                font-weight: 700;
                font-size: 12px;
              }
              &:last-of-type {
                border-bottom: 1px solid ${colors.borderGrey};
              }
          }
        }
      `}
    >
      {children}
    </div>
  );
};

DisplayFieldset.propTypes = {
  children: PropTypes.node,
};

DisplayFieldset.defaultProps = {
  children: null,
};

export default DisplayFieldset;
