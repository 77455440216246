/* eslint-disable react/prop-types */
import React from 'react';
import { useResource } from '@tg/core/hooks';
import { Form, ButtonBar, Button } from '@tg/core/components';
import { useDispatch } from 'react-redux';
import { actions } from '@tg/core/store/modules/toasts';

const EmailReceipients = ({ employerId }) => {
  const { data, isFetching, patchResource } = useResource(
    {
      url: `employers/${employerId}`,
    },
    true,
  );
  const dispatch = useDispatch();

  const { handleSubmit, getFieldProps } = Form.useForm();

  const onSubmit = formData => {
    patchResource({
      formData,
      onSuccess: newData => {
        dispatch(
          actions.addToast({
            id: newData.id,
            type: 'success',
            children: 'Saved',
          }),
        );
      },
    });
  };

  const { invoice_recipients } = data || {};

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className='p-4 -mt-6'>
          <div className='mb-4'>
            <p>
              Add the email addresses that should recieve the invoice. Comma
              seperated
            </p>
            <p>
              <span className='font-bold'>Note: </span>these do not have to be
              Teamed users.
            </p>
          </div>
          <Form.TextField
            {...getFieldProps({ id: 'employer', name: 'invoice_recipients' })}
            defaultValue={invoice_recipients}
          />
        </div>
        <ButtonBar>
          <Button type='submit' loading={isFetching}>
            Save
          </Button>
        </ButtonBar>
      </Form>
    </>
  );
};

export default EmailReceipients;
