import { css } from '@emotion/core';

export default css`
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
  }

  #app,
  main {
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 700;
  }

  h1 {
    font-size: 72px;
  }
  h2 {
    font-size: 38px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 12px;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .reset-button {
    border: none;
    background: none;
    text-align: left;
    color: inherit;
  }

  .reset-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;
