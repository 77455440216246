export const transformCollectionResponse = collection => {
  return {
    byId: {
      ...collection.reduce((acc, { id, ...rest }) => {
        acc[id] = { id: String(id), ...rest };
        return acc;
      }, {}),
    },
    allIds: Array.from([...collection.map(item => String(item.id))]),
  };
};

export default {
  transformCollectionResponse,
};

export const transformCalendarificResponse = response =>
  response.response.holidays.reduce(
    (memo, item) => {
      const { national, regional } = memo;
      const {
        type,
        states,
        date: { iso },
        name,
        description,
      } = item;
      if (type.includes('National holiday') || type.includes('Local holiday')) {
        if (typeof states === 'string' && states === 'All')
          national.push({
            name,
            description,
            date: iso,
          });
        if (Array.isArray(states)) {
          states.forEach(state => {
            if (regional[state.iso]) {
              regional[state.iso].holidays.push({
                name,
                description,
                date: iso,
              });
            } else {
              regional[state.iso] = {
                name: state.name,
                holidays: [
                  {
                    name,
                    description,
                    date: iso,
                  },
                ],
              };
            }
          });
        }
      }
      return { national, regional };
    },
    { national: [], regional: {} },
  );
