import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DocumentList, EmptySet } from '@tg/core/components';

const PayslipsList = ({ collection }) => {
  const { t } = useTranslation(['datetime', 'payslips']);

  const documents = collection.map(({ id, document, month, year }) => ({
    id,
    columns: [`${t(`monthnames.${month}`)} ${year}`],
    url: document.url,
  }));

  return (
    <>
      {collection.length ? (
        <DocumentList documents={documents} />
      ) : (
        <EmptySet
          heading={t('payslips:empty')}
          description={t('payslips:empty_description')}
        />
      )}
    </>
  );
};

PayslipsList.propTypes = {
  collection: PropTypes.array.isRequired,
};

export default PayslipsList;
