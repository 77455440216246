/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useResource } from '@tg/core/hooks';
import { Modal, Form, ButtonBar, Button, Heading } from '@tg/core/components';
import EditableList from './EditableList';

const DomicilesAddEditModal = ({ isOpen, onClose, domicileData }) => {
  const mode = domicileData ? 'edit' : 'add';

  const { patchResource, postResource, isFetching } = useResource({
    url: mode === 'edit' ? `domiciles/${domicileData.id}` : 'domiciles',
  });

  const { handleSubmit, getFieldProps, resetForm } = Form.useForm({
    validationSchema: null,
  });

  const closeModal = options => {
    resetForm();
    onClose(options);
  };

  const { employee_identifiers, employer_identifiers, default_language, name } =
    domicileData || {};

  const [employeeIds, setEmployeeIds] = useState([]);
  const [employerIds, setEmployerIds] = useState([]);

  useEffect(() => {
    setEmployeeIds(employee_identifiers || []);
    setEmployerIds(employer_identifiers || []);
  }, [employee_identifiers, employer_identifiers]);

  const onSubmit = values => {
    const formData = {
      country_id: values.manage_domicile.country_id,
      default_language: values.manage_domicile.default_language,
      employee_identifiers: employeeIds,
      employer_identifiers: employerIds,
    };

    if (mode === 'add') {
      postResource({
        formData,
        onSuccess: () => closeModal({ reFetchData: true }),
      });
    } else {
      patchResource({
        formData,
        onSuccess: () => closeModal({ reFetchData: true }),
      });
    }
  };

  const modalTitle = mode === 'add' ? 'Add Domicile' : 'Edit Domicile';

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} title={modalTitle}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Content>
            <div className='grid grid-cols-3 gap-6'>
              {mode === 'add' ? (
                <Form.CollectionField
                  {...getFieldProps({
                    name: 'country_id',
                    id: 'manage_domicile',
                  })}
                  resource='countries'
                  useName
                  search
                />
              ) : (
                <div>
                  <div className='font-semibold mb-2'>Country</div>
                  <div>{name}</div>
                </div>
              )}
              <Form.TextField
                {...getFieldProps({
                  name: 'default_language',
                  id: 'manage_domicile',
                })}
                autocomplete='off'
                defaultValue={default_language}
                required
              />
              <fieldset className='col-span-3 mb-4'>
                <Heading level='h5' element='legend'>
                  Employee Identifiers
                </Heading>
                <EditableList
                  items={employeeIds}
                  onChange={items => setEmployeeIds(items)}
                />
              </fieldset>
              <fieldset className='col-span-3 mb-4'>
                <Heading level='h5' element='legend'>
                  Employer Identifiers
                </Heading>
                <EditableList
                  items={employerIds}
                  onChange={items => setEmployerIds(items)}
                />
              </fieldset>
            </div>
          </Modal.Content>
          <ButtonBar>
            <div className='space-x-2'>
              <Button onClick={() => closeModal()} color='secondary'>
                Cancel
              </Button>
              <Button type='submit' loading={isFetching}>
                Add
              </Button>
            </div>
          </ButtonBar>
        </Form>
      </Modal>
    </>
  );
};

export default DomicilesAddEditModal;
