/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelledInput } from '@tg/core/components';
import { RegisterOptions } from 'react-hook-form';
import InputTextField from './InputTextField';

interface Props {
  name: string;
  errors: {
    type: string;
    message: {
      key: string;
    };
  };
  id: string;
  register: RegisterOptions;
  translationKey: string;
  required: boolean;
  disabled: boolean;
  defaultValue: string;
  autoComplete: string;
  postInput: string;
  preInput: string;
  min: number;
  max: number;
}

const TextFieldInput: FC<Props> = ({
  required,
  name,
  errors,
  id,
  disabled,
  defaultValue,
  translationKey,
  register,
  autoComplete,
  postInput,
  preInput,
  min,
  max,
}) => {
  const { t } = useTranslation('forms');

  const label = t(`labels.${translationKey}`);
  const placeholder = t(`placeholders.${translationKey}`);
  const fieldId = `${id}.${name}`;

  let errorMessage = '';
  if (errors) {
    if (errors.message.key) {
      errorMessage = t(`validations.${errors.message.key}`);
    } else if (errors.type === 'required') {
      errorMessage = t(`validations.field_required`);
    }
  }

  return (
    <LabelledInput
      label={label}
      id={fieldId}
      error={errorMessage}
      required={required && !disabled}
    >
      <InputTextField
        name={fieldId}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        required={required && !disabled}
        autoComplete={autoComplete}
        inputRef={register({ required: required && !disabled })}
        postInput={postInput}
        preInput={preInput}
        min={min}
        max={max}
      />
    </LabelledInput>
  );
};

export default TextFieldInput;
