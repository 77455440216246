import React from 'react';
import { useSelector } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';
import {
  Container,
  PrivateRoute,
  NotFound,
  ErrorBoundary,
  Context,
  LoadingSpinner,
  AppToasts,
} from '@tg/core/components';
import * as theme from '../../theme';
import Auth from '../Auth';
import Administrators from '../Administrators';
import Employers from '../Employers';
import Employees from '../Employees';
import Payroll from '../Payroll';
import Header from '../Header';
import Manage from '../../routes/Manage/Manage';
import AdminReports from '../AdminReports';
import ManagePayroll from '../Payroll/ManagePayroll';
import PayrollInvoice from '../Payroll/PayrollInvoice';

const App = () => {
  const ready = useSelector(state => state.app.bootstrap);
  const apiVersion = useSelector(state => {
    return state.app.api_version;
  });
  const toasts = useSelector(state => {
    return state.toasts.toasts;
  });

  if (!ready) {
    return (
      <div className='flex align-center justify-center w-full p-24'>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Context.Provider
      value={{
        theme,
        lang: 'en-GB',
      }}
    >
      <ErrorBoundary>
        <Router>
          <Header />
          <main className='pt-10 flex-1'>
            <Container>
              <Switch>
                <PrivateRoute path='/' component={Employers} exact />
                <PrivateRoute path='/employers' component={Employers} />
                <PrivateRoute path='/employees' component={Employees} />
                <PrivateRoute path='/managePayroll' component={ManagePayroll} />
                <PrivateRoute path='/payroll' component={Payroll} />
                <PrivateRoute
                  path='/employer/:employerId/payrollInvoices'
                  component={PayrollInvoice}
                />

                <PrivateRoute path='/manage' component={Manage} />
                <PrivateRoute
                  path='/administrators'
                  component={Administrators}
                />
                <PrivateRoute path='/reports' component={AdminReports} />
                <Route path='/auth'>
                  <Auth />
                </Route>
                <Route path='/not-found'>
                  <NotFound />
                </Route>
                <Route path='*'>
                  <Redirect to='/not-found' />
                </Route>
              </Switch>
            </Container>
          </main>
          <footer className='p-4 text-sm'>
            <span className='font-semibold'>API Version:</span> {apiVersion}
          </footer>
          <AppToasts toasts={toasts} />
        </Router>
      </ErrorBoundary>
    </Context.Provider>
  );
};

export default App;
