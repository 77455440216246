import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

const CheckboxField = props => {
  const { onChange, value, ...rest } = props;
  return (
    <Checkbox
      onChange={(_, { checked }) => {
        onChange(checked);
      }}
      defaultChecked={value}
      {...rest}
    />
  );
};

CheckboxField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
};

CheckboxField.defaultProps = {
  value: null,
};

export default CheckboxField;
