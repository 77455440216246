import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const Container = ({ children, width }) => {
  const widths = {
    xl: '1920px',
    lg: '1127px', // The semantic UI Container width
    md: '1042px',
  };

  return (
    <div
      css={css`
        width: 100%;
        max-width: ${widths[width]};
        margin: 0 auto;
        padding: 0 20px;
      `}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  width: PropTypes.oneOf(['xl', 'lg', 'md']),
};

Container.defaultProps = {
  children: null,
  width: 'lg',
};

export default Container;
