import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@tg/core/components';
import classNames from 'classnames';

/**
 * A white box with shadow
 * Styles are taken from semantic-ui, the original
 * component has a lot of props and CSS that we never used
 */
const Segment = ({
  children,
  flush,
  size,
  heading,
  action,
  shadowLevel,
  customClass,
}) => {
  const shadowLevelMap = {
    sm: 'sm:shadow-sm',
    md: 'sm:shadow-md',
    lg: 'sm:shadow-lg',
  };

  const mediumClasses = classNames({
    'sm:rounded-md': true,
    'sm:p-5 md:p-6': !flush,
    [shadowLevelMap[shadowLevel]]: shadowLevel !== 'none',
  });
  const largeClasses = classNames({
    'sm:rounded-3xl': true,
    'sm:p-9 md:px-14 md:py-12 lg:px-24 lg:py-20': !flush,
    [shadowLevelMap.lg]: shadowLevel !== 'none',
  });

  const wrapperClasses = classNames(
    'relative bg-white border-gray-200 sm:border',
    {
      [largeClasses]: size === 'lg',
      [mediumClasses]: size === 'md',
    },
  );

  const customClassName = classNames('relative bg-[#eff0f3]', {
    [largeClasses]: size === 'lg',
    [mediumClasses]: size === 'md',
  });

  return (
    <div className={customClass ? customClassName : wrapperClasses}>
      {heading && (
        <div
          className={classNames(
            `flex ${customClass ? '' : 'mb-5'} justify-between`,
            {
              'p-4 pb-0': flush,
            },
          )}
        >
          <Heading level={`${customClass ? 'h5' : 'h4'}`} zeropad>
            {heading}
          </Heading>
          {action}
        </div>
      )}
      {children}
    </div>
  );
};

export default Segment;

Segment.propTypes = {
  children: PropTypes.node,
  flush: PropTypes.bool,
  size: PropTypes.oneOf(['md', 'lg']),
  heading: PropTypes.string,
  action: PropTypes.node,
  shadowLevel: PropTypes.oneOf(['sm', 'md', 'none']),
  customClass: PropTypes.bool,
};

Segment.defaultProps = {
  children: null,
  flush: false,
  size: 'md',
  heading: null,
  action: null,
  shadowLevel: 'sm',
  customClass: false,
};
