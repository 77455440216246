import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ListAdministrators from './ListAdministrators';
import ShowAdministrator from './ShowAdministrator';
import CreateAdministrator from './CreateAdministrator';

export default () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ListAdministrators />
      </Route>
      <Route path={`${path}/create`}>
        <CreateAdministrator baseUrl={url} />
      </Route>
      <Route path={`${path}/:administratorId`}>
        <ShowAdministrator baseUrl={url} />
      </Route>
    </Switch>
  );
};
