/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Modal, Form, Button, ButtonBar, Message } from '@tg/core/components';
import { useTranslation } from 'react-i18next';

const GenericAddEditModal = ({
  isFetching,
  postResource,
  patchResource,
  isOpen,
  onClose,
  defaultValues,
  mode = 'add',
  data = null,
}) => {
  const { handleSubmit, getFieldProps, resetForm } = Form.useForm();
  const [error, setError] = useState(null);
  const { t } = useTranslation(['contract']);

  const onSuccess = () => {
    onClose();
  };

  const onError = errorValue => {
    if (errorValue.response.error === t(`contract:invalid_name_be_error`)) {
      setError(t(`contract:invalid_name_fe_error`));
    } else setError(errorValue.response.error);
  };

  const onSubmit = values => {
    if (
      data &&
      data.find(
        item =>
          item.code?.toLowerCase() === values?.addEntity?.code?.toLowerCase() ||
          item.name?.toLowerCase() === values?.addEntity?.name?.toLowerCase(),
      ) &&
      mode === 'add'
    ) {
      setError('Name or Code already exists..!');
    } else if (mode === 'add') {
      postResource({
        formData: values.addEntity,
        onSuccess,
        onError: err => onError(err),
      });
    } else if (mode === 'edit') {
      patchResource({
        id: defaultValues.id,
        formData: values.addEntity,
        onSuccess,
        onError: err => onError(err),
      });
    }
  };

  const closeModal = () => {
    resetForm();
    onClose();
    setError(null);
  };

  return (
    <>
      <Modal title='Add New' isOpen={isOpen} onClose={() => closeModal()}>
        {error && <Message type='error'>{error}</Message>}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Content>
            <Form.TextField
              {...getFieldProps({ name: 'name', id: 'addEntity' })}
              required
              defaultValue={defaultValues?.name}
            />
            <Form.TextField
              {...getFieldProps({ name: 'code', id: 'addEntity' })}
              required
              defaultValue={defaultValues?.code}
            />
          </Modal.Content>
          <ButtonBar>
            <div className='space-x-2'>
              <Button color='secondary' onClick={() => closeModal()}>
                Cancel
              </Button>
              <Button type='submit' loading={isFetching}>
                Save
              </Button>
            </div>
          </ButtonBar>
        </Form>
      </Modal>
    </>
  );
};

export default GenericAddEditModal;
