import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
import { Heading, Container, Breadcrumbs } from '@tg/core/components';
import { useSelector } from 'react-redux';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import permissions from '@tg/core/constant';
import SideNavLayout from '../../../layouts/SideNav/SideNav';
import EmployerInvoices from './pages/EmployerInvoices';
import EmployerContracts from './pages/EmployerContracts';
import EmployerOfficers from './pages/EmployerOfficers';
import EmployerDocuments from './pages/EmployerDocuments';
import EmployerOverview from './pages/EmployerOverview';
import EmployerPayroll from './pages/EmployerPayroll';

const {
  EMPLOYER_DETAIL_VIEW_OVERVIEW: { MENU_OPTION },
  EMPLOYER_DETAIL_VIEW_OFFICERS: { MENU_OPTION: OFFICERS_MENU_OPTION },
  EMPLOYER_DETAIL_VIEW: {
    INVOICES: { INVOICES_MENU_OPTION },
    DOCUMENTS: { DOCUMENTS_MENU_OPTION },
    PAYROLL: { PAYROLL_MENU_OPTION },
  },
  EMPLOYER_DETAIL_VIEW_EMPLOYEES: { MENU_OPTION: MENU_OPTION_EMP },
} = permissions;

export default () => {
  const { employerId } = useParams();
  const accesslist = useSelector(state => state?.access?.roles);
  const { data } = useResource(
    {
      url: `employers/${employerId}`,
    },
    true,
  );

  useEffect(() => {
    const hrefData = window.location.href;

    if (
      !getPermission(accesslist, MENU_OPTION) &&
      !getPermission(accesslist, OFFICERS_MENU_OPTION) &&
      !getPermission(accesslist, DOCUMENTS_MENU_OPTION) &&
      !getPermission(accesslist, DOCUMENTS_MENU_OPTION) &&
      !getPermission(accesslist, PAYROLL_MENU_OPTION) &&
      getPermission(accesslist, MENU_OPTION_EMP) &&
      getPermission(accesslist, INVOICES_MENU_OPTION) &&
      !hrefData.includes('employees')
    ) {
      window.location.replace(`${hrefData}/employees`);
    }
  }, []);

  const { name } = data || {};
  return (
    <Container>
      <div className='mb-1'>
        <Breadcrumbs items={[{ to: '/employers', text: 'Employers' }]} />
      </div>
      <Heading level='h3'>{name || '...'}</Heading>

      <SideNavLayout
        pages={[
          ...(getPermission(accesslist, MENU_OPTION)
            ? [
                {
                  title: 'Overview',
                  to: ``,
                  component: () => <EmployerOverview />,
                  icon: 'industry',
                },
              ]
            : []),
          ...(getPermission(accesslist, OFFICERS_MENU_OPTION)
            ? [
                {
                  title: 'Officers',
                  to: `/officers`,
                  component: () => <EmployerOfficers />,
                  icon: 'address card outline',
                },
              ]
            : []),
          {
            title: 'Employees',
            to: `/employees`,
            component: () => <EmployerContracts />,
            icon: 'user',
          },
          ...(getPermission(accesslist, INVOICES_MENU_OPTION)
            ? [
                {
                  title: 'Invoices',
                  to: `/invoices`,
                  component: () => <EmployerInvoices employerData={data} />,
                  icon: 'file alternate outline',
                },
              ]
            : []),
          ...(getPermission(accesslist, DOCUMENTS_MENU_OPTION)
            ? [
                {
                  title: 'Documents',
                  to: `/documents`,
                  component: () => <EmployerDocuments />,
                  icon: 'folder outline',
                },
              ]
            : []),
          ...(getPermission(accesslist, PAYROLL_MENU_OPTION)
            ? [
                {
                  title: 'Payroll',
                  to: `/payroll`,
                  component: () => <EmployerPayroll />,
                  icon: 'money outline',
                },
              ]
            : []),
        ]}
      />
    </Container>
  );
};
