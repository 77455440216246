/**
 * This is the 'default' theme for tgCore components
 * You can set it as your apps theme using a context or
 * use another theme specific to your app
 */
export const colors = {
  black: '#0D2950',
  white: '#FFFFFF',
  primary: '#F63855',
  secondary: '#0EBAF4',
  backgroundGrey: '#F4F4F6',
  borderGrey: '#d8d8d8',
  textGrey: '#606060',
};

export const shadow = {
  default: '0 0 20px 0 rgba(0,0,0,0.1)',
};

export const radius = {
  default: '6px',
  button: '2em',
};
