import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
import { FormBuilder, Container } from '@tg/core/components';

const CreateEmployee = ({ baseUrl }) => {
  const { data, errors, isFetching, postResource } = useResource({
    url: 'employees',
  });

  const onSubmit = formData => {
    postResource({ formData });
  };

  return data ? (
    <Redirect to={`${baseUrl}/${data.id}`} />
  ) : (
    <Container>
      <Segment>
        <FormBuilder
          onSubmit={onSubmit}
          segmented
          isFetching={isFetching}
          errors={errors}
          fieldsets={[
            { id: 'employee', fieldset: 'employee', title: 'Personal details' },
            {
              id: 'primary_address',
              fieldset: 'employee_address',
              title: 'Home address',
            },
          ]}
        />
      </Segment>
    </Container>
  );
};

CreateEmployee.propTypes = {
  baseUrl: PropTypes.string.isRequired,
};

export default CreateEmployee;
