import React, { useEffect, useState } from 'react';
import { useResource } from '@tg/core/hooks';
import {
  PageHeader,
  Button,
  List,
  PaginationControl,
} from '@tg/core/components';
import { capitalise } from '@tg/core/utils/stringHelpers';
import LeaveAddEditModal from '../components/LeaveAddEditModal';

const LeaveTypes = () => {
  const [page, setPage] = useState(1);

  const {
    data,
    isFetching,
    getResource: getLeaveTypesPageWise,
    postResource,
    patchResource,
    pagination,
  } = useResource({ url: `leave_types?page=${page}&pagesize=10` }, true);

  const { data: collection, getResource: getCountries } = useResource(
    { url: `countries` },
    true,
  );

  const { data: colors, getResource: getColors } = useResource({
    url: `leave_types/colours`,
  });

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editingLeaveType, seteditingLeaveType] = useState(null);

  useEffect(() => {
    getCountries();
    getColors();
  }, []);

  const listItems = data
    ? data.map(leave => ({
        id: leave.id,
        columns: [
          capitalise(leave.event_type),
          <span>{leave.applicable_to ? 'All' : 'Custom'}</span>,
          <button
            type='button'
            className='link'
            onClick={() => {
              setAddModalOpen(true);
              seteditingLeaveType(leave);
            }}
          >
            Edit
          </button>,
        ],
      }))
    : [];

  return (
    <>
      <PageHeader
        heading='Leave Types'
        action={
          <Button
            onClick={() => {
              setAddModalOpen(true);
              seteditingLeaveType(null);
            }}
          >
            Add New
          </Button>
        }
      />

      <List
        items={listItems}
        headings={[
          { text: 'Leave Type' },
          { text: 'Applicable to', width: '70px' },
          { text: 'Actions', align: 'right' },
        ]}
      />

      {pagination && (
        <div className='mb-6'>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}

      {addModalOpen && (
        <LeaveAddEditModal
          isOpen={addModalOpen}
          onClose={() => {
            getLeaveTypesPageWise();
            getColors();
            setAddModalOpen(false);
          }}
          isFetching={isFetching}
          postResource={postResource}
          patchResource={patchResource}
          defaultValues={editingLeaveType}
          mode={editingLeaveType ? 'edit' : 'add'}
          collection={collection}
          colors={colors}
          datas={data}
        />
      )}
    </>
  );
};

export default LeaveTypes;
