import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { PhoneField as PhoneInput } from '@tg/core/components';
import { actions } from '@tg/core/store/modules/collections';

const getOptions = ({ dialCodes, countryCodes }) => {
  const options = Object.values(dialCodes.byId).map(
    ({ id, code: value, dialcode }) => ({
      key: id,
      value,
      flag: value.toLowerCase(),
      code: dialcode,
      countryName: countryCodes.byId[id]?.name,
    }),
  );
  return options.sort((a, b) => {
    if (a.text < b.text) return -1;
    return a.text > b.text ? 1 : 0;
  });
};

const PhoneField = ({
  required,
  name,
  errors,
  id,
  translationKey,
  disabled,
  defaultValue,
  control,
}) => {
  const { t } = useTranslation('forms');

  const label = t(`labels.${translationKey}`);
  const fieldId = `${id}.${name}`;

  const dialCodesCollection = useSelector(
    state => state.collections.dialcodes || null,
  );
  const employeeCountriesCollection = useSelector(
    state => state.collections.domiciles || null,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dialCodesCollection)
      dispatch(actions.fetchCollection({ resource: 'dialcodes' }));
    if (!employeeCountriesCollection)
      dispatch(actions.fetchCollection({ resource: 'domiciles' }));
  }, []);

  const selectableOptions = getOptions({
    dialCodes: dialCodesCollection,
    countryCodes: employeeCountriesCollection,
  });

  // Takes '+44' or '44' and returns 'GB'
  const getCountryFromCode = code => {
    if (!code) return '';
    const allCodes = Object.values(dialCodesCollection.byId);
    const selected = allCodes.find(({ dialcode }) => {
      // e.g is it '44' OR '+44'
      return dialcode === code || dialcode === code.substring(1);
    });

    if (!selected) return '';

    return selected.code;
  };

  /**
   * Not all 'defaultValues' will have a `country_code` value so try and find it
   * from the `code`, this wont always work because US and Canada both use +1
   */
  const defaultWithCode = {
    phone: defaultValue.phone,
    country_code:
      defaultValue.country_code || getCountryFromCode(defaultValue.code),
    code: defaultValue.code,
  };

  return (
    <Form.Field required={required && !disabled} error={!!errors}>
      <label htmlFor={fieldId}>{label}</label>
      <Controller
        as={
          <PhoneInput
            defaultValue={defaultWithCode}
            selectableOptions={selectableOptions}
          />
        }
        control={control}
        name={fieldId}
        placeholder={{ phone: 'Number', code: 'Code' }}
        disabled={disabled}
      />
      {errors && <p>{t(`validations.${errors.message.key}`)}</p>}
    </Form.Field>
  );
};

PhoneField.propTypes = {
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  id: PropTypes.string.isRequired,
  translationKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string,
  control: PropTypes.any.isRequired,
};

PhoneField.defaultProps = {
  errors: null,
  defaultValue: '',
};

export default PhoneField;
