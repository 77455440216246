import React, { FC } from 'react';
import { PageHeader, Segment, Button } from '@tg/core/components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import AddPayrollSheet from './AddPayrollSheet';
import GenerateReport from './GenerateReport';
import PayrollStatus from './PayrollStatus';

const {
  PAYROLL: { MANAGE_PAYROLL, CHECK_STATUS },
} = permissions;

const Payroll: FC = () => {
  const accesslist = useSelector(state => state?.access?.roles);

  return (
    <div className='space-y-4'>
      <PageHeader
        heading='Payroll'
        description="Payroll Input Sheets will need to be created here each month before they are available for employers to complete. Generating a report will prevent furhter edits from the employer. Visit the Payroll section on a employer details page to see the values they've entered and if they have submitted them."
      />
      {getPermission(accesslist, MANAGE_PAYROLL) && (
        <Button as={Link} to='/managePayroll'>
          Manage Payroll
        </Button>
      )}
      <Segment heading='1. Create Payroll Sheet' flush>
        <AddPayrollSheet />
      </Segment>
      {getPermission(accesslist, CHECK_STATUS) && (
        <Segment heading='2. Check Status'>
          <PayrollStatus />
        </Segment>
      )}
      <Segment heading='3. Generate Reports' flush>
        <GenerateReport />
      </Segment>
    </div>
  );
};

export default Payroll;
