import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@tg/core/components';

const DeleteDocument = ({ disabled, onClick }) => {
  return (
    <Button size='small' disabled={disabled} onClick={onClick} color='tertiary'>
      Delete
    </Button>
  );
};

DeleteDocument.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

DeleteDocument.defaultProps = {
  disabled: false,
};

export default DeleteDocument;
