import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';
// import { RESOURCE_STATUS } from '@config/constants';

const AuthErrors = ({ error }) => {
  const { t } = useTranslation('auth');
  return error ? (
    <Message negative>
      <Message.Header>{t(`errors.${error.error}`)}</Message.Header>
    </Message>
  ) : null;
};

AuthErrors.propTypes = {
  error: PropTypes.shape(),
};

AuthErrors.defaultProps = {
  error: null,
};

export default AuthErrors;
