import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ListEmployees from './ListEmployees';
import CreateEmployee from './CreateEmployee';
import ShowEmployee from './ShowEmployee';

export default () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ListEmployees />
      </Route>
      <Route path={`${path}/create`}>
        <CreateEmployee baseUrl={url} />
      </Route>
      <Route path={`${path}/:employeeId`}>
        <ShowEmployee />
      </Route>
    </Switch>
  );
};
