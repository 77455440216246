/* eslint-disable react/prop-types */
import React from 'react';

/**
 * A list laid out inline with seperators.
 * e.g. one | two | three
 */
const ListInline = ({ items }) => {
  const sanitisedList = items
    .filter(i => !!i)
    .map((item, index, list) => ({
      content: item,
      key: index,
      isLast: index === list.length - 1,
    }));

  return (
    <ul>
      {sanitisedList.map(({ content, index, isLast }) => (
        <li key={index} className='inline-block'>
          {content}
          {!isLast && <span className='mx-1.5'>&#183;</span>}
        </li>
      ))}
    </ul>
  );
};

export default ListInline;
