import React, { FC, useEffect, useCallback, useReducer } from 'react';
import {
  Button,
  InputFile,
  LabelledInput,
  List,
  StaticAssetActions,
} from '@tg/core/components';
import { Document } from 'src/types';
import { useResource } from '@tg/core/hooks';
import { Modal } from 'semantic-ui-react';

interface Props {
  defaultValue: (File | Document)[];
  getEmployeeId: number;
  getSubId: number;
  fileError: number;
  handleCheck: () => void;
  onChange: (files: (File | Document)[]) => void;
  disabled?: boolean;
}
const validExtensions = [
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'txt',
  'jpg',
  'jpeg',
  'png',
  'gif',
  'csv',
  'ppt',
  'pptx',
];
export const filesReducer = (
  state: any,
  action: {
    type: 'ADD' | 'REMOVE';
    payload?: File | Document;
    index?: number;
  },
) => {
  if (action.type === 'ADD') {
    return [...state, action.payload];
  }
  if (action.type === 'REMOVE') {
    const newFileList = [];
    for (let x = 0; x < state.length; x += 1) {
      if (!state[x].receipt_id) {
        newFileList.push(state[x]);
      }
      for (let y = 0; y < action.payload.length; y += 1) {
        if (state[x].receipt_id === action.payload[y].receipt_id) {
          newFileList.push(state[x]);
        }
      }
    }
    return newFileList;
  }
  if (action.type === 'REMOVE_INVALID') {
    const newFileList = state?.filter((file: File | Document) => {
      const extension = file?.path?.split('.')?.pop()?.toLowerCase();
      return validExtensions.includes(extension);
    });
    const filesWithFilename =
      state?.filter(file => file?.hasOwnProperty('filename')) ?? [];

    const allFormatFile = [...filesWithFilename, ...newFileList];
    return allFormatFile;
  }
  return state;
};

const FilePicker: FC<Props> = ({
  defaultValue,
  getEmployeeId,
  getSubId,
  handleCheck,
  onChange,
  fileError,
  disabled = false,
}) => {
  const [localFiles, dispatchFiles] = useReducer(filesReducer, defaultValue);

  useEffect(() => {
    const invalidFiles =
      localFiles?.filter(file => {
        const extension = file?.path?.split('.')?.pop()?.toLowerCase();
        return !validExtensions.includes(extension);
      }) ?? [];

    const filesWithFilename =
      localFiles?.filter(file => file?.hasOwnProperty('filename')) ?? [];

    const allFormatFile = [...filesWithFilename, ...invalidFiles];
    if (allFormatFile?.length > 0 && fileError > 0) {
      dispatchFiles({ type: 'REMOVE_INVALID' });
    }
  }, [localFiles, fileError]);

  useEffect(() => {
    if (onChange) {
      onChange(localFiles);
    }
  }, [localFiles, onChange]);

  const onDrop = useCallback(dropped => {
    dropped.forEach(f => {
      dispatchFiles({ type: 'ADD', payload: f });
    });
  }, []);

  const onDelete = useCallback(res => {
    dispatchFiles({ type: 'REMOVE', payload: res.data.receipts });
  }, []);

  const { deleteResource } = useResource({
    url: `payroll_adjustments/submissions/${getSubId}/sheets/${getEmployeeId}/receipt`,
  });

  const handleSubmit = (event, data) => {
    if (event.target.textContent === 'Yes') {
      deleteResource({
        id: data,
        onSuccess: res => {
          onDelete(res);
          if (localFiles.length === 1 && handleCheck) {
            handleCheck();
          }
        },
      });
    }
  };

  return (
    <>
      {!disabled && (
        <LabelledInput label='Files' id='file-input'>
          <InputFile multiple onDrop={onDrop} />
        </LabelledInput>
      )}
      {localFiles && !!localFiles.length && (
        <List
          items={localFiles.map(({ url, filename, name, receipt_id }) => ({
            id: url,
            columns: [
              filename || name,
              <>
                {receipt_id ? (
                  <div style={{ display: 'flex', float: 'right' }}>
                    <Modal
                      trigger={<Button color='quaternary'>Delete</Button>}
                      header='Warning!'
                      onActionClick={event => handleSubmit(event, receipt_id)}
                      content='Are you sure you want to delete file?'
                      size='small'
                      actions={[
                        { key: 'No', content: 'No', negative: true },
                        { key: 'Yes', content: 'Yes', positive: true },
                      ]}
                    />
                    <StaticAssetActions url={url} />
                  </div>
                ) : (
                  ''
                )}
              </>,
            ],
          }))}
        />
      )}
    </>
  );
};

export default FilePicker;
