/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useResource } from '@tg/core/hooks';
import { Form, ButtonBar, Button } from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actions } from '@tg/core/store/modules/toasts';

const CodatEmailReceipients = ({ employerId }) => {
  const { t } = useTranslation('codat_receipients');
  const [validationError, setValidationError] = useState('');
  const { data, isFetching, patchResource } = useResource(
    {
      url: `employers/${employerId}`,
    },
    true,
  );
  const dispatch = useDispatch();

  const { handleSubmit, getFieldProps } = Form.useForm();

  const validateEmail = email => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      return false;
    }

    if (email.includes('..')) {
      return false;
    }

    const [, domain] = email.split('@');
    if (domain.startsWith('-')) {
      return false;
    }

    return true;
  };

  const onSubmit = formData => {
    const {
      employer: { codat_invoice_recipients },
    } = formData;

    const emails = codat_invoice_recipients
      .split(',')
      .map(email => email.trim());
    if (emails.every(validateEmail) || !codat_invoice_recipients) {
      patchResource({
        formData,
        onSuccess: newData => {
          dispatch(
            actions.addToast({
              id: newData.id,
              type: 'success',
              children: 'Saved',
            }),
          );
        },
      });
    } else {
      setValidationError(
        'Invalid email format. Please use comma-separated valid emails.',
      );
    }
  };

  const { codat_invoice_recipients } = data || {};

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className='p-4 -mt-6'>
          <div className='mb-4'>
            <p>{t('description')}</p>
          </div>
          <Form.TextField
            {...getFieldProps({
              id: 'employer',
              name: 'codat_invoice_recipients',
            })}
            defaultValue={codat_invoice_recipients}
          />
          {validationError && (
            <div style={{ color: 'red' }}>{validationError}</div>
          )}
        </div>
        <ButtonBar>
          <Button type='submit' loading={isFetching}>
            {t('save')}
          </Button>
        </ButtonBar>
      </Form>
    </>
  );
};

export default CodatEmailReceipients;
