/* eslint-disable react/prop-types */
import React, { useState, useReducer } from 'react';
import { Button, LabelledInput, List } from '@tg/core/components';
import classNames from 'classnames';
import { capitalise } from '@tg/core/utils/stringHelpers';
// import { DevTool } from '@hookform/devtools';

const EditableList = ({ items, onChange }) => {
  //   {
  //   "code":"",
  //   "name": "",
  //   "description": "",
  //   "validator": ""
  // }

  const fields = ['code', 'name', 'description', 'validator'];

  const [showNewRow, setShowNewRow] = useState(false);

  const initialState = fields.reduce((acc, key) => {
    acc[key] = '';
    return acc;
  }, {});
  const reducer = (state, { type, payload }) => {
    if (type === 'RESET') {
      return initialState;
    }
    return {
      ...state,
      [type]: payload,
    };
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const emitNewRow = () => {
    const newRow = state;
    const allRows = [...items];
    allRows.push(newRow);
    onChange(allRows);
    dispatch({ type: 'RESET' });
    setShowNewRow(false);
  };

  const removeRow = rowId => {
    const newRows = [...items].filter(({ code }) => code !== rowId);
    onChange(newRows);
  };

  const headings = [
    ...fields.map(key => ({ text: capitalise(key) })),
    { align: 'right' },
  ];
  const listItems = items.map(item => ({
    id: item.code,
    columns: [
      ...fields.map(key => item[key]),
      <Button
        size='small'
        color='tertiary'
        onClick={() => removeRow(item.code)}
      >
        Remove
      </Button>,
    ],
  }));

  return (
    <>
      <List noShadow headings={headings} items={listItems} />

      <>
        <div
          className={classNames('flex justify-around items-end mt-2', {
            hidden: !showNewRow,
          })}
        >
          {fields.map(field => (
            <div key={field}>
              {/* <Form.TextField {...getFieldProps({ name: field, id: 'e' })} /> */}
              <LabelledInput label={field} id={field}>
                <input
                  value={state[field]}
                  onChange={e => {
                    dispatch({ type: field, payload: e.target.value });
                  }}
                />
              </LabelledInput>
            </div>
          ))}
          <div>
            <Button size='small' color='primary' onClick={() => emitNewRow()}>
              ADD
            </Button>
          </div>
        </div>
      </>
      {!showNewRow && (
        <div className='mt-2'>
          <button
            onClick={() => setShowNewRow(true)}
            type='button'
            className='link'
          >
            Add Another
          </button>
        </div>
      )}
    </>
  );
};

export default EditableList;
