const permissions = {
  MAIN_NAV_MENU_EMPLOYERS: 'Main Nav menu - Employers',
  MAIN_NAV_MENU_EMPLOYEES: 'Main Nav menu - Employees',
  MAIN_NAV_MENU_PAYROLL: 'Main Nav menu - Payroll',
  MAIN_NAV_MENU_MANAGE: 'Main Nav menu - Manage',
  MAIN_NAV_MENU_ADMINISTRATORS: 'Main Nav menu - Administrators',
  MAIN_NAV_MENU_REPORTS: 'Main Nav menu - Reports',
  EMPLOYERS_LIST_VIEW: 'Employers - List view',
  EMPLOYERS_DETAIL_VIEW: 'Employer detail view',
  EMPLOYERS_ADD_EMPLOYER: 'Employers - Add Employer',
  EMPLOYER_DETAIL_VIEW_OVERVIEW: {
    MENU_OPTION: 'Employer detail view: Overview (Menu option)',
    SETUP_CHECKLIST: 'Employer detail view: Overview: Setup Checklist',
    SET_UP_PLANNER_YEAR: 'Employer Set-up Planner year',
    CODAT_XERO_CONNECTION: 'Employer codat xero',
    CODAT_EMAIL_RECEPIENTS:
      'Employer detail view: Invoices, Codat email recipients',
    SEND_TO_CODAT:
      'Employer detail view: Invoices Invoice History - Send to Codat option',
    CODAT_QBO: 'Employer codat QBO',
  },
  EMPLOYER_DETAIL_VIEW_OFFICERS: {
    MENU_OPTION: 'Employer detail view: Officers (Menu Option)',
    LIST_VIEW: 'Employer detail view: Officers list view',
    ADD_NEW: 'Employer detail view: Officers, Add new',
    EDIT: 'Employer detail view: Officers, Edit',
  },
  EMPLOYER_DETAIL_VIEW_EMPLOYEES: {
    MENU_OPTION: 'Employer detail view: Employees (Menu option)',
    LIST_VIEW: 'Employer detail view: Employees (List View)',
    VIEW_CONTRACT_LINK: 'Employer detail view: Employees: View Contract Link',
  },
  EMPLOYER_DETAIL_VIEW_EMPLOYEES_CONTRACT: {
    OVERVIEW: {
      CONTRACT_OVERVIEW: 'Employer detail view: Employees: Contract Overview',
      SETUP_CHECKLIST:
        'Employer detail view: Employees: Contract Overview: Setup Checklist',
      CONTRACT_OVERVIEW_SECTION:
        'Employer detail view: Employees: Contract Overview: Contract Overview section',
      LINE_MANAGER_VIEW:
        'Employer detail view: Employees: Contract Overview: Line Manager (View)',
      LINE_MANAGER_EDIT:
        'Employer detail view: Employees: Contract Overview: Line Manager  (Edit)',
      CONTRACT_JOB_TITLES_VIEW:
        'Employer detail view: Employees: Contract Overview: Contract Job titles  (View)',
      JOB_TITLES_EDIT:
        'Employer detail view: Employees: Contract Overview: Contract Job titles  (Edit)',
      JOB_TITLES_CREATE_NEW:
        'Employer detail view: Employees: Contract Overview: Contract Job titles  (Create new)',
      CONTRACT_DOCUMENTS_VIEW:
        'Employer detail view: Employees: Contract Overview: Contract Documents (View)',
      CONTRACT_DOCUMENTS_UPLOAD:
        'Employer detail view: Employees: Contract Overview: Contract Documents (Upload)',
      SALARY_HISTORY_VIEW:
        'Employer detail view: Employees: Contract Overview: Salary History  (View)',
      SALARY_HISTORY_EDIT:
        'Employer detail view: Employees: Contract Overview: Salary History   (Edit)',
      SALARY_HISTORY_CREATE_NEW:
        'Employer detail view: Employees: Contract Overview: Salary History (Create new)',
      TERMINATE_CONTRACT:
        'Employer detail view: Employees: Contract Overview: Terminate Contract',
    },
  },
  EMPLOYER_DETAIL_VIEW_EMPLOYEE: {
    VIEW_EMPLOYEE_LINK: 'Employer detail view: Employees: View Employee Link',
    EMPLOYEE_DETAIL_VIEW: {
      SET_UP_CHECK_LIST:
        'Employer detail view: Employees: Employee detail view, Set up Check list',
      VIEW_OPTIONAL_ACTIONS:
        'Employer detail view: Employees: Employee detail view, Optional actions',
      VIEW_PERSONAL_DETAILS:
        'Employer detail view: Employees: Employee detail view, Personal details',
      VIEW_PERSONAL_DETAILS_EDIT_SAVE_CHANGES:
        'Employer detail view: Employees: Employee detail view, Personal details (Edit/Save Changes)',
      VIEW_HOME_ADDRESS:
        'Employer detail view: Employees: Employee detail view, Home Address',
      VIEW_HOME_ADDRESS_EDIT_SAVE_CHANGES:
        'Employer detail view: Employees: Employee detail view, Home Address (Edit/Save Changes)',
      VIEW_BANK_DETAILS:
        'Employer detail view: Employees: Employee detail view, Bank details',
      VIEW_EMERGENCY_CONTACT:
        'Employer detail view: Employees: Employee detail view, Emergency Contact',
      VIEW_IDENTITY_AND_COMPLIANCE_DOCUMENTS_DRAG_DROP:
        'Employer detail view: Employees: Employee detail view, Identity and Compliance Documents (drag & Drop)',
      VIEW_CONTRACTS:
        'Employer detail view: Employees: Employee detail view, Contracts',
      VIEW_CONTRACTS_VIEW_DETAILS:
        'Employer detail view: Employees: Employee detail view, Contracts: View details',
    },
    PAYROLL_MENU_OPTION:
      'Employer detail view: Employees: Payroll (menu option)',
    BENEFITS_VIEW: 'Employer detail view: Employees: Benefits (View)',
    BENEFITS_REMOVE: 'Employer detail view: Employees: Benefits (Remove)',
    BENEFITS_ADD: 'Employer detail view: Employees: Benefits (Add)',
    TIME_OFF_VIEW: 'Employer detail view: Employees: Time Off (View)',
    TIME_OFF_CREATE_NEW:
      'Employer detail view: Employees: Time Off (Create New)',
    TIME_OFF_PLANNED_YEARS_REPORT_EDIT:
      'Employer detail view: Employees: Time Off, Planned Years (Report, Edit)',
    CONTRACT_OVERVIEW: {
      VIEW_EMPLOYEE_LINK:
        'Employer detail view: Employees: Contract Overview (View Employee Link)',
      CONTRACT_JOB_TITLES_VERIFY:
        'Employer detail view: Employees: Contract Overview: Contract Job titles (Verify)',
      SALARY_HISTORY_VERIFY:
        'Employer detail view: Employees: Contract Overview: Salary History (Verify)',
    },
    VIEW_EMPLOYEE: 'Employer detail view: Employees: View Employee',
  },
  EMPLOYER_DETAIL_VIEW_EMPLOYEES_EMPLOYEE: {
    DETAIL_VIEW: {
      DETAILS_BELL_ICON_CLICK:
        'Employer detail view: Employees: Employee detail view, Personal details (Bell icon click option for missing information )',
      HOME_ADDRESS_BELL_ICON:
        'Employer detail view: Employees: Employee detail view, Home Address(Bell icon click option for missing information )',
      BANK_DETAILS_BELL_ICON:
        'Employer detail view: Employees: Employee detail view, Bank details(Bell icon click option for missing information )',
      EMERGENCY_CONTACT_BELL_ICON:
        'Employer detail view: Employees: Employee detail view, Emergency Contact(Bell icon click option for missing information )',
    },
  },
  EMPLOYER_DETAIL_VIEW: {
    INVOICES: {
      INVOICES_MENU_OPTION: 'Employer detail view: Invoices (menu option)',
      CREATE_AN_INVOICE_FROM_EXCEL_UPLOAD:
        'Employer detail view: Invoices, Create an invoice from Excel (Upload)',
      UPLOAD_AN_EXISTING_INVOICE_UPLOAD:
        'Employer detail view: Invoices, Upload an existing invoice (Upload)',
      INVOICE_EMAIL_RECIPIENTS:
        'Employer detail view: Invoices, Invoice email recipients',
      INVOICE_HISTORY_VIEW:
        'Employer detail view: Invoices Invoice History - View',
      INVOICE_HISTORY_STATUS_CHANGES:
        'Employer detail view: Invoices Invoice History - Status changes',
      INVOICE_HISTORY_EMAIL:
        'Employer detail view: Invoices Invoice History - Email',
      INVOICE_HISTORY_DELETE:
        'Employer detail view: Invoices Invoice History - Delete',
    },
    DOCUMENTS: {
      DOCUMENTS_MENU_OPTION: 'Employer detail view: Documents (Menu Option)',
      DRAG_AND_DROP_CONTRACT_DOCUMENTS:
        'Employer detail view: Documents (Drag and Drop contract documents)',
      LIST_OF_DOCUMENTS_UPLOADED:
        'Employer detail view: Documents List of Documents uploaded',
      LIST_OF_DOCUMENTS_DELETE:
        'Employer detail view: Documents List of Documents: Delete',
    },
    PAYROLL: {
      PAYROLL_MENU_OPTION: 'Employer detail view: Payroll (Menu Option)',
      PAYROLL_LIST_VIEW: 'Employer detail view: Payroll: Payroll List view',
      EDIT_EMAIL_RECIPIENTS:
        'Employer detail view: Payroll: Edit Email Recipients',
      DETAILED_MONTH_YEAR_VIEW:
        'Employer detail view: Payroll (detailed month/Year View)',
      DETAILED_MONTH_YEAR_VIEW_EDIT:
        'Employer detail view: Payroll (detailed month/Year View): Edit',
      DETAILED_MONTH_YEAR_VIEW_SUBMIT:
        'Employer detail view: Payroll (detailed month/Year View): Submit',
    },
  },
  EMPLOYEE_LIST_VIEW: 'Employee List view',
  EMPLOYEE_SEARCH: 'Employee: Search',
  EMPLOYEE_DETAIL: {
    CLICK_ON_LABEL: 'Employee Detail view (ability to click on the label)',
    SETUP_CHECK_LIST: 'Employee detail view, Set up Check list',
    OPTIONAL_ACTIONS: 'Employee detail view, Optional actions',
    PERSONAL_DETAILS: 'Employee detail view, Personal details',
    PERSONAL_DETAILS_EDIT_SAVE_CHANGES:
      'Employee detail view, Personal details (Edit/Save Changes)',
    HOME_ADDRESS: 'Employee detail view, Home Address',
    HOME_ADDRESS_EDIT_SAVE_CHANGES:
      'Employee detail view, Home Address (Edit/Save Changes)',
    BANK_DETAILS: 'Employee detail view, Bank details',
    EMERGENCY_CONTACT: 'Employee detail view, Emergency Contact',
    IDENTITY_AND_COMPLIANCE_DOCUMENTS_DRAG_DROP:
      'Employee detail view, Identity and Compliance Documents (drag & Drop)',
    CONTRACTS: 'Employee detail view, Contracts',
    VIEW_DETAILS: 'Employee detail view, Contracts: View details',
  },
  PAYROLL: {
    MANAGE_PAYROLL: 'Payroll: Manage Payroll',
    CREATE_PAYROLL: 'Payroll: Create Payroll',
    CHECK_STATUS: 'Payroll: Check Status',
    GENERATE_REPORT: 'Payroll: Generate Report',
  },
  MANAGE: {
    DOMICILES: 'Manage: Domiciles',
    CURRENCIES: 'Manage: Currencies',
    BENEFITS: 'Manage: Benefits',
    ATTENDANCE_TYPES: 'Manage: Attendance Types',
    CONTRACT_TYPES: 'Manage: Contract Types',
    INDUSTRY_SECTORS: 'Manage: Industry Sectors',
    PAYROLL_DOCUMENT_TYPES: 'Manage: Payroll Document Types',
    INVOICE_TYPES: 'Manage: Invoice Types',
    LEAVE_TYPES: 'Manage: Leave Types',
  },
  ADMINISTRATORS: {
    VIEW_ADMINISTRATORS_LIST: 'Administrators: View Administrators (list)',
    ADD_ADMINISTRATORS: 'Administrators: Add Administrators',
    EDIT_ADMINISTRATORS: 'Administrators: Edit Administrators',
  },
  REPORT: {
    PLANNER_REPORT: 'Report: Planner Report',
    ALL_EMPLOYEES_REPORT: 'Report: All employees report',
    REPORT_OVERVIEW: 'Report: Overview',
    SALARY_INCREASE_REPORT: 'Report: Salary Increase Report',
    ACTIVITY_LOG: 'Report: activity log',
    OFFICER_REPORT: 'Officer Report',
    TIME_OFF_TAKEN: 'Report: time off taken',
  },
};

export default permissions;
