/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useResource } from '@tg/core/hooks';
import { getActiveStorageUrl } from '@tg/core/utils/urlHelpers';
import {
  FileField,
  SelectField,
  Button,
  List,
  PaginationControl,
} from '@tg/core/components';
import PublishDocument from '../../PublishDocument';
import DeleteDocument from '../../DeleteDocument';
import ErrorModal from '@tg/core/components/molecules/ErrorModal';

const ContractPayslips = () => {
  const { control, handleSubmit, register, errors: fieldErrors } = useForm();
  const [files, setFiles] = useState([]);
  const { contractId } = useParams();
  const [page, setPage] = useState(1);
  const [isValidFormat, setIsValidFormat] = useState({
    message: null,
    isValid: true,
  });

  const {
    data,
    isFetching,
    pagination,
    postResource,
    deleteResource,
  } = useResource(
    { url: `contracts/${contractId}/payslips?page=${page}&pagesize=10` },
    true,
  );

  const { t } = useTranslation(['forms', 'datetime']);

  const onSubmit = values => {
    if (!files.length) {
      return console.log('invalid form');
    }

    const formData = new FormData();
    formData.append('month', values.month);
    formData.append('year', values.year);
    formData.append('file', files[0]);

    return postResource({
      formData,
      headers: {},
      onError: err => {
        if (err?.response?.error?.includes('invalid file type')) {
          setIsValidFormat({ message: err?.response?.error, isValid: false });
        }
      },
    });
  };

  const onDrop = useCallback(dropped => {
    setFiles(dropped);
  }, []);

  const now = new Date();
  const defaultValues = {
    month: now.getMonth(),
    year: now.getFullYear(),
  };

  const onDeleteSuccess = () => {
    // Edge Case Scenario
    if (page > 1 && data.length % 10 === 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      {/* <Heading level='h3'>Payslips</Heading> */}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-4'>
          <FileField onDrop={onDrop} />
        </div>

        <Form.Group widths={2}>
          <Form.Field required error={!!fieldErrors.month}>
            <label>Month</label>
            <Controller
              as={
                <SelectField
                  options={Array.from(new Array(12)).map((_, index) => ({
                    text: t(`datetime:monthnames.${index}`),
                    value: index,
                  }))}
                />
              }
              control={control}
              name='month'
              defaultValue={defaultValues.month}
              rules={{ required: t('errors.required') }}
            />
            {fieldErrors.month && <p>{fieldErrors.month.message}</p>}
          </Form.Field>

          <Form.Field required error={!!fieldErrors.year}>
            <label>Year</label>
            <input
              name='year'
              type='number'
              ref={register({ required: t('errors.required') })}
              defaultValue={defaultValues.year}
            />
            {fieldErrors.year && <p>{fieldErrors.year.message}</p>}
          </Form.Field>
        </Form.Group>

        {/* Selected files, to be uploaded */}
        <ul className='mb-2'>
          {files.map(f => (
            <li key={f.name}>{f.name}</li>
          ))}
        </ul>

        <Button type='submit'>{t('buttons.upload')}</Button>
      </Form>

      <div className='mt-4'>
        {data && !!data.length && (
          <List
            headings={[{ text: 'Month' }, { text: '', align: 'right' }]}
            items={data.map(({ id, document, visible, month, year }) => ({
              id,
              columns: [
                <a href={`${getActiveStorageUrl(document.url)}`}>{`${t(
                  `datetime:monthnames.${month}`,
                )} ${year}`}</a>,
                <div className='space-x-2'>
                  <PublishDocument
                    type='payslips'
                    owner={contractId}
                    visible={visible}
                    id={id}
                  />
                  <DeleteDocument
                    onClick={() => {
                      deleteResource({ id, onSuccess: onDeleteSuccess });
                    }}
                  />
                </div>,
              ],
            }))}
          />
        )}
        {data && !data.length && !isFetching && 'No Payslips Uploaded'}
        {pagination && (
          <div>
            <PaginationControl {...pagination} onPageChange={setPage} />
          </div>
        )}
      </div>
      <ErrorModal
        size='tiny'
        open={!isValidFormat?.isValid}
        header='Error'
        content={isValidFormat?.message}
        actions={['OK']}
        onClose={() => setIsValidFormat({ message: null, isValid: true })}
      />
    </>
  );
};

export default ContractPayslips;
