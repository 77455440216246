import React from 'react';
import { Modal } from 'semantic-ui-react';

const ErrorModal = ({ size, open, header, content, actions, onClose }) => {
  return (
    <Modal
      size={size}
      open={open}
      header={header}
      content={content}
      actions={actions}
      onClose={onClose}
    />
  );
};

export default ErrorModal;
