export const month_names = {
  'en-GB': [
    { abbr: 'jan', full: 'January' },
    { abbr: 'feb', full: 'February' },
    { abbr: 'mar', full: 'March' },
    { abbr: 'apr', full: 'April' },
    { abbr: 'may', full: 'May' },
    { abbr: 'jun', full: 'June' },
    { abbr: 'jul', full: 'July' },
    { abbr: 'aug', full: 'August' },
    { abbr: 'sep', full: 'September' },
    { abbr: 'oct', full: 'October' },
    { abbr: 'nov', full: 'November' },
    { abbr: 'dec', full: 'December' },
  ],
};
