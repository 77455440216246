/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import Step1 from './Step1';
import Step2 from './Step2';

const initialState = {
  step: 1,
  data: {
    public_holidays: [],
  },
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 1:
      return {
        ...state,
        step: 2,
        data: {
          ...state.data,
          ...payload,
        },
      };

    case 2:
      return initialState;

    default:
      return state;
  }
};

const getPublicHolidayRegion = contract => {
  const {
    entitlement_public_holidays_employer_domicile,
    employer,
    employee,
  } = contract;
  return entitlement_public_holidays_employer_domicile
    ? { country: employer.domicile.code.toLowerCase(), state: '' }
    : { country: employee.domicile.code.toLowerCase(), state: '' };
};

const Component = ({ contract, onSubmit, onCancel, event_types, data }) => {
  const getDefaultValues = data();

  const getPublicHoliday = getDefaultValues?.planneryear_leaves?.find(
    item => item?.leave_type === 'public-holidays',
  );

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    data: {
      public_holidays: getPublicHoliday?.leave_type_value || [],
    },
  });

  return state.step === 1 ? (
    <Step1
      data={state.data}
      onSubmit={formData => dispatch({ type: 1, payload: formData })}
      onCancel={onCancel}
      event_types={event_types}
      getEditableData={data}
    />
  ) : (
    <Step2
      data={state.data}
      onSubmit={formData => {
        dispatch({ type: 2 });
        onSubmit({ ...state.data, public_holidays: formData });
      }}
      onCancel={onCancel}
      publicHolidayRegion={getPublicHolidayRegion(contract)}
      contractId={contract.id}
    />
  );
};

Component.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.func,
  contract: PropTypes.shape().isRequired,
};

Component.defaultProps = {
  data: () => initialState.data,
};

export default Component;
