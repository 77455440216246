import React from 'react';
import { Container, PageHeader } from '@tg/core/components';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import { useSelector } from 'react-redux';
import SideNavLayout from '../../layouts/SideNav/SideNav';
import Domiciles from './pages/Domiciles/Domiciles';
import Currencies from './pages/Currencies';
import Benefits from './pages/Benefits';
import AttendanceTypes from './pages/AttendanceTypes';
import ContractTypes from './pages/ContractTypes';
import IndustrySectors from './pages/IndustrySectors';
import LeaveTypes from './pages/LeaveType';
import XeroConnection from './pages/XeroConnections';
import ExchangeRate from './pages/ExchangeRate';

const {
  MANAGE: { LEAVE_TYPES },
  EMPLOYER_DETAIL_VIEW_OVERVIEW: { CODAT_XERO_CONNECTION },
} = permissions;

const Manage = () => {
  const accesslist = useSelector(state => state?.access?.roles);

  const roleId = useSelector(state => state?.access?.rolesId);

  return (
    <Container>
      <PageHeader
        heading='Manage'
        description='Manage entities that are used by all users in the Teamed app.'
      />

      <SideNavLayout
        pages={[
          {
            title: 'Domiciles',
            to: ``,
            component: () => <Domiciles />,
            icon: 'world',
          },
          ...(+roleId === 26 || +roleId === 6
            ? [
                {
                  title: 'Exchange Rate Adjustment',
                  to: `/exchangerate`,
                  component: () => <ExchangeRate />,
                  icon: 'exchange',
                },
              ]
            : []),
          {
            title: 'Currencies',
            to: `/currencies`,
            component: () => <Currencies />,
            icon: 'money',
          },
          {
            title: 'Benefits',
            to: `/benefits`,
            component: () => <Benefits />,
            icon: 'leaf',
          },
          {
            title: 'Attendance Types',
            to: `/attendance_types`,
            component: () => <AttendanceTypes />,
            icon: 'clock outline',
          },
          {
            title: 'Contract Types',
            to: `/contract_types`,
            component: () => <ContractTypes />,
            icon: 'file alternate outline',
          },
          {
            title: 'Industry Sectors',
            to: `/industry_sectors`,
            component: () => <IndustrySectors />,
            icon: 'industry',
          },
          ...(getPermission(accesslist, LEAVE_TYPES)
            ? [
                {
                  title: 'Leave Types',
                  to: `/leave_types`,
                  component: () => <LeaveTypes />,
                  icon: 'list alternate outline',
                },
              ]
            : []),
          ...(getPermission(accesslist, CODAT_XERO_CONNECTION)
            ? [
                {
                  title: 'Xero Connection',
                  to: `/xero_connections`,
                  component: () => <XeroConnection />,
                  icon: 'exchange',
                },
              ]
            : []),
        ]}
      />
    </Container>
  );
};

export default Manage;
