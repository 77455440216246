import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const fontStyles = {
  h1: `72px`,
  h2: `38px`,
  h3: `26px`,
  h4: `20px`,
  h5: `16px`,
  h6: `12px`,
};

const Heading = ({ level, subLevel, centered, children, primary, zeropad }) => {
  return (
    <div
      css={css`
        ${!zeropad && 'margin-bottom: 1rem;'}
        ${centered && 'text-align: center;'}
        ${primary && 'color: #f63855;'}
        font-size: ${fontStyles[level]};
        font-weight: 700;
        line-height: 1;
        > span {
          display: block;
          &:nth-of-type(even) {
            margin-top: 1rem;
            font-size: ${fontStyles[subLevel]};
          }
        }
      `}
    >
      {children}
    </div>
  );
};

Heading.propTypes = {
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
  subLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
  centered: PropTypes.bool,
  children: PropTypes.node,
  primary: PropTypes.bool,
  zeropad: PropTypes.bool,
};
Heading.defaultProps = {
  level: 'h1',
  subLevel: null,
  centered: false,
  children: '',
  primary: false,
  zeropad: false,
};

export default Heading;
