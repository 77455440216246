/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import classNames from 'classnames';

interface Props {
  element?: 'footer' | 'div';
  type?: 'border' | 'filled';
}

const ButtonBar: FC<Props> = ({
  children,
  element = 'footer',
  type = 'filled',
}) => {
  const Element = element;
  return (
    <Element
      className={classNames('p-2.5 flex justify-end', {
        'bg-gray-100 rounded-bl rounded-br': type === 'filled',
        'border-t pt-8': type === 'border',
      })}
    >
      {children}
    </Element>
  );
};

export default ButtonBar;
